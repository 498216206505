import React from 'react'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../../dashboard.module.scss'
export default function Hardware() {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', marginTop: 20}}>
        <div style={{width: '547px', height: '186px', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p style={{color: 'white', fontSize: 18, fontWeight: '600'}}>A3251F32C4B8CB4QPFB5682A301992E3</p>
                    </div>
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                        <p  style={{color: 'white', fontSize: 14}}>IP</p>
                        <p style={{color: '#afafaf', fontSize: 14}}>41.90.18.0</p>
                    </div>
                    <div style={{display: 'flex', gap: 20, alignItems: 'center'}}>
                        <p  style={{color: 'white', fontSize: 14}}>Location</p>
                        <p style={{color: '#afafaf', fontSize: 14}}>41.90.18.0</p>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                        <p style={{color: '#afafaf', fontSize: 12}}>Scranton, PA - USA (EST)</p>
                    </div>
                </div>
                <div style={{width: '547px', height: '186px', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                        <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                    </div>
                    <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                    <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                        <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                    </div>
                </div>
                
    </div>
    )
}
