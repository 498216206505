import { Theme } from '@emotion/react';
import { Button, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import styles from './auth.module.scss';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../core/reducers/authReducer';
import { setNotificationAction, updatePasswordRecoverRequest } from '../../core/actions/authActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorNotification from '../Account/common/ErrorNotification';
import logoSignSVG from '../../icons/Login-Illustration.svg';
import logo from '../../icons/Defendis_Logo_White.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidPassword: {
      borderBottom: '1px solid red !important',
    },
    validPassword: {
      borderBottom: '1px solid green !important',
    },
  })
);

function FirstTimeSetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);

  const classes = useStyles();

  const [password, setPassword] = useState<string>('');
  const [rePassword, setRePassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorRetrieveToken, setErrorRetrieveToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // State to track loading

  const useQuery = () => {
    return new URLSearchParams(useLocation().hash.substring(1)); // Access hash part of the URL
  };

  const query = useQuery();

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setPassword(value);
    setError(value !== rePassword);
  };

  const handleChangeRePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setRePassword(value);
    setError(password !== value);
  };

  const submit = () => {
    if (!error && accessToken) {
      
        setLoading(true); // Start loading state
      dispatch(updatePasswordRecoverRequest({
        jwt: accessToken,
        password
      }));
      setTimeout(() => {
        
        dispatch(setNotificationAction({
          success: true,
          error: '',
          errorCode: null
        }))
        setLoading(false);
        setOpenNotification(false)
      }, 3500);
    }
  };

  useEffect(() => {
    document.title = 'Login - New Password';
    const token = query.get('access_token');
    const errorMessage = query.get('error');
    const errorCode = query.get('error_code');
    const errorDescription = query.get('error_description');
    const typeEmail = query.get('type');

    if (errorMessage) {
      setErrorRetrieveToken(`Error ${errorCode}: ${decodeURIComponent(errorDescription || 'Unknown error')}`);
    } else if (token) {
      setAccessToken(token);
    } else {
      setErrorRetrieveToken('No access token found or the link might have expired.');
    }

    if (loading && (auth.passwordIsUpdated || auth.error !== '')) {
      // Handle the case where password update is successful or has errors
      setOpenNotification(true);
      setLoading(false);
      if (auth.passwordIsUpdated) {
        setTimeout(() => {
          if (typeEmail === 'recovery') {
            navigate('/');
          } else {
            navigate('/userInvited');
          }
        }, 1500);
      }
    }
  }, [query, auth.error, auth.passwordIsUpdated]);

  const handleCloseNotification = () => {
    setOpenNotification(false);
  };

  return (
    <div className={styles.main}>
      <form onSubmit={e => e.preventDefault()}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0 }}>
          <div className={styles.login}>
            <div style={{ width: '70%' }}>
              <div className={styles.loginInputs}>
                <p className={styles.titleLogin}>New Password</p>
                <p style={{ fontSize: 12 }} className={styles.lightText}>Choose a long and unique password for ultimate account protection:</p>
                <div className={styles.primaryEmailPersonalInfo}>
                  <TextField
                    type='password'
                    onChange={handleChangePassword}
                    className={password !== '' && rePassword !== '' ? error ? classes.unvalidPassword : classes.validPassword : undefined}
                    id="standard-basic"
                    label="Password"
                    variant="standard"
                  />
                  {password !== '' && rePassword !== '' && (
                    <FontAwesomeIcon style={{ marginTop: 36, marginLeft: -20 }} icon={error ? faCircleXmark : faCircleCheck} color={error ? 'red' : 'green'} />
                  )}
                </div>
                <div className={styles.primaryEmailPersonalInfo}>
                  <TextField
                    type='password'
                    onChange={handleChangeRePassword}
                    className={password !== '' && rePassword !== '' ? error ? classes.unvalidPassword : classes.validPassword : undefined}
                    id="standard-basic"
                    label="Re-type Password"
                    variant="standard"
                  />
                  {password !== '' && rePassword !== '' && (
                    <FontAwesomeIcon style={{ marginTop: 36, marginLeft: -20 }} icon={error ? faCircleXmark : faCircleCheck} color={error ? 'red' : 'green'} />
                  )}
                </div>
                <Button onClick={submit} type='submit' className={styles.btnLogin} disabled={loading}>
                  {loading ? 'Setting Password...' : 'Set Password'}
                </Button>
              </div>
            </div>
            <img src={logoSignSVG} className={styles.illus} style={{ marginTop: '20%' }} />
          </div>
        </div>
      </form>
      {
        openNotification && auth.error !== "" && auth.errorCode && <ErrorNotification
          severity={auth.errorCode !== 200 ? 'error' : 'success'}
          open={openNotification}
          message={auth.error}
          handleClose={handleCloseNotification}
        />
      }
    </div>
  );
}

export default FirstTimeSetPassword;
