import { faComment, faFolder, faHouse, faListCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectChangeEvent } from '@mui/material';
import React from 'react';

import logoPersonalAccount from "../../icons/Settings/personal.svg"
import logoSecurity from "../../icons/Settings/security.svg"
import logoEntreprise from "../../icons/Settings/entreprise.svg"
import logoUsers from "../../icons/Settings/users.svg"
import logoWatchList from "../../icons/Settings/assets.svg"
import logoNotifications from "../../icons/Settings/notifications.svg"
import logoSupport from "../../icons/Settings/support.svg"

export interface menuItem {
    id: number;
    title: string;
    icon: any;
    path: string;
}


export const menuItems: menuItem[] = [
    {
        id: 1,
        title: "Personal Account",
        icon: <img src={logoPersonalAccount} height={18} width={18}/>,
        path: '/settings'
    },
    {
        id: 2,
        title: "Security",
        icon: <img src={logoSecurity} height={18} width={18}/>,
        path: '/security'
    },
    {
        id: 3,
        title: "Workspace Settings",
        icon:  <img src={logoEntreprise} height={18} width={18}/>,
        path: '/entreprise-setting'
    },
    {
        id: 4,
        title: "Users",
        icon: <img src={logoUsers} height={18} width={18}/>,
        path: '/users'
    },
    {
        id: 5,
        title: "Watch List",
        icon: <img src={logoWatchList} height={18} width={18}/>,
        path: '/watch-list'
    },
    // {
    //     id: 6,
    //     title: "Notification Settings",
    //     icon: <img src={logoNotifications} height={18} width={18}/>,
    //     path: '/notification-setting'
    // },
    {
        id: 7,
        title: "Support",
        icon: <img src={logoSupport} height={18} width={18}/>,
        path: '/support'
    },
]

export interface PersonalInformation {
    firstName: string;
    lastName: string;
    email: string;
    phoneCode: string;
    phone: string;
    secondaryEmail: string;
}

export type typesHandleChange = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> |SelectChangeEvent