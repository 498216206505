import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import { Button, MenuItem, Select } from '@mui/material'
import { useIntercom } from '../IntercomSetup'
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@emotion/react'
import { GET_WATCH_LIST_DOMAIN, WatchListDomain } from '../../../core/constants'
import { useDispatch, useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { getWatchListDomainApi } from '../../../core/api/watchListApi'
import { saveTakeDownApi } from '../../../core/api/brandIntelApi'

const useStyles = makeStyles({
    option: {
    "&.Mui-focused ": {
    backgroundColor: " #1f1f1f !important",
    },
    },
    });
function DomainTakeDownRequest() {
    const navigate = useNavigate()
    const classes = useStyles();
    const dispatch = useDispatch() 
    const { watchListDomain } = useSelector(watchListSelector)
    const { dns, domaineDetail } = useSelector(brandIntelSelector)
    const [reason, setReason] = useState("copyrightInfringement")
    const [domaines, setDomaines] = useState<WatchListDomain[] | null>()
    useEffect(() => {
        getWatchListDomainApi()
            .then((values) => setDomaines(values))
    })

    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })

    const handleSubmit = () => {
        saveTakeDownApi({
            domaine: domaineDetail ? domaineDetail.domain : "",
            status: "Waiting",
            reason,
            
        })
    }
  
    return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div onClick={()=>navigate("/brandIntel/domainDetail")} style={{display: "flex",alignItems: "center", gap: 10, cursor: "pointer"}}>
            <FontAwesomeIcon color='white' icon={faArrowLeft} height={11} width={12}/>
            <p style={{fontSize:14, color: "white", textAlign: "start"}}>Go back</p>
        </div>
        <hr className={styles.divider}/>
        <div style={{display: 'flex', justifyContent: "center", marginTop: 100}}>
            <div >
                <p style={{color: "white", fontSize: 20, fontWeight: "600",textAlign: "center"}}>Domain Takedown Request</p>
                <input style={{color: "#333333", height: 48, border:"none", backgroundColor:"#1f1f1f",width: '540px'}} disabled value={`${domaineDetail?.domain} (${dns?.answers.a})`}/>
                <div style={{marginTop: 15}}>
                <p style={{color: "white", fontSize: 12}}>Reason for takedown</p>
                <Select
                style={{width: 540}}
                            label='Reason for takedown'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={styles.selectPhoneNumberPersonalInfo}
                        >
                           {
                               domaines?.map((item: any) => 
                           <MenuItem value={item.domaine}>{item.domaine}</MenuItem>
                                )
                           }
                </Select>
                
                </div>
                <div style={{marginTop: 15}}>
                
                <p style={{color: "white", fontSize: 12}}>Reason for takedown</p>
                <Select
                style={{width: 540}}
                            value={reason}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className={`${styles.selectPhoneNumberPersonalInfo} ${classes.option}`}
                            sx={{
                                '& .MuiList-root': {  // Targeting the Listbox component
                                  backgroundColor: '#1f1f1f',  // Set your desired background color
                                }
                              }}
                            onChange={e=>setReason(e.target.value)}
                            defaultValue="copyrightInfringement"
                        >
                            <MenuItem value="copyrightInfringement">
                                <p style={{color: "black", textAlign: "start"}}>Copyright Infringement <span style={{color: '#afafaf'}}>(Infringes on your copyrighted material)</span></p>
                            </MenuItem>
                            <MenuItem value="trademarkInfringement">
                                <p style={{color: "black", textAlign: "start"}}>Trademark Infringement <span style={{color: '#afafaf'}}>(Domain name is similar to your trademark)</span></p>
                            </MenuItem>
                            <MenuItem value="defamation">
                                <p style={{color: "black", textAlign: "start"}}>Defamation <span style={{color: '#afafaf'}}>(False and damaging statements about your brand)</span></p>
                            </MenuItem>
                            <MenuItem value="privacyViolations">
                                <p style={{color: "black", textAlign: "start"}}>Privacy Violations <span style={{color: '#afafaf'}}>(Profiting from your trademark by misleading users)</span></p>
                            </MenuItem>
                            <MenuItem value="illegalActivities">
                                <p style={{color: "black", textAlign: "start"}}>Illegal Activities <span style={{color: '#afafaf'}}>(Collecting, storing, or sharing your user information)</span></p>
                            </MenuItem>
                            <MenuItem value="phishing">
                                <p style={{color: "black", textAlign: "start"}}>Phishing <span style={{color: '#afafaf'}}>(Impersonating your brand to steal user information)</span></p>
                            </MenuItem>
                            <MenuItem value="malware">
                                <p style={{color: "black", textAlign: "start"}}>Malware <span style={{color: '#afafaf'}}>(Distributes malware under your brand name)</span></p>
                            </MenuItem>
                            <MenuItem value="hateSpeech">
                                <p style={{color: "black", textAlign: "start"}}>Hate Speech <span style={{color: '#afafaf'}}>(promotes hatred or violence under your brand name)</span></p>
                            </MenuItem>
                </Select>
                </div>
                <div style={{display: "flex", justifyContent: "end"}}>
                    <Button onClick={handleSubmit} style={{backgroundColor: "white", color: "black", width: 100}}>Submit</Button>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default DomainTakeDownRequest