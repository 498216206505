import { faBell, faCalendar, faChevronDown, faCircleCheck, faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import Submitted from './CompilanceSections/Submitted'
import ToSubmit from './CompilanceSections/ToSubmit'
import styles from './dashboard.module.scss'
import { useIntercom } from './IntercomSetup'
export default function CompilnaceView() {
    const [activeCompo, setactiveCompo] = useState('toSubmit')
    const renderSections = () => {
        switch (activeCompo) {
            case 'toSubmit':
               return <ToSubmit/>
            case 'submitted':
                return <Submitted/>
            default:
                return;
        }
    }
    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
                <div className={styles.menuContent}>
                    <div className={styles.firstSectionMenu}>
                        <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('toSubmit')} className={activeCompo === 'toSubmit' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>To Submit</p>
                        <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('submitted')} className={activeCompo === 'submitted' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Submitted</p>
                    </div>
                </div>
                <hr className={styles.divider}/>
                {
                    renderSections()
                }
            </div>
        </div>
    )
}
