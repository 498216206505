import { Reducer } from 'redux'
import { AuthState, AuthAction, LOGIN_REQUEST, LOGIN_SUCCESS, IS_FIRST_TIME_LOGIN, SET_FIRST_TIME_NEW_PASSWORD_REQUEST, SEND_OTP, VERIFY_OTP, VERIFY_OTP_REQUEST, OTP_REQUEST, Roles, SET_NOTIFICATION, RESET_AUTH_STATE, RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, UPDATE_RECOVER_PASSWORD_REQUEST, UPDATE_RECOVER_PASSWORD_SUCCESS, SET_PICTURE_PROFILE, LoginReq, LoginRes, LOGOUT, RESET_NOTIFICATION, SET_JWT, LOGIN_FAILED } from '../constants'

interface AuthStateSelector {
    auth: AuthState
}

const initialState: AuthState = {
    jwt: '',
    email: '',
    password: '',
    login: '',
    authenticated: false,
    success: false,
    isFirstTime: false,
    notification: '',
    otpIsValid: false,
    otpIsSent: false,
    role: Roles.public,
    resetMailIsSent: false,
    passwordIsUpdated: false,
    approuved: 0,
    firstName: '',
    lastName: '',
    loginEntreprise: null,
    hmacValue: '',
    profilePicture: '',
    dateToUpdateOtp: '',
    error: "",
    errorCode: null,
    isLoadingLogin: false
}




export const authReducer:Reducer<AuthState, AuthAction> = (state, action): AuthState => {
    if(state === undefined) return initialState;
    switch (action.type) {
        
        case LOGIN_REQUEST:
            return {
                ...state,
                ...action.payload as any,
                isLoadingLogin: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                ...action.payload as any,
                isLoadingLogin: false
            }
        case LOGIN_FAILED: 
            return {
                ... state,
                isLoadingLogin: false
            }
        case IS_FIRST_TIME_LOGIN:
            return {
                ...state,
                ...action.payload as any
            }
        case SET_FIRST_TIME_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                ...action.payload as any
            }
        case SEND_OTP:
            return {
                ...state,
                ...action.payload as any
            }
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                ...action.payload as any
            }
        case VERIFY_OTP:
            return {
                ...state,
                ...action.payload as any
            }
        case OTP_REQUEST:
            return {
                ...state,
                ...action.payload as any
            }
        case SET_NOTIFICATION:
            return {
                ...state,
                ...action.payload as any
            }
        case RESET_AUTH_STATE:
                return {
                    ...initialState,
                };
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                ...action.payload as any
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                ...action.payload as any
            } 
        case UPDATE_RECOVER_PASSWORD_REQUEST: 
            return {
                ...state,
                ...action.payload as any
            } 
        case UPDATE_RECOVER_PASSWORD_SUCCESS:
            return {
                ...state,
                ...action.payload as any
            } 
        case SET_PICTURE_PROFILE:
            return {
                ...state,
                profilePicture: action.payload as string
            }
        case RESET_NOTIFICATION:
            return {
                ...state,
                notification: ''
            }
        case SET_JWT:
            return {
                ... state,
                jwt: action.payload as string
            }
        case LOGOUT:
            return initialState
        default:
            return state;
    }
}

export const authSelector = (state: AuthStateSelector) => state;