import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import styles from '../account.module.scss';
import { uploadUserPictureProfileAPI } from '../../../core/api/userApi';
import { useDispatch } from 'react-redux';
import { setPictureProfileAction } from '../../../core/actions/authActions';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../core/reducers/authReducer';
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';
import { getInfosEntrepriseRequest, setPictureEntrepriseAction } from '../../../core/actions/entrepriseActions';
import { removeEntreprisePictureAPI, uploadEntreprisePictureProfileAPI } from '../../../core/api/entrepriseApi';
import { env } from '../../../environement/env';
import EmptyEntrepriseLogo from '../../../icons/Empty Users/emptyEntreprise.jpg'
import { userSelector } from '../../../core/reducers/userReducer';

const Input = styled('input')({
  display: 'none',
});

const AvatarUploadEntreprise: React.FC = () => {
  const dispatch = useDispatch()
  const {entreprise} = useSelector(entrepriseSelector)
  const { roleUser } = useSelector(userSelector)
  const [avatarSrc, setAvatarSrc] = useState<string>(entreprise.avatarUrl ? entreprise.avatarUrl:EmptyEntrepriseLogo);
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img: File = event.target.files[0];
      setAvatarSrc(URL.createObjectURL(img));
      uploadAvatarFile(img)
      // setTimeout(() => {
      //   dispatch(getInfosEntrepriseRequest())
      // }, 500);
    }
  };

  const handleRemoveAvatar = async () => {
   const dataSrc = await removeEntreprisePictureAPI()
    setAvatarSrc(dataSrc);
    setTimeout(() => {
      dispatch(getInfosEntrepriseRequest())
    }, 500);
  };

  const uploadAvatarFile = async (file: File) => {
    const formData: FormData = new FormData()
    formData.append('profilePicture', file)
     const response = await uploadEntreprisePictureProfileAPI(formData);
     dispatch(getInfosEntrepriseRequest())
     dispatch(setPictureEntrepriseAction(response||""))
  }

  return (
    <div className={styles.avatarUpload}>
      <img src={avatarSrc} style={{width:56, height:56, overflow: "hidden", borderRadius:8}} />
      {
        roleUser?.companySettings ? avatarSrc ? (
          <div style={{marginTop: -10}}>
            <p className={styles.uploadAvatarBtn}>Upload Avatar</p>
          <div className={styles.uploadAndRemoveAvatar}>
          
       <label htmlFor="avatar-upload-input" >
        <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
        <p className={styles.changeAvatarBtn} role="button">
          Change 
        </p>
      
      </label>
          {
             !avatarSrc?.includes('emptyEntreprise.jpg') && <p
            className={styles.removeAvatar}
            role="button"
            onClick={handleRemoveAvatar}
          >
           -  Remove
          </p>
          }
      </div>
      </div>
        ):
        (
          <label htmlFor="avatar-upload-input" className={styles.uploadAndRemoveAvatar}>
        <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
        <p style={{cursor: 'pointer', paddingTop: 18}} className={styles.uploadAvatarBtn} role="button">
          Upload avatar
        </p>
        
      </label>
        ):
        <></>
      }
      
      
    </div>
  );
};

export default AvatarUploadEntreprise;
