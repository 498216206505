import { GroupsRansome, MessageData, Negociations, RansomeOverview, SET_MESSAGES, SUCCESS_NEGOCIATIONS, SUCCESS_RANSOME, SUCCESS_RANSOME_OVERVIEW, SUCCESS_VICTIMS, Victims } from "../constants";

export const setGroupsAction = (data: GroupsRansome[]) => ({
    type: SUCCESS_RANSOME,
    payload: data
})

export const setVictimsAction= (data: Victims[]) => ({
    type: SUCCESS_VICTIMS,
    payload: data
})

export const setNegociationAction = (data: Negociations[]) => ({
    type: SUCCESS_NEGOCIATIONS,
    payload: data
})

export const setMessageChatAction = (data: Negociations) => ({
    type: SET_MESSAGES,
    payload: data
})

export const setRansomeOverviewSuccess = (data: RansomeOverview) => ({
    type: SUCCESS_RANSOME_OVERVIEW,
    payload: data
})