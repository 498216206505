import React from 'react';
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';

interface Props {
  data: any[] | undefined;
}

let coordinateDot: { x: number; y: number } = {
  x: 0,
  y: 0,
};

const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [heightT, setHeight] = React.useState(75);
  coordinateDot = coordinate;
  React.useEffect(() => {
    if (tooltipRef.current) {
      const height = tooltipRef.current.offsetHeight;
      setHeight(height);
    }
  }, [payload]);
  console.log(payload)
  if (active && payload && payload.length) {
    return (
      <div
        ref={tooltipRef}
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
          overflow: 'hidden',
          borderRadius: 8,
        }}
      >
        <p style={{ color: '#1f1f1f' }}>{`Victims : ${payload[0].value}`}</p>
        <p style={{ color: '#1f1f1f' }}>{`Date : ${payload[0].payload.date}`}</p>
      </div>
    );
  }

  return null;
};

const CustomCursor = ({ points, width, height, stroke, strokeWidth, strokeDasharray }: any) => {
  const { x, y } = points[0];
  return (
    <line
      x1={x}
      y1={coordinateDot.y}
      x2={x}
      y2={height}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
    />
    );
};

const CustomActiveDot = (props: any) => {
  const { cx, cy, payload, value } = props;
  coordinateDot = { x: cx, y: cy }; // Set the global coordinateDot variable
  return (
    <circle cx={cx} cy={cy} r={4} stroke="yellow" strokeWidth={2} fill="#f5c500" />
  );
};

function ChartHomeRansome({ data }: Props) {
  return (
    <ResponsiveContainer width="100%" height="83%">
      <AreaChart
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <defs>
          <linearGradient id="colorCredentials" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#f5c500" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#282722" stopOpacity={0.8} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="victims"
          stroke="#f5c500"
          fill="url(#colorCredentials)"
          activeDot={<CustomActiveDot />}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={<CustomCursor stroke="#f5c500" strokeWidth={3} strokeDasharray="5 5" />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default ChartHomeRansome;
