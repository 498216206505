import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import Card from '../components/Card'
import ChartHome from '../components/ChartHome'
import CriticalRiskBar from '../components/CriticalRiskBar'
import RecentThreats from '../components/RecentThreats'
import styles from '../dashboard.module.scss'
import { CVEItem, HomeViewStats, Vulnerability } from '../../../core/constants'
import Loader from "../../../icons/Loader.svg"
import { useSelector } from 'react-redux'
import { statsSelector } from '../../../core/selectors/statsSelector'
import LeakPersonnalBar from '../components/LeakPersonnalBar'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import PIeChartHomeTypeUser from '../components/PIeChartHomeTypeUser'
import EmptyStateLogo from "../../../icons/Emptystate.svg"
import CriticalBarVulBreakCategory from '../components/CriticalBarVulBreakCategory'

type User = {
    userType: 'Client' | 'Executive' | 'Employee';
};

export default function Overview() {
    
    const { homeStats, isLoadingHome } = useSelector(statsSelector)
    const { credentialsLeak, databaseLeak, documentsLeak, creditsCard,  } = useSelector(threatIntelSelector)


    const countUserTypes = (): Record<string, number> => {
        const counts = { Client: 0, Executive: 0, Employee: 0 };
    
        credentialsLeak?.data.forEach(user => {
            if (user?.typeUser === 'Client') {
                counts.Client++;
            } else if (user?.typeUser === 'Executive') {
                counts.Executive++;
            } else if (user?.typeUser === 'Employee') {
                counts.Employee++;
            }
        });
    
        return counts;
    };

    const countRiskScores = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { Low: 0, Medium: 0, High: 0, Critical: 0, None:0 };
    
        objects?.forEach(obj => {
            const category = getRiskScore(obj?.base_score);
            counts[category]++;
        });
    
        return counts;
    };

    const getRiskScore = (riskScore:number) => {
        if (riskScore === undefined) return "None"
        else if(riskScore <= 3.9) return "Low";
        else if(riskScore >= 4.0 && riskScore <= 6.9) return "Medium";
        else if(riskScore >= 7.0 && riskScore <= 8.9) return "High";
        else return "Critical";
      }
      const getRiskScoreStyle = (riskScore:number) => {
        if(riskScore <= 3.9) return "LowStyle";
        else if(riskScore >= 4.0 && riskScore <= 6.9) return "MediumStyle";
        else if(riskScore >= 7.0 && riskScore <= 8.9) return "HighStyle";
        else return "CriticalStyle";
      }
      const riskCounts = countRiskScores(homeStats?.vulnerabilitites);
      console.log(countUserTypes())
      const dateFormats = [
        'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
        'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
        'DD.MM.YYYY HH:mm:ss',
        'DD.MM.YYYY h:mm:ss',   // Format for '20.06.2024 7:53:01'
      ];
      const data = homeStats?.credentialsTimeLine?.map(item =>
      ({
        date: moment(item?.date, dateFormats, true).format("MMMM DD, YYYY"),
        credentials: Number(item?.credentials),
      })
      )
      const totalCredentialsLeaks = Number(homeStats?.paymentCardsLeak)
                                        + Number(homeStats?.credentialsLeakCount)
                                        + Number(homeStats?.documentsLeak?.[0]?.count ? homeStats?.documentsLeak?.[0]?.count:0)
                                        + (databaseLeak?.length ? Number(databaseLeak?.length) : 0) 
      console.log("database leak: "+databaseLeak?.length)

    return (
        <>
            {
                !isLoadingHome ?
                <div className={styles.statsRecent}>
                {/* <div className={styles.ScoreRecentThrears}>
                    <div className={`${styles.cardRiskScore} ${styles[getRiskScoreStyle(stats?.totalRiskScore||0)]}`}>
                        <div className={styles.cardRiskScoreHeader}>
                            <p style={{fontSize: 14, fontWeight: "500"}}>Risk score</p>
                            <img src='/hexagon-exclamation.png' height={20} width={20}/>
                        </div>
                        {
                            stats?.totalRiskScore ?
                            <>  
                            <p style={{textAlign: 'center', fontSize: 36, fontWeight: "700",marginTop: 16}}>{stats?.totalRiskScore}</p>
                            <p style={{textAlign:'center', fontSize:14,marginTop: -32}}>{getRiskScore(stats?.totalRiskScore ||0)}</p>
                            </>
                            :
                            <>
                                <br />
                                <p style={{textAlign:'center', fontSize:14,marginTop: -32}}>Loading ...</p>
                            </>
                        }
                        
                    </div>
                    <RecentThreats/>
                </div> */}
                <div className={styles.statsThreats}>
                    <div className={styles.cards}>
                    {/* <div className={`${styles.cardRiskScore} ${styles[getRiskScoreStyle(stats?.totalRiskScore||0)]}`}>
                        <div className={styles.cardRiskScoreHeader}>
                            <p style={{fontSize: 14, fontWeight: "500"}}>Risk score</p>
                            <img src='/hexagon-exclamation.png' height={20} width={20}/>
                        </div>
                        {
                            stats?.totalRiskScore ?
                            <>
                            <p style={{textAlign: 'center', fontSize: 36, fontWeight: "700",marginTop: 16}}>{stats?.totalRiskScore}</p>
                            <p style={{textAlign:'center', fontSize:14,marginTop: -8}}>{getRiskScore(stats?.totalRiskScore ||0)}</p>
                            </>
                            :
                            <>
                                <br />
                                <p style={{textAlign:'center', fontSize:14,marginTop: -8}}>Loading ...</p>
                            </>
                        }
                        
                    </div> */}
                    <div className={`${styles.card} ${styles[getRiskScoreStyle(Number(homeStats?.totalRiskScoreHomeOverview))]}`}>
                        <p style={{fontSize: 14, fontWeight: 500}}>Risk score</p>
                        <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", marginTop: 0, paddingTop:0, paddingRight:20}}>
                            <p style={{fontSize: 20, fontWeight: 600}}>{Number(homeStats?.totalRiskScoreHomeOverview).toFixed(1)}</p>
                            <p style={{fontSize: 12}}>{getRiskScore(Number(homeStats?.totalRiskScoreHomeOverview))}
                                {/* <FontAwesomeIcon icon={percent > 0 ? faArrowTrendUp : faArrowTrendDown} /> */}
                                    </p>  
                        </div>
                    </div>
                        <Card 
                            text='Leaked Credentials'
                            num={Number(homeStats?.credentialsLeakCount)|| 0}
                            //percent={-60.15}
                            />
                       
                        <Card
                            text='Leaked Documents'
                            num={Number(homeStats?.documentsLeak?.[0]?.count) || 0}
                            // percent={-20.15}
                        />
                        
                        <Card
                            text='Vulnerabilities'
                            num={homeStats?.vulnerabilitites.length ||0}
                            //percent={+11.01}
                        />
                    </div>  
                    <div className={styles.chartHomeWrapper}>
                        <div className={styles.chartContainer}>
                            <p style={{color: "white", fontSize:14, fontWeight: "500"}}>Leaked Credentials Timeline</p>
                           {
                            data?.length !== 0 ?
                            <ChartHome data={data}/>:
                            <div style={{display:"flex", alignItems: "center", verticalAlign:"middle", height:"80%",  justifyContent:"center"}}>
                                <div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                <img src={EmptyStateLogo}/>
                                </div>
                                <p
                                style={{textAlign: "center",
                                    color:"white"
                                }}
                            >No Leaked Credentials  found</p>
                                </div>
                            </div>
                           }
                        </div>
                        <div className={styles.vulnerabilityContainer}>
                            <div className={styles.recentThreats_Header}>
                                <p style={{color: "white", fontSize: 14,  fontWeight: "500"}}>Risk Breakdown</p>
                                {/* <div className={styles.seeAll}>
                                    <span style={{fontSize: 12}}>See all</span>
                                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 8}}/>
                                </div> */}
                            </div>
                            <div className={styles.riskProgressBar}>
                                    <CriticalRiskBar value={homeStats ? (homeStats?.riskScores && homeStats?.riskScores?.Critical / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length!==0 ? homeStats?.vulnerabilitites.length: 1 ))*100 :0} title="Critical risk" />
                                    <CriticalRiskBar value={homeStats ? (homeStats?.riskScores && homeStats?.riskScores?.High / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length!==0 ? homeStats?.vulnerabilitites.length: 1 ))*100 :0} title="High risk" />
                                    <CriticalRiskBar value={homeStats ? (homeStats?.riskScores && homeStats?.riskScores?.Medium / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length!==0 ? homeStats?.vulnerabilitites.length: 1 ))*100 :0} title="Medium risk" />
                                    <CriticalRiskBar value={homeStats ? (homeStats?.riskScores && homeStats?.riskScores?.Low / (homeStats?.vulnerabilitites && homeStats?.vulnerabilitites.length!==0 ? homeStats?.vulnerabilitites.length: 1 ))*100 :0} title="Low risk" />
                                </div>
                        </div>
                    </div>
                    <div className={styles.threatAndMentionsBreakdown}>
                        <div className={styles.threatBreakdown}>
                        <div className={styles.recentThreats_Header}>
                                <p style={{color: "white",fontSize: 14, fontWeight: "500"}}>User Type Breakdown</p>
                                
                            </div>
                            {
                                !(Number(homeStats?.countUsersType?.[0]?.countexecutive)=== 0
                                && Number(homeStats?.countUsersType?.[0]?.countemployee)===0
                                && Number(homeStats?.countUsersType?.[0]?.countcustomer)===0)
                                ?
                                <PIeChartHomeTypeUser 
                                Executives={Number(homeStats?.countUsersType?.[0]?.countexecutive)}
                                Employees={Number(homeStats?.countUsersType?.[0]?.countemployee)}
                                Clients={Number(homeStats?.countUsersType?.[0]?.countcustomer)}
                            />:
                            <div style={{display:"flex", alignItems: "center", verticalAlign:"middle", height:"80%",  justifyContent:"center"}}>
                                <div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                <img src={EmptyStateLogo}/>
                                </div>
                                <p
                                style={{textAlign: "center",
                                    color:"white"
                                }}
                            >User Type Breakdown</p>
                                </div>
                            </div>
                            }
                            
                            {/* <div className={styles.riskProgressBar} style={{paddingTop: 30, display:"flex", justifyContent: "space-evenly",}}>
                                <LeakPersonnalBar value={Number(homeStats?.countUsersType?.[0]?.countexecutive)} title="Executives" />
                                <LeakPersonnalBar value={Number(homeStats?.countUsersType?.[0]?.countemployee)} title="Employees" />
                                <LeakPersonnalBar value={Number(homeStats?.countUsersType?.[0]?.countcustomer)} title="Customers" />
                            </div> */}
                        </div>
                        <div className={styles.mentionsBreakdown}>
                        <div className={styles.recentThreats_Header}>
                                <p style={{color: "white",fontSize: 14, fontWeight: "500"}}>Vulnerabilities Category Breakdown</p>

                            </div>
                        <div className={styles.riskProgressBar} style={{marginTop: 20}}>
                                <CriticalRiskBar value={homeStats ? (Number(homeStats?.credentialsLeakCount)/(totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks))*100 : 0} title="Credentials" />
                                <CriticalRiskBar value={homeStats ? (Number(homeStats?.paymentCardsLeak)/(totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks))*100 : 0} title="Payment Cards" />
                                <CriticalRiskBar value={homeStats ? (Number(homeStats?.documentsLeak?.[0]?.count ?homeStats?.documentsLeak?.[0]?.count:0)/(totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks))*100 : 0} title="Documents" />
                                <CriticalRiskBar value={homeStats && databaseLeak ? (Number(databaseLeak.length)/(totalCredentialsLeaks === 0 ? 1 : totalCredentialsLeaks))*100  : 0} title="Databases" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
           <div className={styles.loaderVul}>
                <CircularProgress/>
            </div>
            }
        </>
        
    )
}
