import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from "../../dashboard.module.scss"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getAllNotificationsApi } from '../../../../core/api/notificationApi'
import { maskString } from '../../ThreatIntelSections/CredentialsLeaks'
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import iconMenu5 from "../../../../icons/Main/Menu-5.svg"
import iconMenu6 from "../../../../icons/Main/Menu-6.svg"
import iconMenu4 from "../../../../icons/Main/Menu-4.svg"
import { notificationSelector } from '../../../../core/selectors/notificationBellSelector'
import moment from 'moment'

function formatTimestamp(timestamp: string) {
  const now = moment();
  const inputTime = moment(timestamp);
  const duration = moment.duration(now.diff(inputTime));

  const seconds = duration.asSeconds();
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();

  if (seconds < 60) {
    return `${Math.floor(seconds)} seconds ago`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minutes ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hours ago`;
  } else {
    return inputTime.format('MMM DD, YYYY');
  }
}

export default function AllNotification() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { credentialsLeak } = useSelector(threatIntelSelector)
   const { notifs } = useSelector(notificationSelector)
    // const data = credentialsLeak?.data?.filter( item => {
    //     if(allNotifs && allNotifs?.filter(da => da.userid === item.userid).length>0){
    //         return item
    //     }
    // })
   

    const renderVul = (msg: any, created_at?:string) => {
      return <tbody>
      <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
          
          //navigate("/vulnerabilities/vulnerabilityDetail")
      }}>
          <td className={styles.tableCell} style={{width: 50,}}>
              <div style={{display:'flex', justifyContent:'center'}}>
              <img src={iconMenu5} width={18}/>
              </div>
          </td>
          <td className={styles.tableCell}>
                          <p style={{ fontSize: '14' }}>{msg?.cve_id} added. {msg?.description?.substring(0,155)} ...</p>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                              <FontAwesomeIcon icon={faCalendar} />
                              <p>{created_at && formatTimestamp(created_at)}</p>
                          </div>
                      </td>
                      
                    
                  </tr>
              
          </tbody>
  }



  const renderRansom = (msg: any, created_at?:string) => {
      return <tbody>
      <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
          
          //navigate("/vulnerabilities/vulnerabilityDetail")
      }}>
          <td className={styles.tableCell} style={{width: 50,}}>
              <div style={{display:'flex', justifyContent:'center'}}>
              <img src={iconMenu6} width={18}/>
              </div>
          </td>
          <td className={styles.tableCell}>
                          <p style={{ fontSize: '14' }}>New ransomware attack detected in your country by {msg.name}</p>
                          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                              <FontAwesomeIcon icon={faCalendar} />
                              <p>{created_at && formatTimestamp(created_at)}</p>
                          </div>
                      </td>
                      
                      
                  </tr>
              
          </tbody>
  }

  const renderDarkweb = (msg: any, created_at?:string) => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
        
        //navigate("/vulnerabilities/vulnerabilityDetail")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img src={iconMenu4} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell}>
                        <p style={{ fontSize: '14' }}>New Dark Web post by {msg.author?.replace('(', '')?.replace(')','')} mentionned your organisation</p>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <FontAwesomeIcon icon={faCalendar} />
                            <p>{created_at && formatTimestamp(created_at)}</p>
                        </div>
                    </td>
                 
                    
                </tr>
            
        </tbody>
}

const renderDomain = (msg: any, created_at?:string) => {
  return <tbody>
  <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
      
      //navigate("/vulnerabilities/vulnerabilityDetail")
  }}>
      <td className={styles.tableCell} style={{width: 50,}}>
          <div style={{display:'flex', justifyContent:'center'}}>
          <img src={iconMenu4} width={18}/>
          </div>
      </td>
      <td className={styles.tableCell}>
      <p style={{ fontSize: '14' }}>New ransomware attack detected in '{msg.domain}'</p>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                          <FontAwesomeIcon icon={faCalendar} />
                          <p>{created_at && formatTimestamp(created_at)}</p>
                      </div>
                  </td>
                  
                  
              </tr>
          
      </tbody>
}

const renderSurfaceWeb = (msg: any, created_at?:string) => {
  return <tbody>
  <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
      
      //navigate("/vulnerabilities/vulnerabilityDetail")
  }}>
      <td className={styles.tableCell} style={{width: 50,}}>
          <div style={{display:'flex', justifyContent:'center'}}>
          <img src={iconMenu6} width={18}/>
          </div>
      </td>
      <td className={styles.tableCell}>
                      <p style={{ fontSize: '14' }}>The {msg.profile_name} mentionned you in an article titled '{msg.post_title}'</p>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                          <FontAwesomeIcon icon={faCalendar} />
                          <p>{created_at && formatTimestamp(created_at)}</p>
                      </div>
                  </td>
                 
                  
              </tr>
          
      </tbody>
}
  const creditsCard = notifs.filter(item => item.isCreditsCard === true)
  const renderCreditsCard = () => {
    return <tbody>
    <tr className={styles.tableActivityRowRansomWatchList } onClick={() => {
        
        //navigate("/vulnerabilities/vulnerabilityDetail")
    }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img src={iconMenu6} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell}>
                        <p style={{ fontSize: '14' }}>{creditsCard.length} compromised credit card</p>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <FontAwesomeIcon icon={faCalendar} />
                            <p>{creditsCard[0] && formatTimestamp(creditsCard[0].created_at)}</p>
                        </div>
                    </td>
                   
                    
                </tr>
            
        </tbody>
  }
  return (
    <table className={styles.table} style={{ marginTop: 25 }}>
                
                       {
                        notifs.map(item => {
                            if(item.isVul)
                              return renderVul(item.data, item.created_at)
                            else if(item.isRansome)
                                return renderRansom(item.data, item.created_at)
                            else if(item.isDarkWeb)
                              return renderDarkweb(item.data, item.created_at)
                            else if(item.isDomains)
                              return renderDomain(item.data, item.created_at)
                            else if(item.isSurfaceWeb && item.data?.type?.includes("news"))
                              return renderSurfaceWeb(item.data, item.created_at)
                          })
                       }  
                       { creditsCard.length > 0 &&  renderCreditsCard()  } 
            </table>
  )
}
