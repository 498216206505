import { Reducer } from "react";
import { ADD_NOTIFICATION, CredentialsLeak, DELETE_NOTIFICATION, GroupsRansome, NotificationBell, NotificationsReducer, RansomeWareAction, REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS, REQUEST_RANSOME, SET_CREDENTIAL_LEAK_DETAIL, SUCCESS_CREADENTIALS_LEAK, SUCCESS_NOTIFICATIONS, SUCCESS_RANSOME, SUCCESS_VICTIMS, ThreatIntel, UPDATE_NOTIFICATION, Victims } from "../constants";

export interface NotificationBellState {
    notifs: NotificationBell[]
    isLoading: boolean
}

const initialState: NotificationBellState = {
    notifs: [],
    isLoading: false
}

export const NotificationBellReducer: Reducer<NotificationBellState, NotificationsReducer> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case REQUEST_NOTIFICATIONS:
            return {
                ...state,
                isLoading: true
            }
            case SUCCESS_NOTIFICATIONS:
                return {
                  ...state,
                  isLoading: false,
                  notifs: actions.payload as NotificationBell[], // Replace the entire notifications array
                };
            case ADD_NOTIFICATION:
                    return {
                      ...state,
                      notifs: [actions.payload as NotificationBell, ...state.notifs], // Prepend new notification
                    };    
            case UPDATE_NOTIFICATION:
                        return {
                          ...state,
                          notifs: state.notifs.map((notif) =>
                            notif.id === actions.payload.id ? actions.payload : notif
                          ),
                        };
                  
            case DELETE_NOTIFICATION:
                        return {
                          ...state,
                          notifs: state.notifs.filter(
                            (notif) => notif.id !== actions.payload.id
                          ),
                        };
        default:
            return state;
    }
}