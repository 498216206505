import React, { useEffect, useState } from 'react';
import { menuItem, menuItems } from '../constants'
import styles from '../../Dashboard/dashboard.module.scss';
import { style } from '../PersonalAccount.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from './defendisLogoWhite.svg'
import { useSelector } from 'react-redux';
import { authSelector } from '../../../core/reducers/authReducer';
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer';
import { getInfosEntrepriseRequest } from '../../../core/actions/entrepriseActions';
import { useDispatch } from 'react-redux';
import { getUserInfoRequest } from '../../../core/actions/userActions';
import { env } from '../../../environement/env';
import EmptyEntrepriseLogo from '../../../icons/Empty Users/emptyEntreprise.jpg'
function Menu() {
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const { entreprise } = useSelector(entrepriseSelector)
    const currentPath = location.pathname;

    const handlePreviousHome = () => {
        navigate('/')
    }

    useEffect(() => {
        dispatch(getInfosEntrepriseRequest())
      dispatch(getUserInfoRequest())

    }, [])


    return (
        <div className={ styles.menu }>
            <div className={styles.mainLogo}>
                <img src={logo} className={styles.logo} height={16}/>
            </div>
            <div onClick={handlePreviousHome} className={styles.homeBack}>
                <FontAwesomeIcon icon={faArrowLeft}/>
                <p>Home</p>
                </div>
            <div>
            <div className={styles.menuList}>
                {
                    menuItems.map( (item: menuItem) => (
                        <NavLink to={item.path} style={{textDecoration: 'none', color: 'white'}}
                        className={item.path === currentPath ? styles.activeMenuItem : styles.menuItem}
                        key={item.id}>
                            <>{item.icon}</>
                           <p style={{fontSize: 14}}>{item.title}</p>
                        </NavLink>
                    ))
                }
            </div>
            <div className={styles.menuProfile}>
                <img src={
                      entreprise.avatarUrl ?  
                      entreprise.avatarUrl:
                      EmptyEntrepriseLogo
                } className={styles.profileLogo} />
                <div>
                    <span className={styles.profileName}>{entreprise?.entrepriseName}</span>
                    <br/>
                    <span className={styles.profilePlan}>{entreprise?.subscriptionName}</span>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Menu
