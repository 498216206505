import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../dashboard.module.scss'
import Victims from './GroupDetailComponents/Victims'
import CryptoWallet from './GroupDetailComponents/CryptoWallet'
import ExternalInfo from './GroupDetailComponents/ExternalInfo'
import NegociationChats from './GroupDetailComponents/NegociationChats'
import RansomeNote from './GroupDetailComponents/RansomeNote'
import Urls from './GroupDetailComponents/Urls'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import moment from 'moment'
function GroupDetail() {
    const navigate = useNavigate()
    const { groupDetail } = useSelector(ransomeSelector)
    const [activeCompo, setactiveCompo] = useState('urls')
    const renderSections = () => {
        switch (activeCompo) {
            case 'urls':
                return <Urls/>
            case 'externalInfo':
                return <ExternalInfo/>
            case 'ransomNote':
                return <RansomeNote/>
            case 'cryptoWallet':
                return <CryptoWallet/>
            case 'negoChat':
                return <NegociationChats/>
            case 'victims':
                return <Victims/>
            default:
                return;
        }
    }
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
            <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/ransomwareIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <p>Go back / {groupDetail?.id}</p>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{groupDetail?.name}</span>
                    </div>
                </div>
            </div>
            <div style={{marginTop: 25, fontSize: 14}}>
                    <span style={{color: "#afafaf"}}>Last updated </span>
                    <span style={{color: "white"}}>{moment(groupDetail?.updated).format("MMMM DD, YYYY")} </span>
            </div>
            <div style={{backgroundColor: "#141414", padding: 10, marginTop: 25, borderRadius: 8}}>
                <p style={{color: "#cbcbcb", fontSize: 14}}>Avaddon is a ransomware malware targeting Windows systems often spread via malicious spam. The first known attack where Avaddon ransomware was distributed was in February 2020. Avaddon encrypts files using the extension .avdn and uses a TOR payment site for the ransom payment.</p>
            </div>
            <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px'  }} className={styles.firstSectionMenu}>
                    <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('urls')} className={activeCompo === 'urls' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>URLs</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('externalInfo')} className={activeCompo === 'externalInfo' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>External Information</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('ransomNote')} className={activeCompo === 'ransomNote' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Ransom note</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('cryptoWallet')} className={activeCompo === 'cryptoWallet' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Crypto Wallet</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('negoChat')} className={activeCompo === 'negoChat' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Negotiation Chats</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('victims')} className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Victims</span>
                </div>
            {renderSections()}
            </div>

        </div>
    )
}

export default GroupDetail
