import { Reducer } from "react";
import { CredentialsLeak, DetailStateReducer, GroupsRansome, RansomeWareAction, REQUEST_CREADENTIALS_LEAK, REQUEST_RANSOME, SET_CREDENTIAL_LEAK_DETAIL, SUCCESS_CREADENTIALS_LEAK, SUCCESS_RANSOME, SUCCESS_VICTIMS, ThreatIntel, Victims } from "../constants";

export interface DetailState {
    credentialLeakDetail: CredentialsLeak | null
}

const initialState: DetailState = {
    credentialLeakDetail: null
}

export const detailStateReducer: Reducer<DetailState, DetailStateReducer> = (state, actions) => {
    if(state === undefined) return initialState
        switch(actions.type){
        case SET_CREDENTIAL_LEAK_DETAIL:
            return {
                ...state,
                credentialLeakDetail: actions.payload as CredentialsLeak
            }
        default:
            return initialState;
    }
}