import { Reducer } from "react";
import { SET_STEP_VUL, StepsNavPage, StepsNavPageReducer } from "../constants";

const initialState:StepsNavPage = {
    vulStepName: 'overview'
}

export const stepsNavPageReducer: Reducer<StepsNavPage, StepsNavPageReducer> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case SET_STEP_VUL:
            return {
                ... state,
                vulStepName: actions.payload.vulStepName
            }
        default:
            return initialState;
    }
}
