import { call, put } from "redux-saga/effects";
import { CredentialsLeak, CreditCardsThreatIntel, DatabaseLeak, DocumentsLeakIntel, FAILED_CREDENIALS_LEAK, FAILED_CREDITS_CARD, FAILED_DATABASELEAK, FAILED_DOCUMENTS_LEAK, FAILED_TOTAL_RECORDS_CREDENTIALS, HitEntry, NotificationBell, NotificationBellReq, REQUEST_NOTIFICATIONS, SEND_NOTIFICATIONS, SUCCESS_DATABASELEAK, SUCCESS_TOTAL_RECORDS_CREDENTIALS, ThreatIntel } from "../constants";
import { getCredentialsLeakApi, getCreditsCardAi, getDocumentsLeakApi, getTotalRecordsCredentialsApi, PageSort } from "../api/threatIntelApi";
import { setCredentialsLeak, setCreditsCards, setDocumentLeak, setTotalRecordsCredentialsLeak } from "../actions/threatIntelActions";
import { getNotificationsApi } from "../api/notificationApi";
import { sendNotificationAction } from "../actions/notificationActions";
import { getDatabaseLeaksApi } from "../api/databaseLeaksApi";

export function* getCredentialsLeakSaga(actions: ThreatIntel) {
    try { 
        const data: CredentialsLeak = yield call(getCredentialsLeakApi, actions.payload as PageSort)
        
        const usersId:number[] = data?.data?.map(item => item?.passwordid)
        const dataSendNotif: NotificationBellReq = {
            usersId
        }
        yield put(sendNotificationAction(dataSendNotif))
        yield put(setCredentialsLeak(data))
    } catch (error) {
        yield put({
            type: FAILED_CREDENIALS_LEAK
        })
    }
}


export function* getCreditsCardSaga(actions: ThreatIntel) {
    try {
        const data: CreditCardsThreatIntel = yield call(getCreditsCardAi, actions.payload as PageSort)
        yield put(setCreditsCards(data))
    } catch (error) {
        yield put({
            type: FAILED_CREDITS_CARD
        })
    }
}

export function* getDocumentsLeakSaga(actions: ThreatIntel) {
    try {
        const data: DocumentsLeakIntel = yield call(getDocumentsLeakApi, actions.payload as PageSort)
        yield put(setDocumentLeak(data))
    } catch (error) {
        yield put({
            type: FAILED_DOCUMENTS_LEAK
        })
    }
}

export function* getTotalRecordsCredentialsSaga(actions: ThreatIntel) {
    try {
        const data: number = yield call(getTotalRecordsCredentialsApi)
        yield put(setTotalRecordsCredentialsLeak(data))
    } catch (error) {
        yield put({
            type:FAILED_TOTAL_RECORDS_CREDENTIALS
        })
    }
}

export function* searchDatabaseLeakSaga(actions: ThreatIntel) {
    try {
        const data:  HitEntry[] = yield call(getDatabaseLeaksApi, actions.payload as string)
        yield put({
            type: SUCCESS_DATABASELEAK,
            payload: data
        })
    } catch (error) {
        yield put({
            type: FAILED_DATABASELEAK
        })
    }
}