import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDownload, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../dashboard.module.scss';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { ransomeSelector } from '../../../core/selectors/ransomeSelector';
import { Negociations } from '../../../core/constants';
import { setMessageChatAction } from '../../../core/actions/ransomeActions';
import { CircularProgress } from '@mui/material';

type SortOrder = 'asc' | 'desc';

function NegotiationsSection() {
    const navigate = useNavigate();
    const { negociations } = useSelector(ransomeSelector);
    const [search, setSearch] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>('name_group');
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = 'Negotiations';
        ReactGA.send({ hitType: 'Ransomware Intel - Negotiations', page: window.location.pathname });
    }, []);

    const resultSearch = search !== ''
        ? negociations?.filter(item => item.name_group.toLowerCase().includes(search.toLowerCase()))
        : negociations;

    const handleSort = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const sortedNegotiations = resultSearch?.sort((a, b) => {
        let comparison = 0;
        if (sortBy === 'name_group') {
            comparison = a.name_group.localeCompare(b.name_group);
        } else if (sortBy === 'initial_ransom') {
            comparison = a.initial_ransom.localeCompare(b.initial_ransom);
        } else if (sortBy === 'negotiated_ransom') {
            comparison = a.negotiated_ransom.localeCompare(b.negotiated_ransom)
        } else if (sortBy === 'paid') {
            comparison = a.paid === b.paid ? 0 : a.paid ? 1 : -1;
        } else if(sortBy === 'messages') {
            comparison = a?.message_data?.length - b.message_data?.length
        } else if (sortBy === 'ransome') {
          comparison = a.name_group.localeCompare(b.name_group)
      }

        return sortOrder === 'asc' ? comparison : -comparison;
    });

    const setMessageDetail = (item: Negociations) => {
        dispatch(setMessageChatAction(item));
        navigate('/ransomwareIntel/conversations');
    };

    return (
        <div className={styles.awaitingApproval} style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: "45px" }}>
                <div className={styles.searchAndIcon}>
                    <input
                        className={styles.search}
                        name='search'
                        placeholder='Search'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x' />
                </div>
            </div>
            {sortedNegotiations ?
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.tableHead}>
                            <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('name_group')}>
                                Name {" "}
                                <FontAwesomeIcon
                                    icon={sortBy === 'name_group' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th>
                            
                            <th style={{paddingLeft: 8, cursor: 'pointer'}} onClick={() => handleSort('ransome')}>
                            Ransomware {" "}
                            <FontAwesomeIcon
                                    icon={sortBy === 'ransome' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th> 
                            <th onClick={() => handleSort('messages')} style={{paddingLeft: 8, cursor: 'pointer'}}>
                            Messages {" "}
                            <FontAwesomeIcon
                                    icon={sortBy === 'messages' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th>
                            <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('initial_ransom')}>
                                Initial Ransom {" "}
                                <FontAwesomeIcon
                                    icon={sortBy === 'initial_ransom' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th>
                            <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('negotiated_ransom')}>
                                Negotiated Ransom {" "}
                                <FontAwesomeIcon
                                    icon={sortBy === 'negotiated_ransom' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th>
                            <th style={{ paddingLeft: 8, cursor: 'pointer' }} onClick={() => handleSort('paid')}>
                                Paid {" "}
                                <FontAwesomeIcon
                                    icon={sortBy === 'paid' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                    width={11}
                                    height={12}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedNegotiations?.map(item =>
                            <tr
                                className={styles.tableActivityRowDomainBrand}
                                onClick={() => setMessageDetail(item)}
                                style={{ cursor: 'pointer' }}
                                key={item.chat_id}
                            >
                                <td style={{ width: '219px', padding: '14px 16px' }} className={styles.tableCell}>
                                    {item?.chat_id}
                                </td>
                                <td style={{ width: '219px', padding: '14px 16px', color: '#e9bd08' }} className={styles.tableCell}>
                                    {item?.name_group}
                                </td>
                                <td style={{ width: '200px' }} className={styles.tableCell}>
                                    {item?.message_data?.length} messages
                                </td>
                                <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
                                    {item.initial_ransom}
                                </td>
                                <td style={{ width: '200px', padding: '14px 16px' }} className={styles.tableCell}>
                                    {item.negotiated_ransom}
                                </td>
                                <td style={{ width: '80px', padding: '14px 16px' }} className={styles.tableCell}>
                                    <FontAwesomeIcon icon={item.paid ? faCircleCheck : faXmarkCircle} width={11} height={12} color="#afafaf" />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                : <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
            }
        </div>
    );
}

export default NegotiationsSection;
