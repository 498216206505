import React, { useEffect, useState } from 'react'
import styles from  "../../dashboard.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { maskString } from '../../ThreatIntelSections/CredentialsLeaks'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import iconMenu5 from "../../../../icons/Main/Menu-5.svg"
import iconMenu6 from "../../../../icons/Main/Menu-6.svg"
import iconMenu4 from "../../../../icons/Main/Menu-4.svg"
import circlCheck from "../../../../icons/circle-check.svg"
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getNotificationsApi, updateNotificationsApi } from '../../../../core/api/notificationApi'
import { notificationSelector } from '../../../../core/selectors/notificationBellSelector'
import moment from 'moment'
import { supabaseClient } from '../../../../core/supabase'
import { setNamePageLocalStorage } from '../../../../core/actions/stepsNavPageActions'


function formatTimestamp(timestamp: string) {
    const now = moment();
    const inputTime = moment(timestamp);
    const duration = moment.duration(now.diff(inputTime));
  
    const seconds = duration.asSeconds();
    const minutes = duration.asMinutes();
    const hours = duration.asHours();
    const days = duration.asDays();
  
    if (seconds < 60) {
      return `${Math.floor(seconds)} seconds ago`;
    } else if (minutes < 60) {
      return `${Math.floor(minutes)} minutes ago`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hours ago`;
    } else {
      return inputTime.format('MMM DD, YYYY');
    }
  }
export default function Unread() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {credentialsLeak} = useSelector(threatIntelSelector)
    const [messages, setMessages] = useState<any[]>([]);
    const { notifs } = useSelector(notificationSelector)
    useEffect(() => {
        const ws = new WebSocket('ws://localhost:9090'); // Ensure the URL is correct
    
        ws.onopen = () => {
            console.log('WebSocket connected');
        };

        ws.onmessage = (event) => {
            console.log('Données brutes reçues:', event.data);
            if (!event.data) {
                console.error('Aucun message reçu.');
            } else {
                const newMessage = JSON.parse(event.data);
                console.log('Message après parsing:', newMessage);
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            }
        };
    
        ws.onclose = () => {
            console.log('WebSocket connection closed');
        };
    
        ws.onerror = (error) => {
            console.error('WebSocket error:', JSON.stringify(error));
        };
    
        
        
    
        return () => {
            ws.close();
        };
    }, []);
   
   
    const NotificationUnread = notifs.filter(item => item.read === false)

    const markAsRead = async(id:any) => {
        await supabaseClient.from('Notification').update({
            read: true
        }).eq('id', id)
    }

    const markAsReadCreditsCard =  () => {
        creditsCard.forEach(async item => await supabaseClient.from('Notification').update({
            read: true
        }).eq('id', item.id))
    }

    const renderVul = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } onClick={() => {
            setNamePageLocalStorage("list")
            navigate("/vulnerabilities")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu5} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell}>
                            <p style={{ fontSize: '14' }}>{msg?.cve_id} added. {msg?.description?.substring(0,155)} ...</p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{created_at && formatTimestamp(created_at)}</p>
                            </div>
                        </td>
                        
                        <td  className={styles.tableCell} style={{width:160}}>
                        <div onClick={()=>markAsRead(id)} style={{display:'flex', justifyContent:'end',paddingRight:20,alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                    </tr>
                
            </tbody>
    }
  
  
  
    const renderRansom = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:'pointer'}}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu6} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell}>
                            <p style={{ fontSize: '14' }}>New ransomware attack detected in your country by {msg.name}</p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{created_at && formatTimestamp(created_at)}</p>
                            </div>
                        </td>
                        
                        <td  className={styles.tableCell}  style={{width:160}}>
                        <div onClick={()=>markAsRead(id)} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                    </tr>
                
            </tbody>
    }
    const renderDarkweb = (msg: any,id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("groups")
            navigate("/ransomwareIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu4} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell}>
                            <p style={{ fontSize: '14' }}>New Dark Web post by {msg.author?.replace('(', '')?.replace(')','')} mentionned your organisation</p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{created_at && formatTimestamp(created_at)}</p>
                            </div>
                        </td>
                        
                        <td  className={styles.tableCell} style={{width:160}}>
                        <div  onClick={()=>markAsRead(id)} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                    </tr>
                
            </tbody>
    }
    const renderSurfaceWeb = (msg: any, id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("surfaceWeb")
            navigate("/brandIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu6} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell}>
                            <p style={{ fontSize: '14' }}>The {msg.profile_name} mentionned you in an article{msg.post_title ? ` titled '${msg.post_title}.'`:"."} </p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{created_at && formatTimestamp(created_at)}</p>
                            </div>
                        </td>
                        
                        <td  className={styles.tableCell} style={{width:160}}>
                        <div  onClick={()=>markAsRead(id)} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                    </tr>
                
            </tbody>
      }
    const renderDomain = (msg: any,id:any, created_at?:string) => {
        return <tbody>
        <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("domains")
            navigate("/brandIntel")
        }}>
            <td className={styles.tableCell} style={{width: 50,}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                <img src={iconMenu4} width={18}/>
                </div>
            </td>
            <td className={styles.tableCell}>
            <p style={{ fontSize: '14' }}>New ransomware attack detected in '{msg.domain}'</p>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                <FontAwesomeIcon icon={faCalendar} />
                                <p>{created_at && formatTimestamp(created_at)}</p>
                            </div>
                        </td>
                       
                        <td  className={styles.tableCell} style={{width:160}}>
                        <div  onClick={()=>markAsRead(id)} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                    </tr>
                
            </tbody>
      }
      const creditsCard = NotificationUnread.filter(item => item.isCreditsCard === true)
  const renderCreditsCard = () => {
    return <tbody>
    <tr className={styles.tableActivityRowWatchList } style={{cursor:"pointer"}} onClick={() => {
            setNamePageLocalStorage("paymentCardLeaks")
            navigate("/threatIntel")
        }}>
        <td className={styles.tableCell} style={{width: 50,}}>
            <div style={{display:'flex', justifyContent:'center'}}>
            <img src={iconMenu6} width={18}/>
            </div>
        </td>
        <td className={styles.tableCell}>
                        <p style={{ fontSize: '14' }}>{creditsCard.length} compromised credit card</p>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <FontAwesomeIcon icon={faCalendar} />
                            <p>{creditsCard[0] && formatTimestamp(creditsCard[0].created_at)}</p>
                        </div>
                    </td>
                     
                    <td  className={styles.tableCell} style={{width:160}}>
                        <div onClick={markAsReadCreditsCard} style={{display:'flex', justifyContent:'end',paddingRight:20, alignItems:'center', gap:10, cursor:'pointer'}}>
                            <img src={circlCheck}/>
                            <p style={{fontWeight:400}}>Mark as read</p>
                        </div>
                        </td>
                </tr>
            
        </tbody>
  }


 

  return (
    <table className={styles.table} style={{ marginTop: 25 }}>

{
                        NotificationUnread.map(item => {
                            if(item.isVul)
                              return renderVul(item.data, item.id,item.created_at)
                            else if(item.isRansome)
                                return renderRansom(item.data,item.id, item.created_at)
                            else if(item.isDarkWeb)
                                return renderDarkweb(item.data, item.id,item.created_at)
                            else if(item.isDomains)
                                return renderDomain(item.data, item.id,item.created_at)
                            else if(item.isSurfaceWeb && item.data?.type?.includes("news"))
                                return renderSurfaceWeb(item.data, item.id,item.created_at)
                          })
                       }   
                      { creditsCard.length > 0 &&  renderCreditsCard()  }  
            </table>
  )
}
