import React from 'react'
import styles from "../dashboard.module.scss"
import EmptyStateLogo from "../../../icons/Emptystate.svg"

interface props {
    text: string
}
export default function EmptyState() {
  return (
    <div className={styles.emptyState}>
    <div style={{width: '300px'}}>
        <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>

    <img src={EmptyStateLogo}/>
        </div>
      <div style={{marginTop: 40}}> 
        <h2 style={{textAlign: "center",color:"white", fontWeight:"600",fontSize:20}}>No Threats</h2>
        <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14}}>No threats detected. New threats will be shown here when available.</p>
      </div>
    </div>
  </div>
  )
}
