import { Reducer } from "react";
import { CredentialsLeak, GroupsRansome, NotificationBell, NotificationsReducer, RansomeWareAction, REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS, REQUEST_RANSOME, SET_CREDENTIAL_LEAK_DETAIL, SUCCESS_CREADENTIALS_LEAK, SUCCESS_NOTIFICATIONS, SUCCESS_RANSOME, SUCCESS_VICTIMS, ThreatIntel, Victims } from "../constants";

export interface NotificationBellState {
    notifs: NotificationBell[] | null;
    isLoading: boolean
}

const initialState: NotificationBellState = {
    notifs: null,
    isLoading: false
}

export const NotificationBellReducer: Reducer<NotificationBellState, NotificationsReducer> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case REQUEST_NOTIFICATIONS:
            return {
                ...state,
                isLoading: true
            }
        case SUCCESS_NOTIFICATIONS:
            return {
                ...state,
                isLoading: false,
                notifs: actions.payload as NotificationBell[]
            }
        
        default:
            return initialState;
    }
}