import { CredentialsLeak, CreditCardsThreatIntel, DatabaseLeak, DocumentsLeakIntel, REQUEST_CREADENTIALS_LEAK, SET_CREDENTIAL_LEAK_DETAIL, SUCCESS_CREADENTIALS_LEAK, SUCCESS_CREDITS_CARD, SUCCESS_DATABASELEAK, SUCCESS_DOCUMENTS_LEAK, SUCCESS_TOTAL_RECORDS_CREDENTIALS } from "../constants";

export const setCredentialsLeak = (data: CredentialsLeak) => ({
    type: SUCCESS_CREADENTIALS_LEAK,
    payload: data
})

export const setCredentialLeakDetail = (data: CredentialsLeak) => {
    return ({
        type:SET_CREDENTIAL_LEAK_DETAIL,
        payload: data
    })
}

export const setCreditsCards = (data: CreditCardsThreatIntel) => ({
    type: SUCCESS_CREDITS_CARD,
    payload: data
})

export const setDocumentLeak = (data: DocumentsLeakIntel) => ({
    type: SUCCESS_DOCUMENTS_LEAK,
    payload: data
})

export const setTotalRecordsCredentialsLeak = (data: number) =>({
    type: SUCCESS_TOTAL_RECORDS_CREDENTIALS,
    payload: data
})

export const successDatabaseLeak = (data: DatabaseLeak) => ({
    type: SUCCESS_DATABASELEAK,
    payload: data
})