import { Reducer } from "react";
import { CredentialsLeak, CreditCardsThreatIntel, ThreatIntel, REQUEST_CREADENTIALS_LEAK, SUCCESS_CREADENTIALS_LEAK, SUCCESS_CREDITS_CARD, REQUEST_CREDITS_CARD, FAILED_CREDENIALS_LEAK, FAILED_CREDITS_CARD, DocumentsLeakIntel, REQUEST_DOCUMENTS_LEAK, SUCCESS_DOCUMENTS_LEAK, FAILED_DOCUMENTS_LEAK, SUCCESS_TOTAL_RECORDS_CREDENTIALS, REQUEST_TOTAL_RECORDS_CREDENTIALS, FAILED_TOTAL_RECORDS_CREDENTIALS, REQUEST_DATABASELEAK, DatabaseLeak, SUCCESS_DATABASELEAK, FAILED_DATABASELEAK, HitEntry } from "../constants";

export interface ThreatIntelState {
    credentialsLeak: CredentialsLeak | null,
    creditsCard: CreditCardsThreatIntel | null,
    documentsLeak: DocumentsLeakIntel | null,
    databaseLeak: HitEntry[] | null,
    isLoadingDocumentsLeak: boolean,
    isLoadingCredentials: boolean,
    isLoadingCreditCard: boolean,
    isLoadingTotalRecords: boolean,
    isLoadingDatabaseLeak: boolean
    totalRecordsCredentials: number | null
    page: number
}

const initialState: ThreatIntelState = {
    credentialsLeak: null,
    creditsCard: null,
    documentsLeak: null,
    databaseLeak: null,
    isLoadingDatabaseLeak: false,
    isLoadingCredentials: false,
    isLoadingCreditCard: false,
    isLoadingDocumentsLeak: false,
    totalRecordsCredentials: null,
    isLoadingTotalRecords: false,
    page: 1
}

export const ThreatIntelReducer: Reducer<ThreatIntelState, ThreatIntel> = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CREADENTIALS_LEAK:
            return {
                ...state,
                isLoadingCredentials: true
            };
        case SUCCESS_CREADENTIALS_LEAK:
            return {
                ...state,
                isLoadingCredentials: false,
                credentialsLeak: action.payload as CredentialsLeak
            };
        case REQUEST_CREDITS_CARD:
                return {
                    ...state,
                    isLoadingCreditCard: true
                };
        case SUCCESS_CREDITS_CARD:
            return {
                ...state,
                isLoadingCreditCard: false,
                creditsCard: action.payload as CreditCardsThreatIntel
            };
        case FAILED_CREDENIALS_LEAK:
            return {
                ...state,
                isLoadingCredentials: false
            }
        case FAILED_CREDITS_CARD:
                return {
                    ...state,
                    isLoadingCreditCard: false
                }
        case REQUEST_DOCUMENTS_LEAK: 
            return {
                ...state,
                isLoadingDocumentsLeak: true
            }
        case SUCCESS_DOCUMENTS_LEAK: 
            return {
                ... state,
                isLoadingDocumentsLeak: false,
                documentsLeak: action.payload as DocumentsLeakIntel
            }
        case FAILED_DOCUMENTS_LEAK: 
            return {
                ... state,
                isLoadingDocumentsLeak: false
            }
        case REQUEST_TOTAL_RECORDS_CREDENTIALS:
            return {
                ... state,
                isLoadingTotalRecords: true
            }
        case SUCCESS_TOTAL_RECORDS_CREDENTIALS:
            return {
                ...state,
                isLoadingTotalRecords: false,
                totalRecordsCredentials: action.payload as number
            }
        case FAILED_TOTAL_RECORDS_CREDENTIALS:
            return {
                ... state,
                isLoadingTotalRecords:false
            }
        case REQUEST_DATABASELEAK:
            return {
                ... state,
                isLoadingDatabaseLeak: true
            }
        case SUCCESS_DATABASELEAK:
            return {
                ... state,
                isLoadingDatabaseLeak: false,
                databaseLeak: action.payload as HitEntry[]
            }
        case FAILED_DATABASELEAK:
            return {
                ... state,
                isLoadingDatabaseLeak: false
            }
        default:
            return state;
    }
}
