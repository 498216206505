import axios from "axios";
import { firstTimeNewPassword, LoginFirstTimeRes, LoginReq, LoginRes, Otp, OtpRequest, OtpResult, OtpSent, OtpSuccess, RecoverAccountResultRequest, ValidationError, VerifyToken } from "../constants";
import { env } from "../../environement/env";

axios.defaults.withCredentials = true;

export async function loginAPI(dataLogin: LoginReq) {
    try {
        const url = `${env.apiUrl}/login`
        const { data, headers } = await axios.post(url, dataLogin, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
            },
            withCredentials: true
        });
       window.localStorage.setItem('jwt', data?.jwt)
       window.localStorage.setItem("type", data?.typeEntreprise)
        return data as LoginRes | ValidationError;
    } catch (error: any) {
        return error.response.data as ValidationError
    }
}

export async function setNewPasswordAPI(dataSetNewPassword: firstTimeNewPassword) {
    try {
        const url = `${env.apiUrl}/setNewPassword`
        const { data } = await axios.post(url, dataSetNewPassword, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as OtpResult;
    } catch (error) {
        console.error(error);
    }
}

export async function sendOtpAPI(email: string) {
    try {
        const url = `${env.apiUrl}/sendOtp`
        const { data } = await axios.post(url, { email }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as LoginFirstTimeRes;
    } catch (error) {
        throw error;
    }
}

export async function verifyOtpAPI(dataOtp: Otp) {
    try {
        const url = `${env.apiUrl}/sendOtp`
        const { data } = await axios.post(url, dataOtp, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as OtpSuccess;
    } catch (error) {
        throw error;
    }
}

export const resetPasswordAPI = async (email: string) => {
    try {
        const url = `${env.apiUrl}/resetPassword`
        const { data } = await axios.post(url, { email }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
            
        });
        return data as LoginFirstTimeRes;
    } catch (error) {
        throw error;
    }
}

export const updatePasswordRecoverAccountAPI = async (dataReset: RecoverAccountResultRequest) => {
    try {
        const url = `${env.apiUrl}/setNewPassword`
        const { data } = await axios.post(url, dataReset, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        });
        return data as LoginFirstTimeRes;
    } catch (error) {
        
    }
}

export const logoutAPI = async () => {
    try {
        const url = `${env.apiUrl}/signout`
        const { data } = await axios.post(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        window.localStorage.removeItem('jwt')
        return data as LoginFirstTimeRes
    } catch (error) {
        throw error;
    }
}

export const checkIfTokenExpiredApi = async (token: string) => {
    try {
        const url = `${env.apiUrl}/checkIfToken`
        const { data } = await axios.post(url, {
            "jwt": token
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        },
    )
        return data as VerifyToken
    } catch (error) {
        throw error;
    }
}

export const verifyEmailConfirmAPI = async (email: string) => {
    try {
        const url = `${env.apiUrl}/verifyEmail`
        const { data } = await axios.post(url, {
            email
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        },
    )
        return data as VerifyToken
    } catch (error) {
        throw error;
    }
}