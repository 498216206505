import { Reducer } from "react";
import { FAILED_HOME_STATS, HomeViewStats, REQUEST_HOME_STATS, Stats, SUCCESS_HOME_STATS} from "../constants";

export interface StatsState {
    homeStats: HomeViewStats | null;
    isLoadingHome: boolean;
}

const initialState: StatsState = {
    homeStats: null,
    isLoadingHome: false
}

export const StatsReducer: Reducer<StatsState, Stats> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case REQUEST_HOME_STATS:
            return {
                ... state,
                isLoadingHome: true
            }
        case SUCCESS_HOME_STATS: 
            return {
                ... state,
                isLoadingHome: false,
                homeStats: actions.payload as HomeViewStats
            }
        case FAILED_HOME_STATS: {
            return {
                ... state,
                isLoadingHome: false
            }
        }
        default:
            return state;
    }
}