import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ChartHome from '../components/ChartHome'
import CriticalRiskBar from '../components/CriticalRiskBar'
import Piechart from '../components/Piechart'
import styles from '../dashboard.module.scss'
import ReactGA from 'react-ga4';
import PieChartRansomeGroups from '../components/PieChartRansomeGroups'
import { RansomeOverview } from '../../../core/constants'
import { getRansomeOverviexApi } from '../../../core/api/ransomeApi'
import { setNamePageLocalStorage } from '../../../core/actions/stepsNavPageActions'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import ChartHomeRansome from '../components/ChartHomeRansome'
import EmptyStateLogo from "../../../icons/Emptystate.svg"
import { CircularProgress } from '@mui/material'

interface props {
    setStepSetAll: any
}
function OverViewSection({setStepSetAll}: props) {
    const {overview, isLoadingOveriview,victims, groups} =useSelector(ransomeSelector)
    const [isRender, setisRender] = useState(false)
   
    
    
    useEffect(() => {
      document.title = 'Ransomware Intel - Overview'
      ReactGA.send({ hitType: "Ransomware Intel - Overview", page: window.location.pathname });
    }, [])
    console.log(overview?.ransomeGroupsPosts)
    
    const dataGlobalVictims = overview?.globalVictims?.map(item => ({
        date: item?.publish_date,
        victims: Number(item.nbr_of_victims)
    }))

    const setAll = (step: string) => {
        
        setStepSetAll(step)
    }
    console.log
    return (
      <>{
        !isLoadingOveriview ? 
        <div>
        <div className={styles.chartHomeWrapper}>
            <div className={styles.chartContainer}>
                <p style={{color: "white"}}>Global Victims</p>
                {
                    dataGlobalVictims?.length !== 0 ?
                    <ChartHomeRansome data={dataGlobalVictims}/>
                    :
                    <div style={{display:"flex", alignItems: "center", verticalAlign:"middle", height:"80%",  justifyContent:"center"}}>
                            <div>
                            <div style={{display: "flex", justifyContent:"center"}}>
                            <img src={EmptyStateLogo}/>
                            </div>
                            <p
                            style={{textAlign: "center",
                                color:"white"
                            }}
                        >No Victims found</p>
                            </div>
                        </div>
                }
            </div>
        <div className={styles.vulnerabilityContainer}>
            <p style={{color: "white"}}>Ransomware groups</p>
            {overview && <PieChartRansomeGroups overview={overview}/>}
        </div>
    </div>
    <div className={styles.threatAndMentionsBreakdown}>
        <div className={styles.threatBreakdown}>
        <div className={styles.vulnerabilityContainer}>
            <div className={styles.recentThreats_Header}>
                <p style={{color: "white"}}>Targeted Countries</p>
                <div className={styles.seeAll} onClick={()=>setAll("countries")}>
                    <span>See all</span>
                    <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 8}}/>
                </div>
            </div>
            <div className={styles.riskProgressBar}>
            {
                        overview?.targetCountries?.map(item => 
                            <CriticalRiskBar value={Number(item?.nbr_of_victims )/(victims && (victims?.length !== 0)? victims.length: 1)*100} title={item?.country} />
                        )
                    }
            </div>
        </div>
        </div>
        <div className={styles.mentionsBreakdown}>
            <div className={styles.vulnerabilityContainer}>
                <div className={styles.recentThreats_Header}>
                    <p style={{color: "white"}}>Ransomware groups posts</p>
                    <div className={styles.seeAll} onClick={()=>setAll('groups')}>
                        <span>See all</span>
                        <FontAwesomeIcon icon={faChevronRight} style={{fontSize: 8}}/>
                    </div>
                </div>
                <div className={styles.riskProgressBar}>
                    {
                        overview?.groupsPost?.map(item => 
                         item?.group_name !== "Others" && <CriticalRiskBar value={Number(item?.percent )} title={item?.group_name} />
                        )
                    }
                  
                </div>
            </div>
        </div>
    </div>
    </div>
    :
    <div className={styles.loaderVul}>
    <CircularProgress />
    </div>
       }</> 
    )
}

export default OverViewSection
