import React, {useState, useEffect} from 'react'
import { Button, TextField } from '@mui/material'
import styles from "./auth.module.scss"
import BackBtn from './components/BackBtn'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resePasswordRequest } from '../../core/actions/authActions'
import { useSelector } from 'react-redux'
import { authSelector } from '../../core/reducers/authReducer'
import ErrorNotification from '../Account/common/ErrorNotification'
import ReactGA from 'react-ga4';
import logoSignSVG from '../../icons/Login-Illustration.svg'
import { useIntercom } from '../Dashboard/IntercomSetup'
import logo from "../../icons/Defendis_Logo_White.svg"

function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { auth } = useSelector(authSelector);
    const [email, setemail] = useState('');
    const [OpenNotification, setOpenNotification] = useState(false)
    useEffect(() => {
        ReactGA.send({ hitType: "Login - Forgot Password", page: window.location.pathname });
        document.title = "Login - Forgot Password Password"
      if(auth.resetMailIsSent) {
        setOpenNotification(true)
        
      }
    }, [auth])
    
  
    const sendResetEmail = () => {
        if(email!=='') {
            dispatch(resePasswordRequest({email}))
        }
    }

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    return (
        <div className={styles.main}>
            <form onSubmit={e=>e.preventDefault()}>
                <img src={logo} alt="Logo" className={styles.logo} />
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0}}>
                    <div className={styles.login}>
                        <div style={{width:'50%'}}>
                        <div className={styles.loginInputs}>
                            <BackBtn/>  
                            <p className={styles.titleLogin}>Forgot Password</p>
                            <p style={{fontSize: 12}} className={styles.lightText}>Enter your email adress to help us find your account</p>
                            <TextField onChange={(e)=>setemail(e.target.value)} id="standard-basic" label="Work email" variant="standard" />
                            <Button onClick={sendResetEmail} type='submit' className={styles.btnLogin} style={{fontSize:14, fontWeight:"600"}}>Recover Account</Button>
                       </div>
                        </div>
                        <img src={logoSignSVG} className={styles.illus} style={{marginTop: '20%'}}/>
                    </div>
                    </div>
                    {
                auth.resetMailIsSent && <ErrorNotification
                    severity='success'    
                    open={OpenNotification}
                    message={auth.notification}
                    handleClose={handleCloseNotification}
                />
             }
             </form>
        </div>
    )
}

export default ForgotPassword
