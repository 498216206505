import React, { useEffect, useState } from 'react'
import Menu from './common/Menu'
import Header from './common/Header'
import styles from './account.module.scss'
import HeaderWatchList from './components/HeaderWatchList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons'
import BrandKeywordsWatchList from './components/BrandKeywordsWatchList'
import AssetsWatchList from './components/AssetsWatchList'
import DomainsWatchList from './components/DomainsWatchList'
import BinsWatchList from './components/BinsWatchList'
import RansomGroupsWatchList from './components/RansomGroupsWatchList'
import ExecutiveWatchList from './components/ExecutiveWatchList'
import IpWatchList from './components/IpWatchList'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../core/reducers/watchListReducer'
import { useDispatch } from 'react-redux'
import { GET_WATCH_LIST } from '../../core/constants'
import { useIntercom } from '../Dashboard/IntercomSetup'
import ReactGA from 'react-ga4';
import { CircularProgress } from '@mui/material'

function WatchList() {
  const dispatch = useDispatch()
  const { watchList, isLoading } = useSelector(watchListSelector)
    const [selectType, setSelectType] = useState('')
    const [open, setOpen] = useState(false)
    const [emailStorage, setemailStorage] = useState<string | undefined>()
    const [hmacStorage, sethmacStorage] = useState<string | undefined>()
    const [fullName, setfullName] = useState<string | undefined>()
    
    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useEffect(() => {
      document.title = "Settings - Watch list"
      ReactGA.send({ hitType: "Settings - Watch list", page: window.location.pathname });

      dispatch({type: GET_WATCH_LIST})
      
    }, [])
    
    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed
  })

    const renderComponent = () => {
        switch (selectType) {
            case 'assets':
                return <AssetsWatchList open={open} setOpen={setOpen}/>
            case 'brandKeyword':
                return <BrandKeywordsWatchList open={open} setOpen={setOpen}/>
            case 'domains':
                return <DomainsWatchList open={open} setOpen={setOpen}/>
            case 'bins':
                return <BinsWatchList open={open} setOpen={setOpen}/>
            case 'executives':
                return <ExecutiveWatchList open={open} setOpen={setOpen}/>
            case 'ransomGroups':
                return <RansomGroupsWatchList open={open} setOpen={setOpen}/>
            case 'ips':
                return <IpWatchList open={open} setOpen={setOpen}/>
            default:
                break;
        }
    }

    const renderComponentTitle = () => {
        switch (selectType) {
            case 'assets':
                return 'Assets Watch List'
            case 'brandKeyword':
                return 'Brand Keywords Watch List'
            case 'domains':
                return 'Domains Watch List'
            case 'bins':
                return 'BIN Watch List'
            case 'executives':
                return 'Executive Watch List'
            case 'ransomGroups':
                return "Ransom Groups Watch List"
            case 'ips':
                return "IP Watch List"
            default:
                break;
        }
    }

    console.log(isLoading)

  return (
    <div className={ styles.main }>
        <Menu/>
        <Header/>
        <div className={styles.content}>  
            <HeaderWatchList open={open} setOpen={setOpen} selectType={selectType} goBack={selectType === '' 
            ? 
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            :
            <p  onClick={()=>setSelectType("")} style={{cursor: 'pointer',color:"#9d9d9d",fontSize:"13px",fontWeight:500, display: 'flex',alignItems: "center", gap:"7px"}}>
                <FontAwesomeIcon icon={faArrowLeft}/>
                Go back
            </p>
            
        } title={selectType === '' ? 'Watch List': 
            renderComponentTitle()
        }/>
            {
              isLoading ?
              <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
                     :
                selectType === ''
                 ? 
                 <div className={styles.avatarPersonalAccountCard}>
                 <p style={{color: 'white', fontSize: '14px'}}>
                 This Watch List is to monitor vulnerabilities of products, servers, enterprise software, and programs your company runs.
                 </p>
                 
             <table className={styles.table}>
               <thead>
                 <tr className={styles.tableHead}>
                   <th style={{paddingLeft: 8, width: '382px'}}>Item</th>
                   <th style={{paddingLeft: 8, width:150}}>Items</th>
                   <th style={{paddingLeft: 8, width:190}}>Last modified</th>
                   
                 </tr>
               </thead>
               <tbody>
               <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('assets')} style={{cursor: 'pointer'}} className={styles.tableCell}>Assets Watch List</td>
                       <td className={styles.tableCell}>{watchList?.assets}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
                     <tr  className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('brandKeyword')} style={{cursor: 'pointer'}} className={styles.tableCell}>Brand Keywords Watch List</td>
                       <td className={styles.tableCell}>{watchList?.brandKeyword}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
               <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('domains')} style={{cursor: 'pointer'}} className={styles.tableCell}>Domain Watch List</td>
                       <td className={styles.tableCell}>{watchList?.domains}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
                     <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('ips')} style={{cursor: 'pointer'}} className={styles.tableCell}>IP Watch List</td>
                       <td className={styles.tableCell}>{watchList?.ips}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
                     <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('bins')} style={{cursor: 'pointer'}} className={styles.tableCell}>BINs Watch List</td>
                       <td className={styles.tableCell}>{watchList?.bins}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
               <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('executives')} style={{cursor: 'pointer'}} className={styles.tableCell}>Executive Watch List</td>
                       <td className={styles.tableCell}>{watchList?.executives}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
                  <tr className={styles.tableActivityRowWatchList}>
                       <td onClick={()=>setSelectType('ransomGroups')} style={{cursor: 'pointer'}} className={styles.tableCell}>Ransom Group Watch List</td>
                       <td className={styles.tableCell}>{watchList?.ransomes}</td>
                       <td style={{display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell} >
                         <FontAwesomeIcon icon={faCalendar}/>
                         <p>April 22, 2024</p>
                       </td>
                     </tr>
                     
                 </tbody>
             </table>
               </div>
                :
                renderComponent()
            }
        </div>
        
    </div>
  )
}

export default WatchList