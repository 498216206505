import { call, put } from "redux-saga/effects";
import { getAllVulnerabilitiesAction, setVulDetailAction, successVulOverviewAction } from "../actions/vulnerabilitiesActions";
import { getAllVulnerabilitiesAPI, getOverviewVulAPI, getVulnerabilitieByCVEAPI } from "../api/vulnerabilityApi";
import { CVEItem, FAILED_VULNERABILITIES, FAILED_VULNERABILITIES_OVERVIEW, Page, SortVul, VulnerabilitiesAction, Vulnerability, VulnerabilityList } from "../constants";

export function* getAllVulnerabilitiesSaga(action: VulnerabilitiesAction) {
    try {
        
        const response:VulnerabilityList = yield call(getAllVulnerabilitiesAPI, action.payload as SortVul);
        yield put(getAllVulnerabilitiesAction(response))
    } catch (error) {
        yield put({
            type: FAILED_VULNERABILITIES
        })
    }
}

export function* getOverviewVulSaga(action: VulnerabilitiesAction) {
    try {
        
        const response:VulnerabilityList = yield call(getOverviewVulAPI, action.payload as string);
        yield put(successVulOverviewAction(response))
    } catch (error) {
        yield put({
            type: FAILED_VULNERABILITIES_OVERVIEW
        })
    }
}


export function* getVulnerabilitieByCVESaga(action: VulnerabilitiesAction) {
    try {
        const query = action.payload as string
        const response:Vulnerability = yield call(getVulnerabilitieByCVEAPI, query);
        yield put(setVulDetailAction(response))
    } catch (error) {
        throw error;
    }
}