import axios from "axios";
import { env } from "../../environement/env";
import { DataLeaksView, HomeViewStats } from "../constants";

axios.defaults.withCredentials = true

export async function getHomeViewStatsApi(sortByDate: string) {
        const { data } = await axios.get(`${env.apiUrl}/stats/home?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as HomeViewStats
}


export async function getHomeViewStatsDataLeak() {
    try {
        const { data } = await axios.get(`${env.apiUrl}/stats/statsDataLeak`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as DataLeaksView
    } catch (error) {
        console.error(error)
    }
}