import React from 'react'
import styles from "../../dashboard.module.scss"
function CryptoWallet() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
          <th style={{paddingLeft: 8}}>
          Address
          </th>
          <th style={{paddingLeft: 8}}>
          Blockchain
          </th>
          <th style={{paddingLeft: 8}}>
          Balance
          </th>
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowWatchList}>
              <td style={{width: '698px', padding: '10px 16px', color: "#e9bd08"}} className={styles.tableCell}>
                bc1q65f238kv6gc235smuzcehshxcqljn2g7l5sz7j
              </td>
              <td style={{width: '240px', padding: '10px 16px'}} className={styles.tableCell}>
                bitcoin
              </td>
              <td style={{width: '180px', padding: '10px 16px'}} className={styles.tableCell}>
                $1,185
              </td>
              </tr>
            
          
          </tbody>
      </table>
    )
}

export default CryptoWallet
