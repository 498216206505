import { faCalendar, faChevronDown, faChevronUp, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import moment from 'moment'
import { REQUEST_VICTIMS } from '../../../core/constants'
import ReactGA from 'react-ga4';
import UnassignedLogo from '../../../icons/Unassigned.svg'
import { exportToExcel } from '../components/ExportToExcel'
import { Alert, Avatar, CircularProgress, Snackbar } from '@mui/material'
import Loader from  "../../../icons/Loader.svg"
import { CircleFlag } from 'react-circle-flags'
import EmptyState from '../common/EmptyState'
import EmptyStateVictims from '../common/EmptyStateVictims'
type SortOrder = 'asc' | 'desc'

function VictimsSection() {
  const dispatch = useDispatch()
  const { victims, isLoadingVictims } = useSelector(ransomeSelector)
  const [search, setsearch] = useState('')
  const [isDonwloaded, setisDonwloaded] = useState(false)
  const [sortBy, setSortBy] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  useEffect(() => {
    
    document.title = "Victims"
    ReactGA.send({ hitType: "Ransomware Intel - Victims", page: window.location.pathname });

    
  }, [])

  

  const dataXlsx = victims?.map(item => ({
      'country': item?.country,
      'Victims': item?.post_title,
      'Groups': item?.group_name,
      'Discovery date': moment(item?.discovered).format('MMMM DD, YYYY'),
      'Attack date': moment(item?.published).format('MMMM DD, YYYY')
  }))

  const handleOpenNotf = () => setisDonwloaded(!isDonwloaded)

  const handleExportList = () => {
    setisDonwloaded(true)
    exportToExcel(dataXlsx || [], `Ransomeware-victims-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
};
const handleSort = (column: string) => {
  if (sortBy === column) {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
  } else {
    setSortBy(column)
    setSortOrder('asc')
  }
}

  const results = victims?.filter( values => 
    search !== '' ? 
    (values.post_title.includes(search) || values.post_title.includes(search))
    : values
   )
   const sortedVictims = results?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'country') {
      comparison = a.country.localeCompare(b.country)
    } else if (sortBy === 'post_title') {
      comparison = a.post_title.localeCompare(b.post_title)
    } else if (sortBy === 'discovered') {
      comparison = a.discovered.localeCompare(b.discovered)
    } else if (sortBy === 'group_name') {
        comparison = a.group_name.localeCompare(b.group_name)
    } else if (sortBy === 'published') {
      comparison = a.published.localeCompare(b.published)
    }
    

    return sortOrder === 'asc' ? comparison : -comparison
  })
console.log(isLoadingVictims)
    return (
        <div className={styles.awaitingApproval} style={{marginTop:20}}>
                        
                        <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
                            <div className={styles.searchAndIcon}>
                                <input onChange={(e)=>setsearch(e.target.value)} className={styles.search} name='search' placeholder='Search'/>
                                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                            </div>
                            <div className={styles.removeSelected} onClick={handleExportList}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 12}} />
                            </div>
                        </div>
                    {
                      !isLoadingVictims?
                      sortedVictims && sortedVictims?.length !== 0 ?

                      <table className={styles.table} >
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8, cursor:'pointer'}} onClick={() => handleSort('country')}>
                            Country {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'country' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}} onClick={() => handleSort('post_title')}>
                            Victims {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'post_title' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}} onClick={() => handleSort('group_name')}>
                            Groups {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'group_name' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}} onClick={() => handleSort('discovered')}>
                            Discovery date {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'discovered' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, display:"flex", justifyContent:"start", paddingRight:13, cursor:'pointer'}} onClick={() => handleSort('published')}>
                                    <p style={{display:"flex" , alignItems:'center', gap: 5, width:150}}>
                                    <span>Attack date</span>
                                    <FontAwesomeIcon 
                                      icon={sortBy === 'published' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                                      size="1x" 
                                      height={12} 
                                      width={11} 
                                    />
                                    </p>
                                    </th>
                        </tr>
                      </thead>
                      <tbody>
                          {
                            sortedVictims?.map(item =>
                              <tr className={styles.tableActivityRowWatchListAssets}>
                            <td style={{width: '104px', padding: '10px 16px'}} className={styles.tableCell}>
                            {
                              item?.country ? 
                              <CircleFlag countryCode={item?.country?.toLowerCase()} height="32" />
                              :
                              <Avatar
                            src={UnassignedLogo}
                            sx={{
                              width: 32,
                              height: 32,
                              borderRadius: '50%',
                              overflow: 'hidden',
                              img: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '50%', // Ensure the image itself is rounded
                              },
                            }}
                          />
                            }
                              
                                
                            </td>
                              <td style={{width: '434px', padding: '10px 16px'}}  className={styles.tableCell}>
                                {item?.post_title}
                              </td>
                              <td style={{width: '200px', color: "#e9bd08"}} className={styles.tableCell}>
                              {item?.group_name}
                              </td>
                              <td style={{width: '190px', padding: '10px 16px'}} className={styles.tableCell} >
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "+moment(item?.discovered).format('MMMM DD, YYYY')}
                              </td>
                              <td style={{paddingLeft: 8, width:150}} className={styles.tableCell} >
                                <div>
                                <div style={{  display: 'flex', alignItems: "center", gap: 10, justifyContent: "start"}}>
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "+moment(item?.published).format('MMMM DD, YYYY')}
                                </div>
                                </div>
                              </td>
                            </tr>
                            )
                          }
                          
                        
                        </tbody>
                    </table>:
                     <EmptyStateVictims/>

                     :
                     <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
                    }
                    <Snackbar 
      open={isDonwloaded} 
      autoHideDuration={300} 
      onClose={handleOpenNotf}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      
    >
      <Alert onClose={handleOpenNotf} severity={"success"} sx={{ width: '100%' }}>
        {"Download has started..."}
      </Alert>
    </Snackbar>
                      </div> 
    )
}

export default VictimsSection
