import React from 'react'
import styles from '../dashboard.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTrendDown, faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';

interface props {
    text: string;
    num: number;
    percent?:number;
}

function Card({ text, num, percent }: props) {
    return (
        <div className={styles.card}>
            <p style={{fontSize: 14, fontWeight: 500}}>{text}</p>
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", marginTop: 0, paddingTop:0, paddingRight:20}}>
                <p style={{fontSize: 20, fontWeight: 600}}>{num}</p>
                {/* <p style={{fontSize: 12, color: percent > 0 ? "green": 'red'}}>{percent} <FontAwesomeIcon icon={percent > 0 ? faArrowTrendUp : faArrowTrendDown} /></p>   */}
            </div>
        </div>
    )
}

export default Card
