import React, {FormEvent, useState, useEffect} from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import AvatarUpload from './components/AvatarUpload'
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import { getUserInfoAction, getUserInfoRequest, updateUserInfoRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { UserInfo } from '../../core/constants'
import phonecodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import { useNavigate } from 'react-router-dom'
import { authSelector } from '../../core/reducers/authReducer'
import { useIntercom } from '../Dashboard/IntercomSetup'
import ReactGA from 'react-ga4';
import { checkUsernameApi } from '../../core/api/userApi'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
    },
    validEmail: {
    },
    disabledInput:{
        color: "#333333"
    }
  }),
);

const PersonnalAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { auth } = useSelector(authSelector)
    const { userInfo, message } = useSelector(userSelector)
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [userNameError, setuserNameError] = useState<boolean | undefined>(false)
    const [emailSecondary, setEmailSecondary] = useState<string>('');
    const [errorSecondary, setErrorSecondary] = useState<boolean>(false);
    const [formState, setformState] = useState<UserInfo>(userInfo);
    const [openNotification, setopenNotification] = useState(false)
    const [openNotfUser, setopenNotfUser] = useState(false)
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean>(false)
    
    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })
    useEffect(() => {
        document.title = "Settings - Account"
        ReactGA.send({ hitType: "Settings - Account", page: window.location.pathname });

        const isUpdated = Object.keys(userInfo)
        .filter(key => key !== 'profilePicture') // Exclude 'profilePicture' from the comparison
        .some(key => userInfo[key] !== formState[key]);
        
        setDataIsUpdated(isUpdated);
            
      }, [formState, userInfo])
    const validateEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);
        setError(!validateEmail(value));
    };

    const handleChangeEmailSecondary = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmailSecondary(value);
        setErrorSecondary(!validateEmail(value));
    };


    // const handleChange = (event: SelectChangeEvent) => {
    //     setRecent(event.target.value as string);
    //   };

    const handleChange = async (event: typesHandleChange) => {
        const { name, value } = event.target;
        if(name === 'secondaryEmail') {
            setErrorSecondary(!validateEmail(value));
        }
        if(name === 'email') {
            setError(!validateEmail(value));
        }
        
        setformState((prevState: any) => ({
        ...prevState,
        [name]: value,
        }));
    }

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(updateUserInfoRequest({
            userReq: formState
        }))
        setTimeout(() => {
            dispatch(getUserInfoRequest())
            setopenNotification(true)
        }, 2000);
       
    }

    useEffect(() => {
      setformState(userInfo);
    }, [userInfo])
    
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }

    const handleCloseNotificationUsername = () => {
        setopenNotfUser(false)
    }


    
   
    
    
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderPersonalInfo title='Personal Account' dataIsUpdated={dataIsUpdated} handleSubmit={handleSubmit} setformState={setformState as any}/>
                <div  className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Avatar</p>
                  <p className={styles.avatarPersonalAccountCard_text}>Your profile photo appears when you log in to Defendis and is visible to others in your organisation. You can update your profile photo at any time and it will automatically update any where it appears.</p>
                    <AvatarUpload/>
                </div>
                <div  style={{marginTop: 24}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Personal information</p>
                    <p className={styles.avatarPersonalAccountCard_text}>
                        This the personal information you use to access and manage your account. You can also add a mobile
                        phone number and secondary email for account security, recovery, and notifications.
                    </p>
                    <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" fullWidth>

                    <div className={styles.personalInfosInputs}>
                        <TextField
                        value={formState.firstName}
                         name='firstName'
                          id="standard-basic"
                           label="First Name" 
                           variant="standard" 
                           onChange={handleChange}
                           />
                        <TextField
                        value={formState.lastName}
                        onChange={handleChange}
                         name='lastName'
                          id="standard-basic"
                           label="Last Name"
                            variant="standard" />
                    </div>
                    <div className={styles.personalInfosInputs}>
                        <div className={styles.primaryEmailPersonalInfo}>
                            <TextField 
                            onChange={handleChange}
                            name='email'
                            className={formState.email != "" ? error ? classes.unvalidEmail: classes.validEmail : undefined}
                             value={formState.email} id="standard-basic"   label="Email" variant="standard" />
                            {formState.email!=="" && (
                                <FontAwesomeIcon style={{marginTop: 36,marginLeft:-20}} icon={error ? faCircleXmark : faCircleCheck} color={error ? "red" : "green"}/>
                            )}
                        </div>
                        <div className={styles.primaryEmailPersonalInfo}>
                        <TextField 
                        
                        name='username'
                    
                        onChange={handleChange}value={formState.username}
                        id="standard-basic" label="Username" variant="standard" />
                        
                        {//@ts-ignore
                        !formState.isUnique && (
                                <FontAwesomeIcon style={{marginTop: 36,marginLeft:-20}} icon={faCircleXmark} color={"red"}/>
                            )}
                        </div>
                        
                    </div>
                    <div className={styles.phoneNumberPersonalInfo} style={{width:'49%'}}>
                        <Select
                            name='phonecode'
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formState.phonecode}
                            onChange={handleChange}
                            className={styles.selectPhoneNumberPersonalInfo}
                        >

                            {
                                // @ts-ignore
                                phonecodes.sort((a, b)=>a?.dial_code-b?.dial_code).map((code) => <MenuItem value={code.dial_code}>{code.dial_code}</MenuItem>)
                            }
                        </Select>
                        <TextField onChange={handleChange} value={formState.phone} name='phone' id="standard-basic" className={styles.phoneNumberTextField} variant="standard" />
                    </div>
                    </FormControl>
                    </form>
                </div>
                {/* <div style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Preferences</p>
                    <div className={styles.preferenceAccountInfo}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recent}
                        onChange={handleChange}
                        className={styles.selectPreferences}
                    >
                        <MenuItem value={'10'}>{"English (United kingdom)"}</MenuItem>
                        <MenuItem value={'20'}>{"Frensh (France)"}</MenuItem>
                    </Select>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recent}
                        onChange={handleChange}
                        className={styles.selectPreferences}
                        
                    >

                        <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                        <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
                    </div>      
                </div> */}
            </div>
            <ErrorNotification
                severity={message ==="User updated successfuly" ? 'success': 'error'}
                open={openNotification}
                message={message}
                handleClose={handleCloseNotification}
            />
           
        </div>
  )
}

export default PersonnalAccount