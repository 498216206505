import React, { useEffect } from 'react'
import styles from '../dashboard.module.scss'
import Card from "./Card"
import ChartHome from './ChartHome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import CriticalRiskBar from './CriticalRiskBar'
import Piechart from './Piechart'
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import { CVEItem, Vulnerability, VulnerabilityList } from '../../../core/constants'
import { CircularProgress } from '@mui/material'
import ChartHomeVul from './ChartHomeVul'
import EmptyStateLogo from "../../../icons/Emptystate.svg"

function OverviewComponent() {
    const { overviewVul, isLoadingVulOverview, vulnerabilies } = useSelector(vulnerabilitiesSelector)
    useEffect(() => {
      document.title = "Vulnerability Intel - Overview"
      ReactGA.send({ hitType: "Vulnerability Intel - Overview", page: window.location.pathname });
    }, [])
    const getRiskScore = (riskScore:number) => {
        if (riskScore === undefined) return "None"
        else if(riskScore <= 3.9) return "Low";
        else if(riskScore >= 4.0 && riskScore <= 6.9) return "Medium";
        else if(riskScore >= 7.0 && riskScore <= 8.9) return "High";
        else return "Critical";
      }
    const countRiskScores = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { Low: 0, Medium: 0, High: 0, Critical: 0, None:0 };
    
        objects?.forEach(obj => {
            const category = getRiskScore(obj?.base_score);
            counts[category]++;
        });
    
        return counts;
    };
    const countRiskScoresExploibility = (objects:Vulnerability[]| undefined): Record<string, number> => {
        const counts = { LOW: 0, MEDIUM: 0, HIGH: 0, CRITICAL: 0, NONE:0 };
    
        objects?.forEach(obj => {
            const category = obj?.base_severity as "LOW" |  "MEDIUM" | "HIGH" | "CRITICAL" | "NONE"
            counts[category]++;
        });
    
        return counts;
    };
    const countAttackVectors = (objects: Vulnerability[] | undefined): Record<string, number> => {
        const counts = { NETWORK: 0, PHYSICAL: 0, ADJACENT: 0, LOCAL: 0};
    
        objects?.forEach(obj => {
            const category = obj?.attack_vector as "NETWORK" |  "LOCAL" | "ADJACENT" | "PHYSICAL" 
            counts[category]++;
        });
    
        return counts;
    };
    const riskCounts = countRiskScores(overviewVul?.cves);
    const riskExploibility = countRiskScoresExploibility(overviewVul?.cves)
    const attackVectors = countAttackVectors(overviewVul?.cves)
   console.log(vulnerabilies?.cves.filter(item => item.attack_vector === 'LOCAL').length)
    const data = overviewVul?.vulnTimeLines?.map(item => ({
        date: item.published_date,
        vulnerabilities: Number(item.vulnerabilities)
    }))
   const totalRiskScoresExploibility = riskCounts.Critical + riskCounts.High + riskCounts.Medium + riskCounts.Low
   const  totalattackVectors = riskExploibility.CRITICAL + riskExploibility.HIGH + riskExploibility.MEDIUM + riskExploibility.LOW
   console.log("low: "+riskCounts.Critical/totalRiskScoresExploibility)
    return (
    isLoadingVulOverview ?
    <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
    :
    <div className={styles.statsThreats}>
        <div className={styles.cards}>
            <Card 
                text='Critical'
                num={riskCounts.Critical}
                percent={-60.15}
            />
            <Card
                text='High'
                num={riskCounts.High}
                percent={+9.15}
            />
            <Card
                text='Medium'
                num={riskCounts.Medium}
                percent={-20.15}
            />
            
            <Card
                text='Low'
                num={riskCounts.Low}
                percent={+11.01}
            />
        </div>
        <div className={styles.chartHomeWrapper}>
            <div className={styles.chartContainer}>
                <p style={{color: "white"}}>Vulnerabilities Timeline</p>
                {
                    data?.length!==0 ?
                    <ChartHomeVul data={data}/>:
                    <div style={{display:"flex", alignItems: "center", verticalAlign:"middle", height:"80%",  justifyContent:"center"}}>
                                <div>
                                <div style={{display: "flex", justifyContent:"center"}}>
                                <img src={EmptyStateLogo}/>
                                </div>
                                <p
                                style={{textAlign: "center",
                                    color:"white"
                                }}
                            >No Vulnerabilities found</p>
                                </div>
                            </div>
                }
            </div>
            <div className={styles.vulnerabilityContainer}>
                <p style={{color: "white"}}>Attack Vectors</p>
                <Piechart
                    Network={(attackVectors.NETWORK/(vulnerabilies && vulnerabilies.cves.length !==0 ? vulnerabilies.cves.length:1))*100}
                    Adjacent={(attackVectors.ADJACENT/(vulnerabilies && vulnerabilies.cves.length !==0 ? vulnerabilies.cves.length:1))*100}
                    Physical={(attackVectors.PHYSICAL/(vulnerabilies && vulnerabilies.cves.length !==0 ? vulnerabilies.cves.length:1))*100}
                    Local={(attackVectors.LOCAL/(vulnerabilies && vulnerabilies.cves.length !==0 ? vulnerabilies.cves.length:1))*100}
                />
            </div>
        </div>
        <div className={styles.threatAndMentionsBreakdown}>
            <div className={styles.threatBreakdown}>
            <div className={styles.vulnerabilityContainer}>
                <div className={styles.recentThreats_Header}>
                    <p style={{color: "white"}}>Severity Breakdown</p>
                    
                </div>
                <div className={styles.riskProgressBar}>
                <CriticalRiskBar value={(riskExploibility.CRITICAL/totalattackVectors)*100} title="Critical risk" />
                    <CriticalRiskBar value={(riskExploibility.HIGH/totalattackVectors)*100} title="High risk" />
                    <CriticalRiskBar value={(riskExploibility.MEDIUM/totalattackVectors)*100} title="Medium risk" />
                    <CriticalRiskBar value={(riskExploibility.LOW/totalattackVectors)*100} title="Low risk" />
                </div>
            </div>
            </div>
            <div className={styles.mentionsBreakdown}>
            <div className={styles.vulnerabilityContainer}>
                <div className={styles.recentThreats_Header}>
                    <p style={{color: "white"}}>Exploitability Breakdown</p>
                    
                </div>
                <div className={styles.riskProgressBar}>
                    <CriticalRiskBar value={(riskCounts.Critical/totalRiskScoresExploibility)*100} title="Critical risk" />
                    <CriticalRiskBar value={(riskCounts.High/totalRiskScoresExploibility)*100} title="High risk" />
                    <CriticalRiskBar value={(riskCounts.Medium/totalRiskScoresExploibility)*100} title="Medium risk" />
                    <CriticalRiskBar value={(riskCounts.Low/totalRiskScoresExploibility)*100} title="Low risk" />
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default OverviewComponent