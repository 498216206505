import { faCalendar, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { useDispatch } from 'react-redux'
import { Domaine, REQUEST_DOMAINE } from '../../../core/constants'
import { setDomainDetailAction } from '../../../core/actions/brandIntelActions'
import ReactGA from 'react-ga4'
import { CircularProgress } from '@mui/material'
import EmptyStateDomaines from '../common/EmptyStateDomaines'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'

type SortOrder = 'asc' | 'desc'

function DomainsSection() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { domains, isLoadingDomaines } = useSelector(brandIntelSelector)
    const [sortBy, setSortBy] = useState<string>('severity')
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
    const [search, setSearch] = useState<string>("")
    const { notifs } = useSelector(notificationSelector)
    const result = search !== "" ? domains?.filter(item => item.domain.toLowerCase().includes(search.toLowerCase())) : domains

    useEffect(() => {
        
        document.title = "Domains"
        ReactGA.send({ hitType: "Brand Intel - Domains", page: window.location.pathname })
    }, [dispatch])

    const getRiskScore = (riskScore: number): string => {
        if (riskScore <= 39) return "Low"
        else if (riskScore >= 40 && riskScore <= 69) return "Medium"
        else if (riskScore >= 70 && riskScore <= 89) return "High"
        else return "Critical"
    }

    const setDomaineDetail = (data: Domaine) => {
        navigate("/brandIntel/domainDetail")
        dispatch(setDomainDetailAction(data))
    }

    const handleSort = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else {
            setSortBy(column)
            setSortOrder('asc')
        }
    }
    const domainsNotif = notifs.filter(item => item.isDomains === true && item.read === false)
    const sortedResult = result?.sort((a, b) => {
        const aInDmnNotif = domainsNotif.some((notif) => notif?.data?.id === a.id);
            const bInDmnNotif = domainsNotif.some((notif) => notif?.data?.id === b.id);
    
            // Sort elements in vulNotif to the top
            if (aInDmnNotif && !bInDmnNotif) return -1;
            if (!aInDmnNotif && bInDmnNotif) return 1;
        let comparison = 0
        if (sortBy === 'domain') {
            comparison = a.domain.localeCompare(b.domain)
        } else if (sortBy === 'riskScore') {
            comparison = a.score - b.score
        } else if (sortBy === 'date') {
            comparison = new Date(a.detection_date).getTime() - new Date(b.detection_date).getTime()
        }

        return sortOrder === 'asc' ? comparison : -comparison
    })

    return (
        <div style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", height: 45 }}>
                <div className={styles.searchAndIcon}>
                    <input 
                        value={search} 
                        onChange={(e) => setSearch(e.target.value)} 
                        className={styles.search} 
                        name='search' 
                        placeholder='Search' 
                    />
                    <FontAwesomeIcon 
                        className={styles.searchIcon} 
                        color='#333333' 
                        icon={faSearch} 
                        size='1x' 
                    />
                </div>
            </div>
            {
                !isLoadingDomaines ?
                    sortedResult && sortedResult.length !== 0 ?
                        <div style={{ width: "100%" }}>
                            <table className={styles.table}>
                                <thead>
                                    <tr className={styles.tableHead}>
                                        <th></th>
                                        <th style={{ paddingLeft: 8 }} onClick={() => handleSort('domain')}>
                                            <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                                <span>Domain </span>
                                                <FontAwesomeIcon
                                                    icon={sortBy === 'domain' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                                    size="1x"
                                                    height={8}
                                                    width={7}
                                                />
                                            </p>
                                        </th>
                                        <th style={{ paddingLeft: 8 }} onClick={() => handleSort('riskScore')}>
                                            <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                                                <span>Risk Score </span>
                                                <FontAwesomeIcon
                                                    icon={sortBy === 'riskScore' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                                    size="1x"
                                                    height={8}
                                                    width={7}
                                                />
                                            </p>
                                        </th>
                                        <th style={{ paddingLeft: 8, display: "flex", justifyContent: "end", paddingRight: 13 }} onClick={() => handleSort('date')}>
                                            <p style={{ fontSize: 14, color: "white", fontWeight: 500, width: 150 }}>
                                                <span>Date Added </span>
                                                <FontAwesomeIcon
                                                    icon={sortBy === 'date' && sortOrder === 'asc' ? faChevronUp : faChevronDown}
                                                    size="1x"
                                                    height={8}
                                                    width={7}
                                                />
                                            </p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sortedResult?.map(item =>
                                            <tr  onClick={() => setDomaineDetail(item)} className={styles.tableActivityRowUsers}>
                                                 <td className={styles.tableCell} style={{width:30}}>
                            {
                                        domainsNotif.some((respItem: any) => {
                                            
                                            return respItem?.data?.id === item.id;
                                        }) ? (
                                            <div className={styles.redPoint} />
                                        ) : (
                                         null // Nothing is displayed if the condition is not met
                                        )
                                    }
                              </td>
                                                <td className={styles.tableCell} style={{ width: "70%" }}>{item.domain}</td>
                                                <td className={styles.tableCell}>
                                                    <p className={`${styles.riskScore} ${styles[getRiskScore(item.score)]}`}>
                                                        {item.score}
                                                    </p>
                                                </td>
                                                <td className={styles.tableCell} style={{ display: "flex", justifyContent: "end" }}>
                                                    <div style={{ padding: 2, display: 'flex', alignItems: "center", gap: 10, width: '160px', justifyContent: "start" }}>
                                                        <FontAwesomeIcon icon={faCalendar} size='1x' height={12} width={11} />
                                                        <p style={{ fontSize: 14 }}>{item.detection_date}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        <EmptyStateDomaines />
                    :
                    <div className={styles.loaderVul}>
                        <CircularProgress />
                    </div>
            }
        </div>
    )
}

export default DomainsSection
