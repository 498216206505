import React from 'react'
import classes from '../views/Dashboard/dashboard.module.scss'
import Logo from '../icons/Defendis_Logo_White.svg'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function ErrorPage() {
    const navigate = useNavigate()
    const previous = () => navigate(-1)
  return (
    <div className={classes.main}>
        <img src={Logo} alt="Logo" className={classes.logo} />
        <div className={classes.errorContainer}>
            <h2 style={{textAlign:'center', color:'white'}}>Oops! Something Went Wrong</h2>
            <p style={{textAlign:'center', color:'white'}}>We’re sorry, but it looks like something didn’t go as planned. If it happens again, please get in touch with the Support Team.</p>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Button onClick={previous}  className={classes.btnLogin} style={{fontSize:14, fontWeight:"600", width:231}}>Go back</Button>
            </div>
        </div>
    </div>
  )
}
