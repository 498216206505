import { EmailsInviteLink, GET_SECURITY_PAGE, GET_SECURITY_PAGE_REQUEST, GET_USER_INFO_FAILURE, GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, NotificationMessage, OTP_REQUEST, Otp, OtpRequest, SEND_INVITE_LINK, SET_MESSAGE, Security, UPDATE_SECURITY_PAGE, UPDATE_USER_INFO_REQUEST, User, UserReq } from "../constants";

export const getUserInfoRequest = () => ({
    type: GET_USER_INFO_REQUEST
})

export const getUserInfoAction = (data: User) => ({
    type: GET_USER_INFO_SUCCESS,
    payload: data
})


export const getUserInfoFailureAction = () => ({
    type: GET_USER_INFO_FAILURE
})

export const updateUserInfoRequest = (dataReq: UserReq) => ({
    type: UPDATE_USER_INFO_REQUEST,
    payload: dataReq
})

export const getInfoSecurityPageRequest = () => ({
    type: GET_SECURITY_PAGE_REQUEST
})

export const getInfoSecurityUser = (dataRes: Security) => ({
    type: GET_SECURITY_PAGE,
    payload: dataRes
})

export const updateSecurityInfoUserRequest = (dataShouldUpdate: Security) => ({
    type: UPDATE_SECURITY_PAGE,
    payload: dataShouldUpdate
})

export const setMessageAction = (message: NotificationMessage) => ({
    type: SET_MESSAGE,
    payload: message
})

export const sendInviteLinkRequest = (data: EmailsInviteLink) => ({
    type: SEND_INVITE_LINK,
    payload: data
})