import React from 'react';
import styles from '../dashboard.module.scss'; // Make sure to import the CSS file


interface props {
    title: string;
    value: number;
}

const CriticalRiskBar = ({ title, value}: props) => {
  // Calculate the width of the inner bar based on the value and maximum value
  const width = (value / 100) * 100;

  return (
    <div>
      <div className={styles.critical_risk_bar_header}>
            <span style={{fontSize:13}}>{title}</span>
            <span style={{fontSize:13}}>{ value < 10 ? "0"+value.toFixed(0) : value.toFixed(0)}%</span>
        </div>
      <div className={styles.critical_risk_bar}>
      <div className={styles.critical_risk_bar_inner} style={{ width: `${width >= 100 ? 100: width}%` }}/>
    </div>
    </div>
  );
};

export default CriticalRiskBar;