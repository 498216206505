import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import ReactGA from 'react-ga4';
import styles from '../dashboard.module.scss';
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector';
import { REQUEST_SURFACEWEB, SurfaceWeb } from '../../../core/constants';

function SurfaceWebSection() {
    const dispatch = useDispatch();
    const { isLoading, surfaceWeb } = useSelector(brandIntelSelector);

    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        document.title = "Surface Web";
        ReactGA.send({ hitType: "Brand Intel - Surface Web", page: window.location.pathname });
    }, []);

    if (isLoading) {
        return <Skeleton variant="rectangular" width={210} height={118} />;
    }

    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };

    const toggleSortOrder = () => {
        setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
    };

    // Filter the data based on the search term and source
    const filteredData = Object.values(surfaceWeb).filter((item: SurfaceWeb) =>
        item.source.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Sort the filtered data based on publishedAt
    const sortedData = filteredData.sort((a: SurfaceWeb, b: SurfaceWeb) => {
        const dateA = new Date(a.publishedAt).getTime();
        const dateB = new Date(b.publishedAt).getTime();
        return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });

    return (
        <div style={{ marginTop: 20 }}>
            <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                <div className={styles.searchAndIcon}>
                    <input 
                        className={styles.search} 
                        name='search' 
                        placeholder='Search by source' 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)} 
                    />
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x' />
                </div>
                <div style={{ display: 'flex', alignItems: "center", gap: 25, cursor: 'pointer' }} onClick={toggleSortOrder}>
                    <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                        <span>{sortOrder === 'desc' ? 'Latest First' : 'Oldest First'} </span>
                        <FontAwesomeIcon icon={sortOrder === 'desc' ? faChevronDown : faChevronUp} size="1x" height={12} width={11} />
                    </p>
                </div>
            </div>
            <div className={styles.surfaceWebRow}>
                {sortedData.length > 0 ? (
                    sortedData.map((item: SurfaceWeb, index: number) => (
                        <div key={index} className={styles.surfaceWebCol} onClick={() => openlinkDetail(item?.rssLink)}>
                            <div style={{ display: "flex", gap: 24 }}>
                                <p style={{ color: "white", fontSize: 14 }}>{item?.title}</p>
                                <img src={item?.image} className={styles.surfaceWebImg} />
                            </div>
                            <div style={{height:13, display: "flex", justifyContent: "space-between", alignItems: "center", marginTop:24}}>
                                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                                    <div style={{ width: 6, height: 6, backgroundColor: "#e11900", borderRadius: "50%" }} />
                                    <p style={{ color: "#afafaf", fontSize: 12 }}>{item?.source}</p>
                                </div>
                                <p style={{ color: "#afafaf", fontSize: 12 }}>{moment(item?.publishedAt).fromNow()}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={styles.emptyState}>
                        <p style={{ color: "#afafaf", fontSize: 16 }}>No results found for "{searchTerm}"</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SurfaceWebSection;
