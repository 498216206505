import { useEffect, useRef } from 'react';

type IntercomSettings = {
  app_id: string;
  [key: string]: any;
};

export function useIntercom(appId: string, settings?: IntercomSettings): void {
  const initialized = useRef(false);

  useEffect(() => {
        if (!initialized.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = `https://widget.intercom.io/widget/${appId}`;
            script.onload = () => {
              if ((window as any).Intercom) {
                window.intercomSettings = { app_id: appId, ...settings };
                (window as any).Intercom('boot', window.intercomSettings);
                initialized.current = true;  // Mark as initialized
              }
            };
            document.body.appendChild(script);
          }
    if (initialized.current) {
        (window as any).Intercom('update', settings || {});
      }
  }, [appId, settings]);  // Dependencies only include appId to ensure one-time initialization

  useEffect(() => {
    
  }, [settings]);  // This effect runs only when settings change

  useEffect(() => {
    return () => {
      if (window.Intercom) {
        window.Intercom('shutdown');
      }
    };
  }, []);
}

