import { Reducer } from 'redux'
import { EmailsInviteLink, ExpiredToken, FAILED_ROLE_USER, FAILED_ROLES, GET_ROLE_USER, GET_SECURITY_PAGE, GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, OtpRequest, REQUEST_ROLES, Roles, RolesSupabase, Security, SecurityPage, SET_ROLE_USER, SUCCESS_ROLES, UPDATE_SECURITY_PAGE, UPDATE_USER_INFO_REQUEST, UPDATE_USER_INFO_SUCCESS, User, UserAction, UserInfo, UserReq } from "../constants";

interface userSelectorState {
    user: State
}

export interface State {
    userInfo: UserInfo,
    userReq: UserInfo,
    message: string,
    name: string,
    status: number,
    security: SecurityPage,
    roleUser: RolesSupabase | null
    roles: RolesSupabase[] | null
}


const initialData: UserInfo = {
    isUnique: false,
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    username: '',
    phonecode: '',
    dateToUpdateOtp: null,
    profilePicture:''
}

const initialDataSecurity: SecurityPage = {
    password: '',
    newPassword: '',
    sessions: [],
    email: "",
}

type StateData = State | EmailsInviteLink

const initialState: StateData = {
    userInfo: initialData,
    userReq: initialData,
    message:'',
    name: '',
    status: 0,
    security: initialDataSecurity,
    emails: [],
    roleUser: null,
    roles: null
}



export const userReducer:Reducer<State | EmailsInviteLink, UserAction> = (state,action): StateData =>  {
    if(state === undefined) return initialState;
    switch(action.type) {
        
        case UPDATE_USER_INFO_REQUEST:
        case GET_SECURITY_PAGE:
        case UPDATE_SECURITY_PAGE:
        case GET_USER_INFO_SUCCESS:
            return {
                ...state,
                ...action.payload as any
            }
        case SET_ROLE_USER:
            return {
                ... state,
                roleUser: action.payload as RolesSupabase
            }
        case FAILED_ROLE_USER:
            return {
                ... state,
                roleUser: null
            }
        case SUCCESS_ROLES:
            return {
                ...state,
                roles: action.payload as RolesSupabase[]
            }
        case FAILED_ROLES:
            return {
                    ...state,
                    roles: null
            }

        case UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                message: action.payload as string
            }
        default:
            return state;
    }
}

export const userSelector = ((state: userSelectorState) => state.user)