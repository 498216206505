import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { AuthState } from "./constants";
import { authReducer } from "./reducers/authReducer";
import { watcherSaga } from "./sagas/rootSaga";
import { userReducer } from "./reducers/userReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import { entrepriseReducer } from "./reducers/entrepriseReducer";
import { watchListReducer } from "./reducers/watchListReducer";
import { vulnerabilitiesReducer } from "./reducers/vulnerabilitiesReducer";
import { BrandIntelReducer } from "./reducers/brandIntelReducer";
import { RansomeReducer } from "./reducers/ransomeWareReducer";
import { ThreatIntelReducer } from "./reducers/threatIntelReducer";
import { NotificationBellReducer } from "./reducers/notifsReducer";
import { detailStateReducer } from "./reducers/detailReducer";
import { stepsNavPageReducer } from "./reducers/stepsNavPageReducer";
import { StatsReducer } from "./reducers/statsReducer";
// Define the entire application state type


// Correctly combine reducers
const rootReducer = combineReducers<any>({
  auth: authReducer,
  user: userReducer,
  entreprise: entrepriseReducer,
  watchList: watchListReducer,
  vulnerabilities: vulnerabilitiesReducer,
  brandIntel: BrandIntelReducer,
  ransome: RansomeReducer,
  threatIntel: ThreatIntelReducer,
  notifications: NotificationBellReducer,
  details: detailStateReducer,
  steps: stepsNavPageReducer,
  stats: StatsReducer
});

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(watcherSaga);
  return store;
};

export default configureStore;
