import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../dashboard.module.scss'
import { getVictimsByCountryApi } from '../../../core/api/ransomeApi'
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { SET_COUNTRY_CODE, SET_LAST_UPDATED_COUNTRY } from '../../../core/constants'
import { CircleFlag } from 'react-circle-flags'
import { byIso } from  'country-code-lookup'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import EmptyStateCountries from '../common/EmptyStateCountries'
function CountriesSection() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { countries, isLoadingCountries } = useSelector(ransomeSelector)
    const [search, setsearch] = useState('')
    
   const resultSort = countries?.sort((a,b)=>a?.countryName?.localeCompare(b?.countryName))
   console.log(JSON.stringify(resultSort)) 
   useEffect(() => {
        document.title = "Countries"
        ReactGA.send({ hitType: "Ransomware Intel - Countries", page: window.location.pathname });

    }, [])

console.log(isLoadingCountries)
    const results = countries?.filter( values => search!== "" ? values?.countryName?.toLowerCase().includes(search.toLowerCase()) : values)
    
    const setCountryCode = (code: string, lastUpdate: string) => {
        dispatch({
            type: SET_COUNTRY_CODE,
            payload: code
        })
        dispatch({
            type: SET_LAST_UPDATED_COUNTRY,
            payload: lastUpdate
        })
        navigate('/ransomwareIntel/countryDetail')
    }
    return (
        <div className={styles.awaitingApproval} style={{marginTop:20}}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
                            <div className={styles.searchAndIcon}>
                                <input onChange={(e)=>setsearch(e.target.value)} className={styles.search} name='search' placeholder='Search'/>
                                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                            </div>
                        </div>
            {
                !isLoadingCountries? 
                countries && countries.length !== 0? <div  className={styles.countries} style={{marginTop:20}}>
                {
                    results?.map(item => 
                        <div className={styles.countriesGrid} onClick={()=>setCountryCode(item?.country, item?.last_publish_date)} style={{border: '1px solid  #242424',  borderRadius: 8, padding: '10px 16px',  cursor:"pointer"}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 25}} >
                       <div  style={{height: '24px', width: 24, borderRadius: '50%'}}>
                       <CircleFlag countryCode={item?.country?.toLowerCase()} height="32" />
                        </div>
                        <div>
                            <span style={{fontSize: 14, color: 'white'}}>{item.countryName}</span><br/>
                            <span style={{color: '#757575', fontSize: 12}}>{item?.victim_count} attack</span>
                        </div>
                    </div>
                </div>  
                    )
                }</div>
                :
                <EmptyStateCountries/>
            :
            <div className={styles.loaderVul}>
                <CircularProgress />

    </div>
            }        
        </div>
    )
}

export default CountriesSection
