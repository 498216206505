import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import styles from '../account.module.scss';
import { removeUserPictureAPI, uploadUserPictureProfileAPI } from '../../../core/api/userApi';
import { useDispatch } from 'react-redux';
import { setPictureProfileAction } from '../../../core/actions/authActions';
import { useSelector } from 'react-redux';
import { authSelector } from '../../../core/reducers/authReducer';
import { getUserInfoRequest } from '../../../core/actions/userActions';
import { userSelector } from '../../../core/reducers/userReducer';
import emptyUser from "../../../icons/Empty Users/emptyUser.jpg"
import { env } from '../../../environement/env';

const Input = styled('input')({
  display: 'none',
});

const AvatarUpload: React.FC = () => {
  const dispatch = useDispatch()
  const {userInfo} = useSelector(userSelector)
  const [avatarSrc, setAvatarSrc] = useState<string>(userInfo.profilePicture);
  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const img: File = event.target.files[0];
      setAvatarSrc(URL.createObjectURL(img));
      const formData: FormData = new FormData()
    formData.append('profilePicture', img);
      uploadAvatarFile(formData)
      // setTimeout(() => {
      //   dispatch(getUserInfoRequest())
      // }, 4000);
    }
  };

  const handleRemoveAvatar = async () => {
    const dataSrc = await removeUserPictureAPI()
    setAvatarSrc(dataSrc)
    setTimeout(() => {
      dispatch(getUserInfoRequest())
    }, 500);
  };

  const uploadAvatarFile = async (formData: FormData, isRemoval = false) => {
    
     const response = await uploadUserPictureProfileAPI(formData);
     if (!isRemoval) {
      dispatch(setPictureProfileAction(response || ""));
      dispatch(getUserInfoRequest())
    } else {
      dispatch(setPictureProfileAction(""));  // Set to an empty string or appropriate default on removal
    }
  }

  return (
    <div className={styles.avatarUpload}>
      <Avatar src={avatarSrc} sx={{ width: '56px', height: '56px' }} />
      {
        avatarSrc ? (
          <div style={{marginTop: -10}}>
            <p className={styles.uploadAvatarBtn}>Upload Avatar</p>
          <div className={styles.uploadAndRemoveAvatar}>
          
      <label htmlFor="avatar-upload-input" >
        <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
        <p className={styles.changeAvatarBtn} role="button">
          Change
        </p>
      
      </label>
          {
            !avatarSrc.includes('emptyUser.jpg') && <p
            className={styles.removeAvatar}
            role="button"
            onClick={handleRemoveAvatar}
          >
            -  Remove
          </p>
          }
      </div>
      </div>
        ):
        (
          <label htmlFor="avatar-upload-input" className={styles.uploadAndRemoveAvatar}>
        <Input accept="image/*" id="avatar-upload-input" type="file" onChange={handleAvatarChange} />
        <p style={{cursor: 'pointer', paddingTop: 18}} className={styles.uploadAvatarBtn} role="button">
          Upload avatar
        </p>
        
      </label>
        )
      }
      
      
    </div>
  );
};

export default AvatarUpload;
