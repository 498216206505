import { Button, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import styles from "./auth.module.scss"
import { NavLink } from 'react-router-dom'
import LogoSVG from '../../icons/Login-Illustration.svg'
function SignIn() {
    useEffect(() => {
      document.title = "Login"
    }, [])
    
    return (
        <div className={styles.main}>
            <div  className={styles.img}>
            <img src={"/Content.png"} height="99.3vw" width="100%" />
            <img src={LogoSVG} className={styles.logo}/>
            </div>
            <div className={styles.login}>
                <h2 className={styles.lightText}>Login</h2>
                <p style={{fontSize: 12}} className={styles.lightText}>Welcome back! Please enter your login details below:</p>
                <TextField id="standard-basic" label="Work email" variant="standard" />
                <TextField id="standard-basic" label="Password" variant="standard" />
                <NavLink to="/forgotPassword" style={{fontSize: 12, textDecoration: "underline", marginTop: 10, float: "right"}} className={styles.lightText}>Forgot Password</NavLink>
                <Button className={styles.btnLogin}>Log in</Button>
                <div className={styles.demo}>
                <p style={{fontSize: 12, textAlign: "center"}} className={styles.lightText}>Don't have an account? <span style={{textAlign: "center", fontWeight:"600", textDecoration:"underline"}}>Get a demo</span></p>
                </div>
            </div>
        </div>
    )
}

export default SignIn
