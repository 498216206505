import React, { useState } from 'react'
import styles from './dashboard.module.scss';
import Header from './common/Header';
import Menu from './common/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ThreatsSection from './ThreatManagementSections/ThreatsSection';
import ArchivesSection from './ThreatManagementSections/ArchivesSection';
import AnalyticsSection from './ThreatManagementSections/AnalyticsSection';
import { faColumns, faTable } from '@fortawesome/free-solid-svg-icons';
import { useIntercom } from './IntercomSetup';

function ThreatManagementView() {
    const [activeCompo, setactiveCompo] = useState('threats')
    const [recent, setRecent] = useState('10')
    const [grid, setgrid] = useState(false)

    const handleChange = (event: SelectChangeEvent) => {
        setRecent(event.target.value as string);
      };
    const renderSection = () => {
        switch (activeCompo) {
            case 'threats':
                return <ThreatsSection grid={grid}/>
            case 'archive':
                return <ArchivesSection/>
            case 'analytics':
                return <AnalyticsSection/>
            default:
                return;
        }
    }
    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })
  return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
        <div className={styles.content}>
            
            <div style={{alignItems: "center"}} className={styles.menuContent}>
                    <div style={{ justifyContent:"center", borderRadius: 16  }} className={styles.firstSectionMenu}>
                        <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('threats')} className={activeCompo === 'threats' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Threats</p>
                        <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('archive')} className={activeCompo === 'archive' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Archive</p>
                        <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('analytics')} className={activeCompo === 'analytics' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Analytics</p>
                    </div>
                    {
                        activeCompo === 'threats' && <FontAwesomeIcon onClick={()=>setgrid(!grid)} icon={!grid ? faColumns : faTable} height={20} width={19} color='white' style={{cursor: "pointer"}}/>
                    }
            </div>
            <hr className={styles.divider}/>
            {
                renderSection() 
            }
        </div>
    </div>
  )
}

export default ThreatManagementView