import React, { useEffect, useState } from 'react'
import styles from '../../dashboard.module.scss'
import { CreditCardsThreatIntel } from '../../../../core/constants'
export default function CreditCardDetailIsuwer() {
  const [creditCard, setcreditCard] = useState<any>()
    useEffect(() => {
      const data = window.localStorage.getItem("item")
      if(data) {
        setcreditCard(JSON.parse(data))
      }
    }, [])
    return (
        <table className={styles.table}style={{marginTop:25}}>
    <tbody>
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px', padding: '13px 16px'}} className={styles.tableCell}>
          Bank
          </td>
              <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
              {creditCard?.bank_name}
              </td>
        </tr>
        {/* <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Bank Website
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          dunderbank.com
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Bank Phone
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          (+1) 866-356-9500
              </td>
        </tr> */}
        {/* <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Country Name
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          United States
            </td>
        </tr> */}
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Country ISO
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.countrycode}
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Country Currency
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          {creditCard?.currency}
            </td>
        </tr>
      </tbody>
  </table>
    )
}
