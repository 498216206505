import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import EmptyStateCerts from '../../common/EmptyStateCerts'
function Certs() {
  const dispatch = useDispatch()
  const { isLoadingCountryDetail, countryDetail } = useSelector(ransomeSelector)
  console.log(countryDetail)  
  return (
       
       <>
       {
        countryDetail?.certCountry?.length!== 0 ?
      
         <table className={styles.table}style={{marginTop:25}}>
        <tr  className={styles.tableHead}>
              <th style={{width: '200px', padding: '14px 16px'}} >
              CERT name
              </th>
                  <th style={{width: '918px',padding: '14px 16px'}}>
                    Link
                  </th>
            </tr>
        <tbody>
            
            
            {
              countryDetail?.certCountry?.map(item => 
                <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '200px',padding: '14px 16px'}} className={styles.tableCell}>
              {item?.team_name}
              </td>
                  <td style={{width: '918px',padding: '14px 16px'}} className={styles.tableCell}>
                  {item?.website}
                  </td>
            </tr>
              )
            }
            
            
          </tbody>
      </table>
       : 
       <EmptyStateCerts/>} 
       </>
    )
}

export default Certs
