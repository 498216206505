import axios from "axios"
import { env } from "../../environement/env"
import { HitEntry } from "../constants"

export const getDatabaseLeaksApi = async (query: string) => {
    const url = `${env.apiUrl}/search`
    const { data } = await axios.post(url,{
        query
    }, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    return data as HitEntry[]
}