import React, { useEffect, useState } from 'react'
import styles from './account.module.scss';
import { Switch } from '@mui/material';
import Header from './common/Header';
import Menu from './common/Menu';
import { useIntercom } from '../Dashboard/IntercomSetup';
import ReactGA from 'react-ga4';

function Nottifications() {
  const [emailStorage, setemailStorage] = useState<string | undefined>()
    const [hmacStorage, sethmacStorage] = useState<string | undefined>()
    const [fullName, setfullName] = useState<string | undefined>()

    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
  useIntercom('isows87y', {
    app_id: 'isows87y',
    api_base: 'https://api-iam.intercom.io',
    email: emailStorageVa as string,
    user_hash: hmacValue as string,
    name: fullNamed
})

useEffect(() => {
  document.title = "Settings - Notifications"
  ReactGA.send({ hitType: "Settings - Notifications", page: window.location.pathname });

}, [])

  return (
    <div className={styles.main}>
        <Menu/>
        <Header/>
        <div className={styles.content}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>Notifications</p>
        </div>
        <div  className={styles.avatarPersonalAccountCard}>
        <p className={styles.avatarPersonalAccountCard_title}>Email notifications</p>
        <div  className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>New comments</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment mention</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                    </div>

                   

                    <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment reply</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>New threats</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                    </div>

                    
    </div>  
    <div className={styles.avatarPersonalAccountCard}>
        <p style={{width: '235px'}} className={styles.avatarPersonalAccountCard_title}>Dashboard notifications</p>
        <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>New comments</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment reply</p>
                        <Switch   name='verifyWithSms'/>
                      </div>
                    </div>

                    <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment mention</p>
                        <Switch name='verifyWithEmail'/>
                      </div>
                      <div style={{width: "50%"}} className={styles.personalInfosInputs}>
                        <p style={{}} className={styles.avatarPersonalAccountCard_text}>Comment resolved</p>
                        <Switch   name='verifyWithSms'/>
                      </div>
                    </div>


                    
    </div> 
        </div>
    </div>
  )
}

export default Nottifications