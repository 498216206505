import React from 'react'
import styles from "../dashboard.module.scss"
 
import EmptyStateLogo from "../../../icons/EmptyStateUsers.svg"
export default function EmptyStateSocialMedia() {
    return (
        <div className={styles.emptyState}>
        <div style={{width: '100%'}}>
            <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>
    
        <img src={EmptyStateLogo}/>
            </div>
          <div style={{marginTop: 40}}> 
            <h2 style={{textAlign: "center",color:"white", fontWeight:"600",fontSize:20}}>No Social Media</h2>
            <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14}}>No Social Media in your watch list brand keywords.</p>
          </div>
         
        </div>
      </div>
      )
}
