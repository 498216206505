import axios from "axios";
import { CheckUserNameResp, EmailsInviteLink, ExpiredToken, LoginFirstTimeRes, OtpRequest, RolesSupabase, Security, SecurityPage, UpdateRoleUserParams, User, UserReq } from "../constants";
import { env } from "../../environement/env";

axios.defaults.withCredentials = true

export const getUserInfoAPI = async () => {
    try {
        const url = `${env.apiUrl}/user/personalInfo`;
        const { data } = await axios.get(url ,{
            headers: {
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as User | ExpiredToken;
    } catch (error) {
        throw error;
    }
}

export const updateUserInfoAPI = async (dataUser: UserReq) => {
    try {
        const url = `${env.apiUrl}/user/update`;
        const { data } = await axios.post(url, dataUser, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as User | ExpiredToken;
    } catch (error) {
        throw error;
    }
}

export const getInfosSecurityAPI = async () => {
    try {
        const url = `${env.apiUrl}/user/securityInfoUser`
        const { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data.security; 
    } catch (error) {
        throw error
    }
}

export const updateSecurityInfoUserAPI = async (dataSecurity: Security) => {
    try {
        const url = `${env.apiUrl}/user/updateUserInfosSecurity`
        const { data } = await axios.post(url, dataSecurity, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const sendInviteLinkAPI = async (dataSend: EmailsInviteLink) => {
    try {
        const url = `${env.apiUrl}/user/sendInviteLink`
        const { data } = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as LoginFirstTimeRes; 
    } catch (error) {
        throw error;
    }
}

export async function uploadUserPictureProfileAPI(dataSend: FormData) {
    try {
        const { data } =await axios.post(`${env.apiUrl}/user/uploadProfilePicture`,dataSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as string
    } catch (error) {
        console.error(error)
    }
}

export const checkUsernameApi = async (username: string) => {
    try {
        try {
            const { data } =await axios.post(`${env.apiUrl}/user/checkUsername`,
                {
                    username
                }
            , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-requested-by': 'ci-app',
                    'Origin': false
                }
            })
            return data as CheckUserNameResp
        } catch (error) {
            console.error(error)
        }
    } catch (error) {
        
    }
}

export const removeUserPictureAPI = async () => {
    try {
         const {data} =await axios.post(`${env.apiUrl}/user/removeUserPictureProfile`, {},{
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
         }
        )
        return data
    } catch (error) {
        throw error
    }
}


export const getRoleUserAPI = async () => {
    try {
         const {data} =await axios.get(`${env.apiUrl}/user/getRoleUser`,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
         }
        )
        return data as RolesSupabase
    } catch (error) {
        throw error
    }
}


export const updateRoleUserAPI = async ({ userid, role }: UpdateRoleUserParams) => {
    try {
         const {data} = await axios.post(`${env.apiUrl}/user/updateRoleUser`, 
            { userid, role }
            ,{
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
         }
        )
        return data
    } catch (error) {
        throw error
    }
}


    export const getRolesUserAPI = async () => {
    try {
         const {data} = await axios.get(`${env.apiUrl}/user/roles`, 
            {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
         }
        )
        return data as RolesSupabase[]
    } catch (error) {
        throw error
    }
}