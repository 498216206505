  import { faCalendar, faChevronDown, faDownload, faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import styles from '../../../dashboard.module.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { threatIntelSelector } from '../../../../../core/selectors/threatIntelSelector';
import { HitEntry, REQUEST_DATABASELEAK } from '../../../../../core/constants';
import ReactGA from 'react-ga4';
import EmptyState from '../../../common/EmptyState';
import { CircularProgress } from '@mui/material';

export function maskString(str: string, keepStart: number = 0, keepEnd: number = 0): string | null {
  if (str) {
    keepStart = Math.min(keepStart, str.length);
    keepEnd = Math.min(keepEnd, str.length - keepStart);

    const start = str.substring(0, keepStart);
    const end = str.substring(str.length - keepEnd);
    const middle = str.substring(keepStart, str.length - keepEnd);
    const maskedMiddle = middle.replace(/./g, '*');

    return start + maskedMiddle + end;
  }
  return null;
}

export default function DatabaseLeaks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { databaseLeak } = useSelector(threatIntelSelector);
  const [visiblePasswords, setVisiblePasswords] = useState<Record<string, boolean>>({});

  useEffect(() => {
    document.title = "Credentials";
    ReactGA.send({ hitType: "Threat Intel - Credentials", page: window.location.pathname });
  }, [dispatch]);

  const togglePasswordVisibility = (id: string) => {
    setVisiblePasswords(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Ensure databaseLeak is an array before filtering
  const filtredResult = Array.isArray(databaseLeak) ? databaseLeak.filter((item: HitEntry) => 
    item._source.login.includes(search) || item?._source?.url?.includes(search)) : [];

  const searchResult = search !== "" ? filtredResult : databaseLeak || [];

  return (
    <div style={{marginTop: 20}}>
      <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:45}}>
        <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' placeholder='Search' onChange={(e) => setSearch(e.target.value)}/>
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} />
        </div>
        <div className={styles.removeSelected}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 14}} />
                            </div>
      </div>
      <div style={{width: "100%"}}>
        
         { searchResult && searchResult?.length > 0 ?
          <table className={styles.table}>
            <tr className={styles.tableHead} >
                  <th style={{paddingLeft: 8}}>
                      <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Username </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                </th>
                  <th style={{paddingLeft: 8}}>
                    <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Password </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                  <th style={{paddingLeft: 8}}>
                  <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            <span>Source </span>
                            <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                        </p>
                  </th>
                  
                </tr>
            {searchResult?.map((item: any) => (
              <tr className={styles.tableActivityRowWatchList}>
                 <td className={styles.tableCell} style={{width:"20%"}}>
                <p>{item._source.login}</p>
              </td>   
              <td className={styles.tableCell} style={{width:"15%"}}  >
                <div className={styles.passwordContainer} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width:'70%'}}>
                  <p>{visiblePasswords[item._id] ? item._source.password : item._source.password.replace(/./g, '*')}</p>
                  <FontAwesomeIcon icon={visiblePasswords[item._id] ? faEyeSlash : faEye} size="1x" onClick={() => togglePasswordVisibility(item._id)} style={{cursor:"pointer"}}/>
                </div>
              </td>
              <td className={styles.tableCell} >
              <p> {item?._source?.url? item?._source?.url?.includes('android')?item?._source?.url?.split('@')[1]:item?._source?.url?.substring(0,43): "Source Unavailable"}</p>
              </td> 
              </tr>
            ))}
          </table>
          :
          <EmptyState />
        }
      </div>
    </div>
  );
}
