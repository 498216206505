import React from 'react'
import styles from "../dashboard.module.scss"

function Comments() {
  return (
    <div className={styles.commentsSection}>
        <div style={{width: 640}}>
            <div className={styles.inputComment}>
                <img src="/logoProfile.png" width={24} height={24} style={{overflow: "hidden", border: "none", borderRadius: "50%"}}/>
                <input  style={{border: "none", backgroundColor: "transparent"}} placeholder='Leave comment'/>
            </div>

            <div style={{display: "flex", alignItems:"center",gap: 10,marginTop: 15}}>
                <img src="/logoProfile.png" width={24} height={24} style={{overflow: "hidden", border: "none", borderRadius: "50%"}}/>
                <p style={{fontSize: 14, color: "white"}}>Dewight Schrute</p>
            </div>

            <p style={{fontSize: 14, color: "white", marginTop:-8}}><span style={{color:"#e9bd08"}}>@pambeasley</span> Can you get Nick to verify if the last email Micheal forwarded the whole brunch contained a malware?</p>
            <div style={{display: 'flex',justifyContent: 'space-between', alignItems: "center"}}>
                <p style={{fontSize: 12, color: "#afafaf"}}>Reply</p>
                <p style={{fontSize: 12, color: "#afafaf"}}>3 hours ago</p>
            </div>

            <div style={{display: "flex",gap:5}}>
                <div style={{width: 16, height: 16, borderLeft: '1px solid #757575', borderBottom:'1px solid #757575'}}/>
                <div>
                <div style={{display: "flex", alignItems:"center",gap: 10,marginTop: -7}}>
                <img src="/logoProfile.png" width={24} height={24} style={{overflow: "hidden", border: "none", borderRadius: "50%"}}/>
                <p style={{fontSize: 14, color: "white"}}>Pam besseley</p>
            </div>

            <p style={{fontSize: 14, color: "white", marginTop:-8}}><span style={{color:"#e9bd08"}}>@dewightschrute</span> I'll take care of it.</p>
            <div style={{display: 'flex',justifyContent: 'space-between', alignItems: "center"}}>
                <p style={{fontSize: 12, color: "#afafaf"}}>Reply</p>
                <p style={{fontSize: 12, color: "#afafaf"}}>3 hours ago</p>
            </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Comments