import React from 'react'
import styles from "../auth.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';

function BackBtn() {
    const navigate = useNavigate();
    
    const previous = () => {
        navigate(-1)
    }
  return (
      <p onClick={previous} className={styles.backBtn}>
          <FontAwesomeIcon icon={faArrowLeft} />
          <span> Back</span>
      </p>
  )
}

export default BackBtn