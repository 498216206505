import React from 'react'
import styles from "../dashboard.module.scss"
 
import EmptyStateLogo from "../../../icons/EmptyStateUsers.svg"
import { useSelector } from 'react-redux'
import { userSelector } from '../../../core/reducers/userReducer'

interface props {
    open: boolean,
    setOpen: any
}

function EmptyStateUsers({open, setOpen}: props) {
  const { roleUser, userInfo } = useSelector(userSelector)
  return (
    <div className={styles.emptyState}>
    <div style={{width: '100%'}}>
        <div style={{display: "flex", justifyContent:"center",alignItems: "center"}}>

    <img src={EmptyStateLogo}/>
        </div>
      <div style={{marginTop: 40}}> 
        <h2 style={{textAlign: "center",color:"white", fontWeight:"600",fontSize:20}}>No Users</h2>
        <p style={{textAlign: "center",color:"#AFAFAF", fontWeight:'400', fontSize: 14}}>No users in your workspace. Use the button below to invite your team.</p>
      </div>
      <div style={{display: "flex", justifyContent: "center", marginTop: 30, fontWeight:"500"}}>

      { roleUser?.role === "Admin" && roleUser?.userSettings && <button type='button' onClick={()=>setOpen(!open)}
        
      className={styles.personnalInfoHeader_secondSection_Save}>Invite User
      </button>}
      </div>
    </div>
  </div>
  )
}

export default EmptyStateUsers