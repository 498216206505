import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'
import { VulnerabilityConfigurationNodes } from '../../../core/constants'
import EmptyState from '../common/EmptyState'
import EmptyStateConfig from '../common/EmptyStateConfig'
export default function Config() {
  const { vulDetail, vulnerabilies } = useSelector(vulnerabilitiesSelector)
  const references = vulnerabilies?.references.filter(item => item.cve_id === vulDetail?.cve_id)
  return (
      <div>
          <p style={{fontSize: 18, fontWeight: '600', color: 'white'}}>
          Initial Analysis - January 11, 2024 10:17
          </p>
          <table className={styles.table}style={{marginTop:25}}>
      <thead>
        <tr className={styles.tableHead}>
          <th style={{paddingLeft: 8}}>
          Type
          </th>
          <th style={{paddingLeft: 8}}>
          Old Value
          </th>
          <th style={{paddingLeft: 8}}>
          New Value
          </th> 
        </tr>
      </thead>
      <tbody>
          <tr className={styles.tableActivityRowWatchList}>
            <td style={{width: '200px'}} className={styles.tableCell}>
                <p style={{fontSize: 14}}>CPE Configuration</p>
            </td>
            
            <td style={{width: '459px'}} className={styles.tableCell} >
              
            </td>
            <td className={styles.tableCell}>
            <p>{vulDetail?.operator ? vulDetail?.operator : "OR"}</p>
            <p>*cpe:2.3:a:online_food_ordering_system_project:online_food_ordering_system:1.0:*:*:*:*:*:*:*</p>
            </td>
            </tr>
            <tr className={styles.tableActivityRowWatchList}>
              <td style={{width: '200px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>CVSS V3.1</p>
              </td>
              
              <td style={{width: '459px'}} className={styles.tableCell} >
                
              </td>
              <td style={{width:'459px'}} className={styles.tableCell}>
                {vulDetail?.vector_string}
              </td>
            </tr>
            
            {
              references?.map(item =>

                <tr className={styles.tableActivityRowWatchList}>
              <td style={{width: '200px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>Reference Type</p>
              </td>
              
              <td  className={styles.tableCell} >
              </td>
              <td style={{width:'50%'}} className={styles.tableCell}>
              {item?.url}
              </td>
            </tr>
              )
            }
                   </tbody>
                   </table>

                   <p style={{fontSize: 18, fontWeight: '600', color: 'white'}}>
                   New CVE - January 05, 2024 14:15
          </p>
          <table className={styles.table}style={{marginTop:25}}>
      <thead>
        <tr className={styles.tableHead}>
          <th style={{paddingLeft: 8}}>
          Type
          </th>
          <th style={{paddingLeft: 8}}>
          Old Value
          </th>
          <th style={{paddingLeft: 8}}>
          New Value
          </th> 
        </tr>
      </thead>
      <tbody>
          <tr className={styles.tableActivityRowWatchList}>
            <td style={{width: '200px'}} className={styles.tableCell}>
                <p style={{fontSize: 14}}>Description</p>
            </td>
            
            <td  className={styles.tableCell} >
              
            </td>
            <td className={styles.tableCell} style={{width:'50%'}} >
            {vulDetail?.description}
            </td>
            </tr>
            
           
                   </tbody>
                   </table>
      </div>
  )
}
