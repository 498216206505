import { faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import styles from '../../../dashboard.module.scss'
import EmptyState from '../../../common/EmptyState'
import moment from 'moment'

export default function IdentityDoc({documents, userProfile, email}: any) {
    return (
        <table className={styles.table}style={{marginTop:25, width:'100%'}}>
        <tr className={styles.tableHead}>
                                    <th style={{paddingLeft: 8, textAlign: 'start'}}>
                                    File Name
                                    </th>
                                    
                                    
                                    
                                    <th style={{paddingLeft: 8}}>
                                    Last Updated {" "}
                                        <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                                    </th>
                                    </tr>
                                    {
                        documents && documents?.length !==0 ?
                                        documents?.map((item: any) => 
                                          <tr className={styles.tableActivityRowRansomWatchList}>
                                        <td  className={styles.tableCell} style={{width:"88%"}}>
                                          <p style={{color: '#cbcbcb', fontSize: 14, textAlign: 'start'}}>{item?.file_name}</p>
                                          </td>
                                        
                                          
                                          
                                          <td style={{ paddingLeft: 8}} className={styles.tableCell} >
                                            <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/> {item?.upload_date && moment(item?.upload_date).format("MMMM DD, YYYY")}
                                            
                                          </td>
                                        </tr>
                                        )
                                      
                                :
                                <EmptyState/>
                       }
      </table>
    )
}
