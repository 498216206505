import { NotificationBell, NotificationBellReq, SEND_NOTIFICATIONS, SUCCESS_NOTIFICATIONS } from "../constants";

export const successNotificationsBellAction = (data: NotificationBell[]) => ({
    type: SUCCESS_NOTIFICATIONS,
    payload: data
})

export const sendNotificationAction = (data: NotificationBellReq) => ({
    type: SEND_NOTIFICATIONS,
    payload: data
})