import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'

export default function AffectProduct() {
    const { vulDetail} = useSelector(vulnerabilitiesSelector)
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
    <tbody>
        <tr  className={styles.tableActivityRowRansom1Col}>
          <td style={{width: '1118px', padding: '14px 16px'}} className={styles.tableCell}>
          {
            vulDetail?.cpe_product
          }
          </td>
        </tr>
        
        
      </tbody>
  </table>
        </div>
    )
}
