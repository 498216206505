import React, { FormEvent, useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest } from '../../../core/actions/userActions'
import { SecurityPage, Session, UserInfo } from '../../../core/constants'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../core/reducers/userReducer'

interface props {
  handleSubmit : (event: FormEvent<HTMLFormElement>) => void
  setformState: (value: React.SetStateAction<SecurityPage>) => void,
  title: string;
  sessions: Session[];
  setsessionUpdate: any
  password: string;
  newPassword: string;
  setpassword: any;
  setNewPassword: any
  security: SecurityPage
}

function HeaderSecurity({security,password,newPassword, setNewPassword, setpassword,setsessionUpdate, handleSubmit, setformState, title, sessions}:props) {
  const dispatch = useDispatch()
  const [shouldUpdate, setshouldUpdate] = useState<boolean>(false)
  const [initialState, setInitialState] = useState<SecurityPage>(security);

      
      console.log(sessions)
      const handleCancel = () => {
          // Reset form state to the initial security state from the Redux store
        
          const combinedSessions = [...security.sessions, ...sessions];
         
      // Reset the form state to the initial security state from the Redux store
      setformState({...security, sessions:combinedSessions});

      // Update the session state with the combined sessions
      setsessionUpdate([]);

      // Toggle the shouldUpdate state to possibly re-render any UI that depends on it
      setshouldUpdate(prev => !prev);
      };
  
      const hasChanges = () => {
        return ( (typeof security.password !== "undefined" && security.password !== "") || (typeof security.newPassword !== "undefined" && security.newPassword !== "") || sessions.length !== 0);
      };
  return (
    <form onSubmit={handleSubmit} className={styles.personalInfoHeader}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>{title}</p>
        </div>
        {
          hasChanges()   && <div className={styles.personnalInfoHeader_secondSection}>
          <button onClick={()=>setshouldUpdate(!shouldUpdate)} type="submit"  className={styles.personnalInfoHeader_secondSection_Save}>Save</button>
          <button type='button' onClick={handleCancel} className={styles.personnalInfoHeader_secondSection_Cancel}>Cancel</button>
      </div>
        }
        </form>
  )
}

export default HeaderSecurity