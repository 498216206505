import { faArrowLeft, faChevronDown, faCreditCard, faHandSparkles, faMagicWandSparkles, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from "../dashboard.module.scss"
import CreditCardDetailDetails from './Components/CreditCardDetailDetails'
import CreditCardDetailIsuwer from './Components/CreditCardDetailIsuwer'
import { CreditCardsThreatIntel } from '../../../core/constants'
import { maskString } from './CredentialsLeaks'
import logoVisa from "../../../icons/CreditCards/visa.svg"
import logoMastercard from "../../../icons/CreditCards/mastercard.svg"
import logoAmex from "../../../icons/CreditCards/amex.svg"
import logoDiscover from "../../../icons/CreditCards/discover.svg"
import logoUndefined from "../../../icons/CreditCards/undefined.svg"
import ReactGA from 'react-ga4';
import { getEmailApi } from '../../../core/api/threatIntelApi'

import logoExecutive from "../../../icons/Executive.svg"
import logoEmployee from "../../../icons/Employee.svg"
import logoClient from "../../../icons/Client.svg"
import { useIntercom } from '../IntercomSetup'
import moment from 'moment'
export default function CreditCardDetail() {    
    const navigate = useNavigate()
    const [activeCompo, setactiveCompo] = useState('details')
    const [creditCard, setcreditCard] = useState<any>()
    
    useEffect(() => {
        document.title = "Threat Intel - Credit Card Detail"
        ReactGA.send({ hitType: "Threat Intel - Credit Card Detail", page: window.location.pathname });
      const data = window.localStorage.getItem("item")
      if(data) {
        setcreditCard(JSON.parse(data))
        
      }
    }, [])
    const emailStorageVa = window.localStorage.getItem('email')
      const hmacValue = window.localStorage.getItem('hmac')
      const fullNamed = window.localStorage.getItem("fullName")
      const token = window.localStorage.getItem('jwt');
      const typeEntreprise = localStorage.getItem("type") 
useIntercom('isows87y', {
app_id: 'isows87y',
api_base: 'https://api-iam.intercom.io',
email: emailStorageVa as string,
user_hash: hmacValue as string,
name: fullNamed
})
    
    const renderSections = () => {
        switch (activeCompo) {
            case 'details':
                return <CreditCardDetailDetails/>
            case 'issuerBank':
                return <CreditCardDetailIsuwer/>
            default:
                return;
        }
    }
    const getUserProfile = async (userid?:number) => {
       
        window.localStorage.setItem('credentialLeakDetail', JSON.stringify({userid}))
        navigate("/threatIntel/userProfile    ")
    }

    console.log(creditCard?.brand)
    return (
        <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/threatIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <p>Go back / {creditCard?.cardid}</p>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop: 4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span style={{fontSize: 20, fontWeight: "600"}}>{creditCard?.cardnumber }</span>
                        <span className={styles.scoreSeverity} style={{
                            // @ts-ignore
                            color: creditCard?.color,
                            // @ts-ignore
                            backgroundColor: creditCard?.bgColor,
                            // @ts-ignore
                            border: `1px solid ${
                                // @ts-ignore
                                creditCard?.borderColor
                            }`
                        }}>{
                            Number(creditCard?.total_risk_score)?.toFixed(1)
                        }</span>
                    </div>  
                </div>
                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <div style={{marginTop: -30}}>
                    <Button onClick={()=>getUserProfile(creditCard?.userid)} style={{width: '120px', height: '40px', gap: 10, fontSize:14, fontWeight:'500', backgroundColor: "white", display: 'flex', alignItems: "center"}}>
                        User Profile
                    </Button>
                        </div>
                        </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24}}>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Detected on</span>
                    <span style={{color: "white"}}>{moment(creditCard?.created_at).format("MMMM DD, YYYY")}</span>
                    </div>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Type</span>
                    <div className={styles.credentialUserType} >
                    <img src={
                            creditCard?.typeUser === "Executive" ? logoExecutive :
                            creditCard?.typeUser === "Employee" ? logoEmployee :
                             logoClient
                        } className={styles.logoProfileHeader} />
                        <span style={{fontSize: 12}}>{creditCard?.typeUser}</span>
                    </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                    <div>
                    <div style={{marginTop: 16}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>This card has a risk score of {creditCard?.riskscore} risk because it is a {creditCard?.level} card.</span>
                    </div> 
                    </div>
                    </div>
                    
                    <div style={{backgroundColor: "#141414", width: '420px', padding: 16, borderRadius: 8}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div >
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>{creditCard?.nameoncard ? creditCard?.nameoncard :"NA    "}</span>
                            </div>
                            <img src={
                                creditCard?.brand?.toLowerCase() === "visa" ? logoVisa : 
                                creditCard?.brand?.toLowerCase()  === "discover" ? logoDiscover :
                                creditCard?.brand?.toLowerCase()  === "amex" ? logoAmex : 
                                creditCard?.brand?.toLowerCase()  === "mastercard" ? logoMastercard : logoUndefined
                            }/>
                        </div>
                        <div style={{marginTop: 52}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 12}}>
                        <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>{creditCard?.cardnumber }</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 12}}>
                        <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>{creditCard?.expiredate}
                        {/* <span style={{marginLeft: 16}}>490</span> */}
                        </span>
                        </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 40}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>{creditCard?.currency}</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>{creditCard?.level} {creditCard?.type}</p>
                        </div>
                        
                    </div>
                </div>
           
                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 25, justifyContent:"center", borderRadius: 6, padding: '4px 6px'  }} className={styles.firstSectionMenu}>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('details')} className={activeCompo === 'details' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Details</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('issuerBank')} className={activeCompo === 'issuerBank' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Issuer Bank</span>
                    </div>

                {
                    renderSections()
                }
            </div>

        </div>
    )
}
