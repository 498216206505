import React, {useEffect, useState} from 'react'
import styles from "../dashboard.module.scss"
import Menu from './Menu'
import Header from './Header'
import Unread from './NotificatioSections/Unread'
import AllNotification from './NotificatioSections/AllNotification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
function Notification() {
    const navigate = useNavigate()
    const [activeCompo, setactiveCompo] = useState('unread')
    const renderSection = () => {
        switch (activeCompo) {
            case 'unread':
                return <Unread/>
            case 'allNotifs':
                return <AllNotification/>
            default:
                return;
        }
    }

    useEffect(() => {
      document.title = "Notifications"
    }, [])
    

  return (
    <div className={ styles.main }>
        <Menu/>
        <Header/>
        <div className={styles.content}>
            <div className={styles.menuContent}>
                <div className={styles.firstSectionMenu}>
                    <p style={{color: "white", cursor: 'pointer'}} onClick={()=>navigate(-1)}><FontAwesomeIcon height={20} width={11} icon={faArrowLeft}/></p>
                    <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('unread')} className={activeCompo === 'unread' ? styles.firstSectionMenu_item : ""}>Unread</p>
                    <p style={{cursor: 'pointer'}} onClick={()=>setactiveCompo('allNotifs')} className={activeCompo === 'allNotifs' ? styles.firstSectionMenu_item : ""}>All Notifications</p>
                    
                </div>
            </div>
            <hr className={styles.divider}/>
              {
                  renderSection()
              }
        </div>
    </div>
  )
}

export default Notification