import React from 'react'
import styles from '../../dashboard.module.scss'
export default function Antivirus() {
    return (
        <table className={styles.table}style={{marginTop:8}}>
        <tbody>
            <tr  className={styles.tableActivityRowRansom1Col}>
              <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
              Norton™ AntiVirus Plus 4.18.1909.5
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            Microsoft Defender Antivirus 1.405.913.0
              </td>
            </tr>
            
            
            
          </tbody>
      </table>
    )
}
