import React, { useState, useEffect } from 'react';
import { Button, TextField } from '@mui/material';
import styles from "./auth.module.scss";
import BackBtn from './components/BackBtn';
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendOtpRequestAction,
  verifyOtpRequestAction,
} from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { Roles } from '../../core/constants';
import ErrorNotification from '../Account/common/ErrorNotification';
import ReactGA from 'react-ga4';
import { useIntercom } from '../Dashboard/IntercomSetup';
import logo from "../../icons/Defendis_Logo_White.svg"
import logoSignSVG from '../../icons/Login-Illustration.svg'

function OtpVerification() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const { auth } = useSelector(authSelector);

  // useEffect(() => {
  //   // Conditionally dispatch sendOtpRequestAction based on specific auth state conditions
  //   if (auth.otpIsValid) {
  //     if(auth.role !== Roles.boAdmin) {
  //       navigate("/dashboard");
  //     }
  //     else {
  //       navigate('/admin')
  //     }
  //   }
  // }, [auth.otpIsSent, auth.otpIsValid, auth.email, dispatch, navigate]);

  useEffect(() => {
    document.title = "Login - Otp"
    ReactGA.send({ hitType: "Login - Otp", page: window.location.pathname });

    if (auth.otpIsValid) {
      if(auth.role !== Roles.boAdmin) {
        navigate("/");
      }
      else {
        navigate('/admin')
      }
    }
  }, [auth])
  

  const login = () => {
    dispatch(verifyOtpRequestAction({
      email: auth.email,
      otp: otp,
    }));
    
  };



  const anonymizeEmail = (email:string) => {
    // Split the email into the local part and the domain
    const [localPart,] = email.split('@');
    // Check if the local part is long enough to anonymize
    if (localPart.length > 2) {
      // Keep the first and last character, replace the rest with asterisks
      const anonymizedPart = localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1];
      return anonymizedPart + '@defendis.io';
    } else {
      // If the local part is too short, just return it as is with the new domain
      return localPart + '@defendis.io';
    }
  }

  return (
    <div className={styles.main}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <form onSubmit={e=>e.preventDefault()}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0}}>
                    <div className={styles.login}>
                        <div style={{width:'70%'}}>
                        <div className={styles.loginInputs}>
                          <BackBtn/>  
                            
                            <p className={styles.titleLogin}>Verification </p>
                            <p className={styles.lightText}>Email verification code sent to: {anonymizeEmail(auth.email)} 
                                
                              </p>
                              <br/>
                              <p style={{marginBottom: 30}} className={styles.lightText}>It will expire in 10 minutes, remember to check your spam folder.</p>

                                <OTPInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={4}
                                  inputStyle={styles.otpInputs}
                                  renderInput={(props) => <input {...props} />}
                                />
                              <Button onClick={login} type='submit' style={{marginTop: 20}} className={styles.btnLogin}>Login</Button>                      
                              </div>
                        </div>
                        <img src={logoSignSVG} className={styles.illus} style={{marginTop: '20%'}}/>
                    </div>
                    </div>
                    </form>
        </div>
  );
}

export default OtpVerification;
