import { call, put } from "redux-saga/effects"
import { NotificationBell, NotificationBellReq, NotificationsReducer, REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS } from "../constants"
import { getNotificationsApi, sendNotificationsApi } from "../api/notificationApi"
import { successNotificationsBellAction } from "../actions/notificationActions"

export function* getNotificationsSaga() {
    try {
        const data: NotificationBell[] = yield call(getNotificationsApi)
        yield put(successNotificationsBellAction(data))
    } catch (error) {
        console.error(error)
    }
}

export function* sendNotificationSaga(actions: NotificationsReducer) {
    try {
        const data = actions.payload as NotificationBellReq
        yield call(sendNotificationsApi, data)
        
        
    } catch (error) {
        console.error(error)
    }
}