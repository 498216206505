import { call, put } from "redux-saga/effects"
import { Countries, CountryDetail, FAILED_RANSOME, FAILED_RANSOME_COUNTRIES, FAILED_RANSOME_COUNTRY_DETAIL, FAILED_RANSOME_OVERVIEW, FAILED_VICTIMS, GroupsRansome, Negociations, RansomeOverview, RansomeWareAction, SUCCESS_RANSOME_COUNTRIES, SUCCESS_RANSOME_COUNTRY_DETAIL, Victims } from "../constants"
import { getAllNegociationApi, getCountryDetailApi, getGroupsApi, getRansomeOverviexApi, getVictimsApi, getVictimsByCountryApi } from "../api/ransomeApi"
import { setGroupsAction, setNegociationAction, setRansomeOverviewSuccess, setVictimsAction } from "../actions/ransomeActions"

export function* getGroupsSaga(actions: RansomeWareAction) {
    try {
        const response: GroupsRansome[] = yield call(getGroupsApi, actions.payload as string)
        yield put(setGroupsAction(response))
    } catch (error) {
        yield put({
            type: FAILED_RANSOME
        })
    }
}


export function* getVictimsSaga(actions: RansomeWareAction) {
    try {
        const response: Victims[] = yield call(getVictimsApi, actions.payload as string)
        yield put(setVictimsAction(response))
    } catch (error) {
        yield put({
            type: FAILED_VICTIMS
        })
    }
}

export function* getNegociationsSaga(actions: RansomeWareAction) {
    try {
        const response: Negociations[] = yield call(getAllNegociationApi)
        console.log(response)
        yield put(setNegociationAction(response))
    } catch (error) {
        
    }
}

export function* getRansomeOverviewSaga(actions: RansomeWareAction) {
    try {
        const response: RansomeOverview = yield call(getRansomeOverviexApi, actions.payload as string)
        yield put(setRansomeOverviewSuccess(response))
    } catch (error) {
        yield put({
            type: FAILED_RANSOME_OVERVIEW
        })
    }
}

export function* getCountryDetailSaga(actions: RansomeWareAction) {
    try {
        const code = actions.payload as string
        const response: CountryDetail = yield call(getCountryDetailApi, code)
        yield put({
            type: SUCCESS_RANSOME_COUNTRY_DETAIL,
            payload: response
        })
    } catch (error) {
        yield put({
            type: FAILED_RANSOME_COUNTRY_DETAIL
        })
    }
}

export function* getCountriesSaga(actions: RansomeWareAction) {
    try {
        const response: Countries[] = yield call(getVictimsByCountryApi, actions.payload as string)
        yield put({
            type: SUCCESS_RANSOME_COUNTRIES,
            payload: response
        })
    } catch (error) {
        yield put({
            type: FAILED_RANSOME_COUNTRIES,
        })
    }
}