import { GET_WATCH_LIST_ASSETS, SAVE_WATCH_LIST_ASSETS, SAVE_WATCH_LIST_BIN, SAVE_WATCH_LIST_BRAND_KEYWORD, SAVE_WATCH_LIST_DOMAIN, SAVE_WATCH_LIST_EXECUTIVE, SAVE_WATCH_LIST_IP, SAVE_WATCH_LIST_RANSOME, SET_WATCH_LIST, SET_WATCH_LIST_ASSETS, SET_WATCH_LIST_BIN, SET_WATCH_LIST_BRAND_KEYWORD, SET_WATCH_LIST_DOMAIN, SET_WATCH_LIST_EXECUTIVE, SET_WATCH_LIST_IP, SET_WATCH_LIST_RANSOME, WatchList, WatchListAssets, WatchListAssetsReq, WatchListBin, WatchListBinReq, WatchListBrandKeyWord, WatchListBrandKeyWordReq, WatchListDomain, WatchListDomainReq, WatchListExecutive, WatchListExecutiveReq, WatchListGroup, WatchListGroupReq, WatchListIp, WatchListIpReq } from "../constants";

export const setWathListAction = (data: WatchList) => ({
    type: SET_WATCH_LIST,
    payload: data
})

export const setWatchListAssetsReq = (data: WatchListAssetsReq) => ({
    type: SAVE_WATCH_LIST_ASSETS,
    payload: data
})

export const setWatchListAssets = (data: WatchListAssets[]) => ({
    type: SET_WATCH_LIST_ASSETS,
    payload: data
})

export const saveWatchListBrandKeywordReq = (data: WatchListBrandKeyWordReq) => ({
    type: SAVE_WATCH_LIST_BRAND_KEYWORD,
    payload: data
})

export const setWatchListBrandKeywords = (data: WatchListBrandKeyWord[]) => ({
    type: SET_WATCH_LIST_BRAND_KEYWORD,
    payload: data
})

export const saveWatchListDomainReq = (data: WatchListDomainReq) => ({
    type: SAVE_WATCH_LIST_DOMAIN,
    payload: data
})

export const setWatchListDomains = (data: WatchListDomain[]) => ({
    type: SET_WATCH_LIST_DOMAIN,
    payload: data
})

export const saveWatchListIpReq = (data: WatchListIpReq) => ({
    type: SAVE_WATCH_LIST_IP,
    payload: data
})

export const setWatchListIps = (data: WatchListIp[]) => ({
    type: SET_WATCH_LIST_IP,
    payload: data
})

export const saveWatchListBinReq = (data: WatchListBinReq) => ({
    type: SAVE_WATCH_LIST_BIN,
    payload: data
})

export const setWatchListBins = (data: WatchListBin[]) => ({
    type: SET_WATCH_LIST_BIN,
    payload: data
})

export const saveWatchListExecutiveReq = (data: WatchListExecutiveReq) => ({
    type: SAVE_WATCH_LIST_EXECUTIVE,
    payload: data
})

export const setWatchListExecutives = (data: WatchListExecutive[]) => ({
    type: SET_WATCH_LIST_EXECUTIVE,
    payload: data
})

export const saveWatchListRansomeReq = (data: WatchListGroupReq) => ({
    type: SAVE_WATCH_LIST_RANSOME,
    payload: data
})

export const setWatchListRansomes = (data: WatchListGroup[]) => ({
    type: SET_WATCH_LIST_RANSOME,
    payload: data
})