import { call, put } from "redux-saga/effects";
import { BranIntelAction, Dark, Domaine, FAILED_DARK, FAILED_DOMAINE, FAILED_SOCIALMEDIA, Places, SocialMedia, SocialMediaPost, SurfaceWeb } from "../constants";
import { getDarkApi, getDomainsApi, getPlacesApi, getSocialMediaApi, getSurfaceWebApi } from "../api/brandIntelApi";
import { setDarkAction, setDomainAction, setPlacesAction, setSocialMediaAction, setSurfaceWebAction } from "../actions/brandIntelActions";

export function* getPlacesSaga(actions: BranIntelAction) {
    try {
        const response: Places[] = yield call(getPlacesApi)
        yield put(setPlacesAction(response))
    } catch (error) {
        
    }
}

export function* getSurfaceWebSaga(actions: BranIntelAction) {
    try {
        const response: SurfaceWeb[] = yield call(getSurfaceWebApi, actions.payload as string)
        yield put(setSurfaceWebAction(response))
    } catch (error) {
        
    }
}

export function* getSocialMediaSaga(actions: BranIntelAction) {
    try {
        const response: SocialMediaPost[] = yield call(getSocialMediaApi, actions.payload as string)
        yield put(setSocialMediaAction(response))
    } catch (error) {
        yield put({
            type: FAILED_SOCIALMEDIA
        })
    }
}

export function* getDarkSaga(actions: BranIntelAction) {
    try {
        const response: Dark[] = yield call(getDarkApi, actions.payload as string)
        yield put(setDarkAction(response))
    } catch (error) {
        yield put({
            type: FAILED_DARK
        })
    }
}

export function* getDomaineSaga(actions: BranIntelAction) {
    try {
        const response: Domaine[] = yield call(getDomainsApi, actions.payload as string)
        yield put(setDomainAction(response))
    } catch (error) {
        yield put({
            type: FAILED_DOMAINE
        })
    }
}

