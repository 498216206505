import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'

export default function Weakness() {
  const { vulDetail } = useSelector(vulnerabilitiesSelector)
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            ID
            </th>
            <th style={{paddingLeft: 8}}>
            Name
            </th>
            <th style={{paddingLeft: 8}}>
            Source
            </th> 
          </tr>
        <tr className={styles.tableActivityRowWatchList}>
                <td style={{width: '200px'}} className={styles.tableCell}>
                    <p style={{fontSize: 14}}>{vulDetail?.cpe23_uri ? vulDetail?.cpe23_uri : "N/A"}</p>
                </td>
                
                <td style={{width: '835px'}} className={styles.tableCell} >
                  <p>no desc à revoire</p>
                </td>
                <td className={styles.tableCell} style={{display: 'flex'}}>
                <p style={{backgroundColor: '#e6e6e6', padding: "4px 8px", borderRadius: "32px", color: 'black'}}>
                  {vulDetail?.weakness_link ? vulDetail?.weakness_link :"N/A"}
                      </p>
                </td>
              </tr>
      </table>
        </div>
    )
}
