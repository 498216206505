import { CVEItem, GET_ALL_VULNERABILITIES, GET_VULNERABILITIES_BY_CVE, Page, SEARCH_QUERY_VUL, SET_PAGE_TABLE, SET_VUL_DETAIL, SUCCESS_VULNERABILITIES, SUCCESS_VULNERABILITIES_OVERVIEW, Vulnerability, VulnerabilityList } from "../constants";

export const getAllVulnerabilitiesAction = (data: VulnerabilityList) => ({
    type: SUCCESS_VULNERABILITIES,
    payload: data
})

export const getAllVulnerabilitiesPerPageAction = () => ({
    type: SET_PAGE_TABLE,
})

export const getVulnerabilitiesByCVEAction = (data: string) => ({
    type: SEARCH_QUERY_VUL,
    payload: data
})

export const setVulnerabilitiesByCVESearchAction = (data: string) => ({
    type: GET_VULNERABILITIES_BY_CVE,
    payload: data
})

export const setVulDetailAction = (data: Vulnerability) => ({
    type: SET_VUL_DETAIL,
    payload: data
})

export const successVulOverviewAction = (data: VulnerabilityList) => ({
    type: SUCCESS_VULNERABILITIES_OVERVIEW,
    payload: data
})