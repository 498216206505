import React, {FormEvent, useState, useEffect} from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import AvatarUpload from './components/AvatarUpload'
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircleCheck, faCircleXmark, faDownload } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import { getUserInfoRequest, updateUserInfoRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { Entreprise, UserInfo } from '../../core/constants'
import phonecodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'
import { setPictureEntrepriseAction, updateInfosEntreprise } from '../../core/actions/entrepriseActions'
import HeaderEntreprise from './components/HeaderEntreprise'
import { useIntercom } from '../Dashboard/IntercomSetup'
import ReactGA from 'react-ga4';
import { uploadEntreprisePictureProfileAPI } from '../../core/api/entrepriseApi'
import AvatarUploadEntreprise from './components/AvatarUploadEntreprise'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
        borderBottom: '1px solid green !important',
    },
    disabledInput :{
      color: "#333333"
    }
  }),
);

const EntrepriseSettings = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { entreprise} = useSelector(entrepriseSelector)
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [emailSecondary, setEmailSecondary] = useState<string>('');
    const [errorSecondary, setErrorSecondary] = useState<boolean>(false);
    const [formState, setformState] = useState<Entreprise>(entreprise);
    const [openNotification, setopenNotification] = useState(false)
    const [dataIsUpdated, setDataIsUpdated] = useState<boolean>(false)
    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = window.localStorage.getItem("fullName") 
   

    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed
  })
    

    const validateEmail = (email: string): boolean => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmail(value);
        setError(!validateEmail(value));
    };

    const handleChangeEmailSecondary = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value } = event.target;
        setEmailSecondary(value);
        setErrorSecondary(!validateEmail(value));
    };


    // const handleChange = (event: SelectChangeEvent) => {
    //     setRecent(event.target.value as string);
    //   };

    const handleChange = (event: typesHandleChange) => {
        const { name, value } = event.target;
        
        setformState(prevState => ({
        ...prevState,
        [name]: value,
        }));
    }

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(updateInfosEntreprise(formState))
        setopenNotification(true)
    }
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }

   
    useEffect(() => {
      document.title = "Settings - Entreprise";
      ReactGA.send({ hitType: "Settings - Entreprise", page: window.location.pathname });
    
      // Filter out 'profilePicture' and 'avatarUrl'
      const keysForm = Object.keys(entreprise).filter(key => key !== "avatarUrl");
    
      // Check if any of the other keys have been updated
      const isUpdated = keysForm.some(key => entreprise[key] !== formState[key]);
    
      // Update state if any key (except profilePicture and avatarUrl) is updated
      setDataIsUpdated(isUpdated);
    }, [formState]);

    
  
    
    
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderEntreprise dataIsUpdated={dataIsUpdated} title='Workspace Settings' handleSubmit={handleSubmit} setformState={setformState as any}/>
                <div  className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Enterprise logo</p>
                  <p className={styles.avatarPersonalAccountCard_text}>Put your brand front and center in Defendis by adding your logo.</p>
                    <AvatarUploadEntreprise/>
                </div>
                <div  style={{marginTop: 24}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Company information</p>
                    <p className={styles.avatarPersonalAccountCard_text}>
                    The information in this section is used for invoicing. Keep it up to date with the latest information available to you.
                    </p>
                    <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" fullWidth>

                    <div className={styles.personalInfosInputs}>
                        <TextField
                        value={formState.entrepriseName}
                         name='entrepriseName'
                         disabled
                         sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#ffffff",
                        },
                        "& .MuiInputLabel-root.Mui-disabled" :{
                          WebkitTextFillColor: "#aaaaaa",
                        }
                      }}
                          id="standard-basic"
                           label="Entreprise name" 
                           variant="standard" 
                           onChange={handleChange}
                           />
                        <TextField
                        value={formState.ein}
                        onChange={handleChange}
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#ffffff",
                        },
                        "& .MuiInputLabel-root.Mui-disabled" :{
                          WebkitTextFillColor: "#aaaaaa",
                        }
                      }}
                         name='ein'
                          id="standard-basic"
                           label="EIN"
                            variant="standard" />
                    </div>
                    <div className={styles.personalInfosInputs}>
                        <div className={styles.primaryEmailPersonalInfo}>
                        
                            <TextField 
                            onChange={handleChange}
                            disabled
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#ffffff",
                            },
                            "& .MuiInputLabel-root.Mui-disabled" :{
                              WebkitTextFillColor: "#aaaaaa",
                            }
                          }}
                            name='adrress1'
                            style={{color: "#333333"}}
                             value={formState.type} id="standard-basic"   label="Type" variant="standard" />
                            
                        </div>

                        <div  className={styles.primaryEmailPersonalInfo}>
                        <TextField 
                        disabled
                        sx={{
                          "& .MuiInputBase-input.Mui-disabled": {
                            WebkitTextFillColor: "#ffffff",
                        },
                        "& .MuiInputLabel-root.Mui-disabled" :{
                          WebkitTextFillColor: "#aaaaaa",
                        }
                      }}
                        name='country'
                        onChange={handleChange} value={formState.country}
                        id="standard-basic" label="Country" variant="standard" />
                        </div>
                        
                        
                    </div>
                    
                    </FormControl>
                    </form>
                </div>
                {/* <div style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Subscription history</p>
                    <p className={styles.avatarPersonalAccountCard_text}>View your current and expired subscriptions to Defendis</p>
                    <div className={styles.preferenceAccountInfo}>
                    <table className={styles.table}>
                      <thead>
                        <tr className={styles.tableHead}>
                          <th style={{paddingLeft: 8}}>Subscription</th>
                          <th style={{paddingLeft: 8}}>Start date</th>
                          <th style={{paddingLeft: 8}}>End date</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                            <tr className={styles.tableActivityRowWatchList}>
                          <td className={styles.tableCell}>{entreprise?.profileLicence}</td>
                          <td className={styles.tableCell} >
                            <div style={{display:'flex', alignItems: 'center', gap:10}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x'/>
                            <p>April 22, 2024</p>
                            </div>
                          </td>
                          <td className={styles.tableCell} >
                            <div style={{display:'flex', alignItems: 'center', gap:10}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x'/>
                            <p>April 22, 2024</p>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>      
                </div> */}
            </div>
            <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'Data updated successfuly'}
                handleClose={handleCloseNotification}
            />
        </div>
  )
}

export default EntrepriseSettings