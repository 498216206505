import { faCalendar, faChevronDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import moment from 'moment'
interface props {
  urls: any[] | null
}
function Urls({urls}: props) {
  const { groupDetail } = useSelector(ransomeSelector)
    return (
        <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}>
                            Title
                        </th>
                        <th style={{paddingLeft: 8}}>
                           
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Last Seen{" "}
                            <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Status {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                        </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                          {
                            urls && urls.map(item => <tr className={styles.tableActivityRowUsers}>
                              <td style={{width: '250px', padding: '10px 16px'}} className={styles.tableCell}>
                              {item?.title}
                              </td>
                              <td style={{width: '640px', padding: '10px 16px', color: "#e9bd08"}} className={styles.tableCell}>
                              {item?.slug}
                              </td>
                                <td style={{width: '250px', padding: '10px 16px'}} className={styles.tableCell} >
                                  <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                  {" "}{moment(item?.lastscrape).format("MMMM DD, YYYY")}
                                </td>
                                <td style={{width: '80px', padding: '10px 16px'}} className={styles.tableCell} >
                                {
                                  item?.enabled ? 
                                  <FontAwesomeIcon icon={faCircleCheck} width={11} height={12} color="#afafaf"/>
                                  :
                                  <div style={{height: 8, width:7, borderRadius:"100%", border: "2px solid #afafaf", backgroundColor:"transparent"}}/>
                                }
                                </td>
                              </tr>)
                          }
                          
                        
                        </tbody>
                    </table>
    )
}

export default Urls
