import { Dark, DnsResponse, Domaine, Places, SET_DARK, SET_DNS, SET_DOMAINE, SET_PLACE_DETAIL, SocialMedia, SocialMediaDetailPlatform, SocialMediaPost, SUCCESS_DARK, SUCCESS_DOMAINE, SUCCESS_PLACES, SUCCESS_SOCIALMEDIA, SUCCESS_SURFACEWEB, SurfaceWeb } from "../constants";

export const setPlacesAction = (data: Places[]) => ({
    type: SUCCESS_PLACES,
    payload: data
})

export const setPlaceDetailAction = (data: Places) => ({
    type: SET_PLACE_DETAIL,
    payload: data
})

export const setSurfaceWebAction = (data: SurfaceWeb[]) => ({
    type: SUCCESS_SURFACEWEB,
    payload: data
})

export const setSocialMediaAction = (data: SocialMediaPost[]) => ({
    type: SUCCESS_SOCIALMEDIA,
    payload: data
})

export const setSocialMediaDetailAction = (data: SocialMediaDetailPlatform) => ({
    type: SUCCESS_SOCIALMEDIA,
    payload: data
})


export const setDarkAction = (data: Dark[]) => ({
    type: SUCCESS_DARK,
    payload: data
})


export const setDarkDetailAction = (data: Dark) => ({
    type: SET_DARK,
    payload: data
})

export const setDomainAction = (data: Domaine[]) => ({
    type: SUCCESS_DOMAINE,
    payload: data
})


export const setDomainDetailAction = (data: Domaine) => ({
    type: SET_DOMAINE,
    payload: data
})

export const setDnsAction = (data: DnsResponse  | undefined) => ({
    type: SET_DNS,
    payload: data
})