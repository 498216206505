import axios from "axios";
import { NotificationBell, NotificationBellReq } from "../constants";
import { env } from "../../environement/env";

export const getNotificationsApi = async () => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/notifications/`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            },
        })
        return data as NotificationBell[];
    } catch (error) {
        
    }
}

export const getAllNotificationsApi = async () => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/notifications/all`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as NotificationBell[];
    } catch (error) {
        
    }
}

export const sendNotificationsApi = async (data: NotificationBellReq) => {
    try {
        await axios.post(`${env.apiUrl}/notifications/save`, data, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
    } catch (error) {
        console.error(error)
    }
}


export const updateNotificationsApi = async (data: number[]) => {
    try {
        await axios.post(`${env.apiUrl}/notifications/update`, {
            userids: data
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
    } catch (error) {
        console.error(error)
    }
}