import React from 'react'
import { Button, TextField } from '@mui/material'
import styles from "./auth.module.scss"
import BackBtn from './components/BackBtn'
import { useIntercom } from '../Dashboard/IntercomSetup'

function ResendEmail() {
    
    return (
        <div className={styles.main}>
            <div  className={styles.img}>
            <img src={"/Content.png"} height="99.3%" width="100%" />
            <img src="/defendisLogo.png" className={styles.logo}/>
            </div>
            <div className={styles.login}>
                <BackBtn/>
                <h2 className={styles.lightText}>Email Sent</h2>
                <p style={{fontSize: 12}} className={styles.lightText}>An link has been sent to the following email address: </p>
                <p style={{fontSize: 12}} className={styles.lightText}>al*****b@client.com</p>
                <Button className={styles.btnLogin}>Resend Email</Button>
                <div className={styles.demo}>
                <p style={{fontSize: 12, textAlign: "center"}} className={styles.lightText}>Need help? <span style={{textAlign: "center", fontWeight:"600", textDecoration:"underline"}}>Connect with support</span></p>
                </div>
            </div>
        </div>
    )
}

export default ResendEmail
