import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../dashboard.module.scss'
import Victims from './GroupDetailComponents/Victims'
import CryptoWallet from './GroupDetailComponents/CryptoWallet'
import ExternalInfo from './GroupDetailComponents/ExternalInfo'
import NegociationChats from './GroupDetailComponents/NegociationChats'
import RansomeNote from './GroupDetailComponents/RansomeNote'
import Urls from './GroupDetailComponents/Urls'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import moment from 'moment'
import { getGroupDescAPI, getGroupUrlsAPI } from '../../../core/api/ransomeApi'
function GroupDetail() {
    const navigate = useNavigate()
    const { groupDetail } = useSelector(ransomeSelector)
        const [activeCompo, setactiveCompo] = useState('urls')
    const [desc, setdesc] = useState<any | null>(null)
    const [dataGroups, setdataGroups] = useState<any[] | null>(null)
    const [isloadingdesc, setisloadingdesc] = useState(false)
    const [isLoadingUrls, setisLoadingUrls] = useState(false)
    const getGroupDesc = async () => {
        setisloadingdesc(true)
        if(groupDetail && groupDetail.name) {
            const response = await getGroupDescAPI(groupDetail.name)
            setdesc(response)
            setisloadingdesc(false)
        }
        else{
            setdesc(null)
            setisloadingdesc(false)
        }
    }

    const getGroupsUrl = async () => {
        setisLoadingUrls(true)
        if(groupDetail && groupDetail.name) {
            const response = await getGroupUrlsAPI(groupDetail.name)
            setdataGroups(response)
            setisLoadingUrls(false)
        } else {
            setdataGroups(null)
            setisLoadingUrls(false)
        }
    }

    useEffect(() => {
        getGroupDesc()
        getGroupsUrl()
    }, [])
    

    const renderSections = () => {
        switch (activeCompo) {
            case 'urls':
                return <Urls urls = {dataGroups}/>
            case 'externalInfo':
                return <ExternalInfo/>
            case 'ransomNote':
                return <RansomeNote/>
            case 'cryptoWallet':
                return <CryptoWallet/>
            case 'negoChat':
                return <NegociationChats/>
            case 'victims':
                return <Victims/>
            default:
                return;
        }
    }
    
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
            <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/ransomwareIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                            <FontAwesomeIcon icon={faArrowLeft}/>
                            <p>Go back / {groupDetail?.id}</p>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{groupDetail?.name}</span>
                    </div>
                </div>
            </div>
            <div style={{marginTop: 25, fontSize: 14}}>
                    <span style={{color: "#afafaf"}}>Last updated </span>
                    <span style={{color: "white"}}>{moment(groupDetail?.updated).format("MMMM DD, YYYY")} </span>
            </div>
            <div style={{backgroundColor: "#141414", padding: 10, marginTop: 25, borderRadius: 8}}>
                <p style={{color: "#cbcbcb", fontSize: 14}}>{
                !isloadingdesc ? desc && desc.description ? desc.description : 'Description not found.':'Description is loading ...'}</p>
            </div>
            <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px'  }} className={styles.firstSectionMenu}>
                    <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('urls')} className={activeCompo === 'urls' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>URLs</span>
                    {/* <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('externalInfo')} className={activeCompo === 'externalInfo' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>External Information</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('ransomNote')} className={activeCompo === 'ransomNote' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Ransom note</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('cryptoWallet')} className={activeCompo === 'cryptoWallet' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Crypto Wallet</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('negoChat')} className={activeCompo === 'negoChat' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Negotiation Chats</span>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('victims')} className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Victims</span> */}
                </div>
            {renderSections()}
            </div>

        </div>
    )
}

export default GroupDetail
