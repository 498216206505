import React from 'react'
import styles from '../../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
function ExternalInfo() {
  const { groupDetail } = useSelector(ransomeSelector)
   
  const openlinkDetail = (link: string) => {
    window.open(link, '_blank');
};
  return (
        <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
          <th style={{paddingLeft: 8}}>
              Link
          </th>
          
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowRansom1Col}>
              <td style={{width: '848px', padding: '10px 16px', color: "#e9bd08"}} className={styles.tableCell}>
              <span style={{cursor:'pointer'}} onClick={()=>openlinkDetail(groupDetail?.slug || "")}>{groupDetail?.slug}</span>
              </td>
              </tr>
            
          
          </tbody>
      </table>
    )
}

export default ExternalInfo
