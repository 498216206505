import { faCalendar, faChevronDown, faCreditCard, faDownload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../dashboard.module.scss'
import { CreditCardsThreatIntel, REQUEST_CREDITS_CARD } from '../../../core/constants'
import { getCreditsCardAi, PageSort } from '../../../core/api/threatIntelApi'
import { maskString } from './CredentialsLeaks'
import moment from 'moment'
import { height } from '@mui/system'
import logoVisa from "../../../icons/CreditCards/visa.svg"
import logoMastercard from "../../../icons/CreditCards/mastercard.svg"
import logoAmex from "../../../icons/CreditCards/amex.svg"
import logoDiscover from "../../../icons/CreditCards/discover.svg"
import logoUndefined from "../../../icons/CreditCards/undefined.svg"
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import EmptyState from '../common/EmptyState'
import { CircularProgress, Pagination } from '@mui/material'
import { useDispatch } from 'react-redux'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'
export default function PaymentCardLeaks() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { creditsCard, isLoadingCreditCard } = useSelector(threatIntelSelector) 
    const [search, setsearch] = useState("")
    const [page, setPage] = useState(1)
    const { notifs } = useSelector(notificationSelector)
    useEffect(() => {
      document.title = "Card Leak (Banks Only)"
      ReactGA.send({ hitType: "Threat Intel - Card Leak (Banks Only)", page: window.location.pathname });
    }, [])

    const setDetailCreditCard = (item: any) => {
      
      const dataLocalStorage: any = item
      dataLocalStorage.color = renderColors(
        Number(item?.total_risk_score).toFixed(1)
      ).textColor
      dataLocalStorage.bgColor = renderColors(
        Number(item?.total_risk_score).toFixed(1)
      ).backgroundColor
      dataLocalStorage.borderColor = renderColors(
        Number(item?.total_risk_score).toFixed(1)
      ).borderColor
      window.localStorage.setItem("item", JSON.stringify(dataLocalStorage))
      navigate('/threatIntel/creditCardDetail')
    }


    const getScoreBrandByWeight = (brand:string) => {
      switch (brand) {
        case "visa":
          return 1.5 * 0.95
        case "amex":
            return 1.5 * 1
        case "mastercard":
            return 1.5 * 0.95
        case "discover":
            return 1.5 * 0.9
        default:
            return 1.5 * 0.85
      }
    }

    const getScoreLevelByWeight = (level: string) => {
      switch (level) {
        case "infinite":
        case "black":
        case "signature":
        case "titanium":
          return 2 * 1
        case "platinum":
        case "world elite":
          return 2 * 0.9;
        case "gold":
        case "world":
          return 2 * 0.7;
        case "classic":
        case "standard":
        case "business":
        case "electron":
          return 2 * 0.4;
        case "prepaid":
          return 2 * 0.4;
        default:
          return 1;
      }
    }

    const getScoreTypeByWeight = (type: string) => {
      switch (type) {
        case "debit":
          return 1;
        case "credit":
          return 1 * 0.9
      
        default:
          return 1;
      }
    }

    const renderColors = (score: string) => {
      const riskScore = parseFloat(score);
      if(riskScore === 0.0)
        return {
          textColor: "#757575",
          backgroundColor: "#e6e6e6",
          borderColor: "#757575"
        }
      else if (riskScore >= 0.1  && riskScore <= 3.9)
        return {
          textColor: "#20b444",
          backgroundColor: "#ecf9ef",
          borderColor: "#20b444"
        }
      else if (riskScore >= 4.0  && riskScore <= 6.9)
        return {
            textColor: "#f5c500",
            backgroundColor: "#fff7d5",
            borderColor: "#f5c500"
          }
        else if (riskScore >= 7.0  && riskScore <= 8.9)
          return {
                textColor: "#fc9418",
                backgroundColor: "#feead1",
                borderColor: "#fc9418"
              }
        else
          return {
          textColor: "#e11900",
          backgroundColor: "#fdede8",
          borderColor: "#e11900"
        }
    }

    const getFinalRiskScore = (
      brand: string,
      level: string,
      type: string
    ) => {
      let score = getScoreBrandByWeight(brand) + 
                  getScoreLevelByWeight(level) +
                  getScoreTypeByWeight(type)
      score /=4.5
      return score*10
    }
    const handleChangePage = (
      event: React.ChangeEvent<unknown>, value: number
    ) => {
      const dataCred: PageSort = {
        page:value,
        sortBy: search
    }
      setPage(value)
      dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
    };
    const handleSearch = (
    ) => {
     if(search!==""){
      const dataCred: PageSort = {
        page:page,
        sortBy: search
    }
      dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
     }else {
      const dataCred: PageSort = {
        page:page,
        sortBy: null
    }
      dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred })
     }
    };

    const creditsCardNotif = notifs.filter(item => item.isCreditsCard === true && item.read === false)
      const sortedResult = creditsCard?.data?.sort((a, b) => {
          const aInCardNotif = creditsCardNotif.some((notif) => notif?.data?.cardid === a.cardid);
              const bInCardNotif = creditsCardNotif.some((notif) => notif?.data?.cardid === b.cardid);
      
              // Sort elements in vulNotif to the top
              if (aInCardNotif && !bInCardNotif) return -1;
              if (!aInCardNotif && bInCardNotif) return 1;
          return 1
      })
    return (
        <div style={{marginTop: 20}}>
          <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
          <div className={styles.searchAndIcon}>
          <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e)=>setsearch(e.target.value)}/>
          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch}/>
            </div><div className={styles.removeSelected}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 14}} />
                            </div>

            </div>
            <div style={{width: "100%"}}>
            {
              !isLoadingCreditCard ?
              sortedResult && sortedResult.length!==0? 
              <>
              <table className={styles.table}>
                        <tr className={styles.tableHead}>
                          <th></th>
                          <th style={{paddingLeft: 8}}>
                              <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                              Card Number
                                </p>
                        </th>
                          <th style={{paddingLeft: 8}}>
                            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                            Expiry
                                </p>
                          </th>
                          {/*<th style={{paddingLeft: 8}}>
                           <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                          Code
                                </p> 
                          </th>*/}
                          <th style={{paddingLeft: 8}}>
                          < p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                          Country
                                </p>
                          </th>
                          <th style={{paddingLeft: 8}}>
                            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                Bank Name
                                </p>
                          </th>
                          <th>Risk Score</th>
                          <th style={{paddingLeft: 8, display:"flex", justifyContent:"end", paddingRight:13}}>
                            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                Brand
                                </p>
                          </th>
                        </tr>
                          {
                            creditsCard?.data?.map((item) => 
                              <tr className={styles.tableActivityRowCard} onClick={()=>setDetailCreditCard(item)}>
                            <td className={styles.tableCell} style={{width:30}}>
                            {
                                        creditsCardNotif.some((respItem: any) => {
                                            
                                            return respItem?.data?.cardid === item.cardid;
                                        }) ? (
                                            <div className={styles.redPoint} />
                                        ) : (
                                         null // Nothing is displayed if the condition is not met
                                        )
                                    }
                              </td>
                            <td style={{width: '240px'}} className={styles.tableCell}>
                            {item?.cardnumber}
                            </td>   
                            
                            <td className={styles.tableCell} style={{width: "140px"}}>
                                <div  style={{ display: 'flex', alignItems: "center", gap: 10, width: "120px" }}>
                                    <FontAwesomeIcon icon={faCalendar} height={12} width={11}/>
                                    <p style={{ fontSize: 14 }}>{item?.expiredate}</p>
                                </div>
                            </td>
                            {/* <td style={{width: "140px"}} className={styles.tableCell}>
                                526
                            </td> */}
                            <td style={{width: "140px"}} className={styles.tableCell}>
                             {item?.countrycode}
                            </td>
                            <td style={{width: "234px"}} className={styles.tableCell}>
                                {item?.bank_name}
                            </td>
                            <td className={styles.tableCell} style={{width: "120px"}}>
                            <span style={{width: 32,
                            color: renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.textColor,
                            backgroundColor: renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.backgroundColor,
                            border: `1px solid ${renderColors(
                              Number(item?.total_risk_score)?.toFixed(1)
                            )?.borderColor}`


                            }} className={styles.scoreSeverity}>{
                              Number(item?.total_risk_score).toFixed(1)
                            }</span>
                            </td>
                            <td className={styles.tableCell} style={{width: "80px"}}>
                            <div style={{ display:"flex", justifyContent:"end", paddingRight:13}}>
                            <img src={
                                item?.brand?.toLowerCase() === "visa" ? logoVisa : 
                                item?.brand?.toLowerCase() === "discover" ? logoDiscover :
                                item?.brand?.toLowerCase() === "amex" ? logoAmex : 
                                item?.brand?.toLowerCase() === "mastercard" ? logoMastercard : logoUndefined
                            }/>
                            </div>
                            </td>
                        
                          </tr>
                            )
                          }
                    </table>
                    <Pagination
                showFirstButton
                showLastButton
                shape="rounded"
                onChange={handleChangePage}
                page={Number(creditsCard?.pagination?.currentPage)}
                count={creditsCard?.pagination?.totalPages}
                sx={{
                 '& .MuiPagination-ul': {
                    justifyContent: "center",
                    display: 'flex'
                },
                  '& .Mui-selected': {
                    color: '#ffffff', // Text color for active page
                    backgroundColor: '#1f1f1f', // Background color for active page
                  },
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff', // Text color for inactive pages
                  },
                }}
            />
                    </>
                    : <EmptyState/>
                    :
                    <div className={styles.loaderVul}>
                    <CircularProgress/>
                    </div>
            }
                    </div>
        </div>
    )
}
