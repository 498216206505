import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
function Credentials() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Username
            </th>
            <th style={{paddingLeft: 8}}>
            Password
            </th>
            <th style={{paddingLeft: 8}}>
            Source
            </th> 
            <th style={{paddingLeft: 8}}>
            Risk Score <FontAwesomeIcon icon={faChevronDown} height={8} width={7}/>
            </th>
            <th style={{paddingLeft: 8}}>
            Application
            </th> 
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowWatchListAssets}>
              <td style={{width: '314px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>m.scott@dundermifflin.com</p>
              </td>
              
              <td style={{width: '180px'}} className={styles.tableCell} >
                <p>HRct76*****</p>
              </td>
              <td style={{width: '314px'}} className={styles.tableCell} >
                  <p style={{fontSize: 14, color: '#cbcbcb'}}>
                  https://member.dundermifflin.com
                  </p>
              </td>
              <td style={{width:'140px'}} className={styles.tableCell}>
              <span className={styles.scoreSeverity}>9.1</span>
              </td>
              <td style={{width:'210px'}} className={styles.tableCell}>
                <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center', backgroundColor: 'white', borderRadius: '50%', height:"2.04vh", width:"22px"}}>
                         <img src="/chrome.png" alt="" height={28} width={36}/>
                    </div>
                    <p style={{color: 'white', fontSize: "14px"}}>Google Chrome</p>
                </div>
              </td>
            </tr>
            
          </tbody>
      </table>
    )
}

export default Credentials
