import React, {FormEvent, useState, useEffect} from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { Avatar, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircleCheck, faCircleXmark, faSearch, faTrash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import {  getUserInfoRequest, updateSecurityInfoUserRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { UserInfo, SecurityPage, UsersEntreprise, Roles, UpdateRoleUserParams } from '../../core/constants'
import phoneCodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import HeaderSecurity from './components/HeaderSecurity'
import HeaderUsers from './components/HeaderUsers'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import { approuveUserRequest, getUsersEntrepriseRequest } from '../../core/actions/entrepriseActions'
import { deleteUserApi, deleteUsersAPI, getUsersEntrepriseAPI } from '../../core/api/entrepriseApi'
import { useIntercom } from '../Dashboard/IntercomSetup'
import ReactGA from 'react-ga4';
import EmptyStateUsers from '../Dashboard/common/EmptyStateUsers'
import { updateRoleUserAPI } from '../../core/api/userApi'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
        borderBottom: '1px solid green !important',
    }
  }),
);

const Users = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const data: any = useSelector(entrepriseSelector)
    const {auth} = useSelector(authSelector)
    const navigate = useNavigate()
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [formState, setformState] = useState<UsersEntreprise[]>();
    const [openNotification, setopenNotification] = useState(false)
    const [newPassword, setnewPassword] = useState<string>('')
    const [rePassword, setrePassword] = useState<string>('')
    const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
    const [emailStorage, setemailStorage] = useState<string | undefined>()
    const [hmacStorage, sethmacStorage] = useState<string | undefined>()
    const [fullName, setfullName] = useState<string | undefined>()
    const [open, setOpen] = useState(false)
    const [msg, setmsg] = useState('')
    const [colorNotf, setcolorNotf] = useState('')
    const [openNotifRole, setopenNotifRole] = useState(false)
    const { userInfo, roles, roleUser } = useSelector(userSelector)
    const [search, setsearch] = useState("")
    const [appouvedSearch, setappouvedSearch] = useState("")
  console.log(selectedUserIds)
    const validatePassword = (password: string, retPassword: string): boolean => {
        return password !== retPassword
    };

    

    

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // dispatch(updateSecurityInfoUserRequest({
        //     security: formState
        // }))
        // setopenNotification(true)
    }
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }


    const deleteUser = (id: number) => {
      deleteUserApi(id)
      dispatch(getUsersEntrepriseRequest())
    }
  
    
    const handleDeleteSession = async (sessionId: number) => {
      // Step 3: Call Backend API to delete the session
      // This is a placeholder function call. Replace it with your actual API call.
      // await deleteSessionAPI(sessionId);
    
      // Step 2: Update State on Deletion
      // Assuming each session object has a unique identifier such as `id`
    //   const updatedSessions = formState.sessions.filter(session => session.id !== sessionId);
    //   setformState({...formState, sessions: updatedSessions});
    
      // Optionally, show a notification to the user
      setopenNotification(true);
    };

    useEffect(() => {
      document.title = "Settings - Users"
      ReactGA.send({ hitType: "Settings - Users", page: window.location.pathname });

      
      
      return
    }, [data])

    const emailStorageVa = window.localStorage.getItem('email')
      const hmacValue = window.localStorage.getItem('hmac')
      const fullNamed = window.localStorage.getItem("fullName")

    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed,
  })
    
    const approuveUser = (id: number) => {
      dispatch(approuveUserRequest({
        userId: id
      }))
    }

    const deleteUsers = () => {
      if(selectedUserIds.length > 0) {
        deleteUsersAPI(selectedUserIds);
      dispatch(getUsersEntrepriseRequest())
      }
    }
    
    const handleSelectUser = (userId: number, isChecked: boolean) => {
      if (isChecked) {
        // Add the user ID to the selectedUserIds array if not already present
        setSelectedUserIds(prevSelectedUserIds => [...prevSelectedUserIds, userId]);
      } else {
        // Remove the user ID from the selectedUserIds array
        setSelectedUserIds(prevSelectedUserIds => prevSelectedUserIds.filter(id => id !== userId));
      }
    };
      console.log(data?.entreprise?.users)
    const userAwaiting = data?.entreprise?.users?.filter((user:UsersEntreprise) => user.email.toLowerCase().includes(search.toLowerCase()) && userInfo.email !== user.email && (user.firstname === null && user.lastname  === null))
    const usersApproved = data?.entreprise?.users?.filter((user:UsersEntreprise) => user.email.toLowerCase().includes(appouvedSearch.toLowerCase()) &&   userInfo.email !== user.email && ( user.firstname ||  user.lastname))
  
    console.log(usersApproved?.[0])

    const handleChange = async (userid: number, event: SelectChangeEvent, email: string) => {
      const role =  event.target.value
     const data: UpdateRoleUserParams = {
      role,
      userid
     }
     const resp = await updateRoleUserAPI(data)
     setTimeout(() => {
      dispatch(getUsersEntrepriseRequest())
     }, 500);
     if(resp === 'FAILED') {
      setmsg(`Failed to change role to "${role}" for user with email "${email}".`)
      setcolorNotf('error')
     }else {
      setmsg(`Successfully changed role to "${role}" for user with email "${email}".`)
      setcolorNotf('success')
     }
     setopenNotifRole(true)
    }

    const usersData = data?.entreprise?.users.filter((user:UsersEntreprise ) => user.email !== userInfo.email)
    console.log(roles)
    return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderUsers title='Users' open={open} setOpen={setOpen}/>
                <form onSubmit={handleSubmit} >
                    <FormControl component="fieldset" fullWidth>
                      
                          <div className={styles.avatarPersonalAccountCard}>
                        <p className={styles.avatarPersonalAccountCard_title}>Awaiting Approval</p>
                        <div className={styles.searchAndIcon}>
                          <input className={styles.search} onChange={(e)=>setsearch(e.target.value)} placeholder='Search'/>
                          <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                      </div>
                        <table className={styles.table}style={{marginTop:25}}>
                      
                      <tbody>
                      {
                        userAwaiting?.length > 0 ? 
                        
                            userAwaiting?.map((user: UsersEntreprise) => 
                             <tr  className={styles.tableActivityRowWatchList}>
                              
                                <td className={styles.tableCell}>{
                                user.firstname || user.lastname ? user.firstname+" "+user.lastname: "Anonymous"
                                }</td>
                                <td className={styles.tableCell}>{user.email}</td>

                                <td style={{padding: '13px 8px'}}  className={styles.tableCell} >
                                <div style={roleUser?.role === "Admin" ? {display: 'flex', alignItems: "center", gap:"15px", justifyContent:"flex-end"}: {display: 'flex', alignItems: "center", gap:"15px", justifyContent:"flex-end",  padding: '10px 8px'}}>
                                {/* <p onClick={()=>approuveUser(user.id)} style={{backgroundColor: "#20b444",color:"white", textAlign:"center",verticalAlign:"middle", fontSize: 12, padding: '4px 8px',cursor:'pointer',  border:'none', borderRadius: 32}} >Approve</p> */}
                                {
                                  roleUser?.role === "Admin" &&   <FontAwesomeIcon onClick={()=>deleteUser(user.id)} style={{cursor: 'pointer'}} icon={faXmarkCircle} color='rgba(255, 255, 255, 0.3)' size='1x'/>
                                }
                                </div>
                                </td>
                              </tr>
                            )   
                        :
                        <div><EmptyStateUsers open={open} setOpen={setOpen}/></div>
                      }
                        </tbody>
                    </table>
                      </div>  
                        
                      <div>
                     
                          <div className={styles.avatarPersonalAccountCard}>
                        <p  style={{cursor: "pointer"}} className={styles.avatarPersonalAccountCard_title}>Approved Users</p>
                        <div className={styles.searchAndRemove}>
                        <div className={styles.searchAndIcon}>
                    <input className={styles.search} onChange={(e)=>setappouvedSearch(e.target.value)} placeholder='Search'/>
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                </div>
                       {
                        roleUser?.role === "Admin" &&  <div onClick={deleteUsers} className={styles.removeSelected}>
                        <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                        <FontAwesomeIcon icon={faTrash} color='white' style={{fontSize: 12}} />
                    </div>
                       }
                    </div>
                    {usersApproved?.length > 0 ? 
                    <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                          <th style={{paddingLeft: 8}}></th>
                          <th style={{paddingLeft: 8}}>Full Name</th>
                          <th style={{paddingLeft: 8}}>Email</th>
                          <th style={{paddingLeft: 8}}>Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                           
                            usersApproved?.map((user: UsersEntreprise) => 
                              <tr className={styles.tableActivityRowUsers}>
                              <td className={styles.tableCell} style={{width:40}}>
                              {
                                roleUser?.role === "Admin" && <input 
                                type="checkbox" 
                                onChange={(e) => handleSelectUser(user.id, e.target.checked)} 
                                checked={selectedUserIds.includes(user.id)}
                            />
                              }
                              </td>
                              <td className={styles.tableCell}>{user.firstname} {user.lastname}</td>
                              <td className={styles.tableCell}>{user.email}</td>
                              <td className={styles.tableCell} style={{padding:'10px 0px'}} >
                                <div style={{padding: "4px 0px"}}>
                                {/* <p style={{backgroundColor: "#e6e6e6",color:"#1a1a1a",verticalAlign:"middle", fontSize: 12, padding: '4px 12px',border:'none', borderRadius: 32, width:"fit-content"}} >{user.role ? user.role : 'No role'}</p> */}
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={user.permissions?.[0].roles?.toString()}
                                    onChange={(e)=> handleChange(user.id, e, user.email)}
                                    style={{backgroundColor: "#e6e6e6",color:"#1a1a1a",verticalAlign:"middle", fontSize: 12, padding: '4px 0px',border:'none', borderRadius: 32, width:"fit-content"}}
                                    disabled={roleUser && roleUser.role === "Admin" ? false: true}
                               >
                                    {
                                     roles && roles.map(item =>
                                       
                                        <MenuItem key={item.id.toString()} value={item.id.toString()}>{item.role}</MenuItem>
                                      )
                                    }
                                           
                                </Select>
                                </div>
                              </td>
                            </tr>
                            ) 
                        }
                        </tbody>
                    </table>
                     : <div>
                              <EmptyStateUsers open={open} setOpen={setOpen}/> 
                            </div>
}
                      </div>
                        
                       </div>
                      
                    {/* <div className={styles.searchAndRemove}>
                        <div className={styles.searchAndIcon}>
                            <input className={styles.search} name='search' placeholder='Search'/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                        </div>
                        <div className={styles.removeSelected}>
                            <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                            <FontAwesomeIcon icon={faTrash} color='white' style={{fontSize: 12}} />
                        </div>
                    </div>
                     */}
                </FormControl>
                    </form>
            </div>
            <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'Data updated successfuly'}
                handleClose={handleCloseNotification}
            />
            <ErrorNotification
                severity={colorNotf}
                open={openNotifRole}
                message={msg}
                handleClose={()=>setopenNotifRole(false)}
            />
        </div>
  )
}

export default Users