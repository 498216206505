import { APPROUVE_USER, ApprouveUserReq, Entreprise, EntrepriseSupaBase, GET_INFOS_ENTREPRISE, GET_USERS_ENTREPRISE, SAVE_USER_ENTREPRISE, SET_ENTREPRISE_PICTURE_PROFILE, SET_INFOS_ENTREPRISE, SET_USERS_ENTREPRISE, SUCCESS_GET_ALL_ENTREPRISES, UPDATE_INFOS_ENTREPRISE, UserEntrepriseReq, UsersEntreprise } from "../constants";

export const getInfosEntrepriseRequest = () => ({
    type: GET_INFOS_ENTREPRISE,
})

export const setInfosEntrepriseSuccess = (data: Entreprise) => ({
    type:SET_INFOS_ENTREPRISE,
    payload: data
})

export const updateInfosEntreprise = (data: Entreprise) => ({
    type: UPDATE_INFOS_ENTREPRISE,
    payload: data
})

export const getUsersEntrepriseRequest = () => ({
    type: GET_USERS_ENTREPRISE
})

export const setUsersEntreriseSucess = (data: {users: UsersEntreprise[]}) => ({
    type: SET_USERS_ENTREPRISE,
    payload: data
})

export const saveUserEntrepriseRequest = (data: UserEntrepriseReq) => ({
    type: SAVE_USER_ENTREPRISE,
    payload: data
})

export const approuveUserRequest = (data: ApprouveUserReq) => ({
    type: APPROUVE_USER,
    payload: data
})

export const setPictureEntrepriseAction = (data: string) => ({
    type: SET_ENTREPRISE_PICTURE_PROFILE,
    payload: data
})

export const setAllEntreprisesAction = (data: EntrepriseSupaBase[]) => ({
    type: SUCCESS_GET_ALL_ENTREPRISES,
    payload: data
})