const processEnv = process.env;

interface EnvVarriable {
  apiUrl: string;
}

export const env: EnvVarriable = {
  apiUrl: processEnv.REACT_APP_API_URL || '',
  
};

