import React, {FormEvent, useState, useEffect} from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './account.module.scss'
import HeaderPersonalInfo from './components/HeaderPersonalInfo'
import { FormControl, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCircleCheck, faCircleXmark, faTrash } from '@fortawesome/free-solid-svg-icons'
import { PersonalInformation, typesHandleChange } from './constants'
import { useDispatch } from 'react-redux'
import {  getInfoSecurityPageRequest, getUserInfoRequest, updateSecurityInfoUserRequest } from '../../core/actions/userActions'
import { useSelector } from 'react-redux'
import { userSelector } from '../../core/reducers/userReducer'
import { Session, SecurityPage } from '../../core/constants'
import phoneCodes from './common/phoneCode.json'
import ErrorNotification from './common/ErrorNotification'
import HeaderSecurity from './components/HeaderSecurity'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment';
import { useIntercom } from '../Dashboard/IntercomSetup'
import ReactGA from 'react-ga4';
import { updateSecurityInfoUserAPI } from '../../core/api/userApi'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
    },
    validEmail: {
        borderBottom: '1px solid green !important',
    }
  }),
);

const Security = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { security } = useSelector(userSelector)
    const { auth } = useSelector(authSelector)
    const [recent, setRecent] = useState('10')
    const [email, setEmail] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [formState, setformState] = useState<SecurityPage>(security);
    const [openNotification, setopenNotification] = useState(false)
    const [newPassword, setnewPassword] = useState<string>(formState.newPassword)
    const [rePassword, setrePassword] = useState<string>(formState.password)
    const [emailStorage, setemailStorage] = useState<string | undefined>()
    const [hmacStorage, sethmacStorage] = useState<string | undefined>()
    const [fullName, setfullName] = useState<string | undefined>()
    const [msg, setmsg] = useState('')
    const [msgColor, setmsgColor] = useState('')
    const [sessionUpdate, setsessionUpdate] = useState<Session[]>([])
    const validatePassword = (password: string, retPassword: string): boolean => {
        return password !== retPassword
    };

    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed= window.localStorage.getItem("fullName")
    useEffect(() => {
      document.title = "Settings - Security"
      ReactGA.send({ hitType: "Settings - Security", page: window.location.pathname });

      
        
    }, [])
    useIntercom('isows87y', {
      app_id: 'isows87y',
      api_base: 'https://api-iam.intercom.io',
      email: emailStorageVa as string,
      user_hash: hmacValue as string,
      name: fullNamed
  })
    

    const [dateState, setDateState] = useState('');
    const updateDateDisplay = (timestamp: string) => {
      const date = moment(parseInt(timestamp));
      if (date.isSame(moment(), 'day')) {
        // If the date is today, set to "current"
        return 'Current';
      } else {
        // Otherwise, set to "month number, year"
        return date.format('MMMM DD, YYYY');
      }
    }
      // Update the display based on the current timestamp
      
    const handleChange = (event: typesHandleChange) => {
        const { name, value} = event.target;
        if ('checked' in event.target && (name === 'verifyWithEmail' || name === 'verifyWithSms')) {
          const { name, checked } = event.target as HTMLInputElement;          // Handle the state update for a checkbox or switch here
          setformState(prevState => ({
            ...prevState,
            [name]: checked,
            }));
        }else {
          setformState(prevState => ({
            ...prevState,
            [name]: value,
          }));
        }
        
        
        if(name === 'password') {setrePassword(value)
          setError(value !== formState.newPassword)}
        if(name === 'newPassword'){
          setError(value !== formState.password)}
        
    }


    

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        formState.sessions = sessionUpdate
        const resp = await updateSecurityInfoUserAPI({
          security: formState
        })
        if(resp.message === "THE_ACTUAL_PASSWORD_IS_WRONG") {
          setmsg('The actual password is incorrect.')
          setmsgColor('error')
        } else if(resp.message === "data_info_security_updated") {
          setmsg("Data information security updated successfully")
          setmsgColor('success')
          setTimeout(() => {
            dispatch(getInfoSecurityPageRequest())
          }, 500);
        } else {
          setmsg('Token Expired')
          setmsgColor('error')
          navigate('/')
        }
        setopenNotification(true)
        setsessionUpdate([])
    }
    
    const handleCloseNotification = () => {
        setopenNotification(false)
    }

  
    
    const handleDeleteSession = async (sessionId: number) => {
      
      const updatedSessions = formState.sessions.filter(session => session.id !== sessionId);
      setformState({...formState, sessions: updatedSessions});
      const sessionToDelete = formState.sessions.filter(session => session.id === sessionId)
      setsessionUpdate(sessionUpdate.concat(sessionToDelete))
      setmsg("Session deleted successfully")
      setmsgColor("success")
      // Optionally, show a notification to the user
      setopenNotification(true);
    };
    
  return (
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <HeaderSecurity security={formState} password={rePassword} setpassword={setrePassword} newPassword={newPassword} setNewPassword={setnewPassword} setsessionUpdate={setsessionUpdate} sessions={sessionUpdate} title='Security' handleSubmit={handleSubmit} setformState={setformState as any}/>
                <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" fullWidth>
                <div className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Password</p>
                  <p className={styles.avatarPersonalAccountCard_text}>To change your password, create a new password that you don’t use elsewhere and verify it with your primary email. Change your password anytime you think it might have been compromised.</p>
                    <div className={styles.personalInfosInputs}>
                      <div className={styles.primaryEmailPersonalInfo}>
                        <TextField name='password' value={formState.password} onChange={handleChange} id="standard-basic" label="Actual password" variant="standard" />
                       
                      </div>
                      <div className={styles.primaryEmailPersonalInfo}>
                        <TextField name='newPassword' value={formState.newPassword} onChange={handleChange} id="standard-basic" label="Confirm new password" variant="standard" />
                       
                      </div>
                        
                    </div>
                </div>
                {/* <div  style={{marginTop: 15}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Two-step verification</p>
                    <p className={styles.avatarPersonalAccountCard_text}>
                    Secure your account by requiring an additional authentication method every time you sign-in. Receive sign-in verification codes by email or text message. If we detect an unusual login, we may email you a code to verify your identity even if you aren’t enrolled in two-step verification.
                    </p>
                    
                    <div className={styles.personalInfosInputs} style={{justifyContent: "space-between"}}>
                      <div className={styles.personalInfosInputs}>
                        <p className={styles.avatarPersonalAccountCard_text}>Verify with email</p>
                        <Switch checked={formState.verifyWithEmail} onChange={handleChange} name='verifyWithEmail'/>
                      </div>
                      <div className={styles.personalInfosInputs}>
                        <p className={styles.avatarPersonalAccountCard_text}>Verify with text messages (SMS)</p>
                        <Switch checked={formState.verifyWithSms} onChange={handleChange} name='verifyWithSms'/>
                      </div>
                    </div>
                    
                </div> */}
                
                <div style={{marginTop: 24}} className={styles.avatarPersonalAccountCard}>
                    <p className={styles.avatarPersonalAccountCard_title}>Active sessions</p>
                    <p className={styles.avatarPersonalAccountCard_text}>The devices and browsers you're signed in to are listed below. If you see unfamiliar devices or locations, change your password immediately.</p>
                    <div className={styles.preferenceAccountInfo}>
                    <table className={styles.table}>
                      <thead>
                        <tr className={styles.tableHead}>
                          <th style={{paddingLeft: 8}}>Browser</th>
                          <th style={{paddingLeft: 8}}>Location</th>
                          <th style={{paddingLeft: 8}}>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          formState.sessions.map((session)=><tr className={styles.tableActivityRow}>
                          <td className={styles.tableCell}>{session.browser.substring(0,30)}</td>
                          <td className={styles.tableCell}>{session.location}</td>
                          <td className={styles.tableCell} style={{display:'flex', alignItems: 'center', gap:10}}>
                            <FontAwesomeIcon icon={faCalendar} size='1x'/>
                            <p>{updateDateDisplay(session.recentActivity)} </p>
                          </td>
                          <td className={styles.tableCell} >
                            <FontAwesomeIcon onClick={() => handleDeleteSession(session.id)} style={{cursor: 'pointer'}} icon={faTrash} size='1x'/>
                          </td>
                        </tr>)
                        }
                        </tbody>
                    </table>
                    </div>      
                </div>
            
                </FormControl>
                    </form>
            </div>
            <ErrorNotification
                severity={msgColor}
                open={openNotification}
                message={msg}
                handleClose={handleCloseNotification}
            />
        </div>
  )
}

export default Security