import React from 'react'
import styles from '../../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
function formatNumber(number: number) {
  return new Intl.NumberFormat('en-US').format(number);
}
function Overview() {
  const { countryDetail } = useSelector(ransomeSelector)
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <tr  className={styles.tableHead}>
              <th style={{width: '200px', padding: '14px 16px'}} >
              Title
              </th>
                  <th style={{width: '918px',padding: '14px 16px'}} >
                  Description
                  </th>
            </tr>
        <tbody>
            
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '200px',padding: '14px 16px'}} className={styles.tableCell}>
              Capital
              </td>
                  <td style={{width: '918px',padding: '14px 16px'}} className={styles.tableCell}>
                  {countryDetail?.countryDetail?.[0]?.capital}
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '200px',padding: '14px 16px'}} className={styles.tableCell}>
              Population
              </td>
                  <td style={{width: '918px',padding: '14px 16px'}} className={styles.tableCell}>
                  {formatNumber(Number(countryDetail?.countryDetail?.[0]?.population))} people
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '200px',padding: '14px 16px'}} className={styles.tableCell}>
              Area
              </td>
                  <td style={{width: '918px',padding: '14px 16px'}} className={styles.tableCell}>
                  {formatNumber(Number(countryDetail?.countryDetail?.[0]?.area_sq_km))} square kilometers
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '200px',padding: '14px 16px'}} className={styles.tableCell}>
              Timezone
              </td>
                  <td style={{width: '918px',padding: '14px 16px'}} className={styles.tableCell}>
                  {countryDetail?.countryDetail?.[0]?.timezones?.replace('}','')?.replace('{','')}
                  </td>
            </tr>
            
          </tbody>
      </table>
    )
}

export default Overview
