import { faCalendar, faCamera, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import styles from '../dashboard.module.scss'
import { Button, MenuItem, Select, TextField } from '@mui/material'
import ReactGA from 'react-ga4';

export default function GenerateReports() {
  useEffect(() => {
    ReactGA.send({ hitType: "Reports - Generate Reports", page: window.location.pathname });

  }, [])
  
  return (
    <div style={{display: 'flex', justifyContent: 'center', width:'100%'}}>
          <div style={{width: '70%'}}>
          <TextField id="standard-basic" label="Report Name" variant="standard" />
          <div style={{display: 'flex', gap: 15, width: '100%', marginTop:15}}>
                <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{width: '50%'}}
                              placeholder='Threat Types'
                              className={styles.selectPreferences}
                              
                          >

                              <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                              <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
                    <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{width: '50%'}}
                              placeholder='Risk score'
                              className={styles.selectPreferences}
                              
                          >

                              <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                              <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
          </div>
          <div style={{display: 'flex', gap: 15, width: '100%', marginTop:15}}>
                <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{width: '50%'}}
                              placeholder='10/04/2024'
                              className={styles.selectPreferences}
                              
                          >

                              <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                              <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
                    <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              style={{width: '50%'}}
                              placeholder='21/04/2024'
                              className={styles.selectPreferences}
                              
                          >

                              <MenuItem value={'10'}>{"GMT+1"}</MenuItem>
                              <MenuItem value={'20'}>{"GMT+2"}</MenuItem>
                    </Select>
          </div>

          <div style={{display: 'flex',justifyContent: 'flex-end'}}>

            <Button style={{backgroundColor: 'white', color:'black', width: 'fit-content'}}>Generate</Button>          </div>
          </div>
    </div>
  )
}
