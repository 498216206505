import { createBrowserRouter } from 'react-router-dom'
import { authRouter } from './auth.router'
import { cboRouter } from './cbo.router';
import { accountRouter } from './account.router';
import Home from '../views/Dashboard/Home';
import React from 'react';
import PersonnalAccount from '../views/Account/PersonnalAccount';
import Security from '../views/Account/Security';
import EntrepriseSettings from '../views/Account/EntrepriseSettings';
import Users from '../views/Account/Users';
import WatchList from '../views/Account/WatchList';
import Nottifications from '../views/Account/Nottifications';
import Support from '../views/Account/Support';
import Notification from '../views/Dashboard/common/Notification';
import ReportDetail from '../views/Dashboard/ReportDetail';
import ReportsView from '../views/Dashboard/ReportsView';
import CompilnaceView from '../views/Dashboard/CompilnaceView';
import UserProfile from '../views/Dashboard/ThreatIntelSections/Components/UserProfile';
import CreditCardDetail from '../views/Dashboard/ThreatIntelSections/CreditCardDetail';
import ArchiveDetail from '../views/Dashboard/ThreatManagementSections/ArchiveDetail';
import ThreatManagementView from '../views/Dashboard/ThreatManagementView';
import UserProfileCredential from '../views/Dashboard/ThreatIntelSections/Components/credentialSections/UserProfileCredential';
import CredentialLeakDetail from '../views/Dashboard/ThreatIntelSections/Components/CredentialLeakDetail';
import ThreatIntelView from '../views/Dashboard/ThreatIntelView';
import Conversations from '../views/Dashboard/RansomeSections/Components/Conversations';
import GroupDetail from '../views/Dashboard/RansomeSections/GroupDetail';
import CountryDetail from '../views/Dashboard/RansomeSections/CountryDetail';
import RansomeView from '../views/Dashboard/RansomeView';
import DomainTakeDownRequest from '../views/Dashboard/BrandIntelSections/DomainTakeDownRequest';
import SocialMediaProfile from '../views/Dashboard/BrandIntelSections/SocialMediaProfile';
import DarkwebDetail from '../views/Dashboard/BrandIntelSections/DarkwebDetail';
import DomainDetail from '../views/Dashboard/BrandIntelSections/DomainDetail';
import BrandIntelView from '../views/Dashboard/BrandIntelView';
import VulnerabilityDetail from '../views/Dashboard/components/VulnerabilityDetail';
import VulnerabilitiesView from '../views/Dashboard/VulnerabilitiesView';
import Admin from '../views/Admin/Admin';
import InviteLink from '../views/auth/InviteLink';
import OtpVerification from '../views/auth/OtpVerification';
import ResendEmail from '../views/auth/ResendEmail';
import NewPassword from '../views/auth/NewPassword';
import ForgotPassword from '../views/auth/ForgotPassword';
import SignIn from '../views/auth/SignIn';
import FirstTimeSetPassword from '../views/auth/FirstTimeSetPassword';
import FirstTimeLogin from '../views/auth/FirstTimeLogin';
import { TokenVerifier } from '../TokenVerifier';
import DatabaseLeaks from '../views/Dashboard/ThreatIntelSections/Components/credentialSections/DatabaseLeaks';
import ErrorPage from './ErrorPage';
import PlaceDetail from '../views/Dashboard/BrandIntelSections/PlaceDetail';
import SelectCompany from '../views/auth/SelectCompany';
const router = createBrowserRouter(
    [
        {
            path: "/setPassword",
            element: <FirstTimeSetPassword/>,
        },
        {
            path: "/add-entreprise",
            element: <SelectCompany/>,
        },
        // signin path
        {
            path: "/login",
            element: <FirstTimeLogin/>
        },
    
        // ForgotPassword path
        {
            path: "/forgotPassword",
            element: <ForgotPassword/>
        },
    
        // NewPassword path
        {
            path: "/newPassword",
            element: <NewPassword/>
        },
    
        // ResendEmail path
        {
            path: "/resendEmail",
            element: <ResendEmail/>
        },
    
        // otp verification
    
        {
            path: "/otp-verification",
            element: <OtpVerification/>
        },
        {
            path: "/userInvited",
            element: <InviteLink/>  
        },
        
        {
            path: "/login",
            element: <SignIn/>
        },
    
        // ForgotPassword path
        {
            path: "/forgotPassword",
            element: <ForgotPassword/>
        },
    
        // NewPassword path
        {
            path: "/newPassword",
            element: <NewPassword/>
        },
    
        // ResendEmail path
        {
            path: "/resendEmail",
            element: <ResendEmail/>
        },
    
        // otp verification
    
        {
            path: "/otp-verification",
            element: <OtpVerification/>
        },
        {
            path: "/userInvited",
            element: <InviteLink/>  
        },
        {
            path: "/admin/accounts",
            element: <TokenVerifier><Admin/></TokenVerifier>
        },
        {
            path: "/vulnerabilities",
            element: <TokenVerifier><VulnerabilitiesView/></TokenVerifier>
        },
        {
            path: "/vulnerabilities/vulnerabilityDetail",
            element: <TokenVerifier><VulnerabilityDetail/></TokenVerifier>
        },
        {
            path: "/brandIntel",
            element: <TokenVerifier><BrandIntelView/></TokenVerifier>
        },
        {
            path: "/brandIntel/placeDetail",
            element: <TokenVerifier><PlaceDetail/></TokenVerifier>
        },
        {
            path: "/brandIntel/domainDetail",
            element: <TokenVerifier><DomainDetail/></TokenVerifier>
        },
        {
            path: "/brandIntel/darkwebDetail",
            element: <TokenVerifier><DarkwebDetail/></TokenVerifier>
        },
        {
            path: "/brandIntel/socialProfile",
            element: <TokenVerifier><SocialMediaProfile/></TokenVerifier>
        },
        {
            path: "/brandIntel/requestTakeDown",
            element: <TokenVerifier><DomainTakeDownRequest/></TokenVerifier>
        },
        {
            path: '/ransomwareIntel',
            element: <TokenVerifier><RansomeView/></TokenVerifier>,
            errorElement: <ErrorPage/>
            
        },
        {
            path: '/ransomwareIntel/countryDetail',
            element: <TokenVerifier><CountryDetail/></TokenVerifier>,
            errorElement: <ErrorPage/>
        },
        {
            path: '/ransomwareIntel/groupDetail',
            element: <TokenVerifier><GroupDetail/></TokenVerifier>,
            errorElement: <ErrorPage/>
        }
        ,
        {
            path: '/ransomwareIntel/conversations',
            element: <TokenVerifier><Conversations/></TokenVerifier>,
            errorElement: <ErrorPage/>
        },
        {
            path: '/threatIntel',
            element: <TokenVerifier><ThreatIntelView/></TokenVerifier>
        },
        {
            path: '/threatIntel/credentialLeaksDetail',
            element: <TokenVerifier><CredentialLeakDetail/></TokenVerifier>
        },
        {
            path: '/threatIntel/userProfile',
            
            element: <TokenVerifier><UserProfileCredential/></TokenVerifier>
        },
        {
            path: '/threatIntel/creditCardDetail',
            element: <TokenVerifier><CreditCardDetail/></TokenVerifier>
        },
        {
            path: "/threatManagement",
            element: <TokenVerifier><ThreatManagementView/></TokenVerifier>
        },
        {
            path: "/threatManagement/archiveDetail",
            element: <TokenVerifier><ArchiveDetail/></TokenVerifier>
        },
        {
            path: "/threatManagement/threatDetail",
            element: <TokenVerifier><CreditCardDetail/></TokenVerifier>
        },
        {
            path: "/threatManagement/threatDetail/userProfile",
            element: <TokenVerifier><UserProfile/></TokenVerifier>
        },
        {
            path: "/compilance",
            element: <TokenVerifier><CompilnaceView/></TokenVerifier>
        },
        {
            path: '/reports',
            element: <TokenVerifier><ReportsView/></TokenVerifier>
        },
        {
            path: '/reports/reportDetail',
            element: <TokenVerifier><ReportDetail/></TokenVerifier>
        }
        ,
        {
            path: '/notifications',
            element: <TokenVerifier><Notification/></TokenVerifier>
        },
        {
            path: '/settings',
            element: <TokenVerifier><PersonnalAccount/></TokenVerifier>
        },
        {
            path: '/security',
            element: <TokenVerifier><Security/></TokenVerifier>
        },
        {
            path: '/entreprise-setting',
            element: <TokenVerifier><EntrepriseSettings/></TokenVerifier>
        },
        {
            path: '/users',
            element: <TokenVerifier><Users/></TokenVerifier>
        }, 
        {
            path: '/watch-list',
            element: <TokenVerifier><WatchList/></TokenVerifier>
        },
        {
            path: '/notification-setting',
            element: <TokenVerifier><Nottifications/></TokenVerifier>
        },
        {
            path: "/support",
            element: <TokenVerifier><Support/></TokenVerifier>
        },
        {
            path: "/",
            element: <TokenVerifier><Home/></TokenVerifier>,
            errorElement: <TokenVerifier><Home/></TokenVerifier>
        },
       {
        path: "/setPassword",
        element: <FirstTimeSetPassword/>,
            
       },
       {
        path: "/error",
        element: <ErrorPage/>,
            
       },
       
    ],
);

export default router;
