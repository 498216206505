import { faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
import { Avatar, CircularProgress } from '@mui/material'
import moment from 'moment'
import { CircleFlag } from 'react-circle-flags'
import UnassignedLogo from '../../../../icons/Unassigned.svg'
function Victims() {
  const { countryDetail } = useSelector(ransomeSelector)
    return (
                       <>
                        {
                     countryDetail && countryDetail?.victims?.length !== 0 ?

                      <table className={styles.table} >
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}>
                            Country {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={11} height={12}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Victims {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={11} height={12}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Groups {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={11} height={12}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Discovery date {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={11} height={12}/>
                        </th>
                        <th style={{paddingLeft: 8, display:"flex", justifyContent:"start", paddingRight:13}}>
                                    <p style={{display:"flex" , alignItems:'center', gap: 5, width:150}}>
                                    <span>Attack date</span>
                                    <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                                    </p>
                                    </th>
                        </tr>
                      </thead>
                      <tbody>
                          {
                            countryDetail?.victims?.map(item =>
                              <tr className={styles.tableActivityRowWatchListAssets}>
                            <td style={{width: '104px', padding: '10px 16px'}} className={styles.tableCell}>
                            {
                              item?.country ? 
                              <CircleFlag countryCode={item?.country?.toLowerCase()} height="32" />
                              :
                              <Avatar
                            src={UnassignedLogo}
                            sx={{
                              width: 32,
                              height: 32,
                              borderRadius: '50%',
                              overflow: 'hidden',
                              img: {
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '50%', // Ensure the image itself is rounded
                              },
                            }}
                          />
                            }
                              
                                
                            </td>
                              <td style={{width: '434px', padding: '10px 16px'}} className={styles.tableCell}>
                                {item?.post_title}
                              </td>
                              <td style={{width: '200px', color: "#e9bd08"}} className={styles.tableCell}>
                              {item?.group_name}
                              </td>
                              <td style={{width: '190px', padding: '10px 16px'}} className={styles.tableCell} >
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "+moment(item?.discovered).format('MMMM DD, YYYY')}
                              </td>
                              <td style={{paddingLeft: 8, width:150}} className={styles.tableCell} >
                                <div>
                                <div style={{  display: 'flex', alignItems: "center", gap: 10, justifyContent: "start"}}>
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "+moment(item?.published).format('MMMM DD, YYYY')}
                                </div>
                                </div>
                              </td>
                            </tr>
                            )
                          }
                          
                        
                        </tbody>
                    </table>:
                     <div className={styles.loaderVul}>
                     <CircularProgress />
                     </div>
                    }
                    </>
    )
}

export default Victims
