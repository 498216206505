import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import Content from './Content'
import styles from "../Account/account.module.scss"
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest } from '../../core/actions/userActions'
import { getInfosEntrepriseRequest, getUsersEntrepriseRequest } from '../../core/actions/entrepriseActions'
import { useSelector } from 'react-redux'
import { authSelector } from '../../core/reducers/authReducer'
import { useNavigate } from 'react-router-dom'
import { REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS } from '../../core/constants'
import ReactGA from 'react-ga4';
import { checkIfTokenExpiredApi } from '../../core/api/authApi'
import { useIntercom } from './IntercomSetup'
import { threatIntelSelector } from '../../core/selectors/threatIntelSelector'
import { entrepriseSelector } from '../../core/reducers/entrepriseReducer'
import { userSelector } from '../../core/reducers/userReducer'
import Loader from "../../icons/Loader.svg"

function Home() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { auth } = useSelector(authSelector)
    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = window.localStorage.getItem("fullName")


useIntercom('isows87y', {
app_id: 'isows87y',
api_base: 'https://api-iam.intercom.io',
email: emailStorageVa as string,
user_hash: hmacValue as string,
name: fullNamed
})
    
    const getAndVerifyToken = async () => {
        const token = window.localStorage.getItem('jwt');
        if(!token) {
         navigate('/login')
        }else {
          const response = await checkIfTokenExpiredApi(token)
          console.log(response)
          if(!response.authentificated)
            navigate("/login")
        
        }
      };
    
        useEffect(() => {
            document.title = "Home - Overview"
            ReactGA.send({ hitType: "Home - Overview", page: window.location.pathname });
           
            
              

        }, [])

        
    
        
    return (
        <div className={ styles.main }>
            <Menu/>
            <Header/>
            <Content/>
        </div>
    )
}

export default Home
