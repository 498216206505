import { faArrowLeft, faArrowUpRightFromSquare, faChevronDown, faComment, faHeart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, MenuItem, Rating, Select } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { TypesSocialMedia } from '../../../core/constants'
import moment from 'moment'
import { useIntercom } from '../IntercomSetup'


function SocialMediaProfile() {
    const navigate = useNavigate()
    const { socialMediaDetail } = useSelector(brandIntelSelector)
    const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })
    return (
        // <div className={styles.main}>
        //     <Header/>
        //     <Menu/>
        //     <div className={styles.content}>
        //     <div className={styles.personalInfoHeader}>
        //             <div >
        //                 <div onClick={()=>navigate('/brandIntel')}  style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
        //                 <FontAwesomeIcon icon={faArrowLeft}/>
        //                 <p>Go back / SOC-2024-0113</p>
        //                 </div>
        //             <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
        //                 <span>Negative Instagram post by {
        //                     socialMediaDetail?.platform === "instagram" ? socialMediaDetail.resultSocialMedia?.ownerUsername
        //                     : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.author
        //                     : socialMediaDetail?.platform === "youtube" ? socialMediaDetail?.resultSocialMedia?.channel_name
        //                     : socialMediaDetail?.resultSocialMedia?.author_name
        //                 }</span>
        //             </div>
        //         </div>
        //             <Button style={{width: '104px', height: '3.70vh', gap: 10, fontSize:14, fontWeight:400, backgroundColor: "#00b0ff",display: 'flex', alignItems: "center", color: 'white'}}>
        //                 Export <FontAwesomeIcon icon={faChevronDown} height={12} width={11}/>
        //             </Button>
        //         </div>
        //         <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14}}>
        //             <p style={{color: "#afafaf"}}>Last modified</p>
        //             <p style={{color: "white"}}>January 11, 2024</p>
        //             <p style={{color: "#afafaf"}}>Assigned to</p>
        //             <div className={styles.profileHeader}>
        //                 <img src="/logoProfile.png" className={styles.logoProfileHeader} />
        //                 <p style={{fontSize: 12}}>Kelly Kapoor</p>
        //                 <FontAwesomeIcon icon={faChevronDown} style={{height: 8}}/>
        //             </div>
        //         </div>
        //         <div style={{display: 'flex', gap: 20}}>
        //         <div className={styles.postSocialMedia} style={{width: '60% !important'}}> 
        //                 <div onClick={()=>navigate("/brandIntel/socialProfile")} className={styles.headerPostSocialMedia}>
        //                     <div style={{display: "flex", alignItems: "center", gap: 15}}>
        //                       <div className={styles.headerPostSocialMediaLogo}>
        //                         <img src="/instagram.png" style={{transform: "translate(55%,35%)"}}/>
        //                       </div>  
        //                       <div>
        //                           <span style={{color: "white", fontWeight: "500", fontSize: 14}}>{
        //                     socialMediaDetail?.platform === "instagram" ? socialMediaDetail.resultSocialMedia?.ownerUsername
        //                     : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.author
        //                     : socialMediaDetail?.platform === "youtube" ? socialMediaDetail?.resultSocialMedia?.channel_name
        //                     : socialMediaDetail?.resultSocialMedia?.author_name
        //                 }</span>
        //                           <br/>
        //                           <span style={{color: "white",  fontSize: 12}}>April 22, 2024</span>
        //                       </div>
        //                     </div>
        //                     <div style={{display: "flex", alignItems: "center", gap: 10 }}>
        //                         <FontAwesomeIcon icon={faArrowUpRightFromSquare} color="#afafaf" height={16} width={20}/>
        //                     </div>
        //                 </div>
        //                 <img src={
        //                     socialMediaDetail?.platform === "youtube" ? socialMediaDetail?.resultSocialMedia.thumbnail_url
        //                     : socialMediaDetail?.platform ==="twitter" || socialMediaDetail?.platform ==="reddit" ? socialMediaDetail?.resultSocialMedia.image_url
        //                     : socialMediaDetail?.resultSocialMedia?.displayUrl
        //                 }  className={styles.postSocialMediaImg}/>
        //                 <p style={{fontSize: 14, color: "#cbcbcb"}}>
        //                 {
        //                     socialMediaDetail?.platform === 'instagram'? socialMediaDetail?.resultSocialMedia?.caption
        //                     : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.body
        //                     : socialMediaDetail?.platform === 'twitter' ? socialMediaDetail?.resultSocialMedia?.tweet
        //                     : socialMediaDetail?.resultSocialMedia?.description
        //                 }
        //                 </p>
        //                 <p style={{fontSize: 11, color: "#cbcbcb", marginTop: 20}}>#customersupport</p>
        //                 <div style={{display: "flex", alignItems: "center", gap: 15}}>
        //                     <div style={{display: "flex", alignItems: "center", gap: 5}}>
        //                         <FontAwesomeIcon icon={faHeart} height={16} width={16} color="#afafaf" />
        //                         <p style={{color: "#afafaf", fontSize: 12}}>
        //                         {
        //                                 socialMediaDetail?.platform === "instagram" ? socialMediaDetail?.resultSocialMedia?.likesCount
        //                                 : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.upvotes_count
        //                                 : socialMediaDetail?.platform === "twitter" ? socialMediaDetail?.resultSocialMedia?.likes
        //                                 : socialMediaDetail?.resultSocialMedia?.like_count
        //                             }
        //                         </p>
        //                     </div>
        //                     <div style={{display: "flex", alignItems: "center", gap: 5}}>
        //                         <FontAwesomeIcon icon={faComment} height={16} width={16} color="#afafaf" />
        //                         <p style={{color: "#afafaf", fontSize: 12}}> 
        //                         {
        //                                 socialMediaDetail?.platform === "instagram" ? socialMediaDetail?.resultSocialMedia?.commentsCount
        //                                 : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.comments_count
        //                                 : socialMediaDetail?.platform === "twitter" ? socialMediaDetail?.resultSocialMedia?.replies?.length
        //                                 : socialMediaDetail?.resultSocialMedia?.comments_count
        //                             }</p>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className={styles.postSocialMedia} style={{width: "500px !important"}}>
        //                 <div className={styles.headerPostSocialMedia}>
        //                      <p style={{color: "white", fontWeight: "500", fontSize: 14}}>Comments (
        //                         {
        //                                 socialMediaDetail?.platform === "instagram" ? socialMediaDetail?.resultSocialMedia?.commentsCount
        //                                 : socialMediaDetail?.platform === "reddit" ? socialMediaDetail?.resultSocialMedia?.comments_count.split(" ")[0]
        //                                 : socialMediaDetail?.platform === "twitter" ? socialMediaDetail?.resultSocialMedia?.replies?.length
        //                                 : socialMediaDetail?.resultSocialMedia?.comments_count?.split(" ")[0]
        //                             }
        //                      )</p>
        //                      <Select
        //                                     name='phoneCode'
        //                                     label='Targeted domain'
        //                                     labelId="demo-simple-select-label"
        //                                     id="demo-simple-select"
        //                                     defaultValue={"10"}
        //                                     style={{fontSize: 14}}
        //                                 >
        //                                     <MenuItem value={"10"} >Negative Comments only</MenuItem>
        //                      </Select> 
        //                 </div>
        //                 {
        //                     socialMediaDetail?.platform === "instagram" ?
        //                     socialMediaDetail?.resultSocialMedia?.latestComments?.map((item)=>
        //                         <div className={styles.reviewsPlaceDetails}>
        //                     <div className={styles.reviewsPlaceDetailsHeader}>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo}>
        //                         <p style={{color: 'white', fontSize: 14}}>{
        //                             item?.ownerUsername
        //                 }</p>
        //                     </div>
        //                     <p style={{color: '#afafaf', fontSize: 12}}>{moment(item?.timestamp).format('MMMM DD, YYYY')}</p>
        //                     </div>
        //                     <p style={{fontSize: 14, color: "#afafaf"}}>{
        //                         item?.text
        //                     }</p>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo} style={{marginTop: -15}}>
        //                     <FontAwesomeIcon icon={faHeart} height={16} width={16} color='#afafaf'/>
        //                     <p style={{fontSize: 12, color: "#afafaf"}}>{item?.likesCount}</p>
        //                     </div>
        //                 </div>
        //                     )
        //                     : socialMediaDetail?.platform === 'youtube' ?

        //                     socialMediaDetail?.resultSocialMedia?.comments?.map((item)=>
        //                         <div className={styles.reviewsPlaceDetails}>
        //                     <div className={styles.reviewsPlaceDetailsHeader}>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo}>
        //                         <p style={{color: 'white', fontSize: 14}}>{
        //                             item?.author
        //                 }</p>
        //                     </div>
                            
        //                     </div>
        //                     <p style={{fontSize: 14, color: "#afafaf"}}>{
        //                         item?.comment_text
        //                     }</p>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo} style={{marginTop: -15}}>
        //                     <FontAwesomeIcon icon={faHeart} height={16} width={16} color='#afafaf'/>
        //                     <p style={{fontSize: 12, color: "#afafaf"}}>{item?.like_count}</p>
        //                     </div>
        //                 </div>
        //                     )
        //                     : socialMediaDetail?.platform === "reddit" ? 
        //                     socialMediaDetail?.resultSocialMedia?.comments?.map((item)=>
        //                         <div className={styles.reviewsPlaceDetails}>
        //                     <div className={styles.reviewsPlaceDetailsHeader}>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo}>
        //                         <p style={{color: 'white', fontSize: 14}}>{
        //                             item?.author
        //                 }</p>
        //                     </div>
        //                     <p style={{color: '#afafaf', fontSize: 12}}>{moment(item?.comment_date).format('MMMM DD, YYYY')}</p>
        //                     </div>
        //                     <p style={{fontSize: 14, color: "#afafaf"}}>{
        //                         item?.comment_text
        //                     }</p>
        //                 </div>
        //                     )
        //                     : socialMediaDetail?.resultSocialMedia?.replies?.map((item)=>
        //                         <div className={styles.reviewsPlaceDetails}>
        //                     <div className={styles.reviewsPlaceDetailsHeader}>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo}>
        //                         <p style={{color: 'white', fontSize: 14}}>{
        //                             item?.author_handle
        //                 }</p>
        //                     </div>
        //                     </div>
        //                     <p style={{fontSize: 14, color: "#afafaf"}}>{
        //                         item?.reply_text
        //                     }</p>
        //                     <div className={styles.reviewsPlaceDetailsHeaderLogo} style={{marginTop: -15}}>
        //                     <FontAwesomeIcon icon={faHeart} height={16} width={16} color='#afafaf'/>
        //                     <p style={{fontSize: 12, color: "#afafaf"}}>{item?.likes}</p>
        //                     </div>
        //                 </div>
        //                     )
        //                 }
        //             </div>    
        //         </div>
        //     </div>
        // </div>
        <></>
    )
}

export default SocialMediaProfile
