import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, easing } from '@mui/material';
import { LoginRequestAction } from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import styles from "./auth.module.scss";
import ErrorNotification from '../Account/common/ErrorNotification';
import { AddUserInviteLinkReq, Roles, UserEntrepriseReq } from '../../core/constants';
import { typesHandleChange } from '../Account/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@emotion/react'
import { createStyles, makeStyles } from '@mui/styles';
import { saveUserEntrepriseRequest } from '../../core/actions/entrepriseActions';
import ReactGA from 'react-ga4';
import logo from "../../icons/Defendis_Logo_White.svg"
import logoSignSVG from '../../icons/Login-Illustration.svg'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unvalidEmail: {
      borderBottom: '1px solid red !important',
      color: 'white !important'
    },
    validEmail: { 
        borderBottom: '1px solid green !important',
        color: 'white !important'
    }
  }),
);

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  

function InviteLink() {
    const classes = useStyles();
    const query = useQuery();
    const fullName = query.get('fullName');
   
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(authSelector);
    const [formState, setformState] = useState<UserEntrepriseReq>({
        firstname: "",
        lastname: '',
        username: '',
        jwt: localStorage.getItem('jwt') || ""
        
    })
    const [openNotification, setOpenNotification] = useState(false);
    const [error, seterror] = useState(false)

    // React to auth state changes


    const login = () => {
        dispatch(saveUserEntrepriseRequest(formState))
       
    };

    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    const handleChange = (event: typesHandleChange) => {
        const { name, value} = event.target;
        
          setformState(prevState => ({
            ...prevState,
            [name]: value,
          })); 
    }

    useEffect(() => {
      document.title = "User Invite"
      ReactGA.send({ hitType: "Login - User Invite", page: window.location.pathname });
      if(auth.loginEntreprise) {
        navigate("/")
      } else if(auth.authenticated) {
        navigate("/login")
      }
    }, [auth])
    
    
    return (
        <div className={styles.main}>
           <form onSubmit={e=>e.preventDefault()}>
                <img src={logo} alt="Logo" className={styles.logo} />
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0}}>
                    <div className={styles.login}>
                        <div style={{width:'70%'}}>
                        <div className={styles.loginInputs}>
                            <p className={styles.titleLogin}>You are invited</p>
                            <p style={{fontSize: 12}} className={styles.lightText}>{fullName} invited you to join your team in Defendis and collaborate on neutralising threats:</p>
                            <TextField onChange={handleChange} name='firstname' value={formState.firstname} id="standard-basic" label="First Name" variant="standard" fullWidth margin="normal"/>
                            <TextField  onChange={handleChange} name='lastname' value={formState.lastname} id="standard-basic" label="Last Name" variant="standard"  fullWidth margin="normal"/>
                
                <TextField  onChange={handleChange} name='username' value={formState.username} id="standard-basic" label="Username" variant="standard"  fullWidth margin="normal"/>
                
                
                <Button onClick={login} type='submit' className={styles.btnLogin}>Continue</Button>
            
            </div>
                        </div>
                        <img src={logoSignSVG} className={styles.illus} style={{marginTop: '20%'}}/>
                    </div>
                    </div>
                    </form>
                    <ErrorNotification
                severity={'error'}
                open={openNotification}
                message={auth.notification}
                handleClose={handleCloseNotification}
            />
        </div>
    );
}

export default InviteLink;
