import React from 'react'
import styles from '../../dashboard.module.scss'
export default function MalwarePath() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            <tr  className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '805px', padding: '0px 16px'}} className={styles.tableCell}>
              C:\Windows\Microsoft.NET\Framework\v4.0.30319\vbc.exe
              </td>
                  <td style={{width: '276px',padding: '13px 16px', color: '#afafaf'}} className={styles.tableCell}>
                  A3251F32C4B8CB4QPFB5682A301992E3  
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '805px', padding: '0px 16px'}} className={styles.tableCell}>
            C:\Windows\Microsoft.NET\Framework\v3.0.50319\tda.exe
              </td>
              <td style={{width: '276px',padding: '13px 16px', color: '#afafaf'}} className={styles.tableCell}>
              A21HD83C4B8CB4QPFB5682A301992E3  
                  </td>
            </tr>
            
            
          </tbody>
      </table>
    )
}
