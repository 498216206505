import { useEffect, useState } from "react";
import { checkIfTokenExpiredApi } from "./core/api/authApi";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authSelector } from "./core/reducers/authReducer";
import { getInfoSecurityPageRequest, getUserInfoRequest } from "./core/actions/userActions";
import { getInfosEntrepriseRequest, getUsersEntrepriseRequest } from "./core/actions/entrepriseActions";
import { GET_ALL_VULNERABILITIES, GET_ROLE_USER, Page, REQUEST_CREADENTIALS_LEAK, REQUEST_CREDITS_CARD, REQUEST_DATABASELEAK, REQUEST_DOCUMENTS_LEAK, REQUEST_HOME_STATS, REQUEST_NEGOCIATIONS, REQUEST_RANSOME, REQUEST_RANSOME_COUNTRIES, REQUEST_RANSOME_OVERVIEW, REQUEST_ROLES, REQUEST_TOTAL_RECORDS_CREDENTIALS, REQUEST_VICTIMS, REQUEST_VULNERABILITIES_OVERVIEW, SortVul } from "./core/constants";
import { threatIntelSelector } from "./core/selectors/threatIntelSelector";
import { entrepriseSelector } from "./core/reducers/entrepriseReducer";
import { userSelector } from "./core/reducers/userReducer";
import { statsSelector } from "./core/selectors/statsSelector";
import { vulnerabilitiesSelector } from "./core/reducers/vulnerabilitiesReducer";
import { CircularProgress, Box } from "@mui/material";
import styles from "./views/Account/account.module.scss";
import FirstTimeLogin from "./views/auth/FirstTimeLogin";
import { ransomeSelector } from "./core/selectors/ransomeSelector";
import { PageSort } from "./core/api/threatIntelApi";
import { setNotificationAction } from "./core/actions/authActions";

interface Props {
    children: React.ReactNode;
}

export function TokenVerifier({ children }: Props) {
    const [isTokenExpired, setIsTokenExpired] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);  // New state for initial loading
    const { auth } = useSelector(authSelector);
   
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        isLoadingCredentials, isLoadingDatabaseLeak,
        isLoadingDocumentsLeak, isLoadingCreditCard
    } = useSelector(threatIntelSelector);
    const { entreprise, users } = useSelector(entrepriseSelector);
    const { security } = useSelector(userSelector);
    const { isLoadingVul } = useSelector(vulnerabilitiesSelector);
    const { isLoadingHome } = useSelector(statsSelector);
    const { isLoadingOveriview } = useSelector(ransomeSelector)
    const token = window.localStorage.getItem('jwt');

    const fetchData = async () => {
        const dataSortVul: SortVul = {
            sortByDate: 'all',
            sortBySeverity: 'all',
            sortById: 'all'
        }
        const dataCred: PageSort = {
            page:1,
            sortBy: null
        }
    //   const requests = [
    //    
    // ];
    // await Promise.all(requests);
    dispatch({type: GET_ROLE_USER})
    dispatch({type: REQUEST_ROLES})
    dispatch(getInfoSecurityPageRequest()),
        dispatch(getUsersEntrepriseRequest()),
        dispatch(getUserInfoRequest()),
        dispatch(getInfosEntrepriseRequest()),
         dispatch({type: REQUEST_RANSOME_OVERVIEW}),
        
        dispatch({
            type: REQUEST_HOME_STATS,
            payload: 'all'
        }),
        dispatch({ type: REQUEST_DATABASELEAK, payload: "" }),
        dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred}),
        dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred }),
        dispatch({ type: REQUEST_CREDITS_CARD, payload: dataCred }),
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSortVul
           }),
           dispatch({   
            type: REQUEST_VULNERABILITIES_OVERVIEW,
            payload: 'all'
           }),
           dispatch({
            type: REQUEST_RANSOME,
            payload: 'all'
          }),
          dispatch({
            type: REQUEST_VICTIMS,
            payload: 'all'
          }),
          dispatch({
            type: REQUEST_NEGOCIATIONS
          }),
          dispatch({
            type: REQUEST_RANSOME_OVERVIEW,
            payload: 'all'
          }),
          dispatch({
            type: REQUEST_RANSOME_COUNTRIES,
            payload: 'all'
          })
    setInitialLoading(false);
    
    };
    const getAndVerifyToken = async () => {
        if (!token) {
            navigate("/login");
        } else if(auth.authenticated && !auth.loginEntreprise) {
             navigate("/login")
        } 
        else {
            try {
                const response = await checkIfTokenExpiredApi(token);
            if (response.authentificated) {
                fetchData();
                setInitialLoading(false); 
                navigate("/"); // Data is being fetched, allow rendering
            } else{
                navigate("/login");
            }
            } catch (error) {
                navigate("/error")
            }
        }
    };

    useEffect(() => {
        getAndVerifyToken();
       
    }, [auth]);

    if (!token) {
        return <FirstTimeLogin />;
    }
//|| isLoadingDatabaseLeak
    if (initialLoading  ) {
        return (
            <div className={styles.main}>
                <div className={styles.loader}>
                    <CircularProgress />
                </div>
                <p className={styles.loaderTitle}>Almost there, we are loading your data...</p>
            </div>
        );
    }

    return <>{children}</>; // Always render children if not loading
}

export default TokenVerifier;
