import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Header from './common/Header'
import Menu from './common/Menu'
import styles from './dashboard.module.scss'
import CountriesSection from './RansomeSections/CountriesSection'
import GroupsSection from './RansomeSections/GroupsSection'
import NegotiationsSection from './RansomeSections/NegotiationsSection'
import OverViewSection from './RansomeSections/OverViewSection'
import TelegramSection from './RansomeSections/TelegramSection'
import VictimsSection from './RansomeSections/VictimsSection'
import { useIntercom } from './IntercomSetup'
import { setNamePageLocalStorage } from '../../core/actions/stepsNavPageActions'
import { REQUEST_RANSOME, REQUEST_RANSOME_COUNTRIES, REQUEST_RANSOME_OVERVIEW, REQUEST_VICTIMS } from '../../core/constants'
import { useDispatch } from 'react-redux'

const nameSteps = [
    "overview",
    "countries",
    "groups",
    "negotiations",
    "telegram",
    "victims"
]

function RansomeView() {
    const dispatch = useDispatch()
    const [recent, setRecent] = useState('all')
    const storedStepName = localStorage.getItem('step') || 'overview';
    const [activeCompo, setactiveCompo] = useState(storedStepName)
    const handleChange = (event: SelectChangeEvent) => {
        if(storedStepName === "victims") {
            dispatch({
                type: REQUEST_VICTIMS,
                payload: event.target.value as string
              })
        } else if(storedStepName === "countries" ) {
            
            dispatch({
                type: REQUEST_RANSOME_COUNTRIES,
                payload: event.target.value as string
              })
        }
        else if(storedStepName === "groups" ) {
            
            dispatch({
                type: REQUEST_RANSOME,
                payload: event.target.value as string
              })
        }
        else if(storedStepName === "overview" ) {
            
            dispatch({
                type: REQUEST_RANSOME_OVERVIEW,
                payload: event.target.value as string
              })
        }
        setRecent(event.target.value as string);
      }
      const emailStorageVa = window.localStorage.getItem('email')
                const hmacValue = window.localStorage.getItem('hmac')
                const fullNamed = window.localStorage.getItem("fullName")
    useIntercom('isows87y', {
        app_id: 'isows87y',
        api_base: 'https://api-iam.intercom.io',
        email: emailStorageVa as string,
        user_hash: hmacValue as string,
        name: fullNamed
    })

    const handleChangeStep = (newStepName: string) => {
        
        setNamePageLocalStorage(newStepName)
        setactiveCompo(newStepName);
      };
      
      useEffect(() => {
        if(nameSteps.includes(storedStepName))
            setactiveCompo(storedStepName);
          else
            setactiveCompo("overview")

            setRecent("all")
       
      }, [storedStepName]);
    
    const renderSection = () => {
        switch (activeCompo) {
            case 'overview':
                return <OverViewSection setStepSetAll={handleChangeStep}/>
            case 'countries':
                return <CountriesSection/>
            case 'groups':
                return <GroupsSection/>
            case 'negotiations':
                return <NegotiationsSection/>
            case 'telegram':
                return <TelegramSection/>
            case 'victims':
                return <VictimsSection/>
            default:
                return;
        }
       
    }
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
                <div className={styles.menuContent}>
                    <div className={styles.firstSectionMenu}>
                    <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</p>
                    <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('victims')} className={activeCompo === 'victims' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Victims</p>
                    <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('countries')} className={activeCompo === 'countries' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Countries</p>
                    <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('groups')} className={activeCompo === 'groups' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Groups</p>
                    <p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('negotiations')} className={activeCompo === 'negotiations' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Negotiations</p>
                    {
                    //<p style={{cursor: 'pointer'}} onClick={()=>handleChangeStep('telegram')} className={activeCompo === 'telegram' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Telegram</p>
                    }
                </div>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: "500"}}
            >
               <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonths'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select>
                </div>
                <hr className={styles.divider}/>
                 {
                     renderSection()
                 }
            </div>
        </div>
    )
}

export default RansomeView
