import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { LoginRequestAction, setNotificationAction } from '../../core/actions/authActions';
import { authSelector } from '../../core/reducers/authReducer';
import styles from './auth.module.scss';
import ErrorNotification from '../Account/common/ErrorNotification';
import logoSignSVG from '../../icons/Login-Illustration.svg';
import logo from '../../icons/Defendis_Logo_White.svg';

function FirstTimeLogin() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auth } = useSelector(authSelector);
    const { error, authenticated, loginEntreprise, errorCode, isLoadingLogin, jwt } = auth
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [openNotification, setOpenNotification] = useState(false);
    const [loading, setLoading] = useState(false)
    // Effect to update the document title and handle auth changes
    useEffect(() => {
        document.title = 'Login';
        if(authenticated && !loginEntreprise) {
            dispatch(setNotificationAction({
                error:'Waiting for Defendis approval',
                errorCode: 400,
                success: true
             }))
             setOpenNotification(true)
        } else if(loading && loginEntreprise){
            navigate("/")
            setLoading(false);
        }
    }, [authenticated, loginEntreprise, jwt, error]);

    // Handle login action
    const login = () => {
        dispatch(LoginRequestAction({ email, password })); 
        setLoading(true)
        setTimeout(() => {
        dispatch(setNotificationAction({
            success: true,
            error: '',
            errorCode: null
          }))
          setLoading(false)
          setOpenNotification(false)
        }, 3500);
    };

    // Close notification handler
    const handleCloseNotification = () => {
        setOpenNotification(false);
    };

    return (
        <div className={styles.main}>
            <form onSubmit={e => e.preventDefault()}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', margin: 0 }}>
                    <div className={styles.login}>
                        <div style={{ width: '50%' }}>
                            <div className={styles.loginInputs}>
                                <p className={styles.titleLogin}>
                                    Welcome back <span><img src="/hi_icon.png" alt="" height={20} width={20} /></span>
                                </p>
                                <p className={styles.lightText} style={{ fontSize: 12 }}>Please enter your email and password to log in:</p>
                                <TextField 
                                    onChange={(e) => setEmail(e.target.value)} 
                                    id="email" 
                                    label="Email" 
                                    variant="standard" 
                                    fullWidth 
                                    margin="normal" 
                                />
                                <TextField 
                                    onChange={(e) => setPassword(e.target.value)} 
                                    id="password" 
                                    label="Password" 
                                    variant="standard" 
                                    type="password" 
                                    fullWidth 
                                    margin="normal" 
                                />
                                <NavLink 
                                    to="/forgotPassword" 
                                    style={{ fontSize: 12, textDecoration: 'underline', marginTop: 10, float: 'right' }} 
                                    className={styles.lightText}
                                >
                                    Forgot Password
                                </NavLink>
                                <Button 
                                    onClick={login} 
                                    type="submit" 
                                    disabled = {isLoadingLogin}
                                    className={styles.btnLogin} 
                                    style={{ fontSize: 14, fontWeight: 600, cursor: isLoadingLogin ? "progress":"pointer" }}
                                >
                                    Login
                                </Button>
                            </div>
                        </div>
                        <img src={logoSignSVG} className={styles.illus} style={{ marginTop: '20%' }} alt="Illustration" />
                    </div>
                </div>
            </form>
            {openNotification && auth.error !== "" && auth.errorCode && (
                <ErrorNotification
                    severity={errorCode !== 200 ? 'error' : 'success'}
                    open={openNotification}
                    message={error}
                    handleClose={handleCloseNotification}
                />
            )}
        </div>
    );
}

export default FirstTimeLogin;
