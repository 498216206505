import React from 'react'
import styles from '../../dashboard.module.scss'
export default function Software() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            <tr  className={styles.tableActivityRowRansom1Col}>
              <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
              Microsoft Edge
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            Surfer 2
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            TLauncher
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            WinRAR 6.01 (32-bit)
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            Java Auto Updated
              </td>
            </tr>
            <tr className={styles.tableActivityRowRansom1Col}>
            <td style={{width: '100%', padding: '13px 16px'}} className={styles.tableCell}>
            Microsoft Visual C++ 2005 Redistributable
              </td>
            </tr>

            
            
          </tbody>
      </table>
    )
}
