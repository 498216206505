import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import styles from "../../dashboard.module.scss"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getAllNotificationsApi } from '../../../../core/api/notificationApi'
import { maskString } from '../../ThreatIntelSections/CredentialsLeaks'
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
export default function AllNotification() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { credentialsLeak } = useSelector(threatIntelSelector)
    const [allNotifs, setallNotifs] = useState<NotificationBell[] | undefined>()
    const data = credentialsLeak?.data?.filter( item => {
        if(allNotifs && allNotifs?.filter(da => da.userid === item.userid).length>0){
            return item
        }
    })
    const getAllNotifs = async () => {
        const data = await getAllNotificationsApi()
        setallNotifs(data)
    }
    useEffect(() => {
        getAllNotifs()
    }, [])

    const setCredentialDetail = (item: any) => {
        item.password = maskString(item.password,2,0) ? maskString(item.password,2,0) as string : "********"
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(item))
        navigate("/threatIntel/credentialLeaksDetail")
      }
    
  return (
    <table className={styles.table} style={{ marginTop: 25 }}>
                
                <tbody>
                          {
                            data?.map(item => 
                              <tr className={styles.tableActivityRowWatchListAssets} onClick={()=>setCredentialDetail(item)}>
                            <td style={{width: '491px'}} className={styles.tableCell}>
                                {item?.user?.username}
                            </td>   
                              
                            <td style={{width: '160px'}} className={styles.tableCell} >
                              {maskString(item?.password, 2, 0)}
                            </td>
                            <td style={{width: "312px"}} className={styles.tableCell}>
                                {item?.url}
                            </td> 
                            <td className={styles.tableCell} style={{width: "120px"}}>
                            <span className={styles.scoreSeverity} style={{width: '22px'}}>{item?.riskscore}</span>
                            </td>
                            <td className={styles.tableCell} style={{width: "190px"}}>
                                <div  style={{ display: 'flex', alignItems: "center", gap: 10, width: "120px" }}>
                                    <FontAwesomeIcon icon={faCalendar} size='1x' />
                            <p style={{ fontSize: 14 }}>April 19, 2024</p>
                                </div>
                            
                        </td>
                        
                          </tr>
                            )
                          }
                        </tbody>
            </table>
  )
}
