import React, { useState, useEffect } from 'react'
import styles from '../dashboard.module.scss'
import { getWhoisDomaineApi } from '../../../core/api/brandIntelApi'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { CircularProgress } from '@mui/material'
import moment from 'moment'

// Define the type for the parsed WHOIS data
interface WhoisData {
    domainName?: string;
    domainStatus?: string;
    registrar?: string;
    registrarUrl?: string;
    abuseEmail?: string;
    abusePhone?: string;
    creationDate?: string;
    updatedDate?: string;
    expiryDate?: string;
}

function WhoisRecords() {
    const { domaineDetail } = useSelector(brandIntelSelector)
    const [response, setResponse] = useState<WhoisData | null>(null)

    useEffect(() => {
        const getWhoisRecord = async () => {
            if (domaineDetail) {
                const data = await getWhoisDomaineApi(domaineDetail.domain)
                setResponse(parseWhoisData(data.body))
            }
        }
        getWhoisRecord()
    }, [domaineDetail])

    const parseWhoisData = (data: string): WhoisData => {
        const parsedData: WhoisData = {}
        const lines = data.split('\r\n')
        lines.forEach(line => {
            if (line.startsWith('Domain Name:')) {
                parsedData.domainName = line.split(': ')[1]
            } else if (line.startsWith('Domain Status:')) {
                parsedData.domainStatus = line.split(': ')[1]
            } else if (line.startsWith('Registrar:')) {
                parsedData.registrar = line.split(': ')[1]
            } else if (line.startsWith('Registrar URL:')) {
                parsedData.registrarUrl = line.split(': ')[1]
            } else if (line.startsWith('Registrar Abuse Contact Email:')) {
                parsedData.abuseEmail = line.split(': ')[1]
            } else if (line.startsWith('Registrar Abuse Contact Phone:')) {
                parsedData.abusePhone = line.split(': ')[1]
            } else if (line.startsWith('Creation Date:')) {
                parsedData.creationDate = line.split(': ')[1]
            } else if (line.startsWith('Updated Date:')) {
                parsedData.updatedDate = line.split(': ')[1]
            } else if (line.startsWith('Registry Expiry Date:')) {
                parsedData.expiryDate = line.split(': ')[1]
            }
        })
        return parsedData
    }

    if (!response) {
        return <div className={styles.loaderVul}>
        <CircularProgress />
        </div>
    }

    return (
        <table className={styles.table} style={{ marginTop: 25 }}>
            <tbody>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Domain Name</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.domainName}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Domain Status</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.domainStatus}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.registrar}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar URL</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.registrarUrl}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar Abuse Email</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.abuseEmail}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registrar Abuse Phone</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.abusePhone}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Creation Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{moment(response.creationDate).format("MMMM DD, YYYY")}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Updated Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{moment(response.updatedDate).format("MMMM DD, YYYY")}</p>
                    </td>
                </tr>
                <tr className={styles.tableActivityRowRansomWatchList}>
                    <td style={{ width: '200px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>Registry Expiry Date</p>
                    </td>
                    <td style={{ width: '918px', padding: '0px 16px' }} className={styles.tableCell}>
                        <p style={{ fontSize: 14, color: 'white' }}>{response.expiryDate}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default WhoisRecords
