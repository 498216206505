import React from 'react'
import Header from '../Dashboard/common/Header'
import Menu from './Menu/Menu'
import styles from "../Dashboard/dashboard.module.scss"

function Admin() {
  return (
    <div className={styles.main}>
      <Header/>
      <Menu/>
      <div className={styles.content}>

      </div>
    </div>
  )
}

export default Admin