import { faCalendar, faChevronDown, faChevronUp, faCircleCheck, faDownload, faSearch, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../core/selectors/ransomeSelector'
import { useDispatch } from 'react-redux'
import { GroupsRansome, REQUEST_RANSOME, SET_GROUP_DETAIL } from '../../../core/constants'
import ReactGA from 'react-ga4';
import Loader from "../../../icons/Loader.svg"
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import EmptyStateGroups from '../common/EmptyStateGroups'
import { exportToExcel } from '../components/ExportToExcel'
import { notificationSelector } from '../../../core/selectors/notificationBellSelector'
type SortOrder = 'asc' | 'desc'
function GroupsSection() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { groups, isLoadingGroups } = useSelector(ransomeSelector)
    const [search, setsearch] = useState("")
    const [isDonwloaded, setisDonwloaded] = useState(false)
    const [sortBy, setSortBy] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const { notifs } = useSelector(notificationSelector)
    const resultsSearch = search !== "" ? groups?.filter(item => item.name.toLowerCase().includes(search.toLowerCase())): groups
    useEffect(() => {
      
      document.title = "Groups"
      ReactGA.send({ hitType: "Ransomware Intel - Groups", page: window.location.pathname });

    }, [])
    const dateFormats = [
      'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
      'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
      'DD.MM.YYYY HH:mm:ss',
      'DD.MM.YYYY h:mm:ss',
      'YYYY-MM-DD'
    ];
    const renderDate = (last_seen: string) => {
      try {
        const parsedDate = moment(last_seen, dateFormats, true); // 'true' for strict parsing
        if (parsedDate.isValid()) {
          return parsedDate.format("MMMM DD, YYYY");
        } else {
          return "Invalid date";
        }
      } catch (error) {
        console.error("Error parsing date:", error);
        return "Invalid date";
      }
    };

    const handleSort = (column: string) => {
      if (sortBy === column) {
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
      } else {
        setSortBy(column)
        setSortOrder('asc')
      }
    }
    const grpsNotif = notifs.filter(item => item.isDarkWeb === true && item.read === false)
       const sortedGroups = resultsSearch?.sort((a, b) => {
        const aInGrpNotif = grpsNotif.some((notif) => notif?.data?.id === a.id);
            const bInGrpNotif = grpsNotif.some((notif) => notif?.data?.id === b.id);
    
            // Sort elements in vulNotif to the top
            if (aInGrpNotif && !bInGrpNotif) return -1;
            if (!aInGrpNotif && bInGrpNotif) return 1;
    
        let comparison = 0
        if (sortBy === 'name') {
          comparison = a.name.localeCompare(b.name)
        } else if (sortBy === 'lastscrape') {
          comparison = a.lastscrape.localeCompare(b.lastscrape)
        } else if (sortBy === 'enabled') {
          comparison =(a.enabled === b.enabled) ? 0 : a.enabled ? 1 : -1;
        } 
        
    
        return sortOrder === 'asc' ? comparison : -comparison
      })

    const setGroupDetail = (item: GroupsRansome) => {
      dispatch({
        type: SET_GROUP_DETAIL,
        payload: item
      });

      navigate("/ransomwareIntel/groupDetail")
    }
    const dataXlsx = groups?.map(item => ({
      'Group': item?.name,
      'Last seen': moment(item?.lastscrape).format("MMMM DD, YYYY"),
      'Status': item?.enabled ? "Yes": "No",
      
  }))
    const handleExportList = () => {
      setisDonwloaded(true)
      exportToExcel(dataXlsx || [], `Ransomeware-groups-${moment(new Date()).format("MMMM DD, YYYY-HH:mm")}`);
  };
    return (
        <div className={styles.awaitingApproval} style={{marginTop:20}}>
                        
                        <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}} >
                            <div className={styles.searchAndIcon}>
                                <input className={styles.search} name='search' placeholder='Search' onChange={(e)=>setsearch(e.target.value)}/>
                                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                            </div>
                            <div className={styles.removeSelected} onClick={handleExportList}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 12}} />
                            </div>
                        </div>
                    {
                      !isLoadingGroups?
                     
                      sortedGroups && sortedGroups.length !==0 ?
                      <table className={styles.table}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th></th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}}onClick={() => handleSort('name')}>
                            Group {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'name' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}}onClick={() => handleSort('lastscrape')}>
                            Last Seen {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'lastscrape' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        <th style={{paddingLeft: 8, cursor:'pointer'}}onClick={() => handleSort('enabled')}>
                            Status {" "}
                            <FontAwesomeIcon 
                              icon={sortBy === 'enabled' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                              size="1x" 
                              height={12} 
                              width={11} 
                            />
                        </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                          {
                            sortedGroups?.map(item =>
                              <tr className={styles.tableActivityRowUsers} onClick={()=>setGroupDetail(item)}>
                            <td className={styles.tableCell} style={{width:30}}>
                            {
                                        grpsNotif.some((respItem: any) => {
                                            
                                            return respItem?.data?.id === item.id;
                                        }) ? (
                                            <div className={styles.redPoint} />
                                        ) : (
                                         null // Nothing is displayed if the condition is not met
                                        )
                                    }
                              </td>
                            <td style={{width: '848px', padding: '0px 16px'}} className={styles.tableCell}>
                                {item?.name}
                            </td>
                              
                              <td style={{width: '190px', padding: '0px 16px'}} className={styles.tableCell} >
                                {item?.lastscrape ? 
                                <div style={{display: 'flex', alignItems:"center", gap: 15}}>
                                  <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                  <p>{moment(item.lastscrape).format("MMMM DD, YYYY")}</p>
                                </div>  
                                : <div>
                                  <p>N/A</p>
                                </div>
                              }
                              </td>
                              <td style={{width: '80px', padding: '0px 16px'}} className={styles.tableCell} >
                                {
                                  item?.enabled ? 
                                  <FontAwesomeIcon icon={faCircleCheck} width={11} height={12} color="#afafaf"/>
                                  :
                                  <div style={{height: 8, width:7, borderRadius:"100%", border: "2px solid #afafaf", backgroundColor:"transparent"}}/>
                                }
                              </td>
                            </tr>
                            )
                          }
                          
                        
                        </tbody>
                    </table>
                    :
                    <div className={styles.loaderVul}>
                    <EmptyStateGroups/>
                    </div>
                    :
                    <div className={styles.loaderVul}>
                    <CircularProgress/>
                    </div>
                    }
                      </div>
    )
}

export default GroupsSection
