import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'

export default function VulInformation() {
    const { vulDetail } = useSelector(vulnerabilitiesSelector)
    const openlinkDetail = (link: string) => {
      window.open(link, '_blank');
  };
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
    <tbody>
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px', padding: '13px 16px'}} className={styles.tableCell}>
          NVD Link
          </td>
              <td style={{width: '918px',padding: '13px 16px', color: "#e9bd08"}} className={styles.tableCell}>
                <span onClick={()=>openlinkDetail(vulDetail?.link || "") } style={{cursor:"pointer"}}>{
                    vulDetail?.cve_id
                }</span>
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
            Publish Date
          </td>
          <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
            <p>
                <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> {moment(vulDetail?.published_date).format("MMMM D, YYYY")} </p>
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '0px 16px'}} className={styles.tableCell}>
          Last Modified
          </td>
          <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
            <p>
                <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> {moment(vulDetail?.last_modified_date).format("MMMM D, YYYY")} </p>
              </td>
            
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Source
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
               {
                vulDetail?.source?.split("@")[1]
               }
            </td>
        </tr>
        
        
      </tbody>
  </table>
        </div>
    )
}
