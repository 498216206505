import axios from "axios";
import { MessageResp, WatchList, WatchListAssets, WatchListAssetsReq, WatchListBin, WatchListBinReq, WatchListBrandKeyWord, WatchListBrandKeyWordReq, WatchListDomain, WatchListDomainReq, WatchListExecutive, WatchListExecutiveReq, WatchListGroup, WatchListGroupReq, WatchListIp, WatchListIpReq } from "../constants";
import { env } from "../../environement/env";

export const getWatchListApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchList
    } catch (error) {
        throw error;
    }
}

export const saveWatchListAssetsApi = async (dataSend: WatchListAssetsReq) => {
    try {
        const url = `${env.apiUrl}/watchList/assets/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListAssetsApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/assets/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListAssets[]
    } catch (error) {
        throw error;
    }
}

export const saveWatchListBrandKeywordApi = async (dataSend: WatchListBrandKeyWordReq) => {
    try {
        const url = `${env.apiUrl}/watchList/brandKeyword/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListBrandKeywordApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/brandKeyword/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListBrandKeyWord[]
    } catch (error) {
        throw error;
    }
}

export const saveWatchListDomainApi = async (dataSend: WatchListDomainReq) => {
    try {
        const url = `${env.apiUrl}/watchList/domain/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListDomainApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/domain/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListDomain[]
    } catch (error) {
        throw error;
    }
}


export const saveWatchListIpApi = async (dataSend: WatchListIpReq) => {
    try {
        const url = `${env.apiUrl}/watchList/ip/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListIpApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/ip/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListIp[]
    } catch (error) {
        throw error;
    }
}

export const saveWatchListBinApi = async (dataSend: WatchListBinReq) => {
    try {
        const url = `${env.apiUrl}/watchList/bin/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListBinApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/bin/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListBin[]
    } catch (error) {
        throw error;
    }
}

export const saveWatchListExecutiveApi = async (dataSend: WatchListExecutiveReq) => {
    try {
        const url = `${env.apiUrl}/watchList/executive/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListExecutiveApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/executive/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListExecutive[]
    } catch (error) {
        throw error;
    }
}

export const saveWatchListRansomeApi = async (dataSend: WatchListGroupReq) => {
    try {
        const url = `${env.apiUrl}/watchList/ransome/save`
        const response = await axios.post(url, dataSend, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as MessageResp
    } catch (error) {
        throw error;
    }
}

export const getWatchListRansomeApi = async () => {
    try {
        const url = `${env.apiUrl}/watchList/ransome/get`
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return response.data as WatchListGroup[]
    } catch (error) {
        throw error;
    }
}


export const SaveWatchListBulkImportApi = async (categorie: string, data: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/builkImport`
        const response = await axios.post(url,{
            categorie,
            data
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListAssetsApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/assets/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListDomainesKeywordApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/domain/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {    
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListBrandsKeywordApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/brandKeyword/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListIpApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/ip/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListBinApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/bin/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListRansomeApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/ransome/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}

export const DeleteWatchListExecutiveApi = async ( assetsIds: any[]) => {
    try {
        const url = `${env.apiUrl}/watchList/executive/delete`
        const { data } = await axios.post(url,{
            assetsIds
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data;
    } catch (error) {
        throw error;
    }
}