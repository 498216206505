import { Reducer } from "react";
import { CVEItem, FAILED_VULNERABILITIES, FAILED_VULNERABILITIES_OVERVIEW, GET_ALL_VULNERABILITIES, Page, REQUEST_VULNERABILITIES_OVERVIEW, SEARCH_QUERY_VUL, SET_PAGE_TABLE, SET_VUL_DETAIL, SUCCESS_VULNERABILITIES, SUCCESS_VULNERABILITIES_OVERVIEW, VulnerabilitiesAction, Vulnerability, VulnerabilityList } from "../constants";

interface VulnerabilitiesState {
    vulnerabilies: VulnerabilityList | null;
    resultsPerPage: number;
    startIndex: number;
    pubStartDate: string;
    pubEndDate: string;
    query: string;
    vulDetail: Vulnerability | null,
    isLoadingVul: boolean,
    overviewVul:  VulnerabilityList | null
    isLoadingVulOverview: boolean
}

const initialState: VulnerabilitiesState = {
    vulnerabilies: null,
    resultsPerPage: 3,
    pubEndDate: new Date().toISOString(),
    pubStartDate: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
    startIndex: 0,
    query: "",
    vulDetail: null,
    isLoadingVul: false,
    overviewVul: null,
    isLoadingVulOverview: false
}

export const vulnerabilitiesReducer: Reducer<VulnerabilitiesState, VulnerabilitiesAction> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case GET_ALL_VULNERABILITIES:
            return {
                ...state,
                isLoadingVul: true
            }
        case SUCCESS_VULNERABILITIES: 
            return {
                ... state, 
                isLoadingVul: false,
                vulnerabilies: actions.payload as VulnerabilityList
            }
        case FAILED_VULNERABILITIES:
            return {
                ... state, 
                isLoadingVul: false
            }
            case REQUEST_VULNERABILITIES_OVERVIEW:
                return {
                    ...state,
                    isLoadingVulOverview: true
                }
            case SUCCESS_VULNERABILITIES_OVERVIEW: 
                return {
                    ... state, 
                    isLoadingVulOverview: false,
                    overviewVul: actions.payload as VulnerabilityList
                }
            case FAILED_VULNERABILITIES_OVERVIEW:
                return {
                    ... state, 
                    isLoadingVulOverview: false
                }
        case SEARCH_QUERY_VUL:
            return {
                ...state,
                query: actions.payload as string
            }
        case SET_VUL_DETAIL: 
            console.log(actions.payload)
            return {
                ...state,
                vulDetail: actions.payload as Vulnerability
            }
        default:
            return state;
    }
}

export const vulnerabilitiesSelector = ((state: any) => state.vulnerabilities as VulnerabilitiesState)