import { faComment, faFolder, faHouse, faListCheck, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import iconMenu1 from "../../../icons/Main/Menu-1.svg"
import iconMenu2 from "../../../icons/Main/Menu-2.svg"
import iconMenu3 from "../../../icons/Main/Menu-3.svg"
import iconMenu4 from "../../../icons/Main/Menu-4.svg"
import iconMenu5 from "../../../icons/Main/Menu-5.svg"
import iconMenu6 from "../../../icons/Main/Menu-6.svg"
import iconMenu7 from "../../../icons/Main/Menu-7.svg"
import iconMenu8 from "../../../icons/Main/Menu-8.svg"
import iconAssets from '../../../icons/Settings/assets.svg'
export interface menuItem {
    id: number;
    title: string;
    icon: any;
    path: string;
}


export const menuItems: menuItem[] = [
    {
        id: 1,
        title: "Accounts",
        icon: <img src={iconMenu8} width={18}/>,
        path: '/admin/accounts'
    },
    {
        id: 2,
        title: "Assets",
        icon: <img src={iconAssets} width={18}/>,
        path: '/admin/assetsReview'
    },
        {
        id: 3,
        title: "Domain TakeDown",
        icon: <img src={iconMenu3} width={18}/>,
        path: '/admin/domainTakeDown'
    },
    
    
]