import React, { useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { GET_WATCH_LIST, GET_WATCH_LIST_RANSOME, WatchListGroup } from '../../../core/constants'
import EmptyStateWatchList from '../../Dashboard/common/EmptyStateWatchList'
import ErrorNotification from '../common/ErrorNotification'
import { DeleteWatchListBinApi, DeleteWatchListRansomeApi } from '../../../core/api/watchListApi'
import { CircularProgress } from '@mui/material'

interface props {
  open: boolean;
  setOpen: any;
}

function RansomGroupsWatchList({open, setOpen}: props) {
  const dispatch = useDispatch()
  const { watchListGroup, isLoadingGroup } = useSelector(watchListSelector)
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [notifMessage, setnotifMessage] = useState<string>("")
  const [openNotif, setOpenNotif] = useState(false)

    const handleCheckboxChange = (id: any) => {
        setSelectedAssets((prev: any) => {
            if (prev.includes(id)) {
                return prev.filter((item: any) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };


    const deleteRansome = async () => {
        const { message } = await DeleteWatchListRansomeApi(selectedAssets)
        setnotifMessage(message)
        setOpenNotif(true)
        setTimeout(() => {
          dispatch({type: GET_WATCH_LIST_RANSOME})
        }, 500);
    }
  useEffect(() => {
    document.title = "Ransom Group Watch list"  
    dispatch({type: GET_WATCH_LIST_RANSOME})
    dispatch({type: GET_WATCH_LIST})
  }, [])
  
  return (
    <>
      {
        !isLoadingGroup ?
        watchListGroup && watchListGroup.length > 0 ?
        <div className={styles.awaitingApproval}>
                        
                        <div className={styles.searchAndRemove}>
                        <div className={styles.searchAndIcon}>
                            <input className={styles.search} name='search' placeholder='Search'/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                        </div>
                        <div className={styles.removeSelected} onClick={deleteRansome}>
                            <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                            <FontAwesomeIcon icon={faTrash} color='white' style={{fontSize: 12}} />
                        </div>
                    </div>
                    <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}> </th>
                          <th style={{paddingLeft: 8}}>Group</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          watchListGroup?.map((data: WatchListGroup) => 
                          <tr className={styles.tableActivityRowRansomWatchList}> 
                            <td style={{width: '32px'}} className={styles.tableCell}>
                            <input
                                type="checkbox"
                                className={styles.customCheckbox}
                                value={data.id}
                                onChange={() => handleCheckboxChange(data.id)}
                                checked={selectedAssets.includes(data?.id)}
                            />
                            </td>
                              <td style={{width: '690px',padding:'13px 0px'}} className={styles.tableCell}>{data.group}</td>

                            </tr>
                          )
                        }
                        
                        </tbody>
                    </table>
                    <ErrorNotification 
                    severity="success"
                    open = {openNotif}
                    message={notifMessage}
                    handleClose={() => setOpenNotif(false)}
                    />
                      </div> 
                      :
                      <EmptyStateWatchList 
                        open={open} 
                        setOpen={setOpen} 
                        title='Ransome groups Watch list is empty'
                        addButtonText='Group'
                        text='Add a group to start monitoring for cybersecurity risks'
                        />
                        :
                        <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
      }
    </>
  )
}

export default RansomGroupsWatchList