import { faArrowLeft, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Comments from '../BrandIntelSections/Comments'
import Header from '../common/Header'
import Menu from '../common/Menu'
import styles from '../dashboard.module.scss'
import Credentials from '../ThreatIntelSections/Components/Credentials'
export default function ArchiveDetail() {
    const navigate = useNavigate()
    return (
        <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/threatManagement")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <p>Go back / DEF-2024-0056</p>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>Stormous - 12 Exposed credentials</span>
                        <span className={styles.scoreSeverity}>9.1</span>
                    </div>  
                </div>
                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <Button style={{width: '120px', height: '40px', gap: 10, fontSize:12, fontWeight:400, backgroundColor: "#00b0ff", display: 'flex', alignItems: "center", color: 'white'}}>
                        Resolving <FontAwesomeIcon icon={faChevronDown} height={19} width={20} />
                    </Button>
                        </div>
                </div>

                <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14}}>
                    <p style={{color: "#afafaf"}}>Last modified</p>
                    <p style={{color: "white"}}>January 11, 2024</p>
                    <p style={{color: "#afafaf"}}>Assigned to</p>
                    <div className={styles.profileHeader}>
                        <img src="/logoProfile.png" className={styles.logoProfileHeader} />
                        <p style={{fontSize: 12}}>Kelly Kapoor</p>
                        <FontAwesomeIcon icon={faChevronDown} style={{height: 8}}/>
                    </div>
                </div>

                <Credentials/>


                <Comments/>
    </div>
    </div>
    )
}
