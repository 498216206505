import { Reducer } from "react";
import { BranIntelAction, Dark, DnsResponse, Domaine, FAILED_DARK, FAILED_DOMAINE, FAILED_SOCIALMEDIA, Places, RedditDetail, REQUEST_DARK, REQUEST_DOMAINE, REQUEST_PLACES, REQUEST_SURFACEWEB, SET_DARK, SET_DNS, SET_DOMAINE, SET_PLACE_DETAIL, SET_SOCIALMEDIA_DETAIL, SET_STEP_PAGE, SocialMedia, SocialMediaDetail, SocialMediaDetailPlatform, SocialMediaPost, SUCCESS_DARK, SUCCESS_DOMAINE, SUCCESS_PLACES, SUCCESS_SOCIALMEDIA, SUCCESS_SURFACEWEB, SurfaceWeb, TwitterDetail, TypesSocialMedia, YoutubeDetail } from "../constants";

export interface BrandIntelState {
    places: Places[] | null;
    placeDetail: Places | null;
    isLoading: boolean;
    surfaceWeb: SurfaceWeb[] | [];
    socialMedia: SocialMediaPost[] | null;
    socialMediaDetail: SocialMediaPost | null;
    dark: Dark[] | null
    darkDetail: Dark | null;
    domains: Domaine[] | null;
    domaineDetail: Domaine | null;
    step: string;
    dns: DnsResponse | null;
    isLoadingDomaines: boolean;
    isLoadingSocialMedia: boolean;
    
    isLoadingDark: boolean;
}

const initialState: BrandIntelState = {
    places: null,
    placeDetail: null,
    isLoading: false,
    surfaceWeb: [],
    socialMedia: null,
    socialMediaDetail: null,
    dark: null,
    darkDetail: null,
    domains: null,
    domaineDetail: null,
    step: "overview",
    dns: null,
    isLoadingDomaines: false,
    isLoadingSocialMedia: false,
    isLoadingDark: false
}

export const BrandIntelReducer: Reducer<BrandIntelState, BranIntelAction> = (state, actions) => {
    if(state === undefined) return initialState
    switch (actions.type) {
        case REQUEST_PLACES: 
            return {
                ...state,
                isLoading: true
            }
        case SUCCESS_PLACES: 
            return {
                ...state,
                isLoading: false,
                places: actions.payload as Places[]
            }
        case SET_PLACE_DETAIL:
            return {
                ...state,
                placeDetail: actions.payload as Places
            }
        case REQUEST_SURFACEWEB: 
            return {
                ...state,
                isLoading: true
            }
        case SUCCESS_SURFACEWEB:
            return {
                ...state,
                isLoading: false,
                surfaceWeb: actions.payload as SurfaceWeb[]
            }
        case REQUEST_SURFACEWEB: 
            return {
                ...state,
                isLoading: true
            }
        case SUCCESS_SOCIALMEDIA:
            return {
                ...state,
                isLoading: false,
                socialMedia: actions.payload as SocialMediaPost[]
            }
        case FAILED_SOCIALMEDIA:
            return {
                ...state,
                isLoadingSocialMedia: false
            }
        case SET_SOCIALMEDIA_DETAIL:
            return {
                ...state,
                socialMediaDetail: actions.payload as SocialMediaPost
            }
        case REQUEST_DARK:
            return {
                ...state,
                isLoadingDark: true
            }
        case SUCCESS_DARK:
            return {
                ...state,
                isLoadingDark: false,
                dark: actions.payload as Dark[]
            }
        case FAILED_DARK:
            return {
                ...state,
                isLoadingDark: false
            }
        case SET_DARK:
            return{
                ...state,
                darkDetail: actions.payload as Dark
            }
        case REQUEST_DOMAINE:
            return {
                ...state,
                isLoadingDomaines: true
            }
        case SUCCESS_DOMAINE:
            return {
                ...state,
                isLoadingDomaines: false,
                domains: actions.payload as Domaine[]
            }
        case FAILED_DOMAINE: {
            return {
                ... state,
                isLoadingDomaines: false
            }
        }
        case SET_DOMAINE:
            return{
                ...state,
                domaineDetail: actions.payload as Domaine
            }
        case SET_STEP_PAGE:
            return {
                ...state,
                step: actions.payload as string
            }
        case SET_DNS: {
            return {
                ...state,
                dns: actions.payload as DnsResponse
            }
        }
        default:
            return state;
    }
}