import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'

export default function IdentityDoc() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Type
            </th>
            <th style={{paddingLeft: 8}}>
            Number
            </th>
            <th style={{paddingLeft: 8}}>
            Country
            </th> 
            <th style={{paddingLeft: 8}}>
            Expiry Date
            </th>
          </tr>
        </thead>
        <tbody>
            <tr className={styles.tableActivityRowUsers}>
              <td style={{width: '304px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>Passport</p>
              </td>
              
              <td style={{width: '160px'}} className={styles.tableCell} >
                <p>FA986345</p>
              </td>
              <td className={styles.tableCell} >
                  <p style={{fontSize: 14}}>US</p>
              </td>
              <td style={{width:'200px'}} className={styles.tableCell}>
              <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> 12/2025  
                         </p>
              </td>
              
            </tr>
            <tr className={styles.tableActivityRowUsers}>
              <td style={{width: '304px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>National ID</p>
              </td>
              
              <td style={{width: '160px'}} className={styles.tableCell} >
                <p>AH219034</p>
              </td>
              <td className={styles.tableCell} >
                  <p style={{fontSize: 14}}>US</p>
              </td>
              <td style={{width:'200px'}} className={styles.tableCell}>
              <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> 12/2025  
                         </p>
              </td>
              
            </tr>
            <tr className={styles.tableActivityRowUsers}>
              <td style={{width: '304px'}} className={styles.tableCell}>
                  <p style={{fontSize: 14}}>Driving License</p>
              </td>
              
              <td style={{width: '160px'}} className={styles.tableCell} >
                <p>APG1P4</p>
              </td>
              <td className={styles.tableCell} >
                  <p style={{fontSize: 14}}>US</p>
              </td>
              <td style={{width:'200px'}} className={styles.tableCell}>
              <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> 12/2025  
                         </p>
              </td>
              
            </tr>
          </tbody>
      </table>
    )
}
