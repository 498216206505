import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import Menu from '../common/Menu'
import Header from '../common/Header'
import { Button, colors } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import moment from 'moment';
import VulInformation from '../VulSections/VulInformation'
import ImpactSection from '../VulSections/ImpactSection'
import AffectProduct from '../VulSections/AffectProduct'
import Reference from '../VulSections/Reference'
import Config from '../VulSections/Config'
import Weakness from '../VulSections/Weakness'
import ChangeHistory from '../VulSections/ChangeHistory'
import ReactGA from 'react-ga4';
import { useIntercom } from '../IntercomSetup'
import { getVulnerabilitieByCVEAPI } from '../../../core/api/vulnerabilityApi'
import { CVEItem } from '../../../core/constants'
import { useDispatch } from 'react-redux'
import { setVulnerabilitiesByCVESearchAction } from '../../../core/actions/vulnerabilitiesActions'
import { setVulStepPageAction } from '../../../core/actions/stepsNavPageActions'

function VulnerabilityDetail() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [activeCompo, setactiveCompo] = useState('information')
    const { vulDetail } = useSelector(vulnerabilitiesSelector);
    const [data, setdata] = useState<CVEItem | null>()
    const emailStorageVa = window.localStorage.getItem('email')
    const hmacValue = window.localStorage.getItem('hmac')
    const fullNamed = window.localStorage.getItem("fullName")
useIntercom('isows87y', {
app_id: 'isows87y',
api_base: 'https://api-iam.intercom.io',
email: emailStorageVa as string,
user_hash: hmacValue as string,
name: fullNamed
})
    const renderInformations = () => {
        switch (activeCompo) {
            case "information":
                return <VulInformation/>
            case 'impact':
                return <ImpactSection/>
            case 'affectedProduct':
                return <AffectProduct/>
            case 'reference':
                return <Reference/>
            case 'config':
                return <Config/>
            case 'changeHistory':
                return <ChangeHistory/>
            case 'weakness':
                return <Weakness/>
            default:
                return;
        }
    }

    

    useEffect(() => {
        
        document.title = "Threat Intel - Credit Card Detail"
        ReactGA.send({ hitType: "Threat Intel - Credit Card Detail", page: window.location.pathname });
    }, [])
    const renderColors = (riskScore: number) => {
        if(riskScore === 0.0)
          return {
            textColor: "#757575",
            backgroundColor: "#e6e6e6",
            borderColor: "#757575"
          }
        else if (riskScore >= 0.1  && riskScore <= 3.9)
          return {
            textColor: "#20b444",
            backgroundColor: "#ecf9ef",
            borderColor: "#20b444"
          }
        else if (riskScore >= 4.0  && riskScore <= 6.9)
          return {
              textColor: "#f5c500",
              backgroundColor: "#fff7d5",
              borderColor: "#f5c500"
            }
          else if (riskScore >= 7.0  && riskScore <= 8.9)
            return {
                  textColor: "#fc9418",
                  backgroundColor: "#feead1",
                  borderColor: "#fc9418"
                }
          else
            return {
            textColor: "#e11900",
            backgroundColor: "#fdede8",
            borderColor: "#e11900"
          }
      }
    console.log(vulDetail)

  return (  
    <div className={ styles.main }>
            <Menu/>
            <Header/>
            <div className={styles.content}>
                <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>{
                            setTimeout(() => {
                                setVulStepPageAction({vulStepName: 'list'})
                            }, 100)
                            navigate("/vulnerabilities")
                            }} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Go back</span>
                        </div>  
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop:4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{vulDetail?.cve_id}</span>
                        <span style={{paddingBottom:10}}> . </span>
                        {
                            vulDetail?.base_severity ?
                            <span >
                             {
                vulDetail?.base_score?
                <div style={{display: "flex", justifyContent:"end"}}>
                    <span className={styles.scoreSeverity} style={{width: '22px',
             color: renderColors(vulDetail?.base_score).textColor,
            backgroundColor:renderColors(vulDetail?.base_score).backgroundColor,
            borderColor:renderColors(vulDetail?.base_score).borderColor
            }}
             >{vulDetail?.base_score}</span>
                </div>
             :
             <div style={{display: "flex", justifyContent:"end"}} >
<span className={styles.scoreUserInt} style={{width:'22px', padding: '2px 6px', color: ""}}>
       N/A
    </span>
</div>
            }
                        </span>
                        :
                        <div style={{padding: '13px 16px', color: "#e9bd08"}} >
                        <span className={styles.scoreUserInt}>
                                      N/A
                                  </span>
                        </div>
                        }
                    </div>
                </div>
                {/* <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <div style={{marginTop: -30}}>
                    <Button style={{width: '120px', height: '40px', gap: 10, fontSize:14, fontWeight:'500', backgroundColor: "#f5c500", display: 'flex', alignItems: "center", color:"white"}}>
                    <p>Review</p>
                     <FontAwesomeIcon icon={faChevronDown} style={{height: 8}}/>   
                    </Button>
                    </div>
                    </div>   */}
                </div>

                {/* <div style={{display: 'flex', alignItems: 'center', gap: 5, fontSize: 14}}>
                    <p style={{color: "#afafaf"}}>Last modified</p>
                    <p style={{color: "white"}}>{moment(vulDetail?.lastModifiedDate).format("MMMM D, YYYY")}</p>
                    <p style={{color: "#afafaf"}}>Assigned to</p>
                    <div className={styles.profileHeader}>
                        <img src="/logoProfile.png" className={styles.logoProfileHeader} />
                        <p style={{fontSize: 12}}>Kelly Kapoor</p>
                        <FontAwesomeIcon icon={faChevronDown} style={{height: 8}}/>
                    </div>
                </div> */}
                <div>
                    <p style={{color: '#cbcbcb', width: "70%", fontSize: 14, }}>
                        {vulDetail?.description}
                    </p>
                </div>
                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px'  }} className={styles.firstSectionMenu}>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('information')} className={activeCompo === 'information' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Information</span>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('impact')} className={activeCompo === 'impact' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Impact</span>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('affectedProduct')} className={activeCompo === 'affectedProduct' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Affected Products</span>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('reference')} className={activeCompo === 'reference' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>References</span>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('config')} className={activeCompo === 'config' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Configurations</span>
                {/* <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('changeHistory')} className={activeCompo === 'changeHistory' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Change History</span> */}
                {/* <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('weakness')} className={activeCompo === 'weakness' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Weaknesses</span> */}
            </div>
            {
                renderInformations()
            }
            </div>
    </div>
  )
}

export default VulnerabilityDetail