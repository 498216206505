import React, { useEffect, useState } from 'react'
import styles from "../../../../Dashboard/dashboard.module.scss"
import { faArrowLeft, faCalendar, faChevronDown, faCreditCard, faDownload, faHandSparkles, faMagicWandSparkles, faSearch, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DocumentsLeakIntel, InsideThreatsIntel, REQUEST_DOCUMENTS_LEAK } from '../../../../../core/constants'
import { getDocumentsLeakApi, getInsideThreatsApi, PageSort } from '../../../../../core/api/threatIntelApi'
import ReactGA from 'react-ga4';
import moment from 'moment'
import Loader from "../../../../../icons/Loader.svg"
import EmptyState from '../../../common/EmptyState'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../../core/selectors/threatIntelSelector'
import { renderColors } from '../../CredentialsLeaks'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Pagination } from '@mui/material'
import { useDispatch } from 'react-redux'
export default function DocumentLeaks() {
    const dispatch = useDispatch()
    const { documentsLeak, isLoadingDocumentsLeak} = useSelector(threatIntelSelector)
    const [page, setPage] = useState(1)
    const [search, setsearch] = useState("")
    const navigate = useNavigate()
    useEffect(() => {
      document.title = "Documents Leak"
      ReactGA.send({ hitType: "Threat Intel - Documents Leak", page: window.location.pathname });
    }, [])
    const setCredentialDetail = (item: any) => {
    
    const itemForDisplay = {
      ...item,
      typeUser: item.type,
      userid: item.userid,
      username: item.email
    };
      window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
      navigate("/threatIntel/userProfile")
    }
    const handleChangePage = (
      event: React.ChangeEvent<unknown>, value: number
    ) => {
      const dataCred: PageSort = {
        page:value,
        sortBy: search
    }
      setPage(value)
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
    };
    const dateFormats = [
      'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
      'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
      'DD.MM.YYYY HH:mm:ss',
      'DD.MM.YYYY h:mm:ss',   // Format for '20.06.2024 7:53:01'
    ];
    const handleSearch = (
    ) => {
     if(search!==""){
      const dataCred: PageSort = {
        page:page,
        sortBy: search
    }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
     }else {
      const dataCred: PageSort = {
        page:page,
        sortBy: null
    }
      dispatch({ type: REQUEST_DOCUMENTS_LEAK, payload: dataCred })
     }
    };
    return (
        <div className={styles.awaitingApproval} style={{marginTop: 20}}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
                            <div className={styles.searchAndIcon}>
                            <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e)=>setsearch(e.target.value)}/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch}/>
                            </div>
                            <div className={styles.removeSelected}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 14}} />
                            </div>
                        </div>
                       {
                        !isLoadingDocumentsLeak ?
                        documentsLeak && documentsLeak.data.length !==0 ?
                        <div style={{width: "100%"}}>
                        <table className={styles.table}>
                                    <tr className={styles.tableHead}>
                                    <th style={{paddingLeft: 8, textAlign: 'start'}}>
                                    File Name
                                    </th>
                                    <th style={{paddingLeft: 8}}>
                                      <span >Username </span>
                                        <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                                    </th>
                                    <th style={{paddingLeft: 8}}>
                                    Type {" "}
                                        <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                                    </th>
                                    
                                    
                                    <th style={{paddingLeft: 8, display:"flex", justifyContent:"end", paddingRight:13}}>
                                    <p style={{display:"flex" , alignItems:'center', gap: 5, width:150}}>
                                    <span>Last Updated</span>
                                    <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                                    </p>
                                    </th>
                                    </tr>
                                      { 
                                        documentsLeak?.data?.map(item => 
                                          <tr className={styles.tableActivityRowUsers} onClick={()=>setCredentialDetail(item)} style={{cursor: "pointer"}}>
                                        <td  className={styles.tableCell}>
                                          <p style={{color: '#cbcbcb', fontSize: 14, textAlign: 'start'}}>{item?.file_name ? item?.file_name: "N/A"}</p>
                                          </td>
                                        <td style={{paddingLeft: 8}} className={styles.tableCell}>
                                        {item.fullName ? item.fullName :"N/A"}
                                        </td>
                                          <td style={{ paddingLeft: 8}} className={styles.tableCell}>
                                            <p style={{padding: '4px 8px', color: "black",backgroundColor: "white", borderRadius: '32px', fontSize: 12, width:'72px', textAlign: 'center'}}>{item.type}</p>
                                          </td>
                                          
                                          <td style={{ paddingLeft: 8, width:150}} className={styles.tableCell} >
                                            <div style={{}}>
                                            {
                                              item?.updated_at ? 
                                              <div style={{ display: 'flex', alignItems: "center", gap: 10, justifyContent: "start"}}>
                                              <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/> {item?.updated_at && moment(item?.updated_at, dateFormats, true).format("MMMM DD, YYYY")}
                                              </div>
                                              :
                                              <div>
                                                <p>N/A</p>
                                              </div>
                                            } 
                                            </div>
                                          </td>
                                        </tr>
                                        )
                                      }
                                      
                                </table>
                                <Pagination
                                    showFirstButton
                                    showLastButton
                                    shape="rounded"
                                    onChange={handleChangePage}
                                    page={Number(documentsLeak?.pagination?.currentPage)}
                                    count={documentsLeak?.pagination?.totalPages}
                                    sx={{
                                    '& .MuiPagination-ul': {
                                        justifyContent: "center",
                                        display: 'flex'
                                    },
                                      '& .Mui-selected': {
                                        color: '#ffffff', // Text color for active page
                                        backgroundColor: '#1f1f1f', // Background color for active page
                                        opacity: 0.3, // Background color for active page
                                      },
                                      '& .MuiPaginationItem-root': {
                                        color: '#ffffff', // Text color for inactive pages
                                      },
                                    }}
                                />
                                </div>
                                :
                                <EmptyState/>
                                :
                                <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
                       }
        </div>
    )
}
