import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../../dashboard.module.scss'
import accountStyles from "../../../../Account/account.module.scss"
import { maskString } from '../../CredentialsLeaks'
import Loader from "../../../../../icons/Loader.svg"

import GoogleLogo from "../../../../../icons/BrowserIcons/Google-Chrome.svg"
import BraveLogo from "../../../../../icons/BrowserIcons/Brave.svg"
import EdgeLogo from "../../../../../icons/BrowserIcons/Edge.svg"
import FireFoxLogo from "../../../../../icons/BrowserIcons/firefox.svg"
import OperaLogo from "../../../../../icons/BrowserIcons/Opera.svg"
import SafariLogo from "../../../../../icons/BrowserIcons/safari.svg"
import { CircularProgress } from '@mui/material'

function Credentials({crendentials}: any) {
    return (
        <>
          {
            crendentials ? 
            <table className={styles.table}>
        <thead>
          <tr className={styles.tableHead}>
            <th style={{paddingLeft: 8}}>
            Username
            </th>
            <th style={{paddingLeft: 8}}>
            Password
            </th>
            <th style={{paddingLeft: 8}}>
            Source
            </th> 
            <th style={{paddingLeft: 8}}>
            Risk Score <FontAwesomeIcon icon={faChevronDown} height={8} width={7}/>
            </th>
            <th style={{paddingLeft: 8, paddingRight: 13, textAlign: 'end'}}>
            Application
            </th> 
          </tr>
        </thead>
        <tbody>
            {
                crendentials?.map((item: any) => 
                    <tr className={styles.tableActivityRowWatchListAssets}>
              <td  className={styles.tableCell}>
                  <p style={{fontSize: 14}}>{item?.username ? item?.username :"N/A"}</p>
              </td>
              
              <td  className={styles.tableCell} >
                <p>{item?.password ? item?.password :"N/A"}</p>
              </td>
              <td  className={styles.tableCell} >
              {item?.url?.includes('android')?item?.url?.split('@')[1]:item?.url?.substring(0,43)}
              </td>
              <td  className={styles.tableCell}>
              <span className={styles.scoreSeverity} style={{width: '22px'}}>{Number(item?.total_risk_score).toFixed(1)}</span>
              </td>
              <td  className={styles.tableCell} style={{ display:"flex", justifyContent:"end", paddingRight:13}}>
                <div style={{display: 'flex', alignItems: 'center',gap:15}}>
                    <div style={{display: 'flex',alignItems: 'center', backgroundColor: 'white', borderRadius: '50%', height:"22px", width:"22px"}}>
                         <img src={
                          item?.application?.toLowerCase()?.includes("google") ? GoogleLogo
                          : item?.application?.toLowerCase()?.includes("brave") ? BraveLogo
                          : item?.application?.toLowerCase()?.includes("edge") ? EdgeLogo
                          : item?.application?.toLowerCase()?.includes("firefox") ? FireFoxLogo
                          : item?.application?.toLowerCase()?.includes("opera") ? OperaLogo 
                          : SafariLogo
                         } alt="" height={28} width={36}/>
                    </div>
                    <div style={{width:120}}>
                    <p style={{color: 'white', fontSize: "14px",paddingLeft:15, }}>{
                      item?.application?.toLowerCase()?.includes("google") ? "Google Chrome"
                      : item?.application?.toLowerCase()?.includes("brave") ? "Brave"
                      : item?.application?.toLowerCase()?.includes("edge") ? "Microsoft Edge"
                      : item?.application?.toLowerCase()?.includes("firefox") ? "Mozilla Firefox"
                      : item?.application?.toLowerCase()?.includes("opera") ? "Opera" 
                      : "Safari"
                    }</p>
                    </div>
                </div>
              </td>
            </tr>
                )
            }
            
          </tbody>
      </table>
      :
      <div className={styles.loader}>
    <CircularProgress />
    </div>
          }
        </>
    )
}

export default Credentials
