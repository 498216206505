import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'


function capitalizeFirstLetter(data: any) {
    return data?.charAt(0)?.toUpperCase() + data?.slice(1)?.toLowerCase();
}

const renderColors = (riskScore: number | string | undefined) => {
    // Default color scheme for undefined riskScore
    const defaultColorScheme = {
       textColor: "#333333",
       backgroundColor: "#757575",
       borderColor: "#757575"
    };
 
    if (riskScore === undefined) {
       return defaultColorScheme;
    }
 
    if (typeof riskScore === 'number') {
       if (riskScore === 0.0) {
           return {
             textColor: "#757575",
             backgroundColor: "#e6e6e6",
             borderColor: "#757575"
           }
       } else if (riskScore >= 0.1 && riskScore <= 3.9) {
           return {
             textColor: "#20b444",
             backgroundColor: "#ecf9ef",
             borderColor: "#20b444"
           }
       } else if (riskScore >= 4.0 && riskScore <= 6.9) {
           return {
               textColor: "#f5c500",
               backgroundColor: "#fff7d5",
               borderColor: "#f5c500"
           }
       } else if (riskScore >= 7.0 && riskScore <= 8.9) {
           return {
               textColor: "#fc9418",
               backgroundColor: "#feead1",
               borderColor: "#fc9418"
           }
       } else {
           return {
             textColor: "#e11900",
             backgroundColor: "#fdede8",
             borderColor: "#e11900"
           }
       }
    } else {
       // Assume riskScore is a string and use capitalizeFirstLetter to normalize input
       const normalizedRisk = capitalizeFirstLetter(riskScore);
       switch (normalizedRisk) {
           case "Low":
               return {
                textColor: "#20b444",
             backgroundColor: "#ecf9ef",
             borderColor: "#20b444"
               };
           case "Medium":
               return {
                   textColor: "#20b444",
                   backgroundColor: "#ecf9ef",
                   borderColor: "#20b444"
                 };
           case "High":
               return {
                   textColor: "#f5c500",
                   backgroundColor: "#fff7d5",
                   borderColor: "#f5c500"
                 };
           case "Critical":
               return {
                   textColor: "#fc9418",
                   backgroundColor: "#feead1",
                   borderColor: "#fc9418"
                 };
           default:
               return defaultColorScheme;
       }
    }
 
    // Return default colors if none of the conditions match
    return defaultColorScheme;
 };
 

export default function ImpactSection() {
    const { vulDetail } = useSelector(vulnerabilitiesSelector)
    
    console.log(renderColors(capitalizeFirstLetter(vulDetail?.confidentiality_impact)))
    return (
        <div style={{marginTop: 25}}>
            
            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 25}}>

          <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
          <p style={{ padding: '13px 16px'}} >
          Exploitability
          </p>
              {
            <div style={{padding: '13px 16px', color: "#e9bd08"}}>
                 {
                    vulDetail?.exploitability_score ?
                  <div style={{display: "flex", justifyContent:"end"}}>
                    <span className={styles.scoreSeverity} style={{width: '22px',
             color: renderColors(vulDetail.exploitability_score).textColor,
            backgroundColor:renderColors(vulDetail?.exploitability_score).backgroundColor,
            borderColor:renderColors(vulDetail?.exploitability_score).borderColor
            }}
             >{vulDetail?.exploitability_score}</span>
                </div>
                           :
                           <span className={styles.scoreUserInt}>N/A</span>
                 }
                </div>
              }
          </div>
          <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
          <p style={{ padding: '13px 16px'}}>
          User Interaction
          </p>
              <div style={{padding: '13px 16px', color: "#e9bd08"}} >
              <span className={styles.scoreUserInt}>
                            {capitalizeFirstLetter(vulDetail?.user_interaction)}
                        </span>
              </div>
          </div>
             </div>

             <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 25, marginTop:15}}>

          <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
          <p style={{ padding: '13px 16px'}} >
          Impact
          </p>
             {
           <div style={{padding: '13px 16px', color: "#e9bd08"}}>
                {
                    vulDetail?.impact_score ? 
                    <span className={styles.scoreSeverity} style={{width: '22px',
                        color: renderColors(vulDetail.impact_score).textColor,
                       backgroundColor:renderColors(vulDetail?.impact_score).backgroundColor,
                       borderColor:renderColors(vulDetail?.impact_score).borderColor
                       }}
                        >{vulDetail?.impact_score}</span>
                        :
                        <span className={styles.scoreUserInt}>N/A</span> 
                }
                 </div>
             }
          </div>
          <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
          <p style={{ padding: '13px 16px'}}>
          Confidentiality Impact
          </p>
              <div style={{padding: '13px 16px', color: "#e9bd08"}} >
              {
                vulDetail && vulDetail?.confidentiality_impact && 
                <span className={styles.scoreSeverity} style={{width: '22px',
                    color: renderColors(capitalizeFirstLetter(vulDetail?.confidentiality_impact)).textColor,
                   backgroundColor:renderColors(capitalizeFirstLetter(vulDetail?.confidentiality_impact)).backgroundColor,
                   borderColor:renderColors(capitalizeFirstLetter(vulDetail?.confidentiality_impact)).borderColor
                   }}
                    >{capitalizeFirstLetter(vulDetail?.confidentiality_impact)}</span>
              }
              </div>
          </div>
             </div>

             <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 25, marginTop:15}}>

                <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                <p style={{ padding: '13px 16px'}} >
                Attack Vector
                </p>
                    <div style={{padding: '13px 16px', color: "#e9bd08"}}>
                    <span className={styles.scoreUserInt}>
                    {capitalizeFirstLetter(vulDetail?.attack_vector)}
                            </span>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                <p style={{ padding: '13px 16px'}}>
                Integrity Impact
                </p>
                <div style={{padding: '13px 16px', color: "#e9bd08"}} >
              {
                vulDetail && vulDetail?.confidentiality_impact && 
                <span className={styles.scoreSeverity} style={{width: '22px',
                    color: renderColors(capitalizeFirstLetter(vulDetail?.integrity_impact)).textColor,
                   backgroundColor:renderColors(capitalizeFirstLetter(vulDetail?.integrity_impact)).backgroundColor,
                   borderColor:renderColors(capitalizeFirstLetter(vulDetail?.integrity_impact)).borderColor
                   }}
                    >{capitalizeFirstLetter(vulDetail?.integrity_impact)}</span>
              }
              </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 25, marginTop:15}}>

                    <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <p style={{ padding: '13px 16px'}} >
                    Attack Complexity
                    </p>
                    <div style={{padding: '13px 16px', color: "#e9bd08"}} >
              {
                vulDetail && vulDetail?.confidentiality_impact && 
                <span className={styles.scoreSeverity} style={{width: '22px',
                    color: renderColors(capitalizeFirstLetter(vulDetail?.attack_complexity)).textColor,
                   backgroundColor:renderColors(capitalizeFirstLetter(vulDetail?.attack_complexity)).backgroundColor,
                   borderColor:renderColors(capitalizeFirstLetter(vulDetail?.attack_complexity)).borderColor
                   }}
                    >{capitalizeFirstLetter(vulDetail?.attack_complexity)}</span>
              }
              </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                    <p style={{ padding: '13px 16px'}}>
                    Availability Impact
                    </p>
                        <div style={{padding: '13px 16px', color: "#e9bd08"}} >
                        <span className={styles.scoreUserInt}>
                        {capitalizeFirstLetter(vulDetail?.availability_impact)}
                                </span>
                        </div>
                    </div>
             </div>


            <div style={{display: "flex", justifyContent: "space-between", alignItems:"center", gap: 25, marginTop:15}}>

                        <div style={{display: "flex", justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <p style={{ padding: '13px 16px'}} >
                        Privileges Required
                        </p>
                        <div style={{padding: '13px 16px', color: "#e9bd08"}} >
              {
                vulDetail && vulDetail?.confidentiality_impact && 
                <span className={styles.scoreSeverity} style={{width: '22px',
                    color: renderColors(capitalizeFirstLetter(vulDetail?.privileges_required)).textColor,
                   backgroundColor:renderColors(capitalizeFirstLetter(vulDetail?.privileges_required)).backgroundColor,
                   borderColor:renderColors(capitalizeFirstLetter(vulDetail?.privileges_required)).borderColor
                   }}
                    >{capitalizeFirstLetter(vulDetail?.privileges_required)}</span>
              }
              </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: "space-between", alignItems:"center"}} className={styles.impactCol}>
                        <p style={{ padding: '13px 16px'}}>
                        Score
                        </p>
                            <div style={{padding: '13px 16px', color: "#e9bd08"}} >
                            <span className={styles.scoreUserInt}>
                            {capitalizeFirstLetter(vulDetail?.scope)}
                                    </span>
                            </div>
                        </div>
             </div>
        </div>
    )
}
