
import { faArrowUpRightFromSquare, faCalendar, faChevronDown, faComment, faHeart, faSearch, faShare, faTrash, faUserCheck, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { style } from '../../Account/PersonalAccount.style'
import styles from '../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { RedditDetail, REQUEST_SOCIALMEDIA, SET_SOCIALMEDIA_DETAIL, SocialMedia, SocialMediaDetail, SocialMediaPost, TwitterDetail, YoutubeDetail } from '../../../core/constants'
import { useDispatch } from 'react-redux'
import { CircularProgress, MenuItem, Select, SelectChangeEvent, Skeleton } from '@mui/material'
import moment from 'moment'
import ReactGA from 'react-ga4';
import logoInstagram from "../../../icons/SocialMedia/instagram.svg"
import logoFacebook from "../../../icons/SocialMedia/facebook.svg"
import logoLinkedin from "../../../icons/SocialMedia/linkedin.svg"
import logoReddit from "../../../icons/SocialMedia/reddit.svg"
import logoTiktok from "../../../icons/SocialMedia/tiktok.svg"
import logohTwitch from "../../../icons/SocialMedia/twitch.svg"
import logoTwitter from "../../../icons/SocialMedia/x.svg"
import logoYoutube from "../../../icons/SocialMedia/youtube.svg"
import { getSocialMediaApi, getSocialMediaDetailApi, getSocialMediaDetailCommentsApi } from '../../../core/api/brandIntelApi'
import Loader from "../../../icons/Loader.svg"
import EmptyStateSocialMedia from '../common/EmptyStateSocialMedia'


function SocialMediaSection() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {socialMedia, isLoadingSocialMedia} = useSelector(brandIntelSelector)
    const [postDetail, setpostDetail] = useState<any>()
    const [platformDetauk, setPlatformDetauk] = useState("")
    const [urlPost, seturlPost] = useState("")
    const [comments, setcomments] = useState<any[]>()
    const detailRef = useRef(null)
    const [recent, setRecent] = useState('all')
    const [sortBy, setSortBy] = useState('all')
    const [severity, setSeverity] = useState("critical")
    const [isLoadingComments, setisLoadingComments] = useState(false)
    const getPostDetail = async (postId: number, platform: string)=> {
        const response = await getSocialMediaDetailApi(postId, platform)
        setpostDetail(response?.[0])
    }

    const getPostDetailComments = async (postId: number)=> {
        setisLoadingComments(true)
        const response = await getSocialMediaDetailCommentsApi(postId)
        setcomments(response)
        setisLoadingComments(false)
    }

    console.log(postDetail)

    useEffect(() => {
      document.title = "Social Media"
      ReactGA.send({ hitType: "Brand Intel - Social Media", page: window.location.pathname });
      
      
    }, [dispatch])

    useEffect(() => {
        if (socialMedia && socialMedia.length > 0) {
            const firstPost = socialMedia[0];
            getPostDetail(
                firstPost?.id,
                firstPost?.type
            )
            setPlatformDetauk(firstPost?.type)
            seturlPost(firstPost?.url)
            getPostDetailComments(firstPost?.id)
        }
    }, [socialMedia])
    const handleChangeSortBY = (event: SelectChangeEvent) => {
        setSortBy(event.target.value as string);
      }
      const handleChange = (event: SelectChangeEvent) => {
        setRecent(event.target.value as string);
      }
      const handleChangeSeverity = (event: SelectChangeEvent) => {
        setSeverity(event.target.value as string);
      }
    
    const filtredPosts = sortBy !== "all" ? socialMedia?.filter(item => item?.type?.toLowerCase().includes(sortBy.toLowerCase())) : socialMedia
    

    // if(isLoading) return <Skeleton variant="rectangular" width={210} height={118} />
    

    // const getRandomItems = (): SocialMediaDetailtype[] | null => {
    //     if (!socialMedia) {
    //         return null;
    //     }

    //     const types = ['youtube', 'instagram', 'reddit', 'twitter'];
    //     const randomItems: SocialMediaDetailtype[] = [];
    //     const selectedItems: { [key: string]: any[] } = {
    //         youtube: [],
    //         instagram: [],
    //         reddit: [],
    //         twitter: []
    //     };

    //     // Iterate through each type
    //     types.forEach(type => {
    //         // @ts-ignore
    //         const data = socialMedia[type]?.results;
    //         if (data && data.length > 0) {
    //             selectedItems[type] = data;
    //         }
    //     });

    //     let index = 0;
    //     while (randomItems.length < types.length * 50) { // Repeat twice for demo purpose
    //         types.forEach(type => {
    //             if (selectedItems[type][index]) {
    //                 randomItems.push({ type, resultSocialMedia: selectedItems[type][index] });
    //             }
    //         });
    //         index++;
    //     }

    //     return randomItems;
    // };

    // const randomItems = getRandomItems();
   
    const setDetailSocialMedia = (data: SocialMediaPost) => {
        // dispatch({
        //     type: SET_SOCIALMEDIA_DETAIL,
        //     payload: data
        // })
        // navigate("/brandIntel/socialProfile")
        setpostDetail(data)
    }

    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };

    const getPostDesc = (postId: number) => {
        const item = socialMedia?.find(item => item.id === postId)
        if(item?.post_body)
            return item?.post_body
        return item?.post_title
     }

const renderImgPost = (url: string) => {
    console.log(url.split("defendisimage"))
    return url;
}


    return(
        <div style={{marginTop: 20}}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>
            <div className={styles.searchAndIcon}>
                    <input className={styles.search} name='search' placeholder='Search'/>
                    <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                </div>
                <div style={{display: 'flex', alignItems: "center", gap: 10}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All Posts</MenuItem>
                        <MenuItem value={'last7Days'}>Unread</MenuItem>
                        <MenuItem value={'lastMonth'}>Read</MenuItem>
            </Select>
           
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                onChange={handleChangeSortBY}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All types</MenuItem>
                        <MenuItem value={'facebook'}>Facebook</MenuItem>
                        <MenuItem value={'youtube'}>YouTube</MenuItem>
                        <MenuItem value={'reddit'}>Reddit</MenuItem>
                        <MenuItem value={'x'}>X</MenuItem>
                        <MenuItem value={'tiktok'}>TikTok</MenuItem>
                        <MenuItem value={'linkedin'}>LinkedIn</MenuItem>
                        <MenuItem value={'instagram'}>Instagram</MenuItem>
            </Select>
        </div>  
            </div>
            {
                !isLoadingSocialMedia ? 
                filtredPosts && filtredPosts?.length > 0 ? <div style={{display: 'flex', gap: 25}}>
                <div style={{width: '40%',height:'70vh', border:'1px solid #242424',padding: "16px 16px 20px 16px", overflowY: 'scroll'}} className={styles.cardSocialMedia}>
                     {
                        filtredPosts?.map(item => 
                            <div onClick={()=>{
                                getPostDetail(
                                    item?.id,
                                    item?.type
                                )
                                getPostDetailComments(item?.id)
                                setPlatformDetauk(item?.type)
                                seturlPost(item?.url)
                                // @ts-ignore
                                detailRef.current.scrollIntoView({ behavior: 'smooth' })
                            }} style={{backgroundColor: '#1a1a1a',border:'1px solid #242424', padding: 15, borderRadius: 8, marginBottom: 16, cursor: "pointer"}}>
                                <div style={{display: "flex", alignItems: "center", gap: 15}}>
                                    <div className={styles.headerPostSocialMediaLogo}>
                                        <img src={
                                            item?.type === "instagram" ? logoInstagram:
                                            item?.type === "reddit" ? logoReddit :
                                            item?.type === "twitch"? logohTwitch : 
                                            item?.type === "linkedin" ? logoLinkedin:
                                            item?.type === "tiktok" ? logoTiktok:
                                            item?.type === "twitter" ? logoTwitter :
                                            item?.type === "facebook" ? logoFacebook :logoYoutube
                                        }  width={16}/>
                                    </div>  
                                 <div>
                              <span style={{color: "white", fontWeight: "500", fontSize: 14}}>{
                                item.profile_name
                              }</span>
                              <br/>
                              <span style={{color: "#cbcbcb",  fontSize: 12}}>
                                {
                                moment(item?.post_date).format('MMMM DD, YYYY')
                            }
                              </span>
                          </div>
                        </div>
                        <p style={{fontSize: 14, color: "#cbcbcb",  margin: "20px 0"}}>{
                       item?.post_body ? item?.post_body?.substring(0, 159)+"..." : item?.post_title?.substring(0, 159)+"..."
                    }</p>
                    <div style={{display: "flex", alignItems: "center", gap: 15}}>
                        <div style={{display: "flex", alignItems: "center", gap: 5}}>
                            <FontAwesomeIcon icon={faHeart} height={16} width={16} color="#afafaf" />
                            <span style={{color: "#afafaf", fontSize: 12}}>
                                {
                                 item.likes
                                }
                            </span>
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: 5}}>
                            <FontAwesomeIcon icon={faComment} height={16} width={16} color="#afafaf" />
                            <span style={{color: "#afafaf", fontSize: 12}}> 
                            
                            {item?.comments_count}
                                </span> 
                        </div>
                        </div>
                            </div>
                        
                        )
                       
                        
                     }
                </div>
                <div style={{width: '60%',height:"70vh",  border:'1px solid #242424',padding: 20, overflowY: 'scroll'}} className={styles.cardSocialMedia} ref={detailRef}>
                    <div style={{display: 'flex', justifyContent:"space-between", alignItems: "center"}}>
                        <span style={{color: "white", fontSize: "14px", fontWeight: '600'}}>Post Details</span>
                        <FontAwesomeIcon onClick={()=>openlinkDetail(urlPost)} style={{cursor:"pointer"}} icon={faArrowUpRightFromSquare} color='white' height={11} width={12} />
                        </div>
                        <div style={{ gap: 15, marginTop:32}}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                        {
                            platformDetauk === 'youtube' && postDetail?.thumbnail_url ? <img src= {postDetail?.thumbnail_url } style={{height: 320,borderRadius: 8, overflow: 'hidden', cursor:"pointer"}}  className={styles.imgPost}/> 
                            :postDetail?.image_url ? <img src={
                                postDetail?.image_url?.split("defendisimage")?.length > 1 ? postDetail?.image_url?.split("defendisimage")[0] : postDetail?.image_url
                                    } style={{height: 320,borderRadius: 8, overflow: 'hidden', cursor:"pointer"}}  className={styles.imgPost}/>
                                :<></>
                                }
                                </div>
                        <p style={{fontSize: 14, color: "#cbcbcb"}}>{
                            getPostDesc(postDetail?.post_id)
                        }</p>
                    </div>  
                    {
                        !isLoadingComments ?
                        comments&&comments.length > 0 ?
                        comments?.map((item: any)=>
                            <div className={styles.reviewsPlaceDetails} style={{marginTop:15}}>
                                    <div className={styles.reviewsPlaceDetailsHeader}>
                                    <div className={styles.reviewsPlaceDetailsHeaderLogo}>
                                        <p style={{color: 'white', fontSize: 14}}>{
                                            item?.author
                                }</p>
                                    </div>
                                    <p style={{color: '#afafaf', fontSize: 12}}>{item?.comment_date && moment(item?.comment_date).format('MMMM DD, YYYY')}</p>
                                    </div>
                                    <p style={{fontSize: 14, color: "#afafaf"}}>{
                                        item?.comment_text
                                    }</p>
                                    <div className={styles.reviewsPlaceDetailsHeaderLogo} style={{marginTop: -15}}>
                                    <FontAwesomeIcon icon={faHeart} height={16} width={16} color='#afafaf'/>
                                    <p style={{fontSize: 12, color: "#afafaf"}}>{item?.like_count}</p>
                                    </div>
                                </div>
                                ):
                                <p style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontWeight:'500',
                                    color: 'white'
                                }}>No comments for this post</p>
                                :
                                <p style={{
                                    width:'100%',
                                    textAlign:'center',
                                    fontWeight:'500',
                                    color: 'white'
                                }}>Comments Loading...</p>
                    }
                    </div>
                    
                </div>
                :<EmptyStateSocialMedia/>
                : <div className={styles.loaderVul}>
                <CircularProgress />
            </div>
            }
        </div>
    )
}

export default SocialMediaSection
