import React from 'react'
import styles from '../dashboard.module.scss'
import { Rating } from '@mui/material'
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      stroke: '#545454', // Border color for empty stars
      strokeWidth: 0.5, // Border width
    },
  });
function ReviewsPlaceDetail() {
  const { placeDetail } = useSelector(brandIntelSelector)
  return (
    <div style={{marginTop: 15}}>
      {

(placeDetail !== null && placeDetail?.reviewsDetail !== null && placeDetail?.reviewsDetail.length > 0) 
 ? 
        placeDetail?.reviewsDetail?.map(item =>
              <div className={styles.reviewsPlaceDetails} style={{marginTop: 15}}>
            <div className={styles.reviewsPlaceDetailsHeader}>
              <div className={styles.reviewsPlaceDetailsHeaderLogo}>
                <img src={item?.reviewerPhotoUrl} style={{height: "2.22vh", width: 24, overflow: 'hidden', borderRadius: "50%"}}/>
                <p style={{color: 'white', fontSize: 14}}>{item.name}</p>
              </div>
              <p style={{color: '#afafaf', fontSize: 12}}>{item?.publishAt}</p>
            </div>
            <StyledRating  name="read-only" value={item?.stars} readOnly size="small"/>
            
              <p style={{fontSize: 14, color: "#afafaf"}}>{item?.text ? item?.text : 'No Comment'}</p>
            
            <div className={styles.reviewsPlaceDetailsHeaderLogo} style={{marginTop: -15}}>
              <FontAwesomeIcon icon={faHeart} height={16} width={16} color='#afafaf'/>
              <p style={{fontSize: 12, color: "#afafaf"}}>{item?.likesCount}</p>
            </div>
          </div>
        )
        :
      <p style={{marginTop: 15, color: 'white', fontWeight: "600"}}>No Reviews</p>
      }
    </div>
  )
}

export default ReviewsPlaceDetail