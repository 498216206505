import { call, put } from "redux-saga/effects";
import { FAILED_WATCH_LIST, GET_WATCH_LIST, LoginFirstTimeRes, MessageResp, WatchList, WatchListActions, WatchListAssets, WatchListAssetsReq, WatchListBin, WatchListBinReq, WatchListBrandKeyWord, WatchListBrandKeyWordReq, WatchListDomain, WatchListDomainReq, WatchListExecutive, WatchListExecutiveReq, WatchListGroup, WatchListGroupReq, WatchListIp, WatchListIpReq } from "../constants";
import { getWatchListApi, getWatchListAssetsApi, getWatchListBinApi, getWatchListBrandKeywordApi, getWatchListDomainApi, getWatchListExecutiveApi, getWatchListIpApi, getWatchListRansomeApi, saveWatchListAssetsApi, saveWatchListBinApi, saveWatchListBrandKeywordApi, saveWatchListDomainApi, saveWatchListExecutiveApi, saveWatchListIpApi, saveWatchListRansomeApi } from "../api/watchListApi";
import { setWatchListAssets, setWatchListBins, setWatchListBrandKeywords, setWatchListDomains, setWatchListExecutives, setWatchListIps, setWatchListRansomes, setWathListAction } from "../actions/watchListActions";

export function* getWatchListSaga(action: WatchListActions) {
    try {
        const response: WatchList = yield call(getWatchListApi)
        yield put(setWathListAction(response))
    } catch (error) {
        yield put({
            type: FAILED_WATCH_LIST
        })
    }
}

export function* saveWatchListAssetsSaga(action: WatchListActions){
    try {
        const data = action.payload as WatchListAssetsReq
        const response: LoginFirstTimeRes = yield call(saveWatchListAssetsApi, data)
        if(response.message === MessageResp.WATCH_LIST_ASSEST_SAVE) {
            const response: WatchList = yield call(getWatchListApi)
            yield put(setWathListAction(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}

export function* setWatchListAssetsSaga(action: WatchListActions) {
    try {
        const response: WatchListAssets[] = yield call(getWatchListAssetsApi)
        yield put(setWatchListAssets(response))
    } catch (error) {
        throw error;
    }
}

export function* getWatchListBrandKeywordSaga(action: WatchListActions) {
    try {
        const response: WatchListBrandKeyWord[] = yield call(getWatchListBrandKeywordApi)
        yield put(setWatchListBrandKeywords(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListBrandKeywordSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListBrandKeyWordReq
        const response: LoginFirstTimeRes = yield call(saveWatchListBrandKeywordApi, data)
        if(response.message === MessageResp.WATCH_LIST_BRAND_KEYWORD_SAVE) {
            const response: WatchListBrandKeyWord[] = yield call(getWatchListBrandKeywordApi)
            yield put(setWatchListBrandKeywords(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}


export function* getWatchListDomainSaga(action: WatchListActions) {
    try {
        const response: WatchListDomain[] = yield call(getWatchListDomainApi)
        yield put(setWatchListDomains(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListDomainSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListDomainReq
        const response: LoginFirstTimeRes = yield call(saveWatchListDomainApi, data)
        if(response.message === MessageResp.WATCH_LIST_DOMAIN_SAVE) {
            const response: WatchListDomain[] = yield call(getWatchListDomainApi)
            yield put(setWatchListDomains(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}

export function* getWatchListIpSaga(action: WatchListActions) {
    try {
        const response: WatchListIp[] = yield call(getWatchListIpApi)
        yield put(setWatchListIps(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListIpSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListIpReq
        const response: LoginFirstTimeRes = yield call(saveWatchListIpApi, data)
        if(response.message === MessageResp.WATCH_LIST_IP_SAVE) {
            const response: WatchListIp[] = yield call(getWatchListIpApi)
            yield put(setWatchListIps(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}

export function* getWatchListBinSaga(action: WatchListActions) {
    try {
        const response: WatchListBin[] = yield call(getWatchListBinApi)
        yield put(setWatchListBins(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListBinSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListBinReq
        const response: LoginFirstTimeRes = yield call(saveWatchListBinApi, data)
        if(response.message === MessageResp.WATCH_LIST_BIN_SAVE) {
            const response: WatchListBin[] = yield call(getWatchListBinApi)
            yield put(setWatchListBins(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}

export function* getWatchListExecutiveSaga(action: WatchListActions) {
    try {
        const response: WatchListExecutive[] = yield call(getWatchListExecutiveApi)
        yield put(setWatchListExecutives(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListExecutiveSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListExecutiveReq
        const response: LoginFirstTimeRes = yield call(saveWatchListExecutiveApi, data)
        if(response.message === MessageResp.WATCH_LIST_EXECUTIVE_SAVE) {
            const response: WatchListExecutive[] = yield call(getWatchListExecutiveApi)
            yield put(setWatchListExecutives(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}

export function* getWatchListRansomeSaga(action: WatchListActions) {
    try {
        const response: WatchListGroup[] = yield call(getWatchListRansomeApi)
        yield put(setWatchListRansomes(response))
    } catch (error) {
        throw error;
    }
}

export function* saveWatchListRansomeSaga(action: WatchListActions) {
    try {
        const data = action.payload as WatchListGroupReq
        const response: LoginFirstTimeRes = yield call(saveWatchListRansomeApi, data)
        if(response.message === MessageResp.WATCH_LIST_RANSOME_SAVE) {
            const response: WatchListGroup[] = yield call(getWatchListRansomeApi)
            yield put(setWatchListRansomes(response))
            yield put({type: GET_WATCH_LIST})
        }
    } catch (error) {
        throw error;
    }
}