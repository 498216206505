import { faBell, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss';
import MenuDropDownHeader from '../../Account/common/MenuDropDownHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationBell, REQUEST_CREADENTIALS_LEAK, REQUEST_NOTIFICATIONS } from '../../../core/constants';
import { useSelector } from 'react-redux';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';
import { getNotificationsApi, updateNotificationsApi } from '../../../core/api/notificationApi';
import { getUserInfoRequest } from '../../../core/actions/userActions';
import { getInfosEntrepriseRequest } from '../../../core/actions/entrepriseActions';

function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [notifs, setnotifs] = useState<NotificationBell[] | undefined>()
    
    
    // useEffect(() => {
    //     // Function that dispatches the action and fetches notifications
    //     const fetchAndDispatch = () => {
    //         dispatch({
    //             type: REQUEST_CREADENTIALS_LEAK
    //         });
    //         getNotifs();
    //     };

    //     // Initial call when component mounts
    //     fetchAndDispatch();

    //     // Set up an interval to repeat the process every 10 seconds
    //     const intervalId = setInterval(() => {
    //         fetchAndDispatch();
    //     }, 15000); // 10000 milliseconds = 10 seconds

    //     // Clean up the interval when the component unmounts
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, [dispatch]);


    
    useEffect(() => {
        // dispatch(getUserInfoRequest())
        //     dispatch(getInfosEntrepriseRequest())
    }, [])
    
    
    return (
        <div className={ styles.header }>
            <div style={{display: "flex", alignItems:"center"}}>
            {/* <div style={{cursor:    "pointer", height: 40, width: 40}} className={styles.iconHover}>
           <FontAwesomeIcon icon={faMagnifyingGlass} style={{cursor: "pointer"}}/>
           </div> */}
           
           <div className={styles.notification} onClick={()=>{
               navigate("/notifications")
        }
           }>
            <div style={{cursor: "pointer", height: 40, width: 40}} className={styles.iconHover}>
            <FontAwesomeIcon icon={faBell} />
            </div>
            {
                notifs && notifs.length > 0 && <span className={styles.numberNotifications}>{notifs.length}</span>
            }
           </div>
           </div>
           <MenuDropDownHeader/>
        </div>
    )
}

export default Header
