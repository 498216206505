import { faArrowLeft, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../../../dashboard.module.scss'
import Antivirus from './Antivirus'
import Banking from './Banking'
import Credentials from './Credentials'
import DataLeak from './DataLeak'
import Hardware from './Hardware'
import IdentityDoc from './IdentityDoc'
import MalwarePath from './MalwarePath'
import Software from './Software'
import Header from '../../../common/Header'
import Menu from '../../../common/Menu'
import { CredentialsLeak } from '../../../../../core/constants'
import { getCredentialsLeakUserProfileApi, getEmailApi } from '../../../../../core/api/threatIntelApi'
import moment from 'moment'
import logoExecutive from "../../../../../icons/Executive.svg"
import logoEmployee from "../../../../../icons/Employee.svg"
import logoClient from "../../../../../icons/Client.svg"
import { useIntercom } from '../../../IntercomSetup'
export default function UserProfileCredential() {
    const navigate = useNavigate();
    const [activeCompo, setactiveCompo] = useState('credentials')
    const [credentialLeakDetail, setcredentialLeakDetail] = useState<any | null>()
    const [userProfile, setuserProfile] = useState<any>()
    const [email, setemail] = useState<string | undefined>()
    const getUserProfile = async(userid: number) => {
        const data = await getCredentialsLeakUserProfileApi(userid)
        const dataEmail = await getEmailApi(userid)
        setuserProfile(data)
        setemail(dataEmail)
    }
    console.log(userProfile)
    useEffect(() => {
      const data = window.localStorage.getItem("credentialLeakDetail")
      setcredentialLeakDetail(data ?  JSON.parse(data) : null)
      if(data) {
        getUserProfile(JSON.parse(data).userid as number)
      }
      
    }, [])
    const emailStorageVa = window.localStorage.getItem('email')
      const hmacValue = window.localStorage.getItem('hmac')
      const fullNamed = window.localStorage.getItem("fullName")
      const token = window.localStorage.getItem('jwt');
      const typeEntreprise = localStorage.getItem("type") 
useIntercom('isows87y', {
app_id: 'isows87y',
api_base: 'https://api-iam.intercom.io',
email: emailStorageVa as string,
user_hash: hmacValue as string,
name: fullNamed
})

    const renderSections = () => {
        switch (activeCompo) {
            case 'credentials':
                return <Credentials crendentials={userProfile?.credentialDetail}/>
            case 'banking':
                return <Banking creditsCard={userProfile?.creditsCard}/>
            case 'identityDoc':
                return <IdentityDoc 
                    documents={userProfile?.documents}
                    userProfile={credentialLeakDetail}
                    email={email}
                    />
            case 'hardware':
                return <Hardware user={userProfile?.user?.[0]}/>
            case 'software':
                return <Software sofwares={userProfile?.sofwares}/>
            case 'antivirus':
                return <Antivirus antivirus={userProfile?.antivirus}/>
            case 'malwarePath':
                return <MalwarePath user={userProfile?.user?.[0]}/>
            case 'dataLeak':
                return <DataLeak/>
            default:
                return;
        }
    }
    const dateFormats = [
        'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
        'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
        'DD.MM.YYYY HH:mm:ss',
        'DD.MM.YYYY h:mm:ss',   // Format for '20.06.2024 7:53:01'
      ];
    return (
        <div className={styles.main}>
            <Header/>
            <Menu/>
            <div className={styles.content}>
                <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate(-1)} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <div>
                        <span>Go back / {credentialLeakDetail?.userid}</span>
                        </div>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10,marginTop: 4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span style={{fontSize: 20, fontWeight: "600"}}>{credentialLeakDetail?.username ? credentialLeakDetail?.username :"N/A"}</span>
                    </div>  
                </div>
                    {/* <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <Button style={{width: '120px', height: '40px', gap: 10, fontSize:12, fontWeight:400, backgroundColor: "white", display: 'flex', alignItems: "center"}}>
                        User Profile
                    </Button>
                        </div> */}
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24}}>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Detected on</span>
                    <span style={{color: "white"}}>{moment(credentialLeakDetail?.user?.log_date, dateFormats, true).format("MMMM DD, YYYY")}</span>
                    </div>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Type</span>
                    <div className={styles.credentialUserType} >
                    <img src={
                            credentialLeakDetail?.typeUser === "Executive" ? logoExecutive :
                             credentialLeakDetail?.typeUser === "Employee" ? logoEmployee :
                             logoClient
                        } className={styles.logoProfileHeader} />
                        <span style={{fontSize: 12}}>{credentialLeakDetail?.typeUser}</span>
                    </div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 30}}>
                    <div>
                    <div >
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>This email has appeared in 4 breaches in the last 2 months.</span>
                        
                    </div> 
                    <div style={{display: 'flex', alignItems: 'center', gap: 10,marginTop: 24}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>This password has appeared in a 20 breaches in the last 7 days.</span>
                        
                        
                    </div> 
                    </div>
                    </div>
                    
                    <div style={{backgroundColor: "#141414", width: '420px', padding: 16, borderRadius: 8}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500',width:72}}>Email:</span>
                            <span style={{color: '#cbcbcb', fontSize: 14}}>{email}</span>
                        </div>
                        {/* <div style={{ marginTop: 5, display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px',color: 'white', fontSize: 14, fontWeight: '500'}}>Phone Number:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>(+1) 917-374-1943</p>
                        </div>
                        <div style={{marginTop: 5, display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px', color: 'white', fontSize: 14, fontWeight: '500'}}>Gender:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>Male</p>
                        </div>
                        <div style={{ marginTop: 5,display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px',color: 'white', fontSize: 14, fontWeight: '500'}}>Age:</p>
                            <p style={{color: '#cbcbcb', fontSize: 14}}>45</p>
                        </div> */}
                        <div style={{ marginTop: 24, display: 'flex', alignItems: 'center', gap: 40}}>
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500', width:37}}>Address:</span>
                            <span style={{color: '#cbcbcb', fontSize: 14}}>{userProfile?.user?.location}</span>
                        </div>
                        {/* <div style={{marginTop: 5,display: 'flex', alignItems: 'center', gap: 5}}>
                            <p style={{width:'136px', color: 'white', fontSize: 14, fontWeight: '500'}}>Social Media:</p>
                            <div style={{display: "flex", gap: 5, alignItems: 'center'}}>
                                <img src='/instagram.png' height={14} width={14}/>
                                <img src='/x.png' height={14} width={14}/>
                            </div>
                        </div> */}
                    </div>
                    
                    
                
                </div>
                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px' }} className={styles.firstSectionMenu}>
                        <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('credentials')} className={activeCompo === 'credentials' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Credentials</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('banking')} className={activeCompo === 'banking' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Banking</span>
                        {/* <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('dataLeak')} className={activeCompo === 'dataLeak' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Data Leaks</span> */}
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('identityDoc')} className={activeCompo === 'identityDoc' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Documents</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('hardware')} className={activeCompo === 'hardware' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Hardware</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('software')} className={activeCompo === 'software' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Software</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('antivirus')} className={activeCompo === 'antivirus' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Antivirus</span>
                        <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('malwarePath')} className={activeCompo === 'malwarePath' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Malware Path</span>
                    </div>

                {
                    renderSections()
                }
            </div>
        </div>
    )
}
