import React, { useEffect, useState } from 'react'
import styles from '../account.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faL, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { watchListSelector } from '../../../core/reducers/watchListReducer'
import { GET_WATCH_LIST, GET_WATCH_LIST_ASSETS, WatchListAssets } from '../../../core/constants'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import EmptyStateWatchList from '../../Dashboard/common/EmptyStateWatchList'
import { DeleteWatchListAssetsApi } from '../../../core/api/watchListApi'
import ErrorNotification from '../common/ErrorNotification'
import { CircularProgress } from '@mui/material'
import { userSelector } from '../../../core/reducers/userReducer'

interface props {
  open: boolean;
  setOpen: any;
}

function AssetsWatchList({open, setOpen}: props) {
  const dispatch = useDispatch()
  const { watchListAssets, isLoadingAssets } = useSelector(watchListSelector)
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [notifMessage, setnotifMessage] = useState<string>("")
  const [openNotif, setOpenNotif] = useState(false)
  const { roleUser } = useSelector(userSelector)
    const handleCheckboxChange = (id: any) => {
        setSelectedAssets((prev: any) => {
            if (prev.includes(id)) {
                return prev.filter((item: any) => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };


    const deleteAssets = async () => {
        const { message } = await DeleteWatchListAssetsApi(selectedAssets)
        setnotifMessage(message)
        setOpenNotif(true)
        setTimeout(() => {
          dispatch({type: GET_WATCH_LIST_ASSETS})
          dispatch({type: GET_WATCH_LIST})
        }, 500);
    }

  useEffect(() => {
    document.title = "Assets Watch List"
    dispatch({type: GET_WATCH_LIST_ASSETS})
  }, [])
  
  return (
    <>
      {
        !isLoadingAssets ?
        watchListAssets && watchListAssets?.length > 0 ?
        <div className={styles.awaitingApproval}>
                        
                        <div className={styles.searchAndRemove}>
                        <div className={styles.searchAndIcon}>
                            <input className={styles.search} name='search' placeholder='Search'/>
                            <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                        </div>
                        {  roleUser?.companySettings && <div className={styles.removeSelected} onClick={deleteAssets}>
                            <p className={styles.avatarPersonalAccountCard_text}>Remove selected</p>
                            <FontAwesomeIcon icon={faTrash} color='white' style={{fontSize: 12}} />
                        </div>}
                    </div>
                    <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}> </th>
                          <th style={{paddingLeft: 8}}>Vendor</th>
                          <th style={{paddingLeft: 8}}>Product</th>
                          <th style={{paddingLeft: 8}}>Version</th>
                          <th style={{paddingLeft: 8}}>Date Added</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          watchListAssets?.map((asset: WatchListAssets) => 
                            <tr className={styles.tableActivityRowWatchListAssets}>
                            <td style={{width: '32px'}} className={styles.tableCell}>
                            <input
                                type="checkbox"
                                className={styles.customCheckbox}
                                value={asset.id}
                                onChange={() => handleCheckboxChange(asset.id)}
                                checked={selectedAssets.includes(asset?.id)}
                            />
                            </td>
                              <td style={{width: '160px'}} className={styles.tableCell}>{asset?.vendor}</td>
                              <td style={{width: '410px'}} className={styles.tableCell}>{asset?.product}</td>
                              <td style={{width: '160px'}} className={styles.tableCell}>{asset?.version}</td>
                              <td style={{width: '190px', display: 'flex', alignItems: 'center', gap: 15}} className={styles.tableCell}>
                                <FontAwesomeIcon icon={faCalendar}/>
                                <p>{
                                    moment(asset.assetsStartDate).format('MMM DD, YYYY')
                                  }</p>
                              </td>
                            </tr>
                          )
                        }
                        
                        </tbody>
                    </table>

                    <ErrorNotification 
                    severity="success"
                    open = {openNotif}
                    message={notifMessage}
                    handleClose={() => setOpenNotif(false)}
                    />
    </div> 
    :
    <EmptyStateWatchList 
    open={open} 
    setOpen={setOpen} 
    title='Assets Watch list is empty'
    addButtonText='Asset'
    text='Add an asset to start monitoring for cybersecurity risks'
    />
    :
    <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
      }
    </>
  )
}

export default AssetsWatchList