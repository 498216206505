import React, { useEffect, useState } from 'react'
import styles from  "../../dashboard.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { maskString } from '../../ThreatIntelSections/CredentialsLeaks'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../core/selectors/threatIntelSelector'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCredentialLeakDetail } from '../../../../core/actions/threatIntelActions'
import { CredentialsLeak, NotificationBell } from '../../../../core/constants'
import { getNotificationsApi, updateNotificationsApi } from '../../../../core/api/notificationApi'
export default function Unread() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {credentialsLeak} = useSelector(threatIntelSelector)
    const [allNotifs, setallNotifs] = useState<NotificationBell[] | undefined>()
    const data = credentialsLeak?.data?.filter( item => {
        if(allNotifs && allNotifs?.filter(da => da.userid === item.userid).length>0){
            return item
        }
    })
    const getAllNotifs = async () => {
        const data = await getNotificationsApi()
        setallNotifs(data)
    }
    useEffect(() => {
        getAllNotifs()
    }, [])
    const setCredentialDetail = (item: any) => {
        item.password = maskString(item.password,2,0) ? maskString(item.password,2,0) as string : "********"
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(item))
        navigate("/threatIntel/credentialLeaksDetail")
      }

      const handleUpdateState = (userid:number) => {
        updateNotificationsApi([userid])
    }

  return (
    <table className={styles.table} style={{ marginTop: 25 }}>
                 <tbody>
                          {
                            data?.map(item => 
                              <tr className={styles.tableActivityRowWatchListAssets} onClick={()=>{
                                handleUpdateState(item?.userid)
                                
                                setTimeout(() => {
                                    setCredentialDetail(item) 
                                }, 1500);
                                }}>
                            <td style={{width: '491px'}} className={styles.tableCell}>
                                {item?.user?.username}
                            </td>   
                              
                            <td style={{width: '160px'}} className={styles.tableCell} >
                              {maskString(item?.password, 2, 0)}
                            </td>
                            <td style={{width: "312px"}} className={styles.tableCell}>
                                {item?.url}
                            </td> 
                            <td className={styles.tableCell} style={{width: "120px"}}>
                            <span className={styles.scoreSeverity} style={{width: '22px'}}>{item?.riskscore}</span>
                            </td>
                            <td className={styles.tableCell} style={{width: "190px"}}>
                                <div  style={{ display: 'flex', alignItems: "center", gap: 10, width: "120px" }}>
                                    <FontAwesomeIcon icon={faCalendar} size='1x' />
                            <p style={{ fontSize: 14 }}>April 19, 2024</p>
                                </div>
                            
                        </td>
                        
                          </tr>
                            )
                          }
                        </tbody>
                {/* <tbody>
                        <tr className={styles.tableActivityRowRansomWatchList} onClick={() => {
                            
                            //navigate("/vulnerabilities/vulnerabilityDetail")
                        }}>
<td className={styles.tableCell}>
                                <p style={{ fontSize: '14' }}>CVE-2023-46953 added. SQL Injection vulnerability in ABO.CMS v.5.9.3, allows remote attackers to execute arbitrary code via the d parameter in the Documents module.</p>
                                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                    <FontAwesomeIcon icon={faCalendar} />
                                    <p>26 minutes ago</p>
                                </div>
                            </td>
                            <td className={styles.tableCell}>
                                <p className={styles.scoreSeverity} style={{width: '22px'}}>9.8</p>
                            </td>
                        </tr>
                    
                </tbody> */}
            </table>
  )
}
