import { faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import styles from '../dashboard.module.scss'
import { Rating, Skeleton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import { Places, REQUEST_PLACES } from '../../../core/constants'
import { setPlaceDetailAction } from '../../../core/actions/brandIntelActions'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import EmptyState from '../common/EmptyState'
import EmptyStatePlaces from '../common/EmptyStatePlaces'

const StyledRating = styled(Rating)({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    stroke: '#545454', // Border color for empty stars
    strokeWidth: 0.5, // Border width
  },
})

type SortOrder = 'asc' | 'desc'

const PlacesSection: React.FC = () => {
  const dispatch = useDispatch()
  const { isLoading, places } = useSelector(brandIntelSelector)
  const [sortBy, setSortBy] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc')
  const [search, setSearch] = useState<string>("")
  const navigate = useNavigate()

  useEffect(() => {
    dispatch({ type: REQUEST_PLACES })
    document.title = "Places"
    ReactGA.send({ hitType: "Brand Intel - Places", page: window.location.pathname })
  }, [dispatch])

  const setPlaceDetailData = (data: Places) => {
    navigate("/brandIntel/placeDetail")
    dispatch(setPlaceDetailAction(data))
  }

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }

  const filteredPlaces = places?.filter((place) =>
    place.name.toLowerCase().includes(search.toLowerCase())
  )

  const sortedPlaces = filteredPlaces?.sort((a, b) => {
    let comparison = 0
    if (sortBy === 'name') {
      comparison = a.name.localeCompare(b.name)
    } else if (sortBy === 'type') {
      comparison = a.type[0].localeCompare(b.type[0])
    } else if (sortBy === 'city') {
      comparison = a.city.localeCompare(b.city)
    } else if (sortBy === 'country') {
      comparison = a.country.localeCompare(b.country)
    } else if (sortBy === 'reviews') {
      comparison = a.reviews - b.reviews
    }

    return sortOrder === 'asc' ? comparison : -comparison
  })

  return (
    <div style={{ marginTop: 20 }}>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
        <div className={styles.searchAndIcon}>
          <input 
            className={styles.search} 
            name='search' 
            placeholder='Search' 
            value={search} 
            onChange={(e) => setSearch(e.target.value)} 
          />
          <FontAwesomeIcon 
            className={styles.searchIcon} 
            color='#333333' 
            icon={faSearch} 
            size='1x' 
          />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width={210} height={118} />
        ) : sortedPlaces && sortedPlaces.length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableHead}>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('name')}>
                  <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                    <span>Name </span>
                    <FontAwesomeIcon 
                      icon={sortBy === 'name' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                      size="1x" 
                      height={12} 
                      width={11} 
                    />
                  </p>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('type')}>
                  <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                    <span>Type </span>
                    <FontAwesomeIcon 
                      icon={sortBy === 'type' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                      size="1x" 
                      height={12} 
                      width={11} 
                    />
                  </p>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('city')}>
                  <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                    <span>City </span>
                    <FontAwesomeIcon 
                      icon={sortBy === 'city' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                      size="1x" 
                      height={12} 
                      width={11} 
                    />
                  </p>
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('country')}>
                  <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                    <span>Country </span>
                    <FontAwesomeIcon 
                      icon={sortBy === 'country' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                      size="1x" 
                      height={12} 
                      width={11} 
                    />
                  </p>
                </th>
                <th style={{ paddingLeft: 8 }}>
                  Rating
                </th>
                <th style={{ paddingLeft: 8 }} onClick={() => handleSort('reviews')}>
                  <p style={{ fontSize: 14, color: "white", fontWeight: 500 }}>
                    <span>Reviews </span>
                    <FontAwesomeIcon 
                      icon={sortBy === 'reviews' && sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                      size="1x" 
                      height={12} 
                      width={11} 
                    />
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedPlaces.map((item: Places) => (
                <tr 
                  className={styles.tableActivityRowDomainBrand} 
                  onClick={() => setPlaceDetailData(item)}
                >
                  <td className={styles.tableCell}>
                    <p style={{ fontSize: 14 }}>{item.name}</p>
                  </td>
                  <td className={styles.tableCell}>
                    <p>{item.type[0]}</p>
                  </td>
                  <td className={styles.tableCell}>
                    <p style={{ fontSize: 14 }}>{item.city}</p>
                  </td>
                  <td className={styles.tableCell}>
                    <p style={{ fontSize: 14 }}>{item.country}</p>
                  </td>
                  <td className={styles.tableCell}>
                    <StyledRating name="read-only" value={item.rating} readOnly size="small" />
                  </td>
                  <td className={styles.tableCell}>
                    <p style={{ fontSize: 14 }}>{item.reviews}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyStatePlaces/>
        )}
      </div>
    </div>
  )
}

export default PlacesSection
