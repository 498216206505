import axios from "axios";
import { Countries, GroupsRansome, Negociations, RansomeOverview, Victims } from "../constants";
import { env } from "../../environement/env";

export const getGroupsApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/ransome/groups?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as GroupsRansome[];
    } catch (error) {
        
    }
}

export const getVictimsApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/ransome/groups/victims?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Victims[];
    } catch (error) {
        
    }
}


export const getVictimsByCountryApi = async (sortByDate: string) => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/ransome/groups/victimsByCountry?sortByDate=${sortByDate}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data as Countries[]
    } catch (error) {
        
    }
}

export const getTelegramsApi = async () => {
    try {
        const { data } = await axios.get(`${env.apiUrl}/ransome/telegrams`, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data
    } catch (error) {
        
    }
}


export async function getAllNegociationApi() {
  try {
    const { data } = await axios.get(`${env.apiUrl}/ransome/negociations`, {
        headers: {
            'Content-Type': 'application/json',
            'x-requested-by': 'ci-app',
            'Origin': false
        }
    })
    return data as Negociations[]
  } catch (error) {
    
  }
}

export async function getRansomeOverviexApi(sortByDate: string) {
    try {
      const { data } = await axios.post(`${env.apiUrl}/ransome/overview?sortByDate=${sortByDate}`,
        {sortByDate}, {
          headers: {
              'Content-Type': 'application/json',
              'x-requested-by': 'ci-app',
              'Origin': false
          }
      })
      return data as RansomeOverview
    } catch (error) {
      
    }
  }


  export const getCountryDetailApi = async (code: string) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/ransome/groups/countryDetail`, {
            code
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data
    } catch (error) {
        
    }
}

export const getGroupDescAPI= async (group_name: string) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/ransome/groups/groupDesc`, {
            group_name
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data
    } catch (error) {
        
    }
}

export const getGroupUrlsAPI= async (group_name: string) => {
    try {
        const { data } = await axios.post(`${env.apiUrl}/ransome/groups/groupUrls`, {
            group_name
        }, {
            headers: {
                'Content-Type': 'application/json',
                'x-requested-by': 'ci-app',
                'Origin': false
            }
        })
        return data
    } catch (error) {
        
    }
}