import React, { useEffect, useState } from 'react'
import styles from "../dashboard.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronDown, faListDots } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material'
import InformationsPlaceDetail from './InformationsPlaceDetail'
import ReviewsPlaceDetail from './ReviewsPlaceDetail'
import { useSelector } from 'react-redux'
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector'
import ReactGA from 'react-ga4';
import Header from '../common/Header'
import Menu from '../common/Menu'
import { useNavigate } from 'react-router-dom'
interface props {
    setPlaceDetail: any
}

function PlaceDetail() {
    const [activeCompo, setactiveCompo] = useState('information')
    const { placeDetail } = useSelector(brandIntelSelector)
    const navigate = useNavigate()
    const renderSection = () => {
        switch (activeCompo) {
            case 'information':
                return <InformationsPlaceDetail/>
            case 'review':
                return <ReviewsPlaceDetail/>
            default:
                return;
        }
    }

    useEffect(() => {
        document.title = "Places - Place Detail"
        ReactGA.send({ hitType: "Places - Place Detail", page: window.location.pathname });
    }, [])
    

  return (
    <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                    <div onClick={()=>{
                            navigate(-1)
                        }} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Go back</span>
                        </div>
                    <div style={{marginTop:5,display:'flex', alignItems: 'center', gap: 10}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span>{placeDetail?.name}</span>
                    </div>
                </div>
                   
                </div>

                <div style={{marginTop: 25, fontSize: 14}}>
                    <span style={{color: "#afafaf"}}>Last updated </span>
                    <span style={{color: "white"}}>April 26, 2024 </span>
                   
                </div>

                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px' }} className={styles.firstSectionMenu}>
                    <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('information')} className={activeCompo === 'information' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Information</p>
                    <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('review')} className={activeCompo === 'review' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Reviews</p>
                </div>

                {renderSection()}
    </div>
    </div>
  )
}

export default PlaceDetail