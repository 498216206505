import React, { useEffect } from 'react'
import styles from '../../dashboard.module.scss'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
function Victims() {
    const dispatch = useDispatch()
    const { victims } = useSelector(ransomeSelector)
    
    
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            <tr className={styles.tableActivityRowWatchList}>
              <td style={{width: '300px', padding: '12px 16px', color: "#e9bd08"}} className={styles.tableCell}>
              Cambian Group
              </td>
              <td style={{width: '596px', padding: '12px 16px'}} className={styles.tableCell}>
              Includes data from Care Tech Holdings PLC (parent holding company, caretech-uk.com) & ByTheBridge.co.uk & Cambian Group. A billion dollar mega-corporation and British orphans, what could go wrong?
              </td>
              <td style={{width: '190px', padding: '12px 16px'}} className={styles.tableCell}>
              November 02, 2023
              </td>
              </tr>
            
          
          </tbody>
      </table>
    )
}

export default Victims
