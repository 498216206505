import { utils, writeFile } from 'xlsx';

export const exportToExcel = (data: any[], fileName: string) => {
    // Create a new workbook and add the table data to a worksheet
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    // Export the workbook to an Excel file
    writeFile(wb, `${fileName}.xlsx`);
};