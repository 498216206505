import { firstTimeNewPassword, isLoginFirstTime, IS_FIRST_TIME_LOGIN, LoginReq, LoginRes, LOGIN_REQUEST, LOGIN_SUCCESS, SET_FIRST_TIME_NEW_PASSWORD_REQUEST, SEND_OTP, LoginFirstTimeRes, VERIFY_OTP, OtpResult, Otp, OTP_REQUEST, OtpRequest, VERIFY_OTP_REQUEST, ValidationError, AuthState, SET_NOTIFICATION, NotifMessage, RESET_AUTH_STATE, OtpSent, RESET_PASSWORD_REQUEST, ResetPasswordNotif, RESET_PASSWORD_SUCCESS, RecoverAccountResultRequest, UPDATE_RECOVER_PASSWORD_REQUEST, RecoverAccountResultSuccess, UPDATE_RECOVER_PASSWORD_SUCCESS, LOGOUT, SET_PICTURE_PROFILE, SET_ENTREPRISE_PICTURE_PROFILE, SET_JWT } from "../constants";

export const LoginRequestAction = (data: LoginReq) => ({
    type: LOGIN_REQUEST,
    payload: data
})

export const LoginSuccessAction = (data: LoginRes) => ({
    type: LOGIN_SUCCESS,
    payload: data
})

export const isFirstTimeLoginAction = (data: isLoginFirstTime) => ({
    type: IS_FIRST_TIME_LOGIN,
    payload: data
})

export const setNewPasswordRequestAction = (data: firstTimeNewPassword) => ({
    type: SET_FIRST_TIME_NEW_PASSWORD_REQUEST,
    payload: data
})


export const sendOtpRequestAction = (data: OtpRequest) => ({
    type: OTP_REQUEST,
    payload: data
})

export const sendOtpAction = (data: OtpSent) => ({
    type: SEND_OTP,
    payload: data
})

export const verifyOtpRequestAction = (data: Otp) => ({
    type: VERIFY_OTP_REQUEST,
    payload: data
})

export const verifyOtpAction = (data: OtpResult) => ({
    type: VERIFY_OTP,
    payload: data
})

export const setNotificationAction = (data: NotifMessage) => ({
    type: SET_NOTIFICATION,
    payload: data
})

export const resetAuthStateAction = () => ({
    type: RESET_AUTH_STATE,
});

export const resePasswordRequest = (data: OtpRequest)=> ({
    type: RESET_PASSWORD_REQUEST,
    payload: data
})

export const resetPasswordSuccess = (data: ResetPasswordNotif) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: data
})

export const updatePasswordRecoverRequest = (data: RecoverAccountResultRequest) => ({
    type: UPDATE_RECOVER_PASSWORD_REQUEST,
    payload: data
})

export const updatePasswordRecoverSuccess = (data: RecoverAccountResultSuccess) => ({
    type: UPDATE_RECOVER_PASSWORD_SUCCESS,
    payload: data
})

export const logoutAction = () => ({
    type: LOGOUT
})

export const setPictureProfileAction = (data: string) => ({
    type: SET_ENTREPRISE_PICTURE_PROFILE,
    payload: data
})

export const setJwtAction = (data: string) => ({
    type: SET_JWT,
    payload: data
})
