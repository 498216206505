import { faCalendar, faChevronDown, faDownload, faEye, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector'
import { CredentialsLeak, REQUEST_CREADENTIALS_LEAK } from '../../../core/constants'
import { setCredentialLeakDetail } from '../../../core/actions/threatIntelActions'
import moment from 'moment'
import ReactGA from 'react-ga4';
import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material'
// import Pagination from '../common/Pagination'
import Pagination from '@mui/material/Pagination';
import { useTheme } from '@mui/material/styles';
import Loader from "../../../icons/Loader.svg"
import EmptyState from '../common/EmptyState'
import { PageSort } from '../../../core/api/threatIntelApi'
export function maskString(str: string, keepStart: number = 0, keepEnd: number = 0): string | null {
  // Ensure parameters are within bounds of the string length
  if(str) {
    keepStart = Math.min(keepStart, str?.length);
    keepEnd = Math.min(keepEnd, str?.length - keepStart);
  
    // Divide the string into parts
    const start = str.substring(0, keepStart);
    const end = str.substring(str.length - keepEnd);
    const middle = str.substring(keepStart, str.length - keepEnd);
  
    // Replace middle characters with asterisks
    const maskedMiddle = middle.replace(/./g, '*');
  
    return start + maskedMiddle + end;
  }
  return null
}

export const renderColors = (riskScore: number) => {
  if(riskScore === 0.0)
    return {
      textColor: "#757575",
      backgroundColor: "#e6e6e6",
      borderColor: "#757575"
    }
  else if (riskScore >= 0.1  && riskScore <= 3.9)
    return {
      textColor: "#20b444",
      backgroundColor: "#ecf9ef",
      borderColor: "#20b444"
    }
  else if (riskScore >= 4.0  && riskScore <= 6.9)
    return {
        textColor: "#f5c500",
        backgroundColor: "#fff7d5",
        borderColor: "#f5c500"
      }
    else if (riskScore >= 7.0  && riskScore <= 8.9)
      return {
            textColor: "#fc9418",
            backgroundColor: "#feead1",
            borderColor: "#fc9418"
          }
    else
      return {
      textColor: "#e11900",
      backgroundColor: "#fdede8",
      borderColor: "#e11900"
    }
}

export default function CredentialsLeaks() {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [recent, setRecent] = useState('all')
  const [severity, setSeverity] = useState("critical")
  const [sortBy, setSortBy] = useState('severity')
  const { credentialsLeak, totalRecordsCredentials,isLoadingCredentials } = useSelector(threatIntelSelector)
  const [visiblePasswords, setVisiblePasswords] = useState<any>({});
  const [page, setPage] = useState(1)
  const [search, setsearch] = useState("")
  const togglePasswordVisibility = (id: any) => {
    setVisiblePasswords((prev:any) => ({
      ...prev,
      //@ts-ignore
      [id]: !prev[id]
    }));
  };

  useEffect(() => {
    
    document.title = "Credentials"
    ReactGA.send({ hitType: "Threat Intel - Credentials", page: window.location.pathname });
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    setRecent(event.target.value as string);
  }

  const handleChangeSeverity = (event: SelectChangeEvent) => {
    setSeverity(event.target.value as string);
  }

  const handleChangeSortBY = (event: SelectChangeEvent) => {
    setSortBy(event.target.value as string);
  }

  const setCredentialDetail = (item: any) => {
    const maskedPassword = maskString(item.password, 2, 0) ? maskString(item.password, 2, 0) : "******";
  const finalRiskScore = getFinalRiskScore(item.url, item.username) + (item.riskscore * 2);
  const colors = renderColors(Number(item?.total_risk_score));
  
  const itemForDisplay = {
    ...item,
    textColor: colors.textColor,
    borderColor: colors.borderColor,
    bgColor: colors.backgroundColor
  };
    window.localStorage.setItem('credentialLeakDetail', JSON.stringify(itemForDisplay))
    navigate("/threatIntel/credentialLeaksDetail")
  }
  

  const handleChangeNextPage = (event: React.ChangeEvent<unknown>, value: number) => {
      
    dispatch({
        type: REQUEST_CREADENTIALS_LEAK,
        payload:value
      })  

      
  };


  const getScoreSourceWeight = (url: string) => {
    const lowerUrl = url.toLowerCase();

    // High risk URLs
    const highRisk = [".gov", ".cisco", ".arcsolutions", "kasperskypartners", ".forcepoint", ".duosecurity", ".huawei",
                      ".awspartner", ".azure", ".tenable", ".bitdefender", ".k-asap", ".thalesgroup", ".gemalto",
                      ".forcepoint", ".gatewatcher", ".webex", ".local", ".wallix", ".salesforce", ".vmware",
                      ".fortinet", ".kaspersky", ".oracle.com", ".paloaltonetworks", ".opentext", ".mcafee",
                      ".teamviewer", ".checkpoint", ".f5", ".infoblox", "phpmyadmin", "admin", "ftp", "srvftp",
                      "proxy", "vm", "cloud", "sso-", "sso.", "administrator", "backend", "wp-admin", "erm.",
                      "backend.", "admin", "token", "config", "secret", "private", "oauth", "auth"];
    if (highRisk.some(part => lowerUrl.includes(part))) {
        return 1.5 * 1;
    }

    // Medium risk URLs
    const mediumRisk = ["settings", "api", "login", "rest", "signin", "debug.", "test"];
    if (mediumRisk.some(part => lowerUrl.includes(part))) {
        return 1.5 * 0.7;
    }

    // Lower risk URLs
    const lowerRisk = ["checkout", "payment", "ajax", "backup", "archive", "temp", "cache", "log", "logs", "debug", "trace"];
    if (lowerRisk.some(part => lowerUrl.includes(part))) {
        return 1.5 * 0.4;
    }

    // Minimal risk URLs
    const minimalRisk = ["stats", "statistics"];
    if (minimalRisk.some(part => lowerUrl.includes(part))) {
        return 1.5 * 0.1;
    }
    return 0
}




const getScoreEmailByWeight = (email: string) => {
  const lowerEmail = email.toLowerCase();

  const commonEmailProviders = ["@gmail", "@yahoo", "@aol", "@live", "@outlook", "@proton", "@icloud"];
  if (commonEmailProviders.some(provider => lowerEmail.includes(provider))) {
      return 1 * 0.5;
  }
  // Highest risk email domain
  else if (lowerEmail.includes("@gov")) {
      return 1 * 1;
  }

  // High risk email domain
  else {
      return 1 * 0.9;
  }
}
const getFinalRiskScore = (source: string, email: string) =>{
  return getScoreSourceWeight(source) + getScoreEmailByWeight(email)

}

const handleChangePage = (
  event: React.ChangeEvent<unknown>, value: number
) => {
  const dataCred: PageSort = {
    page:value,
    sortBy: search
}
  setPage(value)
  dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
};

const handleSearch = (
) => {
 if(search!==""){
  const dataCred: PageSort = {
    page:page,
    sortBy: search
}
  dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
 }else {
  const dataCred: PageSort = {
    page:page,
    sortBy: null
}
  dispatch({ type: REQUEST_CREADENTIALS_LEAK, payload: dataCred })
 }
};
const dateFormats = [
  'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
  'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
  'DD.MM.YYYY HH:mm:ss',
  'DD.MM.YYYY h:mm:ss',   // Format for '20.06.2024 7:53:01'
];
// console.log(moment('3/9/2022 8:54:03 AM', 'M/D/YYYY h:mm:ss A', true).format('MMMM D, YYYY'))
    return (
        <div style={{marginTop: 20}}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
            <div className={styles.searchAndIcon}>
                <input className={styles.search} name='search' value={search} placeholder='Search' onChange={(e)=>setsearch(e.target.value)}/>
                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} onClick={handleSearch}/>
            </div>
            {/* <div style={{display: 'flex', alignItems: "center", gap: 10}}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={recent}
                onChange={handleChange}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7Days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonth'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>
            </Select>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={severity}
                onChange={handleChangeSeverity}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'critical'}>Critical</MenuItem>
                        <MenuItem value={'high'}>High</MenuItem>
                        <MenuItem value={'medium'}>Medium</MenuItem>
                        <MenuItem value={'low'}>Low</MenuItem>
            </Select>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sortBy}
                onChange={handleChangeSortBY}
                style={{fontSize: 14, fontWeight: '500'}}
            >
                <MenuItem value={'severity'}>Severity</MenuItem>
                        <MenuItem value={'date'}>Date</MenuItem>
                        <MenuItem value={'id'}>ID</MenuItem>
            </Select>
        </div> */}

<div className={styles.removeSelected}>
                                <p className={styles.avatarPersonalAccountCard_text}>Export List</p>
                                <FontAwesomeIcon icon={faDownload} color='white' style={{fontSize: 14}} />
                            </div>

            
        </div>
        {
          !isLoadingCredentials ?
          credentialsLeak && credentialsLeak.data.length !==0 ? 
          <div style={{width: "100%"}}>
        <table className={styles.table}>
                        <tr className={styles.tableHead} >
                          <th style={{paddingLeft: 8}}>
                              <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                    <span>Username </span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                                </p>
                        </th>
                          <th style={{paddingLeft: 8}}>
                            <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                    <span>Password </span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                                </p>
                          </th>
                          <th style={{paddingLeft: 8}}>
                          <p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                    <span>Source </span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                                </p>
                          </th>
                          <th style={{paddingLeft: 8}}>
                          < p style={{fontSize: 14, color: "white", fontWeight: 500}}>
                                    <span>Severity </span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                                </p>
                          </th>
                          <th style={{paddingLeft: 8, display:"flex", justifyContent:"end", paddingRight:13}}>
                            <p style={{fontSize: 14, color: "white", fontWeight: 500, width:150}}>
                                    <span>Detect on </span>
                                    <FontAwesomeIcon icon={faChevronDown} size="1x" height={8} width={7}/>
                                </p>
                          </th>
                        </tr>
                          {
                            credentialsLeak?.data?.map(item => 
                              <tr className={styles.tableActivityRowWatchListAssets} onClick={()=>setCredentialDetail(item)}>
                            <td  className={styles.tableCell} >
                                {item?.username ? item?.username  : "N/A"}
                            </td>   
                              
                            <td className={styles.tableCell}>
                            {item.password ? item.password.replace(/./g, '*') : "N/A"}
                                  </td>
                            <td  className={styles.tableCell}>
                                {item?.url?.includes('android')?item?.url?.split('@')[1]:item?.url?.substring(0,43)}
                            </td> 
                            <td className={styles.tableCell}>
                            <span className={styles.scoreSeverity} style={{width: '22px',
                            color: renderColors(Number(item?.total_risk_score)).textColor,
                            border: `1px solid ${renderColors(Number(item?.total_risk_score)).borderColor}`,
                            backgroundColor: renderColors(Number(item?.total_risk_score)).backgroundColor
                          }}>{
                              Number(item?.total_risk_score).toFixed(1)
                            }</span>
                            </td>
                            <td className={styles.tableCell} style={{display:"flex", justifyContent:"end"}}>
                               <div style={{}}>
                               {
                                  item?.user?.log_date ?
                                  <div  style={{ display: 'flex', alignItems: "center", gap: 10, width: '160px', justifyContent:"start" }}>
                                    <FontAwesomeIcon icon={faCalendar} size='1x' />
                            <p style={{ fontSize: 14 }}>{item.user.log_date &&  moment(item.user.log_date, dateFormats, true).format('MMMM DD, YYYY')}</p>
                                </div>:
                                <div style={{ display: 'flex', alignItems: "center", gap: 10, width: '160px', justifyContent:"start"}}>
                                  <p>N/A</p>
                                </div>
                                }
                               </div>
                            
                        </td>
                        
                          </tr>
                            )
                          }
                    </table>
                    <div style={{width: '100%', display: "flex", justifyContent:"center", marginTop:5}}>
          {/* <Pagination 
        
          onChange={handleChangeNextPage}
          page={localStorage.getItem('currentPage')?parseInt(JSON.parse(localStorage.getItem('currentPage')||"1")):1}
          count={credentialsLeak?.pagination?.totalRecords  }
           shape="rounded"
           sx={{
            "& .MuiPaginationItem-root": {
              color: '#333333',  // Sets color for all numbers
          },
          "& .Mui-selected": {
              backgroundColor: '#1f1f1f',  // Sets background for the current page
              color: 'white',  // Optional: Change text color for the selected item
          },
          "& .MuiPaginationItem-root:hover": {
              backgroundColor: theme.palette.action.hover,  // Optional: Change on hover
          }
           }}
           /> */}
          </div>
          <Pagination
                showFirstButton
                showLastButton
                shape="rounded"
                page={Number(credentialsLeak?.pagination?.currentPage)}
                count={credentialsLeak?.pagination?.totalPages}
                onChange={handleChangePage}
                sx={{
                 '& .MuiPagination-ul': {
                    justifyContent: "center",
                    display: 'flex'
                },
                  '& .Mui-selected': {
                    color: '#ffffff', // Text color for active page
                    backgroundColor: '#1f1f1f', // Background color for active page
                    opacity: 0.3
                  },
                  '& .MuiPaginationItem-root': {
                    color: '#ffffff', // Text color for inactive pages
                  },
                }}
            />
                    </div>
                    :
                    <EmptyState/>
                    :
                    <div className={styles.loaderVul}>
                     <CircularProgress/>
                     </div>
        }
         

          
          </div>
    )
}
