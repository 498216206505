import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import styles from '../dashboard.module.scss';
import { CircularProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ReactGA from 'react-ga4';
import { getAllVulnerabilitiesAPI } from '../../../core/api/vulnerabilityApi';
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer';
import { GET_ALL_VULNERABILITIES, SET_VUL_DETAIL, SortVul } from '../../../core/constants';

function ListVulnerabilities() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { vulnerabilies, isLoadingVul } = useSelector(vulnerabilitiesSelector);
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('ASC');
    const [all, setAll] = useState("all");
    const [severity, setSeverity] = useState("all");
    const [sortBy, setSortBy] = useState("all");
    const [search, setsearch] = useState("");
    const [isSortingById, setIsSortingById] = useState(false);

    const handleChangeAll = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: event.target.value as string,
            sortBySeverity: severity,
            sortById: sortBy
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setAll(event.target.value as string);
    };

    const handleChangeSeverity = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: all,
            sortBySeverity: event.target.value as string,
            sortById: sortBy
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setSeverity(event.target.value as string);
    };

    const handleChangeSortBy = (event: SelectChangeEvent) => {
        const dataSort: SortVul = {
            sortByDate: all,
            sortBySeverity: severity,
            sortById: event.target.value as string
        };
        dispatch({
            type: GET_ALL_VULNERABILITIES,
            payload: dataSort
        });
        setSortBy(event.target.value as string);
    };

    const renderColors = (riskScore: number) => {
        if (riskScore === 0.0) return { textColor: "#757575", backgroundColor: "#e6e6e6", borderColor: "#757575" };
        if (riskScore >= 0.1 && riskScore <= 3.9) return { textColor: "#20b444", backgroundColor: "#ecf9ef", borderColor: "#20b444" };
        if (riskScore >= 4.0 && riskScore <= 6.9) return { textColor: "#f5c500", backgroundColor: "#fff7d5", borderColor: "#f5c500" };
        if (riskScore >= 7.0 && riskScore <= 8.9) return { textColor: "#fc9418", backgroundColor: "#feead1", borderColor: "#fc9418" };
        return { textColor: "#e11900", backgroundColor: "#fdede8", borderColor: "#e11900" };
    };

    const handleSort = () => {
        setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC');
        setIsSortingById(!isSortingById);
    };

    useEffect(() => {
        document.title = "Vulnerabilities";
        ReactGA.send({ hitType: "Vulnerabilities - List Vulnerabilities", page: window.location.pathname });
    }, []);

    let searchResults = search === "" ? vulnerabilies?.cves : vulnerabilies?.cves?.filter(item => item.cve_id.toLowerCase().includes(search.toLowerCase()));

    if (searchResults) {
        searchResults = searchResults.sort((a, b) => {
            if (sortOrder === 'ASC') {
                return a.cve_id.localeCompare(b.cve_id);
            } else {
                return b.cve_id.localeCompare(a.cve_id);
            }
        });
    }

    return (
        <div>
            <div className={styles.headerListVul} style={{ marginTop: 20, height: 45 }}>
                <div className={styles.searchAndIcon}>
                    <input value={search} className={styles.search} name="search" placeholder="Search" onChange={(e) => setsearch(e.target.value)} />
                    <FontAwesomeIcon className={styles.searchIcon} color="#333333" icon={faSearch} size="1x" />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, color: "white", fontSize: "14px" }}>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={all} onChange={handleChangeAll} style={{ fontSize: 14, fontWeight: 500 }}>
                        <MenuItem value={'all'} disabled={all === 'all'}>All</MenuItem>
                        <MenuItem value={'last7days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastmonth'}>Last month</MenuItem>
                        <MenuItem value={'last3months'}>Last 3 months</MenuItem>
                    </Select>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={severity} onChange={handleChangeSeverity} style={{ fontSize: 14, fontWeight: 500 }}>
                        <MenuItem value={'all'} disabled={severity === 'severity'}>Severity</MenuItem>
                        <MenuItem value={'CRITICAL'}>Critical</MenuItem>
                        <MenuItem value={'HIGH'}>High</MenuItem>
                        <MenuItem value={'MEDIUM'}>Medium</MenuItem>
                        <MenuItem value={'LOW'}>Low</MenuItem>
                    </Select>
                </div>
            </div>
            {!isLoadingVul ? (
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.tableHead}>
                            <th style={{ paddingLeft: 8, display: 'flex', alignItems: "center", gap: 5, cursor: "pointer" }} onClick={handleSort}>
                                <p>ID</p>
                                <FontAwesomeIcon icon={isSortingById ? faChevronUp : faChevronDown} style={{ height: 8, transform: sortOrder === 'ASC' ? 'rotate(180deg)' : 'none' }} />
                            </th>
                            <th style={{ paddingLeft: 8 }}>Description</th>
                            <th style={{ paddingLeft: 8, display: 'flex', alignItems: "center", gap: 5 }}>
                                <p>Severity</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults?.map((item) => (
                            <tr className={styles.tableActivityRowWatchList} onClick={() => {
                                dispatch({ type: SET_VUL_DETAIL, payload: item });
                                navigate("/vulnerabilities/vulnerabilityDetail");
                            }}>
                                <td style={{ cursor: 'pointer' }} className={styles.tableCell}>{item.cve_id}</td>
                                <td className={styles.tableCell}>
                                    <p style={{ fontSize: '14' }}>{item?.description?.substring(0, 160)}...</p>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <p>Published: {moment(item?.published_date).format("MMMM D, YYYY - HH:mm")}</p>
                                    </div>
                                </td>
                                <td className={styles.tableCell}>
                                    {item?.base_score ? (
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <span className={styles.scoreSeverity} style={{
                                                width: '22px',
                                                color: renderColors(item?.base_score).textColor,
                                                backgroundColor: renderColors(item?.base_score).backgroundColor,
                                                borderColor: renderColors(item?.base_score).borderColor
                                            }}>
                                                {item?.base_score}
                                            </span>
                                        </div>
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "end" }}>
                                            <span className={styles.scoreUserInt} style={{ width: '22px', padding: '2px 6px', color: "" }}>
                                                N/A
                                            </span>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className={styles.loaderVul}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

export default ListVulnerabilities;

