import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useSelector } from 'react-redux'
import { vulnerabilitiesSelector } from '../../../core/reducers/vulnerabilitiesReducer'
import styles from '../dashboard.module.scss'
import moment from 'moment'
import { VulnerabilityRef } from '../../../core/constants'
export default function Reference() {
    const { vulnerabilies, vulDetail } = useSelector(vulnerabilitiesSelector)
    const references = vulnerabilies?.references?.filter(item => item?.cve_id === vulDetail?.cve_id)
    const openlinkDetail = (link: string) => {
        window.open(link, '_blank');
    };
    return (
        <div>
            <table className={styles.table}style={{marginTop:25}}>
    <tbody>
        
        {
            references?.map((item) => 
                <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '890px', padding: '13px 16px', color: "#e9bd08", cursor:'pointer'}} className={styles.tableCell} onClick={()=>openlinkDetail(item?.url)} >
          {item?.url}
          </td>
              <td style={{width: '918px',padding: '0px 16px'}} className={styles.tableCell}>
                 <div style={{display: 'flex', alignItems: "center", gap: 15, justifyContent: 'flex-end'}}>
                            <p style={{backgroundColor: '#e6e6e6', padding: "4px 8px", borderRadius: "32px", color: 'black'}}>
                                {item?.tags ? item?.tags : "Not Available"}
                            </p>
                 </div>
              </td>
        </tr>
            )
        }
        
      </tbody>
  </table>
        </div>
    )
}
