import { call, put } from "redux-saga/effects";
import { FAILED_HOME_STATS, HomeViewStats, Stats } from "../constants";
import { getHomeViewStatsApi } from "../api/statsApi";
import { successStatsAction } from "../actions/statsActions";

export function* getHomeViewSaga(actions: Stats) {
    try {
        const data: HomeViewStats = yield call(getHomeViewStatsApi, actions.payload as string)
        yield put(successStatsAction(data))
    } catch (error) {
        yield put({
            type: FAILED_HOME_STATS
        })
    }
}