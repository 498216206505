import React, { useEffect, useState } from 'react'
import styles from "../../../dashboard.module.scss"
import { useSelector } from 'react-redux'
import { threatIntelSelector } from '../../../../../core/selectors/threatIntelSelector'
import { detailsSelector } from '../../../../../core/selectors/detailSelector'
import { CredentialsLeak } from '../../../../../core/constants'
export default function UserInfoCredentials() {
  const [credentialLeakDetail, setcredentialLeakDetail] = useState<any | null>()
    useEffect(() => {
      const data = window.localStorage.getItem("credentialLeakDetail")
      setcredentialLeakDetail(data ?  JSON.parse(data) : null)
    }, [])
  return (
    <table className={styles.table}style={{marginTop:16}}>
    <tbody>
        <tr  className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px', padding: '13px 16px'}} className={styles.tableCell}>
          Full Name
          </td>
              <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
              {credentialLeakDetail?.user?.username ? credentialLeakDetail?.user?.username :"N/A"}
              </td>
        </tr>
        {/* <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Gender
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Male
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Age
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          45
              </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Company
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Dunder Mifflin
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Job Title
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Regional Manager
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Industry
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Wholesale
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Phone Number
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          (+1) 917-374-1943
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Language
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          English
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Address
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          1725 Slough Avenue, Scranton, PA, USA
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Work email
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          m.scott@dendenmifflir.com
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          personal email 1
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          m.scott@gmail.com
            </td>
        </tr>
        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          personal email 2
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          m.scott@yahoo.com
            </td>
        </tr>

        <tr className={styles.tableActivityRowRansomWatchList}>
          <td style={{width: '200px',padding: '13px 16px'}} className={styles.tableCell}>
          Job Title
          </td>
          <td style={{width: '918px',padding: '13px 16px'}} className={styles.tableCell}>
          Regional Manager
            </td>
        </tr> */}
        
      </tbody>
  </table>
  )
}
