import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette:{
        primary: {
            main: "#ffff" ,
        },
        secondary: {
            main: "#1a1a1a"
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    marginTop: 15,
                    '& label.Mui-focused': {
                        color: '#ffff', // Color of the label when focused
                      },
                      '& label': {
                        color: '#aaaaaa',
                        fontSize: 11// Ensures label color is always #ffff
                      },
                      '&:before': {
                        borderBottom: `1px solid #545454`,
                      },
                      '& .MuiInputBase-input': {
                        color: '#ffff',
                      },
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                underline: {
                    '&:before': {
                      borderBottom: `1px solid #545454`,
                    },
                    '&:hover:not(.Mui-disabled):before': { // Hover state
                        borderBottom: '1px solid #545454', // Increase thickness and change color on hover
                      },
                    '&:after': { // After pseudo-element represents the underline on focus
                        borderBottom: '1px solid #ffff', // Change focused border-bottom color
                      },
                  },
            }
        },
        MuiButton:{
            styleOverrides: {
                root: {
                  width: '100%',
                  backgroundColor: '#ffff', // Utiliser backgroundColor au lieu de background-color
                  color: '#1a1a1a',
                  overflow: 'hidden',
                  border: 'none',
                  borderRadius: '2px',
                  cursor: 'pointer',
                  marginTop: '30px',
                  fontWeight: '700',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#eeeeee', // Exemple de changement de couleur au survol
                  },
                },
              },
        },
        MuiSelect: {
          styleOverrides: {
            root: {
              backgroundColor: '#1a1a1a',
              height: '2.78vh',
              color: 'white', // Set text color to white if needed
              '& .MuiSvgIcon-root': { // Targeting the dropdown icon
                color: 'white', // Set icon color to white
              },
              border: 'none'
            },
            icon: {
              color: 'white', // Ensure dropdown icon color is white
            },
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
              border: 'none', // Remove border
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove border
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Remove border on hover
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none', // Ensure border is removed even when focused
              },
            },
          },
        },
    }
})