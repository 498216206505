import React, { useEffect, useState } from 'react';
import MenueContent from './common/MenueContent';
import Card from './components/Card';
import RecentThreats from './components/RecentThreats';
import styles from "./dashboard.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faColumns, faTable } from '@fortawesome/free-solid-svg-icons';
import ChartHome from './components/ChartHome';
import CriticalRiskBar from './components/CriticalRiskBar';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from '../../core/reducers/authReducer';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import Overview from './HomeSecrions/Overview';
import MyTasks from './HomeSecrions/MyTasks';
import Insights from './HomeSecrions/Insights';
import { statsSelector } from '../../core/selectors/statsSelector';
import { GET_ALL_VULNERABILITIES, REQUEST_HOME_STATS } from '../../core/constants';
import { useDispatch } from 'react-redux';

      
function Content() {
    const navigate = useNavigate()
    const [grid, setgrid] = useState(false)
    const [activeCompo, setactiveCompo] = useState('overview')
    const [recent, setRecent] = useState('all')
    const dispatch = useDispatch()
    const handleChange = (event: SelectChangeEvent) => {
        dispatch({
            type: REQUEST_HOME_STATS,
            payload: event.target.value as string
        }), 
        setRecent(event.target.value as string);
      };
   

      const renderSections = () => {
          switch (activeCompo) {
              case 'overview':
                  return <Overview/>
            //   case 'mytasks':
            //       return <MyTasks grid={grid}/>
            //   case 'insights':
            //       return <Insights/>
              default:  
                  break;
          }
      }
      
      
    return (
        <div className={styles.content}>
            <div className={styles.menuContent}>
            <div style={{justifyContent:"center", borderRadius: 16  }} className={styles.firstSectionMenu}>
                        <p style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('overview')} className={activeCompo === 'overview' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Overview</p>
                        {/* <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('mytasks')} className={activeCompo === 'mytasks' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>My tasks</p> */}
                        {/* <p style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('insights')} className={activeCompo === 'insights' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Insights</p> */}
                    </div>
                    {
                        activeCompo === 'mytasks' && <FontAwesomeIcon onClick={()=>setgrid(!grid)} icon={!grid ? faColumns : faTable} height={20} width={19} color='white' style={{cursor: "pointer"}}/>
                    }

                    {
                        activeCompo !== 'mytasks' && activeCompo !== 'insights'  && <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={recent}
                        onChange={handleChange}
                        style={{fontSize: 14, fontWeight: 500}}
                    >
                        <MenuItem value={'all'}>All</MenuItem>
                        <MenuItem value={'last7days'}>Last 7 days</MenuItem>
                        <MenuItem value={'lastMonths'}>Last month</MenuItem>
                        <MenuItem value={'last3Months'}>Last 3 months</MenuItem>

                    </Select>
                    }
            
        </div>
            <hr className={styles.divider}/>
            
            {
                renderSections()
            }
        </div>
    )
}

export default Content
