import { faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import styles from '../dashboard.module.scss';
import MenuDropDownHeader from '../../Account/common/MenuDropDownHeader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_NOTIFICATION,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  SUCCESS_NOTIFICATIONS,
  REQUEST_DARK,
  REQUEST_DOMAINE,
  REQUEST_SURFACEWEB,
  GET_ALL_VULNERABILITIES,
  REQUEST_RANSOME,
  REQUEST_CREDITS_CARD,
} from '../../../core/constants';
import { notificationSelector } from '../../../core/selectors/notificationBellSelector';
import { supabaseClient } from '../../../core/supabase';
import { cvesFromWatchListAPI } from '../../../core/api/vulnerabilityApi';
import { ransomeSelector } from '../../../core/selectors/ransomeSelector';
import { brandIntelSelector } from '../../../core/selectors/brandIntelSelector';
import { threatIntelSelector } from '../../../core/selectors/threatIntelSelector';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { notifs } = useSelector(notificationSelector);
  const { domains, surfaceWeb  } = useSelector(brandIntelSelector)
  const { creditsCard } = useSelector(threatIntelSelector)

  const filterByWatchList = async (data: any[]) => {
    // Map over data and return cve_id or null for non-matching items
    const cveIdsWithNull = data.map(item => {
      return item.isVul ? item.data.cve_id : null; // Explicitly return null if not isVul
    });
  
    // Filter out null and undefined values
    const cveIds = cveIdsWithNull.filter(item => item !== null && item !== undefined);
    let response:any[] = []
    if(cveIds.length >0 )
       response = await cvesFromWatchListAPI(cveIds)
    
  

    const result = data.filter(item =>
        item.isVul ?  response.some((respItem:any) => respItem.cve_id === item.data.cve_id): item
      );

    return result
  };
  
  const filterDomains = (data:any[]) => {
    const resultsDomainWithNull = data?.filter((item: any) => 
        item?.isDomains && domains?.some(dmn => dmn?.id === item?.data?.id)
        ? item : item
    )

    const resultsDomains = resultsDomainWithNull.filter(item => item !== null && item !== undefined);
    return resultsDomains
}

const filterSurfaceWeb = (data:any[]) => {
    const resultsSurfaceWebWithNull = data?.filter((item: any) => 
        item?.isSurfaceWeb && surfaceWeb?.some(surface => surface?.id === item?.data?.id)
        ? item : item
    )

    const resultssurfaceWeb = resultsSurfaceWebWithNull.filter(item => item !== null && item !== undefined);
    return resultssurfaceWeb
}

const filterCreditsCard= (data:any[]) => {
    const resultsCreditsCardWebWithNull = data?.filter((item: any) => 
        item?.isCreditsCard && creditsCard?.data?.some(crd => crd?.cardid === item?.data?.cardid)
        ? item : item
    )

    const resultscreditsCard = resultsCreditsCardWebWithNull.filter(item => item !== null && item !== undefined);
    return resultscreditsCard
}

  useEffect(() => {
    // Fetch existing notifications
    const fetchNotifications = async () => {
      const { data, error } = await supabaseClient
        .from('Notification')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching notifications:', error);
      } else {
        const resultCVES = await filterByWatchList(data)
        const resultsDomains = filterDomains(resultCVES)
        const resultsSurfaceWeb = filterSurfaceWeb(resultsDomains)
        const resultCreditsCard = filterCreditsCard(resultsSurfaceWeb)

       dispatch({ type: SUCCESS_NOTIFICATIONS, payload: resultCreditsCard });
      }
    };

    fetchNotifications();

    // Subscribe to real-time updates
    const channel = supabaseClient
      .channel('notifications-channel')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'Notification' },
        (payload) => {
          console.log('Real-time event received:', payload);

          switch (payload.eventType) {
            case 'INSERT':
              dispatch({
                type: ADD_NOTIFICATION,
                payload: payload.new,
              });
              dispatch({
                type: REQUEST_DARK,
                payload: 'all'
            })
            dispatch({
                type: REQUEST_DOMAINE,
                payload: 'all'
            })
            dispatch({ 
                type: REQUEST_SURFACEWEB,
                payload: 'all'
            });
            dispatch({
                type: GET_ALL_VULNERABILITIES,
                payload: "all"
               })
               dispatch({
                type: REQUEST_RANSOME,
                payload: 'all'
              })
              dispatch({ type: REQUEST_CREDITS_CARD, payload: "all" })
              break;

            case 'UPDATE':
              dispatch({
                type: UPDATE_NOTIFICATION,
                payload: payload.new,
              });
              break;

            case 'DELETE':
              dispatch({
                type: DELETE_NOTIFICATION,
                payload: payload.old,
              });
              break;

            default:
              console.warn('Unhandled event type:', payload);
          }
        }
      )
      .subscribe();

    // Cleanup on unmount
    return () => {
      supabaseClient.removeChannel(channel);
    };
  }, [dispatch]);

  const notificationsUnread = notifs.filter((item) => item.read === false);
  console.log('Notifications:', notifs);

  return (
    <div className={styles.header}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className={styles.notification}
          onClick={() => {
            navigate('/notifications');
          }}
        >
          <div
            style={{ cursor: 'pointer', height: 40, width: 40 }}
            className={styles.iconHover}
          >
            <FontAwesomeIcon icon={faBell} />
          </div>
          {notificationsUnread && notificationsUnread.length > 0 && (
            <span className={styles.numberNotifications}>
              {notificationsUnread.length}
            </span>
          )}
        </div>
      </div>
      <MenuDropDownHeader />
    </div>
  );
}

export default Header;
