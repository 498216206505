import { faCamera, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import styles from '../dashboard.module.scss'
import ReactGA from 'react-ga4';
import { getTelegramsApi } from '../../../core/api/ransomeApi';
import Loader from "../../../icons/Loader.svg"
import { CircularProgress } from '@mui/material';
 
function TelegramSection() {
  const [telegrams, settelegrams] = useState<any[]>()
  const fetchTelegrams = async () => {
    const data = await getTelegramsApi()
    settelegrams(data)
  }
  useEffect(() => {
    document.title = 'Telegram'
    ReactGA.send({ hitType: "Ransomware Intel - Telegram", page: window.location.pathname });
    fetchTelegrams()
  }, [])
  
    return (
        <div style={{marginTop: 20}}>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "space-between", height:"45px"}}>
                            <div className={styles.searchAndIcon}>
                                <input className={styles.search} name='search' placeholder='Search'/>
                                <FontAwesomeIcon className={styles.searchIcon} color='#333333' icon={faSearch} size='1x'/>
                            </div>
                        </div>

                        {
                          telegrams && telegrams.length !== 0 
                          ? 
                          <table className={styles.table}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}>
                            Name {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Ransomware
                        </th>
                        <th style={{paddingLeft: 8, paddingRight: 13, textAlign:'end'}}>
                            Screen
                        </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                          {
                            telegrams?.map(item => 
                              <tr className={styles.tableActivityRowWatchList}>
                            <td style={{width: '220px', padding: '10px 16px', color:'#e9bd08'}} className={styles.tableCell}>
                                {item?.url?.split("https://t.me/")[1]}
                            </td>
                              
                              <td style={{width: '818px', padding: '10px 16px'}} className={styles.tableCell} >
                                {item?.threat_actor_name}
                              </td>
                              <td style={{width: '48px', padding: '10px 16px',}} className={styles.tableCell} >
                               <div style={{ display:"flex", justifyContent:"end", paddingRight:13}}>
                               <FontAwesomeIcon icon={faCamera} width={16} height={16} color="#afafaf"/>
                               </div>
                              </td>
                            </tr>
                            )
                          }
                          
                        
                        </tbody>
                    </table>
                    :
                    <div className={styles.loaderVul}>
    <CircularProgress />
    </div>
                        }
        </div>
    )
}

export default TelegramSection
