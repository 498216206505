import { faArrowLeft, faChevronDown, faCreditCard, faHandSparkles, faMagicWandSparkles, faWandSparkles } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from "../../dashboard.module.scss"
import Header from '../../common/Header'
import Menu from '../../common/Menu'
import UserInfoCredentials from './credentialSections/UserInfoCredentials'
import HardwareCredentials from './credentialSections/HardwareCredentials'
import { useSelector } from 'react-redux'
import { maskString } from '../CredentialsLeaks'
import { detailsSelector } from '../../../../core/selectors/detailSelector'
import { CredentialsLeak } from '../../../../core/constants'
import ReactGA from 'react-ga4';
import logoExecutive from "../../../../icons/Executive.svg"
import logoEmployee from "../../../../icons/Employee.svg"
import logoClient from "../../../../icons/Client.svg"
import moment from 'moment'
import { useIntercom } from '../../IntercomSetup'
export default function CredentialLeakDetail() {
    const navigate = useNavigate()
    const [credentialLeakDetail, setcredentialLeakDetail] = useState<any | null>()
    const [activeCompo, setactiveCompo] = useState('hardware')
    useEffect(() => {
        document.title = "Threat Intel - Credentials Details"
        ReactGA.send({ hitType: "Threat Intel - Credentials Details", page: window.location.pathname });
      const data = window.localStorage.getItem("credentialLeakDetail")
      setcredentialLeakDetail(data ?  JSON.parse(data) : null)
    }, [])

    console.log(credentialLeakDetail)
    
    const renderSections = () => {
        switch (activeCompo) {
            case 'userInfo':
                return <UserInfoCredentials/>
            case 'hardware':
                return <HardwareCredentials/>
            default:
                return;
        }
    }
    const emailStorageVa = window.localStorage.getItem('email')
      const hmacValue = window.localStorage.getItem('hmac')
      const fullNamed = window.localStorage.getItem("fullName")
      const token = window.localStorage.getItem('jwt');
      const typeEntreprise = localStorage.getItem("type") 
useIntercom('isows87y', {
app_id: 'isows87y',
api_base: 'https://api-iam.intercom.io',
email: emailStorageVa as string,
user_hash: hmacValue as string,
name: fullNamed
})
const dateFormats = [
    'DD/MM/YYYY h:mm:ss A',  // Format for '20/06/2024 7:53:01 AM'
    'M/D/YYYY h:mm:ss A',    // Format for '3/29/2022 8:57:18 AM'
    'DD.MM.YYYY HH:mm:ss',
    'DD.MM.YYYY h:mm:ss',   // Format for '20.06.2024 7:53:01'
  ];
    return (
        <div className={styles.main}>
        <Header/>
        <Menu/>
    <div className={styles.content}>
        <div className={styles.personalInfoHeader}>
                    <div >
                        <div onClick={()=>navigate("/threatIntel")} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: 10, fontSize: '12px', color: '#afafaf'}}>
                        <FontAwesomeIcon icon={faArrowLeft}/>
                        <span>Go back / {credentialLeakDetail?.passwordid}</span>
                        </div>
                    <div style={{display:'flex', alignItems: 'center', gap: 10, marginTop:4}} className={styles.personnalInfoHeadr_firstSection_personnalAccount}>
                        <span style={{fontSize: 20, fontWeight: "600"}}>{credentialLeakDetail?.username ? credentialLeakDetail?.username :"N/A"}</span>
                        <span className={styles.scoreSeverity} style={{
                            backgroundColor: credentialLeakDetail?.bgColor,
                            border: `1px solid ${credentialLeakDetail?.borderColor}`,
                            color: credentialLeakDetail?.textColor
                            }}>{Number(credentialLeakDetail?.total_risk_score)?.toFixed(1)}</span>
                    </div>  
                </div>
                    <div style={{display: "flex", alignItems: "center", gap: 10}}>
                    <div style={{marginTop: -30}}>
                    <Button onClick={()=>navigate("/threatIntel/userProfile")} style={{width: '120px', height: '40px', gap: 10, fontSize:14, fontWeight:'500', backgroundColor: "white", display: 'flex', alignItems: "center"}}>
                        User Profile
                    </Button>
                    </div>
                        </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', gap: 16, fontSize: 14, marginTop: 24}}>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Detected on</span>
                    <span style={{color: "white"}}>{moment(credentialLeakDetail?.user?.log_date, dateFormats, true).format("MMMM DD, YYYY")}</span>
                    </div>
                    <div style={{display: "flex", alignItems: 'center', gap: 5}}>
                    <span style={{color: "#afafaf"}}>Type</span>
                    <div className={styles.credentialUserType} >
                        <img src={
                            credentialLeakDetail?.typeUser === "Executive" ? logoExecutive :
                             credentialLeakDetail?.typeUser === "Employee" ? logoEmployee :
                             logoClient
                        } className={styles.logoProfileHeader} />
                        <span style={{fontSize: 12}}>{credentialLeakDetail?.typeUser}</span>
                    </div>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 30}}>
                    <div>
                       
                    <div>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>This email has appeared in 4 breaches in the last 2 months.</span>
                        
                    </div> 
                    <div style={{display: 'flex', alignItems: 'center', gap: 10,marginTop: 24}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>This password has appeared in a 20 breaches in the last 7 days.</span>
                        
                        
                    </div> 
                    {/* <div style={{display: 'flex', alignItems: 'center', gap: 10,marginTop: 24}}>
                        <div style={{height: 5, width: 5, borderRadius: '50%', backgroundColor: '#cbcbcb'}}/>
                        <span style={{color: '#cbcbcb', fontSize: 14}}>Identity document ‘National ID’ linked with this username has been detected in the dark web.</span>
                        
                    </div>  */}
                    
                    </div>
                    </div>
                    
                    <div style={{backgroundColor: "#141414", width: '420px', padding: 16, borderRadius: 8}}>
                        <div style={{display: 'flex', justifyContent: 'center',alignItems: 'center', backgroundColor: 'white', borderRadius: '50%', height:"32px", width:"32px"}}>
                            <img src="/chrome.png" alt="" width={36}/>
                        </div>
                        <div style={{marginTop: 24}}>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 12}}>
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>Username:</span>
                            <span style={{color: '#afafaf', fontSize: 14}}>{credentialLeakDetail?.username? credentialLeakDetail?.username :"N/A"}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 24}}>
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>Password:</span>
                            <span style={{color: '#afafaf', fontSize: 14}}>{credentialLeakDetail?.password}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', gap: 20, marginTop: 24}}>
                            <span style={{color: 'white', fontSize: 14, fontWeight: '500', width: 72}}>Source:</span>
                            <span style={{color: '#afafaf', fontSize: 14}}>{credentialLeakDetail?.url?.includes('android')?credentialLeakDetail?.url?.split('@')[1]:credentialLeakDetail?.url?.substring(0,43)}</span>
                        </div>
                        </div>
                    </div>
                </div>
           
                <div style={{backgroundColor:"#141414",width: 'fit-content',marginTop: 32, justifyContent:"center", borderRadius: 6, padding: '4px 6px'  }} className={styles.firstSectionMenu}>
                    <span style={{cursor: 'pointer',color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('hardware')} className={activeCompo === 'hardware' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>Hardware</span>
                <span style={{cursor: 'pointer', color: "white", fontSize: 14, fontWeight: 500}} onClick={()=>setactiveCompo('userInfo')} className={activeCompo === 'userInfo' ? styles.firstSectionMenu_item : styles.notfirstSectionMenu_item}>User Information</span>
                    </div>

                {
                    renderSections()
                }
            </div>

        </div>
    )
}
