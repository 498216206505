import { faCalendar, faChevronDown, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
import { useSelector } from 'react-redux'
import { ransomeSelector } from '../../../../core/selectors/ransomeSelector'
function Urls() {
  const { groupDetail } = useSelector(ransomeSelector)
    return (
        <table className={styles.table}style={{marginTop:25}}>
                      <thead>
                        <tr className={styles.tableHead}>
                        <th style={{paddingLeft: 8}}>
                            Title
                        </th>
                        <th style={{paddingLeft: 8}}>
                           
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Last Seen{" "}
                            <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                        </th>
                        <th style={{paddingLeft: 8}}>
                            Status {" "}
                            <FontAwesomeIcon icon={faChevronDown} width={7} height={8}/>
                        </th>
                        
                        </tr>
                      </thead>
                      <tbody>
                          <tr className={styles.tableActivityRowUsers}>
                            <td style={{width: '848px', padding: '10px 16px'}} className={styles.tableCell}>
                            {groupDetail?.title}
                            </td>
                            <td style={{width: '848px', padding: '10px 16px', color: "#e9bd08"}} className={styles.tableCell}>
                            Screenshot
                            </td>
                              <td style={{width: '190px', padding: '10px 16px'}} className={styles.tableCell} >
                                <FontAwesomeIcon icon={faCalendar} width={11} height={12} color="#afafaf"/>
                                {" "}-
                              </td>
                              <td style={{width: '80px', padding: '10px 16px'}} className={styles.tableCell} >
                                <FontAwesomeIcon icon={faCircleCheck} width={11} height={12} color="#afafaf"/>
                              </td>
                            </tr>
                          
                        
                        </tbody>
                    </table>
    )
}

export default Urls
