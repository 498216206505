import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../dashboard.module.scss'
export default function Banking() {
    return (
        <div style={{display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', marginTop: 20}}>
            <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
                    <div style={{width: '261.5px', height: '14.63vh', padding: 8, borderRadius: 8, border: "1px solid #242424"}}>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{color: 'white', fontSize: 14, fontWeight: '500'}}>Dunden Bank</p>
                            <FontAwesomeIcon color='white' icon={faCreditCard} height={24} width={27}/>
                        </div>
                        <p style={{color: 'white', fontSize: 14, marginTop: 20}}>4250 29** **** 3520</p>
                        <p style={{color: 'white', fontSize: 14}}>12/26{"  "}490</p>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p  style={{color: '#afafaf', fontSize: 12}}>US (USD)</p>
                            <p style={{color: '#afafaf', fontSize: 12}}>Business Debit</p>
                        </div>
                    </div>
        </div>
    )
}
