import React, { FormEvent, useEffect, useRef, useState } from 'react'
import styles from '../account.module.scss'
import { useDispatch } from 'react-redux'
import { getInfoSecurityPageRequest, getUserInfoRequest, sendInviteLinkRequest } from '../../../core/actions/userActions'
import { OtpRequest, Roles, SecurityPage, UserEntrepriseReq, UserInfo } from '../../../core/constants'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../core/reducers/userReducer'
import { Button, Drawer, MenuItem, Select, TextField } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { getUsersEntrepriseRequest, saveUserEntrepriseRequest } from '../../../core/actions/entrepriseActions'
import { entrepriseSelector } from '../../../core/reducers/entrepriseReducer'
import { typesHandleChange } from '../constants'
import { authSelector } from '../../../core/reducers/authReducer'
import ErrorNotification from '../common/ErrorNotification'
import * as XLSX from 'xlsx';


interface props {
  title: string;
  open: boolean;
  setOpen: any;
}



type ExcelDataRowUser = {
  email: string;
}




function HeaderUsers({ title, open, setOpen}:props) {
  const dispatch = useDispatch()
  const [shouldUpdate, setshouldUpdate] = useState<boolean>(false)
  const [openAddUserNotf, setopenAddUserNotf] = useState(false)
  const dataUser = useSelector(entrepriseSelector)
  const { users} = useSelector(entrepriseSelector)
  const { auth } = useSelector(authSelector)
  const {userInfo, roleUser} = useSelector(userSelector)
  const [emails, setEmails] = useState(['']);
  const [openNotification, setopenNotification] = useState(false)
  const [items, setItems] = useState<ExcelDataRowUser[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const addEmailField = () => {
    setEmails([...emails, '']); // Add an empty string to the emails array
  };

  const handleCloseNotification = () => {
    setopenNotification(false)
}
  const handleEmailChange = (index: number, value: string) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

    useEffect(() => {
        return
      }, [users])
      
  const handleCancel = () => {
    dispatch(getUserInfoRequest())
    setshouldUpdate(!shouldUpdate)
  }
console.log(auth)
  const rolesEntries = Object.entries(Roles).filter(([key, value]) => 
    auth.role === Roles.boClient ?  
        value !== Roles.boAdmin && 
        value !== Roles.boResellerAnalyst && 
        value !== Roles.boResellerManager
    : value !== Roles.boAdmin
    );

  const handleSubmit = () => { 
    dispatch(sendInviteLinkRequest({
      emails
    }))
    setEmails([])
    setOpen(!open)
    setopenAddUserNotf(true)
};

const handleCloseNotfAddUser = () => setopenAddUserNotf(false)


const copyOnClipBoard = () => {
  const url = `http://localhost:3000 /userInvited?fullName=${userInfo.firstName+" "+userInfo.lastName}&eid=${userInfo.entrepriseId}`
  console.log(url)
  navigator.clipboard.writeText(url)
  setopenNotification(true)
}

const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const emails = XLSX.utils.sheet_to_json<ExcelDataRowUser>(worksheet).map( item =>item.email);
      console.log(emails)
      dispatch(sendInviteLinkRequest({
        emails
      }))
    };
    reader.readAsBinaryString(file);
    setTimeout(() => {
      dispatch(getUsersEntrepriseRequest())
    }, 200);
  }
};  

const handleButtonClick = () => {
  fileInputRef.current?.click(); // Trigger the hidden file input click
};

  return (
    <form  className={styles.personalInfoHeader} onSubmit={(e)=>e.preventDefault()}>
        <div className={styles.personnalInfoHeadr_firstSection}>
            <p className={styles.personnalInfoHeadr_firstSection_settings}>Settings</p>
            <p className={styles.personnalInfoHeadr_firstSection_personnalAccount}>{title}</p>
        </div>
        <div className={styles.personnalInfoHeader_secondSection}>
            {/* <button onClick={copyOnClipBoard}   className={styles.personnalInfoHeader_secondSection_Save}>Copy link</button> */}
           { roleUser?.role === "Admin" &&
           roleUser?.userSettings &&  <button type='button' onClick={()=>setOpen(!open)}className={styles.personnalInfoHeader_secondSection_Save} style={{fontWeight:'500'}}>Invite User</button>}
        </div>
        
        
            <Drawer sx={{
    '& .MuiDrawer-paper': {
      backgroundColor: '#1a1a1a', // Drawer background color
      color: 'white', // Text color for contents
      width: '500px',
      padding: '54px 32px' // Width of the drawer
    },
  }} open={open} onClose={()=>setOpen(!open)} anchor='right'>
            <FontAwesomeIcon icon={faClose} style={{height:'1.85vh', width:'1.39vw', cursor: "pointer"}} onClick={()=>setOpen(!open)}/>
        <p className={styles.addTitleUser}>New User</p>
        <p className={styles.textAddUser}>Manually add a user or use the bulk import button to import multiple users.</p>
        {emails.map((email, index) => (
          <TextField
            key={index}
            name={`email-${index}`}
            id={`standard-basic-${index}`}
            label={index === 0 ? `Email` : `Email ${index+1}`}
            variant="standard"
            value={email}
            onChange={(e) => handleEmailChange(index, e.target.value)}
            style={{ marginBottom: '10px' }}
          />
        ))}
             <div onClick={addEmailField} style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop:15, cursor: "pointer"}}>
                <p style={{color: "white",fontSize: 14}}>Add another user</p>
                <FontAwesomeIcon icon={faPlus} style={{fontSize:12, color: "#afafaf", paddingTop:5}} />
            </div> 
            <div className={styles.saveUser}>
            <div className={styles.bottomAdd}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginTop:15}}>
                <div onClick={handleButtonClick} style={{cursor:"pointer", display: 'flex', alignItems: 'center', gap: '10px'}}>
                <p style={{color: "white",fontSize: 14}}>Bulk import users</p>
                <FontAwesomeIcon icon={faPlus} style={{fontSize:12, color: "#afafaf", paddingTop:5}} />
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }} // Hide the file input
                  accept=".xlsx,.xls"
                  onChange={handleFileUpload}
      />
            </div>
            <Button onClick={handleSubmit} style={{width:'117px', height:'40px'}}>Invite user</Button>
          </div>
        </div>
        </Drawer>
        <ErrorNotification
                severity={'success'}
                open={openNotification}
                message={'url is copied successfully '}
                handleClose={handleCloseNotification}
            />
            <ErrorNotification
                severity={'success'}
                open={openAddUserNotf}
                message={'Invitation send'}
                handleClose={handleCloseNotfAddUser}
                
            />
        </form>
  )
}

export default HeaderUsers