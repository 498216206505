import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styles from '../../../dashboard.module.scss'
export default function DataLeak() {
    return (
        <table className={styles.table}style={{marginTop:25}}>
        <tbody>
            <tr  className={styles.tableActivityRowRansomWatchList}>
              <td style={{width: '928px', padding: '0px 16px'}} className={styles.tableCell}>
              Passport
              </td>
                  <td style={{width: '190px',padding: '0px 16px'}} className={styles.tableCell}>
                     <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> April 18, 2024  
                         </p>
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '928px', padding: '0px 16px'}} className={styles.tableCell}>
            Password, hardware information
              </td>
                  <td style={{width: '190px',padding: '0px 16px'}} className={styles.tableCell}>
                     <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> April 18, 2024  
                         </p>
                  </td>
            </tr>
            <tr className={styles.tableActivityRowRansomWatchList}>
            <td style={{width: '928px', padding: '0px 16px'}} className={styles.tableCell}>
            Username, Full Name, Phone Number
              </td>
                  <td style={{width: '190px',padding: '0px 16px'}} className={styles.tableCell}>
                     <p>
                         <FontAwesomeIcon color='#afafaf' icon={faCalendar} height={12} width={11}/> April 18, 2024  
                         </p>
                  </td>
            </tr>
            
            
          </tbody>
      </table>
    )
}
